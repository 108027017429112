import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  text-align: center;
  font-family: 'Inter', sans-serif;

  .goals-sellers-step {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    h1.goals-sellers-step-title {
      color: #263238;
      font-size: 22px;
      margin-bottom: 24px;
    }

    .goals-sellers-content-boxes {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`

export const ContentBox = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 15px;

  .contentbox-title {
    display: flex;
    align-items: center;
    justify-content: center;

    h1 {
      font-size: 16px;
      color: #56676F;
    }

    span {
      background-color: #EEEEEE;
      color: #65747B;
      border-radius: 5px;
      font-weight: 600;
      font-size: 12px;
      margin-left: 10px;
      padding: 4px 7px;
    }

  }

  ul {
    list-style: none;
    border: 2px solid #EEEEEE;
    max-width: 400px;
    width: 38vw;
    height: 250px;
    padding: 0;
    border-radius: 8px;
    display: flex;
    overflow-y: scroll;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 16px;
    margin: 5px 0;

    -ms-overflow-style: none;
    scrollbar-width: 4px;
    scrollbar-scrollbar-color: #b5b5b5;

    ::-webkit-scrollbar {
      width: 4px;
    }
    
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #b5b5b5;
      border-radius: 50px;
    }

    li + li {
      margin-top: 13px;
    }
  }
`

export const CalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', sans-serif;
  position: relative;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 25px;
    text-align: center;

    h1 {
      font-size: 22px;
      color: #263238;
      text-align: center;
      width: 100%;
    }

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        padding: 5px 10px;
        border-radius: 5px;
        border: none;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        background: ${props => props.suggestionActive ? '#FAB300' : '#65747B'};
        border-bottom: ${props => props.suggestionActive ? 'none' : '2px solid #37474F'};
        border-top: ${props => props.suggestionActive ? '2px solid #d19602' : 'none'};
      }
    }
  }

  .proft-suggestion-container {
    position: absolute;
    right: 0;

    button {
      font-family: 'Inter', sans-serif;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 30px;
    margin-left: auto;

    button {
      font-family: 'Inter', sans-serif;
    }

    
    .create-period-button {
      padding: 8px 10px;
      background: none;
      border-radius: 5px;
      font-weight: 600;
      text-transform: uppercase;
      color: #702cb9;
      border: none;
      cursor: pointer;
      margin-right: 10px;
      
      span {
        background: #EEEEEE;
        padding: 2px 7px;
        border-radius: 50%;
        color: #702CB9;
        margin-right: 5px;
      }
    }

    .create-period-button-need-review {
      background-color: #702CB9;
      color: white;
    }

    .create-period-button-completed {
      background-color: #e5e5e5;
      color: #702CB9;

      span {
        background: #00b362;
        color: white;
      }
    }
    
    .stepper-button-next {
      margin-left: 10px;
      margin-top: 0;

      &:disabled {
        opacity: 1;
        background-color: lightgray;
        color: #a1a1a1;
      }
    }
  }
`

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  font-family: 'Inter', sans-serif;

  .top {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 30px;

    .title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      h1 {
        margin: 0;
        font-size: 20px;
        margin-bottom: 10px;
      }

      span {
        font-size: 12px;
        color: #546E7A;
      }
    }

    .close-button {
      color: lightgray;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
    }
  }

  .center {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    .calendar {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      span {
        text-align: center;
        font-size: 13px;
        margin-top: 10px;
        color: #9E9E9E;
      }
    }

    .sections-table {
      margin-top: 7px;
      margin-left: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .title {
        display: grid;
        grid-template-columns: 30px 150px 80px 30px;
        align-items: center;
        border-bottom: 1px solid #EEEEEE;
        padding-bottom: 7px;
        color: #546E7A;
        font-weight: 500;

        span {
          text-align: center;
          font-size: 14px;
        }
      }

      .no-table {
        background-color: #FAFAFA;
        padding: 10px 30px;
        text-align: center;
        color: #546E7A;
        font-weight: 500;
        font-size: 12px;
        margin: 10px auto;
        border: 1px solid #e5e5e5;
        border-radius: 5px;
      }

      .table {
        height: 230px;
        min-height: 230px;
        max-height: 230px;
        overflow-y: scroll;
        position: relative;
        scrollbar-width: none;
        -ms-overflow-style: none;

        
        -ms-overflow-style: none;
        scrollbar-width: 4px;
        scrollbar-scrollbar-color: #b5b5b5;

        ::-webkit-scrollbar {
          width: 4px;
        }
        
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background: #b5b5b5;
          border-radius: 50px;
        }

        &::--webkit-srollbar {
          display: none;
        }

        .section {
          display: grid;
          grid-template-columns: 30px 150px 80px 30px;
          align-items: center;
          text-align: center;
          background-color: #F5F6F8;
          padding: 5px;
          margin: 5px auto;
          color: #65747B;
          border-radius: 5px;
          font-size: 14px;
          font-weight: 500;

          .index {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            padding: 5px;
            background-color: #E2E4E9;
            font-size: 12px;
            font-weight: 600;
          }

          .delete-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 5px;
            padding: 5px;
            background-color: #E2E4E9;
            transition: 0.2s ease-in-out;

            .delete-icon {
              font-size: 17px;
              color: red;
              transition: 0.2s ease-in-out;
            }

            &:hover {
              background-color: #E64C3D;

              .delete-icon {
                color: white;
              }
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
    .sections-picker-info {
        max-width: 400px;
        display: flex;
        align-items: center;
        background-color: #f1f1f1;
        padding: 10px;
        border-radius: 8px;

        span {
          margin-left: 5px;
          font-size: 12px;
          text-align: left;
          font-weight: 600;
        }
      }

    .section-submit {
      cursor: pointer;
      font-family: 'Inter', sans-serif;
      padding: 10px;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      text-transform: uppercase;
      background-color: #702CB9;
      color: white;

      &:disabled {
        cursor: not-allowed;
        color: grey;
        background-color: lightgrey;
      }
    }
  }
`

export const DeleteAlertModal = styled.div`
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 15px;
  width: 400px;
  border-radius: 8px;
  color: #4f4f4f;
  border: 1px solid #999999;
  display: flex;
  font-size: 13px;
  justify-content: center;
  align-items: center;
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div {
    width: 100%;
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      font-size: 11px;
      font-style: italic;
      color: #b3b3b3;
      font-family: 'Inter', sans-serif;
    }

    button {
      cursor: pointer;
      padding: 10px;
      background-color: #702CB9;
      border: none;
      border-radius: 5px;
      color: white;
      font-weight: 600;
      font-family: 'Inter', sans-serif;
    }
  }
`