import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import Countdown from 'react-countdown';

import { Button } from '../../Components/Button';
import Input from '../../Components/Input'

import { NeoproLogo } from '../../../../assets/Icons/NeoproLogo';
import { ProftFullIcon } from 'assets/Icons/ProftFullIcon';

import api from '../../../../repositories/api';

import { colors } from '../../../../theme/colors';
import { Container } from './styles';
import { ShieldIcon } from 'assets/Icons/ShieldIcon';
import moment from 'moment';
import jwtDecode from 'jwt-decode'
import { userRequest } from 'store/modules/user/actions';
import { sendCodeRequest, userEmailRequest } from 'store/modules/onboarding/actions';
import { Check } from '@material-ui/icons';
import { MailIcon } from 'assets/Icons/MailIcon';
import { CircularProgress } from '@material-ui/core';

const EmailVerification = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { userEmail } = useParams()

  
  const user = useSelector(state => state.user.user)
  const error = useSelector(state => state.onboarding.error)
  const nextSendIn = useSelector(state => state.onboarding.nextSendIn)

  const [code, setCode] = useState()
  const [newEmail, setNewEmail] = useState()

  const [isEditingEmail, setIsEditingEmail] = useState(false)
  const [codeError, setCodeError] = useState(false)
  const [canResendCode, setCanResendCode] = useState(true)

  const [loading, setLoading] = useState(false)

  const identificationEmail = user?.identification?.email?.value || user?.email?.value || user?.email

  const handleChangeEmail = async () => {
    setLoading(true)
    try {
      const response = await api.axios.put(`/user/${user._id}`, {identification: {email: {value: newEmail}}})

      if (response.data) {
        setIsEditingEmail(false)
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false)
  }

  const submitVerification = async () => {
    setLoading(true)
    try {
      const { data } = await api.axios.put(`auth/validate`, {
        email: userEmail,
        code: parseInt(code)
      })
      
      if (data.token) {
        toast.success(
          'Seu email foi verificado com sucesso!', 
          {onClose: () => history.goBack()}
        )
      }
    } catch (error) {
      const message = error.response.data.error.friendlyMsg
      setCodeError(message)
      console.log(error)
    }
    setLoading(false)
  }

  const resendCode = () => {
    dispatch(sendCodeRequest({email: userEmail}))
    setCanResendCode(false)
  }

  useEffect(() => {
    if (user?._id) {
      if (!!user.email?.value) {
        if (user.email.value !== userEmail) { // se o email que ele ta logado nao for o email que ele passou na url
          toast.error(
            'O email não pertence ao usuário autenticado!', 
            {onClose: () => history.push('/')}
            )
        }
        // se for o mesmo email logado e url, e ja tiver validado o email
        else if (user.email?.validated) toast.success('Seu email já foi verificado!', {onClose: () => history.goBack()})
      }
    } else {
      history.push('/')
    }
  }, [user])

  useEffect(() => {
    if (error) {
      setCanResendCode(true)
      toast.error('Não foi possível enviar o código para este email')
    }
  }, [error])

  return (
    <Container>
      {user?.stores?.length === 0 && 
        <Button
          text="Voltar para inicio"
          textColor={colors.darkGrey.darkest}
          onClick={() => {
            history.push('/')	
          }}
          style={{minWidth: 0, padding: 0, position: 'absolute', top: '10px', left: '10px'}}
        />
      }
      { identificationEmail === userEmail ?
        <div className="content">
          <div style={{transform: 'scale(0.5)'}}>
            <ProftFullIcon size={[200]} color={colors.primary.main} />
            {/* <NeoproLogo color={colors.primary.main} /> */}
          </div>
          <div className="content-card">
          {!user?.email?.validated ? (
              <>
                <div className="card-title">
                  <span>{isEditingEmail ? 'Vincule seu novo email' : 'Verificar email'}</span>
                  <div className="icon">
                    { isEditingEmail ? <MailIcon /> : <ShieldIcon /> }
                  </div>
                </div>
                { !isEditingEmail ?
                  <p className="description">
                    Enviamos um código de seis dígitos para o email:
                    <strong> {newEmail || identificationEmail || '...'}</strong>
                    <span onClick={() => setIsEditingEmail(true)} className="edit-email"> (editar email)</span> 
                    <br/><br/>
                    Digite abaixo o código para validarmos a verificação do seu email!
                  </p>
                  : 
                  <p className="description">Digite abaixo o novo email que você deseja vincular a sua conta</p>
                }
                <Input
                  label={isEditingEmail ? "Novo email" : "Código de verificação"}
                  style={{margin: '10px 0px 0px'}}
                  value={isEditingEmail ? newEmail : code || ''}
                  onChange={(e) => {
                    if (isEditingEmail) setNewEmail(e.target.value)
                    else {
                      if (!!codeError) setCodeError(null)
                      setCode(e.target.value)
                    }
                  }}
                  error={codeError}
                />
                  { !isEditingEmail &&
                    <div className="resend-code">
                      <p>
                        Ainda não recebeu o código? <br/>
                        Aguarde e atualize sua caixa de entrada ou <br/>
                        envie um novo código clicando em <br/>
                        <strong>reenviar código</strong>.
                      </p>
                      <div className="countdown">
                        {!canResendCode ?
                          <Countdown 
                            date={nextSendIn}
                            onComplete={() => setCanResendCode(true)}
                            renderer={time => (
                              <span style={{cursor: 'default'}}>{`Próximo código em ${String(time.minutes).padStart(2, '0')}:${String(time.seconds).padStart(2, '0')}`}
                              </span>
                            )} 
                          />
                          : 
                          <Button 
                            text="Reenviar Código"
                            textColor={colors.secondary.main}
                            disabled={!canResendCode} 
                            onClick={resendCode}
                            margin={[0, 0]}
                            style={{padding: 0}}
                          />
                        }
                      </div>
                    </div>
                  }
              </>
            ) : (
              <div className="card-title" style={{display: 'flex', flexDirection: 'column'}}>
                <Check style={{color: colors.success, marginBottom: 10}} fontSize="large" />
                <span style={{color: colors.success, textAlign: 'center'}}>Seu email já foi verificado!</span>
              </div>
            )}
          </div>
          <div className="card-buttons">
           {isEditingEmail && <h3 onClick={() => setIsEditingEmail(false)}>Voltar</h3>}
            <Button 
              text={isEditingEmail ? "Vincular email" : "Verificar"}
              loading={loading}
              textColor={colors.lightGrey.lightest}
              backgroundColor={colors.primary.main}
              disabled={isEditingEmail ? !newEmail?.includes('@') : !code}
              onClick={isEditingEmail ? handleChangeEmail : submitVerification}
            />
          </div>
        </div>
       : <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh'}}>
          <CircularProgress />  
        </div>
      }
    </Container>
  )
}

export default EmailVerification;