import React, { useState } from 'react'

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import LinearProgress from '@material-ui/core/LinearProgress';
import ErrorIcon from '@material-ui/icons/Error';

// Components
// import DialogStoreLimit from '../DialogStoreLimit'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: 350,
    marginBottom: theme.spacing(2)
  },
  cardTitle: {
    color: '#263238',
    fontSize: 14,
  },
  usersContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardText: {
    color: '#263238',
    fontSize: 14,
    fontWeight: 'bold',
    marginRight: theme.spacing(1)
  },
  progressBar: {
    marginTop: theme.spacing(1)
  }
}));

const CustomProgressBar = withStyles({
  root: {
    marginTop: '1rem'
  },
  barColorSecondary: {
    backgroundColor: '#E64C3D'
  }
})(LinearProgress)

const StoreUsersPlan = ({ storeUsers, storePlanUsers }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false)

  return (
    <Card className={classes.root}>
      {/* <DialogStoreLimit
        onClose={() => setOpen(false)}
        onClick={() => {}} 
        open={open}
      /> */}
      <Grid container direction="column">
        <Grid item> 
          <Grid container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography className={classes.cardTitle}>Vendedores cadastrados</Typography>
            </Grid>
            <Grid>
              <Button color="default" onClick={() => setOpen(true)}>
                {storeUsers >= storePlanUsers ? 'Alterar plano' : 'Ver plano'}
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction="column" justify="flex-start">
              <Grid item>
                <div className={classes.usersContainer}>
                  <Typography className={classes.cardText}>
                    {storeUsers}/{storePlanUsers}
                  </Typography>
                  {storeUsers >= storePlanUsers && <ErrorIcon style={{ fontSize: 18 }} color="error" />}
                </div>
              </Grid>
              <Grid item>
                <CustomProgressBar variant="determinate" color={storeUsers >= storePlanUsers ? 'secondary' : 'primary'} value={Number(((storeUsers / storePlanUsers) * 100).toFixed())} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}

export default StoreUsersPlan