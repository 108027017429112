import React, { useEffect, useState } from 'react';

import StepperOption from './Components/StepperOption';

import { Container } from './style';

const StepperProgress = ({ data, activeStep, setActiveStep }) => {
  const [activeProgress, setActiveProgress] = useState(0)

  useEffect(() => {
    if (data) {
      setActiveProgress((index => index < 0 ? 4 : index)([
        !!data.month && !!data.storeId,
        typeof data.salary?.directCommission === "boolean" && typeof data.salary === "object" && typeof data.distribute === "boolean" && data.sections?.length > 0 && typeof data.config?.commission?.storeSold === 'boolean',
        data.dsr >= 0 && data.salary?.base > 0 && data.mainGoals.ticket > 0,
        data.config?.commission?.fixed?.commission >= 0 && data.days?.length > 0 && data.monthGoals?.length > 0 && data.usersWorking?.length > 0
      ].indexOf(false)))
    }
  }, [data])
  

  return (
    <Container>
      <div className='stepper-bar'>
        <div style={{ width: `${((activeProgress)/4)*100}%` }} className='progress-stepper-circle-container'>
          <div className='progress-stepper-circle' />
        </div>
        <div style={{ width: `${((activeProgress)/4)*100}%` }} className='progress-stepper-bar' />
      </div>

      <div className='stepper-options'>
        { ["Mês", "Comissão", "Valores", "Meta", "Revisão"].map((el, i) => (
            <StepperOption 
              key={i}
              active={activeStep === i}
              progressTitle={el}
              conditionToCompleted={activeProgress >= i}
              onClick={() => setActiveStep(i)}
            />  
          ))
        }
      </div>
    </Container>
  );
}

export default StepperProgress;