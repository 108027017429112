import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Inter', sans-serif;
  padding: 24px;
  background: #F6F6F6;
  border-radius: 8px;
  width: 210px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    text-align: start;

    strong {
      color: #37474F;
      font-size: 16px;
      margin-right: 8px;
    }
  }

  h1 {
    color: #702CB9;
    font-size: 22px;
    margin-top: 10px;
    margin-bottom: 0;
  }
`;
