// O filho da puta que chamar formatPriceShort, tem que receber o valor formatado com , substituido por .


export const formatPriceShort = (valor) => new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    notation: 'compact',
    compactDisplay: 'short',
    minimumFractionDigits: 1
  }).format(valor).replace(',', '.')

export const { format: formatPrice } = new Intl.NumberFormat('pt-BR', {
  style: 'currency',
  currency: 'BRL'
})

export const formatPhone = phone => String(phone)?.replace(/\D/g, '')?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');

export const ofuscateEmail = (email) => {
  if (email) {
    const emailArray = email.split('')

    return emailArray.map((letter, index) => {
      if (index < 3 || letter === "@" || index > emailArray.indexOf('@')) return letter
      return '*'
    })
  }
}

export const ofuscatePhone = (phone) => {
  if (phone) {
    const formated = String(phone)?.replace(/\D/g, '')?.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    const phoneArray = formated.split('')

    return phoneArray.map((letter, index) => {
      if (index < 4 || index > phoneArray.length - 4 || letter === '-' || letter === " ") return letter
      return '*'
    })
  }
}