import { all } from 'redux-saga/effects'

import onboarding from './onboarding/sagas'
import goal from './goal/sagas'
import input from './input/sagas'
import user from './user/sagas'
import report from './report/sagas'
import store from './store/sagas'
import main from './main/sagas'
import integration from './integration/sagas'
import day from './day/sagas'


export default function* rootSaga() {
  return yield all([onboarding, goal, input, user, report, store, main, integration, day])
}
