import React from 'react';

import { Container } from './style';


const TopTableDays = ({ topTable }) => {
  return (
    <Container topTable={topTable}>
      { topTable?.map((table, i) => (
        <span key={i}>{table}</span>
      )) }
    </Container>
  
  );
}

export default TopTableDays;