import React from 'react';
import PropTypes from 'prop-types'

import ReactToolTip from 'react-tooltip'

import { Container } from './styles'

const Tooltip = ({ id, content, place = "bottom", effect = "float"}) => {
  return (
    <Container>
      <ReactToolTip 
        className="tooltip-container" 
        id={id} 
        effect={effect}
        place={place} //top, bottom, left, right
      >
        { content }
      </ReactToolTip>
    </Container>
  );
}

Tooltip.propTypes = {
  id: PropTypes.string,
  content: PropTypes.node,
  place: PropTypes.string,
}

export default Tooltip;