import React from 'react'
import PropTypes from 'prop-types'

import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

// import './styles.scss'

import { Container } from './styles'
import { useState } from 'react';
import Tooltip from '../Tooltip';

const CardCheck = ({ setIsSelected, isSelected, value, cardTitle, tooltipContent, disclaimerText, children }) => {
  return (
    <Container isSelected={isSelected}>
      <header className="card-check-header">
        <Checkbox 
          checked={isSelected}
          color="primary"
          onChange={setIsSelected}
        />
        <h1>{cardTitle}</h1>
        { tooltipContent &&
          <>
            <ErrorOutlineIcon data-tip data-for={cardTitle} style={{ color: '#C4C4C4', fontSize: 20 }}/>
            <Tooltip id={cardTitle} content={tooltipContent} />
          </>
        }
      </header>
      <span className="disclaimer">{disclaimerText}</span>
      { children &&
        <div className="card-check-children-container">
          { children }
        </div>
      }
    </Container>
  )
}

CardCheck.propTypes = {
  cardTitle: PropTypes.string,
  inputLabel: PropTypes.string,
  disclaimerText: PropTypes.string,
  value: PropTypes.number,
  setValue: PropTypes.func,
}

export default CardCheck