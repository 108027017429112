import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useHistory } from 'react-router-dom'

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Button,
  Divider,
  List,
  ListItem,
  Typography,
  ListItemText
} from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import { formatPrice } from '../../../utils/format'

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  content: {
    padding: 0
  },
  image: {
    height: 48,
    width: 48
  },
  actions: {
    justifyContent: 'flex-end'
  },
  place: {
    paddingRight: 10,
    paddingLeft: 10
  }
}))

const Ranking = props => {
  const { className, sellers = [], storeId, ...rest } = props

  const classes = useStyles({})
  const history = useHistory()

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader subtitle={`${sellers.length} vendedores`} title='Ranking' />
      <Divider />
      <CardContent className={classes.content}>
        <List>
          {sellers.length > 0 ? (
            [...sellers]
              .sort((a, b) => b.total.sold - a.total.sold).splice(0, 4)
              .map((seller, i) => (
                <ListItem divider={i < sellers.length - 1} key={i}>
                  <Typography
                    style={{ color: '#682AC7' }}
                    variant='h2'
                    className={classes.place}
                  >
                    {i + 1}˚
                  </Typography>
                  <ListItemText
                    primary={<>
                      <Typography variant="h6" style={{ display: 'inline-block' }}>{`${seller.name.first} -`}</Typography>
                      <Typography variant="h6" style={{ paddingLeft: 3, display: 'inline-block', color: ((value) => (value >= 100 ? '#27ae60' : value >= 80 ? '#f39c12' : '#e74c3c'))(seller.total.sold / seller.metric.acDayGoal * 100) }}>
                        {`${(((seller.total.sold / seller.metric.acDayGoal) || 0) * 100).toFixed(2)}%`}
                      </Typography>
                    </>}
                    secondary={`${formatPrice(seller.total.sold)} vendidos. (${seller.projection.goal.name})`}
                  />
                </ListItem>
              ))
          ) : (
              <ListItem key={0}>
                <ListItemText secondary='Não há meta para esse mês.' />
              </ListItem>
            )}
        </List>
      </CardContent>
      <Divider />
      <CardActions className={classes.actions}>
        <Button
          color='primary'
          size='small'
          variant='text'
          onClick={() => history.push(`/${storeId}/report`)}
        >
          Ver relatório <ArrowRightIcon />
        </Button>
      </CardActions>
    </Card>
  )
}

Ranking.propTypes = {
  className: PropTypes.string
}

export default Ranking
