import React from 'react';

import { Container } from './styles';

const CardForm = ({ title, description, icon, children }) => {
  return (
    <Container>
      <div className="top-title">
        <div className="title">
          <h1>{title}</h1>
          { icon &&
            <div className="icon">
              {icon}
            </div>
          }
        </div>
        { description && <span>{description}</span>}
      </div>

      { 
        children &&
        <div className="card-children">{children}</div>
      }
    </Container>
  )
}

export default CardForm;