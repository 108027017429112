import styled, { css } from 'styled-components';
import { colors } from '../../../../theme/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: ${({margin}) => margin ? `${margin[0]}px ${margin[1]}px` : '10px 0px'};
  width: 100%;

  ${props => props.disabled && css`
    opacity: 0.4;
  `}

  .error-container {
    margin-left: auto;
    text-align: end;
  }
`

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-left {
    position: absolute;
    left: 0;
    margin-left: 10px;
    margin-bottom: -3px;
    top: 50%;
    transform: translateY(-40%)
  }

  .icon-right {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-40%);
    margin-right: 10px;
    cursor: pointer;
  }

  .MuiFormControl-root {
    display: flex !important;
    align-items: center !important;
    width: 100% !important;
  }

  .MuiInputBase-root,
  .MuiOutlinedInput-root{
    width: 100% !important;
    border-radius: 10px !important;
    padding-left: ${({hasIcon}) => hasIcon[0] ? '40px' : '0px'} !important;
    padding-right: ${({hasIcon}) => hasIcon[1] ? '22px' : '0px'} !important;
    color: ${colors.darkGrey.darkest} !important;
    font-family: 'Commissioner' !important;
    font-weight: 600 !important;
    letter-spacing: 0.2px !important;
    font-size: 15px !important;

    .MuiInputBase-input {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      min-height: 20px;
      max-height: 20px;
      padding: 15px 15px 15px ${({hasIcon}) => hasIcon[0] ? '0px' : '15px'} !important;
      cursor: ${({selectable, disabled}) => selectable ? 'pointer' : disabled ? 'not-allowed' : 'auto'} !important;

    }
  }

  .MuiFormLabel-root {
    padding-left: ${({hasIcon})=> hasIcon[0] ? '22px' : '0px'} !important;
    font-family: 'Inter' !important;
    color: #9A9AB1 !important;
    font-weight: 500 !important;
    margin-top: -3px !important;
  }

  .MuiOutlinedInput-root.Mui-focused,
  .MuiOutlinedInput-notchedOutline,
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    border-color: ${({error}) => error ? colors.danger.dark : colors.primary.main} !important;
  }
  
  .MuiFormLabel-root.Mui-focused, 
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    color: ${({error}) => error ? colors.danger.dark : colors.primary.main} !important;
    padding-left: 0px !important;
    font-weight: 600 !important;
    font-family: 'Commissioner' !important;
    letter-spacing: 0.5px !important;
    margin-top: 0px !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: ${({error}) => error ? colors.danger.dark : colors.lightGrey.main} !important;
    border-width: 2px !important;
    transition: 0.4s ease-in-out !important;
  }

  `;
