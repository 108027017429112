export function requestLoading() {
  return {
    type: "@onboarding/REQUEST_LOADING",
  }
}

export function stopLoading() {
  return {
    type: "@onboarding/STOP_LOADING",
  }
}

export function sendCodeRequest({phoneNumber, email}) {
  return {
    type: "@onboarding/SEND_CODE_REQUEST",
    payload: { phoneNumber, email }
  }
}

export function emailSuccess(data) {
  return {
    type: "@onboarding/EMAIL_SUCCESS",
    payload: { data }
  }
}

export function phoneNumberSuccess(data) {
  return {
    type: "@onboarding/PHONE_NUMBER_SUCCESS",
    payload: { data }
  }
}

export function codeRequestFailed(message) {
  return {
    type: "@onboarding/CODE_REQUEST_FAILED",
    payload: { message }
  }
}

export function clearEmail() {
  return {
    type: "@onboarding/EMAIL_CLEAR",
  }
}

export function clearPhoneNumber() {
  return {
    type: "@onboarding/PHONE_NUMBER_CLEAR",
  }
}