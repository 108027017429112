import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// Libs
import moment from 'moment'
import jwtDecode from 'jwt-decode'

// Redux
import { inputListRequest } from 'store/modules/input/actions'
import { monthRequest } from '../../store/modules/report/actions'

// Material UI
import { makeStyles } from '@material-ui/styles'
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined'
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import CardHeader from '@material-ui/core/CardHeader'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import RefreshIcon from '@material-ui/icons/Refresh'

// Components
import ValueCard from 'components/ValueCard'
import Ranking from './components/Ranking'
import Performance from './components/Performance'
import Notifications from '../../components/Notifications'
import InputsTable from '../../components/InputsTable'

// Utils
import { formatPrice } from '../../utils/format'
import { clearRedux } from 'store/modules/main/actions'
import { format, getMonth } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  },
  title: {
    padding: theme.spacing(1)
  },
  headerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
  }
}))

const Dashboard = ({ match }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const history = useHistory()

  const inputList = useSelector(state => state.input.inputList)
  const metadata = useSelector(state => state.input.metadata)
  const monthReport = useSelector(state => state.report.monthReport)
  const goalList = useSelector(state => state.goal.goalList)
  const currentGoal = useSelector(state => state.goal.currentGoal)
  const store = useSelector(state => state.store.store)

  const nextMonth = moment().add(1, 'months').startOf('month')

  const token = localStorage.getItem('@Proft:token')
  const { storeId } = match.params
  

  const [performanceData, setPerformanceData] = useState({})

  useEffect(() => {
    if (currentGoal) {
      if (monthReport && inputList) {
        let newData = {}
        const workingDays = currentGoal.days.filter(day => day.working)
        const workingDaysDates = workingDays.map(day => moment(day.date).unix())

        newData.labels = workingDays.map(day => moment(day.date).format('DD - ddd'))
        newData.goals = workingDays.map(day => day.goal.toFixed(2))

        newData.sold = Array(workingDays.length).fill(0)
        const thisMonthInputs = inputList.filter(input => moment(input.date) >= moment(workingDays[0].date)).reverse()

        if (thisMonthInputs.length > 0) {
          thisMonthInputs.forEach(input => {
            const i = workingDaysDates.findIndex(day => day === moment(input.date).unix())
            newData.sold[i] = input.total.sold.toFixed(2)
          })

          newData.future = Array(workingDays.length).fill(0)
          const todayIndex = workingDaysDates.findIndex(day => day === moment(monthReport.store.metric.today).unix())
          newData.future[todayIndex] = monthReport.store.sell.toFixed(2)

          monthReport.store.next.forEach(future => {
            const i = workingDaysDates.findIndex(day => day === moment(future.date).unix())
            newData.future[i] = Number(future.sell).toFixed()
          })
        }

        setPerformanceData(newData)
      }

      if (!monthReport) {
        dispatch(monthRequest(storeId, token))
      }
    }
  }, [currentGoal, monthReport, inputList])

  useEffect(() => {
    if (metadata.pagination.currentPage > 1) dispatch(inputListRequest(storeId, token, 1, 35))
  }, [metadata])

  useEffect(() => {
    if (goalList?.length > 0) {
      if (
        new Date(goalList[0].month).getMonth() 
        && new Date().getMonth()
        && !goalList[0].config.active
       ) confirm({
          title: "Meta em rascunho",
          description: `A sua meta de ${format(new Date(goalList[0].month), 'MMMM', {locale: ptBR})} 
          está em rascunho. Finalize-a para ativá-la!`
        }).then(() => history.push(`/${store?._id}/goals`)).catch(() => {})
    }
  }, [goalList])

  return (
    <div className={classes.root}>
      <div className={classes.headerBox}>
        <Typography variant="h3" className={classes.title}>{((name) => (name.charAt(0).toUpperCase() + name.slice(1)))(currentGoal ? moment(currentGoal.month).format('MMMM/YYYY') : moment().format('MMMM/YYYY'))}</Typography>
        <Button color='primary' onClick={() => {
          dispatch(monthRequest(storeId, token))
          dispatch(inputListRequest(storeId, token, 1, 35))
        }} className={classes.refresh}>
          <RefreshIcon />
        </Button>
      </div>
      <Notifications storeId={storeId} hasGoalInNextMonth={goalList && !!goalList.find(goal => goal.month === nextMonth.toISOString())} />
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title='Faturamento'
            value={`${monthReport ? formatPrice(monthReport.store.total.sold) : 0
              }`}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title='Percentual da meta'
            value={`${(monthReport?.store?.metric?.acDayGoal > 0 ? (monthReport.store.total.sold / monthReport.store.metric.acDayGoal * 100) : 0).toFixed(2)}%`}
            icon={<InsertChartIcon />}
            color='#682AC7'
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title='Projeção'
            value={`${monthReport ? formatPrice(monthReport.store.projection.sold) : 0}`}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ValueCard
            title={`Meta do dia${monthReport ? ` ${moment(monthReport.store.metric.today).format('DD/MM')}` : ''}`}
            value={`${monthReport ? formatPrice(monthReport.store.sell > 0 ? monthReport.store.sell : 0) : 0}`}
            color='#682AC7'
            inversed
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <Performance
            data={performanceData}
          />
        </Grid>
        <Grid item lg={4} md={12} xl={3} xs={12}>
          <Ranking storeId={storeId} sellers={monthReport ? monthReport.sellers : []} />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <Card>
            <CardHeader title='Últimos lançamentos' />
            <InputsTable min inputs={inputList} metadata={metadata} storeId={storeId}/>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
