// import createStore from './createStore';
import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import LogRocket from 'logrocket';

import rootReducer from './modules/rootReducer'
import rootSaga from './modules/rootSaga'

const sagaMonitor =
  process.env.NODE_ENV === 'development'
    ? console.tron.createSagaMonitor()
    : null

const sagaMiddleware = createSagaMiddleware({
  sagaMonitor
})

const enhancer =
  process.env.NODE_ENV === 'development'
    ? compose(console.tron.createEnhancer(), applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware()))
    : applyMiddleware(sagaMiddleware, LogRocket.reduxMiddleware())

const store = createStore(rootReducer, enhancer)

sagaMiddleware.run(rootSaga)

export default store
