// Libs
import React, { useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';

// Utils
import { cpfMask, phoneMask } from '../../../utils/inputMasks'
import api from '../../../repositories/api'

// Components
import UserPermissions from './UserPermissions'
import { Box, Chip, CircularProgress, FormHelperText, InputLabel, OutlinedInput } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { storeListRequest, storeRequest } from 'store/modules/store/actions';
import { useParams } from 'react-router';
import jwtDecode from 'jwt-decode'
import { userAddRequest, userRequest } from 'store/modules/user/actions';
import { Close, PersonAdd } from '@material-ui/icons';
import StoreUsersPlan from './StoreUsersPlan';
import Users from 'views/Input/components/Users';
import { colors } from 'theme/colors';
import { toast } from 'react-toastify';
import PromptModal from 'components/PromptModal';
import { formatPrice } from 'utils/format';
// import StoreUsersPlan from '../StoreUsersPlan'


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  cardContainer: {
    padding: theme.spacing(3)
  },
  sectionTitle: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: '16px',
    marginBottom: theme.spacing(1.5)
  },
  radioButton: {
    border: '2px solid #C4C4C4',
    width: '170px',
    borderRadius: '5px',
    marginRight: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  limitWarning: {
    padding: 5,
    backgroundColor: colors.danger.dark,
    fontFamily: 'Inter',
    color: '#FFFFFF',
    fontWeight: 600,
    fontSize: 12,
    borderRadius: 3,
  }
}))

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const AddUserForm = ({ 
  setAddModal,
  searchLoading,
  handleCheckCpf,
  error,
  currentGoal,
  linkUser,
  addUser 
}) => {
  const dispatch = useDispatch()
  const { storeId } = useParams()
  const classes = useStyles()
  const { register, control, watch } = useForm({
    defaultValues: {
      type: 'seller'
    }
  });
  const type = watch('type')

  const token = localStorage.getItem('@Proft:token')
  const user = jwtDecode(token)
  
  const stores = useSelector(state => state.user.user.stores)
  const store = useSelector(state => state.store.store)
  const wallet = useSelector(state => state.user.wallet)

  const sellersFromStore = store?.users.filter(user => (user.stores.find(s => s.storeId === store._id).type === "seller"))

  const USER_INITIAL_STATE = {
    name: {
      first: null,
      last: null,
    },
    identification: {
      phone: {
        value: null,
      }
    },
    type: 'seller',
    stores: []
  }
  const [data, setData] = useState(USER_INITIAL_STATE)
  const [selectedStores, setSelectedStores] = useState([])
  const [sellerCreationModal, setSellerCreationModal] = useState(false)

  const onSubmit = async ({repeat=false}) => {
    try {
      const userData = {
        name: data.name,
        identification: {phone: {value: data.identification.phone.value}},
        stores: selectedStores.map(store => ({storeId: store.storeId._id, type: data.type})),
        creator: user.id,
      }
  
      addUser(userData)
      
      if (!!repeat) setData(USER_INITIAL_STATE)
      else {
        setAddModal(false)
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const user = jwtDecode(token)
    if (stores?.length === 0) dispatch(userRequest(user.id, token))
  }, [stores])

  const createdMaxUserLimit = wallet?.balance < store?.financial?.sellerValue
  //sellersFromStore?.length > 0 && sellersFromStore?.length >= store?.maxSellers

  console.log(error);

  return (
    <Grid container spacing={4}>
      <Grid item xs={12} md={8} lg={8}>
        { createdMaxUserLimit &&
          <span className={classes.limitWarning}>Você não possui saldo suficiente para criar um novo usuário!</span>
        }
        <Card className={classes.cardContainer}>
          <Grid  container>
            <Grid item xs={12}>
              <Typography  className={classes.sectionTitle}>Função do usuário</Typography>
              <Grid
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                  <FormControl 
                    component="fieldset"
                    onChange={e => setData({...data, type: e.target.value})}
                  >
                    <Controller
                      rules={{ required: true }}
                      control={control}
                      defaultValue="business"
                      name="type"
                      as={
                        <RadioGroup
                          aria-label="type"
                          style={{ display: 'flex', flexDirection: 'row', gap: 15 }}
                        >
                          <FormControlLabel
                            value="seller"
                            control={<Radio color="primary"/>}
                            className={classes.radioButton}
                            label="Vendedor"                         
                          />
                          <FormControlLabel
                            value="sellerVr"
                            control={<Radio color="primary"/>}
                            className={classes.radioButton}
                            label="Vendedor VR"
                          />
                          <FormControlLabel
                            value="manager"
                            control={<Radio color="primary"/>}
                            className={classes.radioButton}
                            label="Gerente"
                          />
                          <FormControlLabel
                            value="super"
                            control={<Radio color="primary"/>}
                            className={classes.radioButton}
                            label="Supervisor"
                          />
                        </RadioGroup>
                      }
                    />
                  </FormControl>
              </Grid>
            <Divider className={classes.divider}/>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle}>Nome do usuário</Typography>
              <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12} md={6}>
                  <TextField 
                    label="Nome"
                    name="firstName"
                    variant="outlined"
                    inputRef={register({ required: true })}
                    fullWidth
                    value={data.name.first}
                    onChange={e => setData({...data, name: {...data.name, first: e.target.value}})}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField 
                    label="Sobrenome"
                    name="lastName"
                    variant="outlined"
                    value={data.name.last}
                    inputRef={register({ required: true })}
                    fullWidth
                    onChange={e => setData({...data, name: {...data.name, last: e.target.value}})}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography  className={classes.sectionTitle} style={{ marginTop: '1rem' }}>Telefone</Typography>
              <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12}>
                  <TextField 
                    label="Celular"
                    variant="outlined"
                    name="phone"
                    fullWidth
                    value={data.identification.phone.value}
                    onChange={e => setData({...data, identification: {phone: {value: (e.target.value).replace(/\D/g, '')}}})}
                    inputRef={register({ required: true })}
                    InputProps={{
                      inputComponent: phoneMask
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.sectionTitle} style={{ marginTop: '1rem' }}>CPF (opcional)</Typography>
              <Grid 
                container
                direction="row"
                justify="center"
                alignItems="center"
                spacing={3}
              >
                <Grid item xs={12}>
                  <TextField 
                    label={searchLoading ? <CircularProgress size={20}/> : 'CPF'}
                    variant="outlined"
                    name="cpf"
                    fullWidth
                    value={data.cpf}
                    onChange={e => {
                      const value = (e.target.value).replace(/[-._]/g, '')
                      setData({...data, cpf: value})
                      value.length === 11 && handleCheckCpf(value)
                    }}
                    InputProps={{
                      inputComponent: cpfMask,
                    }}
                  />
                  {/* <FormHelperText error={error}>{error?.error?.friendlyMsg}</FormHelperText> */}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography  className={classes.sectionTitle} style={{ marginTop: '1rem' }}>Vincular à loja</Typography>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="multiple-label">Selecione as lojas</InputLabel>
                <Select
                  labelId="multiple-label"
                  id="multiple-chip"
                  multiple
                  value={selectedStores}
                  input={<OutlinedInput label="Lojas selecionadas" />}
                  renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                      {selectedStores.map(store => (
                        <Chip
                          style={{margin: '0 5px'}}
                          key={store.storeId.id} 
                          label={store.storeId.name} 
                        />
                      ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}
                >
                  {stores?.length > 0 && stores.map(store => (
                    <MenuItem
                      key={store.storeId._id}
                      value={store.storeId.name}
                      style={{margin: '5px 0px', background: selectedStores.find(s => s === store) ? '#E0E0E0' : '#FFFFFF'}}
                      onClick={() => {
                        if (selectedStores.includes(store)) setSelectedStores(s => s.filter(s => s.id !== store.id))
                        else setSelectedStores(s => [...s, store])
                      }}
                    >
                      {!selectedStores.includes(store) ? <CheckIcon /> : <Close />}
                      {store.storeId.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Card>
        <Grid 
          container
          justify="flex-end"
          alignItems="center"
          style={{ marginTop: '1rem' }}
        >
          <Button
            variant="outlined"
            endIcon={<AddIcon />}
            style={{ marginRight: '1rem' }}
            onClick={() => onSubmit({repeat: true})}
            disabled={
              data.cpf?.length === 11 && !!error ||
              sellersFromStore?.length >= store?.maxSellers ||
              !data.name.first || !data.name.last ||
              selectedStores.length === 0 ||
              data.identification.phone.value.length !== 11
            }
          >
            CADASTRAR E ADICIONAR NOVO
          </Button>
          <Button
            variant="contained"
            color="primary"
            endIcon={<CheckIcon />}
            onClick={() => setSellerCreationModal(true)}
            disabled={
              (!!data?.cpf && data?.cpf?.length === 11 && !!error) ||
              createdMaxUserLimit ||
              !data.name.first || !data.name.last ||
              selectedStores.length === 0 ||
              data.identification.phone.value.length !== 11
            }
          >
            CADASTRAR USUÁRIO
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} lg={4}>
        {/* {createdMaxUserLimit && <StoreUsersPlan onClick={() => {}} storeUsers={sellersFromStore?.length} storePlanUsers={store?.maxSellers} />} */}
        <UserPermissions type={type}/>
      </Grid>
      <PromptModal
        icon={<PersonAdd style={{fontSize: 40}} color="primary" />}
        title="Confirmar criação de usuário"
        open={!!sellerCreationModal}
        description={
          `A criação de um novo usuário para a sua loja custa ${formatPrice(store.financial.sellerValue)} e será descontado de seu saldo de Créditos Proft!`
        }
        rightTitle="Confirmar criação"
        onRight={onSubmit}
        onClose={() => setSellerCreationModal(false)}
        disableRight={store.financial.sellerValue > wallet}
      />
    </Grid>
  )
}

export default AddUserForm