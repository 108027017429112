import styled, { css } from "styled-components";

export const StepperOptionContainer = styled.div`
  display: flex;
  align-items: center;
  
  ${props => props.active ? css`
      background-color: #AC2FAE;
      cursor: pointer;
      color: white;
      padding: 5px;
      border-radius: 5px;
    ` 
    : props.completed && css`
      cursor: pointer;
      color: #702CB9;
    `
  }

  span {
    font-weight: 500;
  }

  .edit-icon {
    font-size: 14px;
    margin-right: 5px;
    cursor: pointer;
    color: ${props => props.active ? 'white' : '#702CB9'}
  }
`;