import React from 'react'

// Material UI
import { makeStyles } from '@material-ui/styles'

// Components
import InputsTable from '../../components/InputsTable'
import { Toolbar, AddInput } from '../../components'

// Redux
import { useSelector, useDispatch } from 'react-redux'
import {
  inputListRequest,
} from '../../store/modules/input/actions'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

export default function Inputs({ match }) {
  const dispatch = useDispatch()
  const classes = useStyles()

  // Input - Redux
  const inputList = useSelector(state => state.input.inputList)
  const metadata = useSelector(state => state.input.metadata)

  // Store - Redux
  const store = useSelector(state => state.store.store)

  const { storeId } = match.params
  const token = window.localStorage.getItem('@Proft:token')

  const onRefresh = () => {
    dispatch(
      inputListRequest(
        storeId,
        token
      )
    )
  }

  const handleChangePage = (page) => {
    if (page + 1 <= metadata.pagination.pageCount) {
      dispatch(inputListRequest(
        storeId,
        token,
        page + 1,
        metadata.pagination.limit
      ))
    }
  }

  const handleChangeRows = (rowsNumber) => {
    dispatch(inputListRequest(
      storeId,
      token,
      metadata.pagination.currentPage,
      Number(rowsNumber.props.value)
    ))
  }

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <Toolbar onRefresh={onRefresh}>
          {store && store.integration?.provider && (
            <AddInput />
          )}
        </Toolbar>
        <InputsTable
          inputs={inputList}
          storeId={storeId}
          onChangeRows={handleChangeRows}
          onChangePage={handleChangePage}
          metadata={metadata}
        />
      </div>
    </div>
  )
}
