import React from 'react'

// Libs
import Lottie from 'react-lottie';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

// Assets
import gitftAnimation from '../../assets/giftRewardAnim.json'
import { useDispatch, useSelector } from 'react-redux';
import { getCreditsRequest } from 'store/modules/user/actions';


const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: 10, 
  },
  description: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))

const DialogFreeCredits = ({ open }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: gitftAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    },
  };

  const token = localStorage.getItem('@Proft:token')

  const user = useSelector(state => state.user.user)

  const handleRedeemFreeCredits = () => {
    const data = {
      name: "welcome",
      type: "event"
    }
    dispatch(getCreditsRequest(user._id, data, token))
  }

  return (
    <Dialog
      open={open}
    >
      <DialogContent className={classes.root}>
        <Grid container justify="center" alignItems="center" direction="column">
          <Grid item xs={12}> 
            <Grid container justify="center" alignItems="center">
              <Lottie 
                height={300}
                width={300}
                style={{marginTop: -100}}
                isPaused={false}
                isStopped={false}
                isClickToPauseDisabled
                options={defaultOptions}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Typography className={classes.title}>Você ganhou um presente!!</Typography>
            </Grid>
          </Grid>          
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Typography className={classes.description}>
                Olá, estamos muito felizes por você estar aqui junto conosco. <br /> 
                <strong>Guardamos um presente</strong> para quando você chegasse! <br />
                Aqui está <strong>R$ 200,00 em créditos Proft</strong> <br />
                para você usar na plataforma!
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container justify="center" alignItems="center">
              <Typography className={classes.description}>
                Esses créditos podem ser utilizados para <strong>cadastrar novos vendedores</strong> <br/>
                (e em breve para novas funcionalidades). 
                <br />
                <br />
                <strong>Aproveite e cadastre sua loja agora mesmo!</strong>
              </Typography>
            </Grid>
          </Grid>
          <Button
            variant="contained"
            endIcon={<AttachMoneyIcon />}
            color="primary"
            onClick={handleRedeemFreeCredits}
          >
            Resgatar meus créditos
          </Button>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default DialogFreeCredits