import api from '../../../repositories/api'
import { all, call, put, takeLatest } from "redux-saga/effects";
import { requestFailed } from "../error/action";
import { codeRequestFailed, emailSuccess, phoneNumberFailed, phoneNumberSuccess } from "./actions";
import { toast } from 'react-toastify';

export function* sendCodeRequest({ payload }) {
  try {
    const { phoneNumber, email } = payload

    const response = yield call(api.axios.post, `user/activateAccount`, 
      !!phoneNumber ? {phone: phoneNumber} : !!email && {email}
    )

    toast.success("Código enviado com sucesso!")

    if (response.data.phone) yield put(phoneNumberSuccess({
      phone: response.data.phone,
      nextUpdateAt: response.data.nextUpdateAt,
    }))
    if (email && response.data.email) yield put(emailSuccess(response.data.email))
  } catch (e) {
    yield put(codeRequestFailed(e.response.data?.error?.friendlyMsg))
    if (e.response && e.response.status >= 500) {
      yield put(
        requestFailed({
          error: {
            friendlyMsg: e.response.data?.error?.friendlyMsg || 'Servidor fora do ar.'
          }
        })
      )
    } else {
      if (e.code === 'ECONNABORTED') {
        yield put(
          requestFailed({
            error: {
              friendlyMsg: 'Internet instável, não foi possível conectar.'
            }
          })
        )
      } else {
        yield put(
          requestFailed(
            e.response && e.response.data
              ? e.response.data
              : {
                error: {
                  friendlyMsg: 'Alguma coisa deu errado...',
                  err: e
                }
              }
          )
        )
      }
    }
  }
}

export default all([
  takeLatest('@onboarding/SEND_CODE_REQUEST', sendCodeRequest),
])
