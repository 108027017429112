import React, { useState } from 'react'

// Material UI
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Slider from '@material-ui/core/Slider';

// Utils
import { formatPrice } from '../../utils/format'
import { colors } from 'theme/colors';

const marks = [
  {
    value: 0,
    label: '1 a 5',
  },
  {
    value: 33,
    label: '6 a 10',
  },
  {
    value: 66,
    label: '11 a 20',
  },
  {
    value: 100,
    label: '21 a 30',
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  sliderWrapper: {
    minWidth: 500,
    marginTop: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  title: {
    color: '#263238',
    fontWeight: 'bold',
    fontSize: 16,
    alignSelf: 'left'
  },
  text: {
    color: '#546E7A',
    fontSize: 14,
  },
  planPriceWrapper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    border: '2px solid #FFC700',
    borderRadius: 5
  },
  planPriceTitle: {
    fontSize: 26,
    color: '#263238',
    fontWeight: 700
  },
  planPriceSubTitle: {
    fontSize: 14,
    color: '#263238',
    marginTop: theme.spacing(0.5)
  },
  balance: {
    color: '#263238',
    fontSize: 12,
    marginTop: theme.spacing(1),
    fontWeight: 700
  },
  disclaimer: {
    fontSize: 12,
    color: '#546E7A',
    textAlign: 'center',
    marginTop: theme.spacing(1)
  }
}));

const CustomSlider = withStyles({
  root: {
    color: '#DDDDDD',
    height: 2,
    padding: '15px 0',
    width: '80%',
  },
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#702CB9',
    marginTop: -7,
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 12px)',
    top: -22,
  },
  track: {
    height: 2,
  },
  rail: {
    height: 3,
    opacity: 0.5,
    backgroundColor: '#DDDDDD',
  },
  mark: {
    backgroundColor: '#fff',
    height: 12,
    width: 12,
    borderRadius: 20,
    marginTop: -5,
    marginLeft: -2,
    border: '1px solid #DDDDDD'
  },
  markActive: {
    opacity: 1,
  },
  markLabel: {
    color: '#263238',
    marginLeft: 5,
    fontWeight: 700
  }
})(Slider);

const SellersPlanSelect = ({ creditsBalance }) => {
  const classes = useStyles();

  const [currentStep, setCurrentStep] = useState(0)
  
  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  const planPrice = {
    0: 50,
    33: 100,
    66: 250,
    100: 350,
  };

  return (
    <Card className={classes.root}>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid style={{ width: '100%' }} item>
          <Typography className={classes.title}>Número de vendedores</Typography>
          <Typography className={classes.text}>Selecione o tamanho do time de vendedores da sua loja.</Typography>
        </Grid>
        <Grid item>
          <Grid container justify="center" alignItems="center">
            <Grid item>
              <div className={classes.sliderWrapper}>
                <Typography style={{ textAlign: 'center', fontSize: 12, fontWeight: 'bold' }} id="discrete-slider-restrict">
                  Vendedores
                </Typography>
                <CustomSlider
                  defaultValue={0}
                  valueLabelFormat={valueLabelFormat}
                  aria-labelledby="discrete-slider-restrict"
                  step={null}
                  marks={marks}
                  onChange={(event, value) => setCurrentStep(Number(value))}
                />
              </div>
            </Grid>
          </Grid>
        </Grid>
            <Grid item>
              <div style={{borderColor: planPrice[currentStep] > creditsBalance && colors.danger.dark}} className={classes.planPriceWrapper}>
                <Typography className={classes.planPriceTitle}>{formatPrice(planPrice[currentStep])}</Typography>
                <Typography className={classes.planPriceSubTitle}>por mês</Typography>
              </div>
            </Grid>
        <Grid item>
          <Typography style={{color: planPrice[currentStep] > creditsBalance && colors.danger.dark}} className={classes.balance}>
            Saldo atual: {formatPrice(creditsBalance || 0)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={classes.disclaimer}>
            O valor será <strong>cobrado mensalmente</strong> do <strong>saldo de crédito disponível na sua conta</strong>.<br />
            Caso precise de um plano maior, entre em contato com um consultor de vendas Proft.
          </Typography>
        </Grid>
      </Grid>
    </Card>
  )
}

export default SellersPlanSelect