import React, { useEffect, useState } from 'react'
import { animateScroll as scroll } from 'react-scroll'
import { Element, Link } from 'react-scroll'

import format from 'date-fns/format'
import ptBR from 'date-fns/locale/pt-BR'
import { getDay } from 'date-fns/esm'
import getDate from 'date-fns/getDate'
import { endOfMonth, getWeeksInMonth, startOfMonth } from 'date-fns'
import Lottie from 'react-lottie'
import { useParams } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { storeRequest } from 'store/modules/store/actions'

// Material
import CreateIcon from '@material-ui/icons/Create'
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { Close, Info } from '@material-ui/icons'
import { CircularProgress, Modal } from '@material-ui/core'
import Delete from '@material-ui/icons/Delete';

// Components
import TransferListCard from './Components/TransferList/components/TransferListCard'
import GoalsTable from './Components/GoalsTable'
import Calendar from './Components/Calendar'
import Tooltip from '../../Tooltip'
import SectionPicker from './Components/SectionPicker'
import { SuggestionAnimation } from './Components/Calendar/Components/CardDay/style'
import PromptModal from '../../../../../../../components/PromptModal'

import { formatPrice } from '../../../../../../../utils/format'
import api from '../../../../../../../repositories/api'

// Styles
import { Container, ContentBox, CalendarContainer, ModalContainer, DeleteAlertModal } from './style'
import WandIcon from '../../../../../../../assets/Icons/WandIcon'
import wandAnimation from '../../../../../../../assets/wandAnim.json'
import { toast } from 'react-toastify'
import ToggleItem from './Components/GoalsTable/components/ToggleItem'
import { userListRequest } from 'store/modules/user/actions'

const GoalStep = (
  {
    data,
    dataMonth,
    dataSections,
    dataUsersWorking,
    dataMainGoals,
    dataDsr,
    dataDaysWeightSuggestion,
    dataMonthGoals,
    dataDays, 
    dataLastDaysBackup,
    dataConfig, 
    handleNextStep, 
    updateGoal,
    setDeleteStep,
  }
) => {
  const token = localStorage.getItem('@Proft:token')
  const { storeId, goalId } = useParams()
  
  const dispatch = useDispatch()
  const store = useSelector(state => state.store.store)
  const userList = useSelector(state => state.user.userList)

  // MonthGoals
  const [monthGoals, setMonthGoals] = useState([]);
  const [isCreatingSection, setIsCreatingSection] = useState(false)
  const [hasFixedCommission, setHasFixedCommission] = useState(false);
  const [fixedCommission, setFixedCommission] = useState(null)

  // UsersWorking
  const [users, setUsers] = useState([])
  const [usersWorking, setUsersWorking] = useState([])
  const [usersLoading, setUsersLoading] = useState(true)
  
  // Days
  const [days, setDays] = useState([]) // Days que vai ser renderizado no calendario (apenas de renderizacao)
  const [sections, setSections] = useState([]) // Sections pura pra ir pro back
  const [sectionsList, setSectionsList] = useState([]) // Sections com valores dos períodos e qtd de dias

  // Auxs
  const [suggestionModal, setSuggestionModal] = useState(false)
  const [suggestionAnimationModal, setSuggestionAnimationModal] = useState(false)
  const [totalDaysValue, setTotalDaysValue] = useState()
  const [sectionsOpened, setSectionsOpened] = useState(false)
  const [calendarLoading, setCalendarLoading] = useState(false)

  // DWT => Day, Week, Total
  const [dwt, setDwt] = useState({
    days: new Array(7).fill(0),
    weeks: new Array(getWeeksInMonth(new Date(dataMonth))).fill(0),
    total: 0
  })
  
  const animationOptions = { // Animacao da varinha de sugestao proft
    loop: true,
    autoplay: true,
    animationData: wandAnimation,
  }

  // USERSWORKING
  const handleAddToGoal = (user) => {
    setUsers(state => state.filter(u => u.userId._id !== user.userId._id))
    setUsersWorking(state => [...state, {...user, dsr: true, goalWeight: 1 }])
  }
  
  const handleRemoveFromGoal = (user) => {
    setUsersWorking(state => state.filter(u => u.userId._id !== user.userId._id))
    setUsers(state => [...state, {...user}])
  }

  const handleUpdateUserDSR = (user, dsr) => {
    const updatedUsers = [...usersWorking]
    updatedUsers.filter(userW => {
      if (userW.userId._id === user.userId._id) {
        userW.dsr = dsr
      }
    })
    setUsersWorking(updatedUsers)
  }

  const handleUpdateUserWeigth = (user, weight) => {
    const updatedUsers = [...usersWorking]
    updatedUsers.filter(userW => {
      if (userW.userId._id === user.userId._id) {
        userW.goalWeight = weight
      }
    })
    setUsersWorking(updatedUsers)
  }

  // CALENDAR
  const handleDeleteSections = (i) => () => {
    if (i > 0) {
      const updatedSections = [...sections]
      updatedSections.splice(i + 1, sections.length - i)
      updatedSections[updatedSections.length - 1].end = null

      setSections(updatedSections)
    }
    else setSections([{start: days[0].date, end: null}])
  }

  const getSuggestionWeights = async () => {
    if (dataDaysWeightSuggestion?.days?.length > 0) {
      return dataDaysWeightSuggestion
    } else {
      try {
        const { data } = await api.axios.get(`/goal/${goalId}/suggestion`)
        if (data) {
          updateGoal({daysWeightSuggestion: {...data}, config: {...dataConfig, suggestion: true}})
          return data
        } else {
          return {days:[]}
        }
      } catch (error) {
        if (error) {
          return {days: []}
        }
      }
    }
  }

  const generateDwt = d => d.reduce((acc, day) => {
      const weekStartIndex = getDay(startOfMonth(new Date(dataMonth)))
      const i = weekStartIndex + (getDate(new Date(day.date)) - 1)
      const weekIndex = Math.floor(i/7) 
      
      acc.weeks[weekIndex] += day.goal
      acc.days[i-weekIndex*7] += day.goal
      acc.total += day.goal
      
      return acc
    }, {
      days: new Array(7).fill(0),
      weeks: new Array(getWeeksInMonth(new Date(dataMonth))).fill(0),
      total: 0
  })

  const handlesuggestion = async () => { // Callback de preencher dias com a sugestao
    try {
      const suggestion = await getSuggestionWeights()
      const weights = suggestion.days
      const daysClone = [...days]

      if (weights.length === 0) {
        throw new Error()
      }

      // Auxiliares
      const daysWorking = daysClone.filter(day => day.working).length
      let notWorkAmount = daysClone.reduce((acc, day, i) => {
        if (!day.working) acc += dataMainGoals.total * weights[i]
        return acc
      }, 0)
      const amountToAdd = notWorkAmount / daysWorking

      const lastWorkingDay = [...daysClone].reverse().indexOf(day => day.working)

      // Faz a soma do proprio valor de cada dia mais a soma dos desabilitados/numero de dias
      const newDays = daysClone.map((day, i) => {
        if (day.working) {
          const newDay = { ...day, goal: (data.mainGoals.total * weights[i]) + ((i === lastWorkingDay) ? notWorkAmount : amountToAdd) }
          notWorkAmount -= amountToAdd
          return newDay
        }else{
          return day
        }
      })

      setDays(newDays)
      setDwt(generateDwt(newDays))
      
      updateGoal({daysWeightSuggestion: suggestion, config: {...dataConfig, suggestion: true}})
      setSuggestionAnimationModal(false)

    } catch (error) {
      console.log(error)
      setSuggestionAnimationModal(false)
      if (error) toast.error('Não foi possível gerar uma sugestão para esse mês')
    }
  }

  const handleOffsuggestion = (newDays) => {
    const daysWorking = [...(newDays ? newDays : days)].filter(day => day.working)
          
    let amount = dataMainGoals.total
    const part = parseFloat(amount / daysWorking.length).toFixed(2)
      
    const redistributedDays = [...days].map((day, i) => {
      if (day.working) {
        if (i !== days.findIndex(day => day === daysWorking[daysWorking.length - 1])) {
          amount -= parseFloat(part)
          return {...day, goal: parseFloat(part)}
        } else return {...day, goal: parseFloat(amount)}
      } else return {...day, goal: 0}
    })

    setDays([])
    setDays(redistributedDays)
    setDwt(generateDwt(redistributedDays))
    updateGoal({config: {...dataConfig, suggestion: false}})
  }

  const handleOpenSections = () => { // Callback de abertura do modal de sections
    setIsCreatingSection(true)
    if (dataSections) setSections(dataSections)
  }

  // OS USEEFFECTS ESTAO EM ORDEM DE ACONTECIMENTOS NA ETAPA DE META
  useEffect(() => {
    if (!store) dispatch(storeRequest(storeId, token))

    if (dataUsersWorking.length > 0) scroll.scrollToBottom()
    else scroll.scrollToTop()
  }, [])

  // DEFINE A LISTA DE USUARIOS PARA RENDERIZAR OS VENDEDORES
  useEffect(() => {
    if (store.users?.length > 0) {
      // users => lista da esquerda | usersWorking => lista da direita
      if (dataUsersWorking.length > 0) {
        setUsers(
          store.users //Todos os usuários
          .filter(user => {
            const currentStore = user.stores.find(userStore => userStore.storeId === store._id)
            return currentStore?.type === "seller" && (dataUsersWorking.length > 0 ? !dataUsersWorking.find(userW => userW.userId?._id === user._id) : true)
          }) //Seleciona só quem não estiver no usersWorking e for do tipo seller
          .map(user => ({
            userId: {
              _id: user._id, 
              name: user.name, 
              photo: user.photo,
            },
            active: true
          })) //Coloca no padrão
        )
        setUsersWorking(dataUsersWorking)
      } else {
        setUsersWorking(
          store.users //Todos os usuários
          .filter(user => {
            const currentStore = user.stores.find(userStore => userStore.storeId === store._id)
            return currentStore?.type === "seller" && (users.length > 0 ? !users.find(u => u.userId?._id === user._id) : true)
          }) //Seleciona só quem ja estiver no usersWorking (sem esse find, ele joga todo mundo pra direita, e precisa manter na esquerda quem ja tava)
          .map(user => {
            return ({
              userId: {
                _id: user._id, 
                name: user.name, 
                photo: user.photo,
              },
              goalWeight: 1,
              dsr: dataDsr > 0 ? true : false,
              active: true
            })
          }) //Coloca no padrão
        )
      }
    }
    setUsersLoading(false)
  }, [store, dataUsersWorking])

  // DEFINE OS DIAS QUE SERAO RENDERIZADOS NO CALENDARIO
  useEffect(() => {
    if (dataMonth && dataUsersWorking.length > 0) {
      if (dataDays.length === 0) {
        const date = new Date(dataMonth)
        const month = new Date(dataMonth)
        const days = [];
        while (date.getUTCMonth() === month.getUTCMonth()) {
          days.push({
            date: new Date(date), 
            goal: 0,
            working: true, 
            users: dataUsersWorking.length > 0 ? [...dataUsersWorking] : []
          })
          date.setUTCDate(date.getUTCDate() + 1)
        }

        if (dataLastDaysBackup.length > 0) { // quer dizer que ele ja avancou uma vez, e para preservar os dados e nao preencher tudo novamente quando alterar days
          setDays(dataLastDaysBackup)
          setDwt(generateDwt(dataLastDaysBackup))
        } else {
          if (dataConfig?.suggestion) {
            if (dataDaysWeightSuggestion.days?.length > 0) {
              const newDays = [...days].map((day, i) => ({...day, goal: dataMainGoals.total * dataDaysWeightSuggestion.days[i]}))
              setDwt(generateDwt(newDays))
              setDays(newDays)
            } else {
              setCalendarLoading(true)
              getSuggestionWeights().then(suggestion => {
               const newDays = [...days].map((day, i) => ({...day, goal: dataMainGoals.total * suggestion.days[i]}))
               setDwt(generateDwt(newDays))
               setDays(newDays)
               setCalendarLoading(false)
              })
            }
          } else {
            const daysWorking = days.filter(day => day.working)
            
            let amount = dataMainGoals.total
            const part = parseFloat(amount / daysWorking.length).toFixed(2)
             
            const newDays = [...days].map((day, i) => {
              if (day.working) {
                if (i !== days.findIndex(day => day === daysWorking[daysWorking.length - 1])) {
                  amount -= parseFloat(part)
                  return ({...day, goal: parseFloat(part)})
                 } else return ({...day, goal: parseFloat(amount)})
               }
             })
  
            setDwt(generateDwt(newDays))
            setDays(newDays)
            updateGoal({lastDaysBackup: newDays})
          }
        }
      } else {
        setDwt(generateDwt(dataDays))
        setDays(dataDays)
        updateGoal({lastDaysBackup: dataDays})
      }
    }
  }, [dataMonth, dataUsersWorking])

  //  DEFINE VALORES NO DWT E A LISTAGEM DE PERIODOS DENTRO DO MODAL DE SECTIONPICKER
  useEffect(() => {
    // SECTIONSLIST
    if (!dataSections.length > 0 && sections && days.length > 0) {
      const newSectionList = [...sections].filter(section => section.start && section.end).map(section => ({start: new Date(section.start), end: new Date(section.end), goal: 0, daysWorking: 0}))
      
      days.forEach(day => {
        if (day.working) {
          newSectionList.map((section, i) => {
            if (new Date(day.date) >= section.start && new Date(day.date) <= section.end) {
              section.goal += day.goal
              section.daysWorking++
            }

            return section
          })
        }
      })
      updateGoal({sectionsList: newSectionList, days})
      setSectionsList(newSectionList)
    }
  }, [days, sections])

  useEffect(() => {
    if (dataSections.length >= 1 ) setSections([...dataSections].map(section => ({start: new Date(section.start), end: new Date(section.end)})))
    else setSections([{start: new Date(days[0]?.date), end: null}])
  }, [dataSections])

  return (
  <>
    <Container>
      <Element name='sellers'>
        <div className='goals-sellers-step'>
            <div style={ dataUsersWorking?.length > 0
              ? { pointerEvents: 'none', opacity: 0.3 } 
              : { pointerEvents: 'auto', opacity: 1 } } 
            >
              <h1 className='goals-sellers-step-title'>Selecione quem irá participar da meta</h1>
              { usersLoading ? <CircularProgress />
                : (
                  <div className='goals-sellers-content-boxes'>
                    <ContentBox>
                      <div className='contentbox-title'>
                        <h1>Não participam</h1>
                        <span>{users.length}</span>
                      </div>
                      <ul>
                        {users.map((user, i) => (
                          <TransferListCard 
                            key={i} 
                            user={user}
                            onClick={() => handleAddToGoal(user)}
                          />
                        ))}
                      </ul>
                    </ContentBox>
                    <ContentBox>
                      <div className='contentbox-title'>
                        <h1>Participam da meta</h1>
                        <span>{(dataUsersWorking?.length > 0 ? dataUsersWorking : usersWorking).filter((userW) => !!userW.userId?._id).length}</span>
                      </div>
                      <ul>
                        {(dataUsersWorking?.length > 0 ? dataUsersWorking : usersWorking).map((userW, i) => !!userW.userId?._id && (
                          <TransferListCard
                            working
                            key={i}
                            user={userW} 
                            inGoal
                            data={data}
                            updateUserDSR={handleUpdateUserDSR}
                            updateUserWeigth={handleUpdateUserWeigth}
                            onClick={() => handleRemoveFromGoal(userW)}
                          />
                        ))}
                      </ul>
                    </ContentBox> 
                  </div>
                )
              }
            </div>
              { Array.isArray(dataUsersWorking) && usersWorking && 
                ((!dataUsersWorking.length > 0 && usersWorking.length >= 1) ?
                  <Link to='monthGoals' offset={-100} smooth={true}>
                    <button 
                    onClick={() => {
                      updateGoal({usersWorking: [...usersWorking].map(user => ({...user, userId: user.userId, active: true}))})
                    }}
                      className='stepper-button-next'
                    >Avançar</button>
                  </Link>
                : dataUsersWorking.length > 0 &&
                  <Link to='sellers' offset={-170} smooth={true}>
                    <button 
                      onClick={() => {
                        setDeleteStep([
                          "usersWorking",
                          "days",
                          "dwt",
                        ])
                      }}
                      className='stepper-button-edit'>
                        Alterar 
                        <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
                    </button>
                  </Link>
                )
              } 
          </div>
      </Element>

      <Element name='monthGoals'>
        { (dataUsersWorking.length > 0 || monthGoals.length > 0) &&
          <div className='goals-sellers-step'>
            <div style={ dataConfig?.commission?.fixed?.commission >= 0 && dataMonthGoals?.length > 0
              ? { pointerEvents: 'none', opacity: 0.3 } 
              : { pointerEvents: 'auto', opacity: 1 } } 
            >
              <h1 className='goals-sellers-step-title'>Meta dos vendedores</h1>
              <div className='goals-sellers-goal-table'>
                <ToggleItem
                  data={data}
                  hasFixedCommission={hasFixedCommission}
                  setHasFixedCommission={setHasFixedCommission}
                  setFixedCommission={setFixedCommission} 
                />
                <GoalsTable
                  data={data}
                  monthGoals={monthGoals}
                  setMonthGoals={setMonthGoals}
                  updateGoal={updateGoal}
                  usersWorking={usersWorking}
                />
              </div>
            </div>
              { (dataConfig.commission?.fixed?.commission >= 0 && monthGoals.length > 0) ?
                <Link to='monthGoals' offset={-170} smooth={true}>              
                  <button 
                    onClick={() => {
                      setDeleteStep([
                        "monthGoals",
                        "fixed",
                      ])
                    }}
                    className='stepper-button-edit'>
                      Alterar 
                      <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
                  </button>
                </Link>
                : monthGoals.length > 0 &&
                <Link to='calendar' offset={-100} smooth={true}>
                  <button 
                    onClick={() => {
                      updateGoal({monthGoals, config: {...dataConfig, commission: {...dataConfig.commission, fixed: {commission: fixedCommission ? fixedCommission : 0, type: 'user'}}}})
                    }}
                    disabled={hasFixedCommission && !fixedCommission}
                    className='stepper-button-next'
                  >Avançar</button>
                </Link>
              } 
          </div>
        }
      </Element>

      <Element name='calendar'>
      { ((dataUsersWorking.length > 0 && dataMonthGoals.length > 0 && dataConfig?.commission?.fixed?.commission >= 0) || dataDays.length > 0) && (
        <div style={ dataDays?.length > 0 && dataSections.length > 0
          ? { pointerEvents: 'none', opacity: 0.3 } 
          : { pointerEvents: 'auto', opacity: 1 } } 
        >
        { !calendarLoading ? (
          <CalendarContainer suggestionActive={dataConfig.suggestion}>
            <div className='title'>
              <h1>Meta da loja para {dataMonth ? format(new Date(dataMonth), 'MMMM', {locale: ptBR}) : '...'}</h1>
              <div className="proft-suggestion-container">
                <ErrorOutlineOutlinedIcon
                  fontSize="small"
                  style={{ color: "#9E9E9E" }}
                  data-tip data-for='tooltip-proft-suggestion' 
                />
                <Tooltip 
                  content={
                    <p>As metas dos dias serão ajustadas para a
                    sua loja pela nossa Inteligência Artificial.</p>
                  } 
                  id='tooltip-proft-suggestion' 
                />

                  <button // o estilo do botao muda pela prop no CalendarContainer
                    onClick={() => {
                      if (dataConfig.suggestion) handleOffsuggestion()
                      else setSuggestionModal(!suggestionModal)
                    }}
                  >
                    <WandIcon color={'#fff'} size={10} />
                    <span style={{marginLeft: 7}}>{
                      `Sugestão Proft ${(dataConfig.suggestion) ? 'Ativada' : 'Desativada'}`
                    }</span>
                  </button>
                <PromptModal 
                  open={suggestionModal}
                  icon={<WandIcon color="#702CB9" size={20} />}
                  onClose={() => setSuggestionModal(false)}
                  title="Aplicar a Sugestão Proft?"
                  description="
                  Nossa Inteligência Artificial analisa sua loja e mercado em vários aspectos.
                  A partir desses dados, é calculada uma Sugestão Proft de meta do mês."
                  onLeft={() => setSuggestionModal(false)}
                  onRight={() => {
                    setSuggestionModal(false)
                    setSuggestionAnimationModal(true)
                    
                    handlesuggestion()
                  }}
                  rightTitle="Fazer a mágica"
                >
                  <span style={{ color: '#546E7A', fontSize: 14, textAlign: 'center', fontWeight: 600 }}>
                    Atenção: os valores já inseridos nos dias serão perdidos!
                    </span>
                </PromptModal>
                <Modal
                  open={suggestionAnimationModal}
                >
                  <SuggestionAnimation> 
                    <Lottie
                      options={animationOptions}
                      width={200}
                      height={200}
                    />
                    <span>
                      <strong>Fazendo a mágica...</strong> <br/>
                      Calculando sugestão
                    </span>
                  </SuggestionAnimation>
                </Modal>
              </div>
            </div>
            <Calendar
              data={data} 
              dwt={dwt}
              setDwt={setDwt}
              days={days} 
              setDays={setDays} 
              weekStartIndex={getDay(new Date(dataMonth))}
              month={new Date(dataMonth)} 
              mainGoalValue={dataMainGoals?.total}
              setTotalDaysValue={setTotalDaysValue}
              updateGoal={updateGoal}
              suggestion={dataConfig.suggestion}
            /> 
            <div className='footer'>
              { !dataDays?.length > 0 &&
                <>
                  { dataSections.length > 1 &&
                    <button
                      onClick={handleOpenSections} 
                      className={`create-period-button ${(!sectionsOpened ? 'create-period-button-need-review' : dataSections.length > 0 && 'create-period-button-completed')}`}
                    >
                      <span>{dataSections?.length > 0 ? dataSections?.length : 0}</span> 
                      {dataSections?.length === 1 ? 'Período' : 'Períodos'}
                    </button>
                  }

                  <div data-tip data-for={'tooltip-distribute-issue'}>
                    <button 
                      disabled={
                        Number(totalDaysValue).toFixed(2) !== Number(dataMainGoals?.total).toFixed(2) || (dataSections.length >= 2 && !sectionsOpened)
                      }
                      onClick={() => {
                        const daysToSave = [...days].map(day => {
                          return ({...day, users: [...day.users].map(user =>  {
                            if (typeof user === "string") return user
                            else if (typeof user === "object") return user.userId?._id
                          })})
                        })

                        updateGoal({lastDaysBackup: daysToSave})
                        updateGoal({days: daysToSave, sections: dataSections ? dataSections : sections})
                        scroll.scrollToBottom()
                      }}
                      style={{ margin: 0 }} 
                      className='stepper-button-next'
                    >
                      Avançar
                    </button>
                  </div>

                {((dataSections.length >= 2 && !sectionsOpened) || Number(totalDaysValue).toFixed(2) !== Number(dataMainGoals?.total).toFixed(2)) && 
                    <Tooltip
                      id="tooltip-distribute-issue"
                      content={
                        <p>
                          { Number(totalDaysValue).toFixed(2) !== Number(dataMainGoals?.total).toFixed(2) 
                            ? 'É necessário que a soma das metas diárias resultem exatamente no total definido para a meta da loja!'
                            : !sectionsOpened && 'Você precisa revisar a criação de períodos para prosseguir!' 
                          }
                        </p>
                      }
                    />
                  }
                </>
              }

            </div>
          </CalendarContainer>
          ) : 
          <div>
            <CircularProgress size={20} /> 
            <span style={{fontSize: 22, marginLeft: 10}}>Calculando dados do Calendário...</span>
          </div>
        }
          </div>
          )}

        { (dataDays?.length > 0 && dataSections?.length > 0) &&
          <Link to='calendar' offset={-170} smooth={true}>
            <button 
              onClick={() => {
                setDeleteStep(["days"])
              }}
              className='stepper-button-edit'>
              Alterar
              <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
            </button>
          </Link>
        } 
      </Element>

      <Element name='next-button-step'>
      { (dataDays?.length > 0 && dataSections.length > 0) && (
        <button 
          onClick={() => {
            handleNextStep()
          }} 
          style={{ margin: 'auto' }} 
          className='stepper-button-next'
        >
          Próxima etapa
          <ArrowForwardIos className='next-button-icon' />
        </button>
      )}
      </Element>
    </Container>

    {/*   MODAL DE SECTIONS COM SECTIONPICKER   */}
    <Modal
      open={isCreatingSection}
    >
      <ModalContainer>
        <div className="top">
          <div className="title">
            <h1>Períodos da meta</h1>
            <span>Adicione e distribua os períodos da meta.</span>
          </div>
          <Close className="close-button" onClick={() => setIsCreatingSection(false)} />
        </div>
        <div className="center">
          <div className="calendar">
            <SectionPicker
              days={days}
              sections={sections}
              setSections={setSections}
            /> 
          </div>
          <div className="sections-table">
            <div className="title">
              <span >#</span>
              <span>Meta do Período</span>
              <span>Dias úteis</span>
            </div>
            { sections[0]?.end ?
              <div className="table">
                { days.reduce((sections, day) => { // Passa por todos os days já somando a meta dentro de cada section
                    if (day.working) {
                      sections = sections.map(section => {
                        if (+new Date(day.date) >= +new Date(section.start) && +new Date(day.date) <= +new Date(section.end)) {
                          section.goal += day.goal 
                          section.days++
                        }
                        return section
                      })
                    }

                    return sections
                  }, sections.reduce((acc, {start, end}) => { //Inicializa valor inicial do reduce apenas com sections completas e valores zerados
                    if (end) acc.push({
                      start: typeof start === 'string' ? new Date(start) : start,
                      end: typeof end === 'string' ? new Date(end) : end,
                      goal: 0,
                      days: 1
                    })
                    return acc
                  }, [])).map((section, i) => (
                  <div key={i} className="section" data-tip data-for={`day-range-section-${i}`}>
                    <span className="index">{i+1}</span>
                    <span className="section-goal">{formatPrice(section.goal)}</span>
                    <div className="util-day">
                      <span>
                        {section.days - 1}
                      </span>
                      <Tooltip 
                        id={`day-range-section-${i}`} 
                        content={
                          <p>
                            {`Período total: ${format(new Date(section.start), 'dd/MM')} - ${format(new Date(section.end), 'dd/MM')}`}
                          </p>
                        } 
                      />
                    </div>
                      <button
                        className="delete-button" 
                        onClick={handleDeleteSections(i)}
                      >
                        <Delete className="delete-icon" />
                      </button>
                  </div>
                ))}
              </div>
              :
              <span className="no-table">Insira os períodos no calendário ao lado!</span>
            }
          </div>
        </div>
        <div className="footer">
         { sections.length > 0 &&
            <>
              <div className="sections-picker-info">
                <Info />
                <span>Para editar os períodos no calendário, delete (clicando na lixeira de cada período) os períodos já existentes na tabela!</span>
              </div> 
              <div data-tip data-for="section-submit-tooltip">
                <button 
                onClick={() => {
                  setIsCreatingSection(false)
                  setSectionsOpened(true)
                  updateGoal({sections, sectionsList})
                }}
                disabled={
                  sections.length < 2 ||
                  !sections[sections.length - 1].end ||
                  getDate(new Date(sections[sections.length - 1].end)) < getDate(endOfMonth(new Date(dataMonth)))
                } 
                className="section-submit"
              >
                Salvar
              </button>
            </div>
          </>
         }
        </div>
      </ModalContainer>
    </Modal>
  </>
  )
}

export default GoalStep;