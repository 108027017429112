import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    background: '#EEEEEE',
    border: '1px solid #DDDDDD',
    width: '100%',
    borderRadius: '4px',
    marginTop: '20px',
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5)
  },
  customIcon: {
    color: '#546E7A', 
    backgroundColor: '#DDDDDD', 
    borderRadius: '50%', 
    padding: theme.spacing(4), 
    marginBottom: '40px',
    padding: '20px',
    transform: 'scale(1.5)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  title: {
    fontSize: '16px',
    color: '#546E7A',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1)
  },
  description: {
    fontSize: '14px',
    color: '#546E7A',
    textAlign: 'center'
  }
}))

const EmptyState = ({icon, title, description}) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.customIcon}>{icon}</div>
      <Typography className={classes.title}>{title}</Typography>
      <Typography className={classes.description}>{description}</Typography>
    </div>
  )
}

export default EmptyState