import React from 'react';

const BackIcon = () => {
  return (
    <svg width="30" height="36" viewBox="0 0 30 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.03476 14.6384L3.44976 12.035C1.79976 14.1617 0.773092 16.6184 0.461426 19.1667H4.16476C4.42143 17.5717 5.06309 16.0134 6.03476 14.6384ZM4.16476 22.8334H0.461426C0.773092 25.3817 1.78143 27.8384 3.43143 29.965L6.01643 27.3617C5.06309 25.9867 4.42143 24.4467 4.16476 22.8334ZM6.01643 32.5867C8.14309 34.2367 10.6181 35.2267 13.1664 35.5384V31.8167C11.5714 31.5417 10.0314 30.9184 8.65643 29.9284L6.01643 32.5867ZM16.8331 6.46171V0.833374L8.49143 9.17504L16.8331 17.3334V10.165C22.0398 11.045 25.9998 15.555 25.9998 21C25.9998 26.445 22.0398 30.955 16.8331 31.835V35.5384C24.0748 34.64 29.6664 28.48 29.6664 21C29.6664 13.52 24.0748 7.36004 16.8331 6.46171Z" fill="#E64C3D"/>
    </svg>
    
  );
}

export default BackIcon;