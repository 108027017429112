import React, { useState } from 'react'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { makeStyles } from '@material-ui/styles'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  divider: {
    borderLeft: '2px solid #CCC',
    padding: theme.spacing(1, 2)
  },
  column: {
    flexBasis: '50%',
    marginTop: '80px'
  }
}))

export default function EditUserGoalModal ({
  modal,
  users,
  onClose,
  handleAddUser,
  ...rest
}) {
  const classes = useStyles()
  const [user, setUser] = useState()

  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={modal}
        onClose={onClose}
        aria-labelledby='title'
      >
        <DialogTitle id='title'>Adicionar vendedor à meta</DialogTitle>
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs>
              <Autocomplete
                options={users}
                getOptionSelected={(option, value) => value.value === option.value}
                onChange={(e, value) => setUser(value)}
                getOptionLabel={(option) => option.name.complete}
                style={{ width: 300 }}
                renderInput={(params) => <TextField {...params} label='Vendedores' variant='outlined' />}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancelar
          </Button>
          <Button
            disabled={!user}
            onClick={() => {
              handleAddUser(user)
              onClose()
            }}
            style={{ height: '100%' }}
          >
            Adicionar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
