import produce from "immer"
import moment from "moment"

const INITIAL_STATE = {
  loading: false,
  user: null,
  email: null,
  phoneNumber: null,
  error: null,
  nextUpdateAt: null,
}

export default function onboarding(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case "@onboarding/REQUEST_LOADING": {
        draft.loading = true
        break
      }
      case "@onboarding/STOP_LOADING": {
        draft.loading = false
        break
      }
      case "@onboarding/PHONE_NUMBER_SUCCESS": {
        draft.loading = false
        draft.phoneNumber = action.payload.data.phone
        draft.nextUpdateAt = action.payload.data.nextUpdateAt
        //draft.nextSendCodeTime = action.payload.data.nextSendIn
        break
      }
      case "@onboarding/EMAIL_SUCCESS": {
        draft.loading = false
        draft.email = action.payload.data
        //draft.nextSendCodeTime = action.payload.data.nextSendIn
        break
      }
      case "@onboarding/EMAIL_CLEAR": {
        draft.email = null
        break
      }
      case "@onboarding/CODE_REQUEST_FAILED": {
        draft.error = action.payload.message
        draft.loading = false
        break
      }
      case "@onboarding/PHONE_NUMBER_FAILED": {
        draft.loading = false
        draft.error = true
        break
      }
      
      case "@onboarding/PHONE_NUMBER_CLEAR": {
        draft.phoneNumber = null
        break
      }
      default: 
        return state
    }
  })
}