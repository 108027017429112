export const colors = {
  background: {
    light: '#F9F9FB'
  },
  success: '#59AC2A',
  info: '#0FAFBD',
  warning: '#ED8C1D',
  error: '#E64C3D',
  danger: {
    dark: '#F34456',
    light: '#DF7B6C'
  },
  primary: {
    main: '#702CB9',
    dark: '#682AC7',
    darkest: '#5F279A',
    light: '#7D2EA0',
    lightest: '#495BD4'
  },
  secondary: {
    main: '#F0B222',
    dark: '#E7A60D',
    darkest: '#DC9D09',
    light: '#F0BC42',
    lightest: '#EFC462'
  },
  lightGrey: {
    main: '#E3E3E8',
    dark: '#D8D8DF',
    darkest: '#CBCBD2',
    light: '#EEEEF1',
    lightest: '#F7F7F8'
  },
  darkGrey: {
    main: '#9A9AB1',
    dark: '#767693',
    darkest: '#5B5B76',
    light: '#ADADBD',
    lightest: '#BABAC5'
  },
}