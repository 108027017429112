import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Divider,
  SwipeableDrawer,
  colors,
  Button,
  Hidden,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core'
import Avatar from '@mui/material/Avatar'
import DashboardIcon from '@material-ui/icons/Dashboard'
import PeopleIcon from '@material-ui/icons/People'
import SettingsIcon from '@material-ui/icons/Settings';
import TimelineIcon from '@material-ui/icons/Timeline'
import AssessmentIcon from '@material-ui/icons/Assessment'
import InputIcon from '@material-ui/icons/Input'
import HelpIcon from '@material-ui/icons/Help'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory'
import SpeedIcon from '@material-ui/icons/Speed';

import { Profile, SidebarNav } from './components'
import SelectStoreMenuDropdown from '../../../../components/SelectStoreMenuDropdown'
import { useSelector } from 'react-redux'

const useStyles = makeStyles(theme => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)'
    }
  },
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(2)
  },
  divider: {
    margin: theme.spacing(2, 0)
  },
  nav: {
    marginBottom: theme.spacing(2),
    height: '100%',
    flexGrow: 1
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  }
}))

const Sidebar = props => {
  const {
    open,
    variant,
    onClose,
    onOpen,
    className,
    user,
    store,
    storeId,
    storeProfileImage,
    storeLocation,
    onLogoff,
    ...rest
  } = props

  const goalList = useSelector(state => state.goal.goalList)

  const classes = useStyles()
  const pages = [
    {
      title: 'Início',
      href: `/${storeId}/dashboard`,
      icon: <SpeedIcon />,
    },
    {
      title: 'Atendimentos',
      href: `/${storeId}/events`,
      icon: <ShoppingCartIcon />,
      disabled: goalList?.length === 0
    },
    {
      title: 'Lançamentos',
      href: `/${storeId}/inputs`,
      icon: <InputIcon />,
      disabled: goalList?.length === 0
    },
    {
      title: 'Relatórios',
      href: `/${storeId}/report`,
      icon: <AssessmentIcon />,
      disabled: goalList?.length === 0
    },
    {
      title: 'Equipe',
      href: `/${storeId}/users`,
      icon: <PeopleIcon />,
      disabled: user?.stores?.length === 0
    },

    // {
    //   title: 'Simulador',
    //   href: '/simulator',
    //   icon: <ExploreIcon/>
    // },

    {
      title: 'Metas',
      href: `/${storeId}/goals`,
      icon: <TimelineIcon />,
      disabled: !store?.users?.find(user => user.stores.find(store => store.storeId === storeId && store.type === "seller"))
    },
    // {
    //   title: 'Minha loja',
    //   href: `/store/${window.localStorage.getItem('@Proft:storeId')}`,
    //   icon: <StoreMallDirectoryIcon />
    // }
    {
      title: 'Configurações',
      href: `/${storeId}/settings`,
      icon: <SettingsIcon />
    },
  ]

  return (
    <SwipeableDrawer
      anchor='left'
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      onOpen={onOpen}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <SelectStoreMenuDropdown userStores={user?.stores}/>
        {/* <Profile storeProfileImage={storeProfileImage} user={user} storeLocation={storeLocation} storeId={storeId}/> */}
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
        <Divider className={classes.divider} />
        <Button className={classes.button} target='_blank' href="https://wa.me/5544991546704">
          <div className={classes.icon}>
            <HelpIcon />
          </div>
            Suporte
        </Button>
        <Hidden lgUp>
          <Button className={classes.button} onClick={onLogoff}>
            <div className={classes.icon}>
              <InputIcon />
            </div>
            Sair
          </Button>
        </Hidden>
      </div>
    </SwipeableDrawer>
  )
}

Sidebar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
  storeProfileImage: PropTypes.string,
  storeLocation: PropTypes.string
}

export default Sidebar
