import React from 'react'
import Switch from 'react-switch';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Container } from './styles';
import { useEffect, useState } from 'react';
import Tooltip from '../../../../../../Tooltip';

const ToggleItem = ({ data, setFixedCommission }) => {
  const [checked, setChecked] = useState(false)
  const [commission, setCommission] = useState();

  useEffect(() => {
    if (checked) {
      document.getElementById('commission-input').focus()
      setCommission('')
    }
    else setCommission(0)
  }, [checked]);

  useEffect(() => {
    if (commission) setFixedCommission(parseFloat(commission/100))
  }, [commission])

  return (
    <Container>
      <div className="goal-switch-content">
        <Switch
          onChange={() => setChecked(check => !check)}
          checked={data.config?.commission?.fixed?.commission > 0 || checked}
          checkedIcon={false}
          uncheckedIcon={true}
          handleDiameter={8}
          height={12}
          width={25}
          offColor='#37474F'
          onColor='#702CB9'
        />
        <strong>Comissão base</strong>
        <ErrorOutlineIcon 
          data-tip data-for='commission-tooltip' 
          style={{ fontSize: 18, marginLeft: 5 }} 
          color='disabled' 
          fontSize='small'
        />
        <Tooltip 
        id='commission-tooltip' 
        content={
          <p>Ative caso seu vendedor receba uma comissão
            base sobre suas vendas até atingir a primeira meta.</p>
        } 
        />
      </div>
      <div className="goal-percent-comission">
        <input
          type="text"
          pattern="[0-9]+"
          maxLength={5}
          max={100}
          id="commission-input"
          value={String(data?.config?.commission?.fixed?.commission ? (data.config.commission.fixed.commission * 100).toFixed(2) : commission).replace('.', ',')}
          onChange={e => {
            setCommission(e.target.value > 100 ? 100 : e.target.value.replace(',', '.'))
          }}
          disabled={!checked}
          placeholder='0,0'
        />
        <label>%</label>
      </div>
    </Container>
  )
}

export default ToggleItem;