import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  .title-container {
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    h1 {
      color: ${colors.darkGrey.main};
      font-size: 22px;
      font-weight: 700;
      margin: 0;
      text-align: left;
    }

    span {
      font-family: "Inter", sans-serif;
      font-weight: 500;
      font-size: 14px;
      text-align: left;
      margin-top: 5px;
      color: ${colors.darkGrey.darkest}
    }
  }
`;
