import React, { useEffect, useState } from 'react';
import { getDate } from 'date-fns/esm';

import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';

import { Picker } from './styles';

const SectionPicker = ({ days, sections, setSections}) => {
  const [disabled, setDisabled] = useState(false)
  const [hoverDay, setHoverDay] = useState()
  const [mousePos, setMousePos] = useState({x: null, y: null})

  const lastDayInMonth = new Date(new Date(days[days.length - 1].date).setHours(12))
  const lastSection = sections[sections.length - 1]

  const handleDayClick = (day) => {
    day.setHours(12)
    const newSections = [...sections]

    if (+new Date(day) > +new Date(lastSection.start)) {
      newSections[newSections.length - 1].end = day
      
      if (+new Date(day) === +new Date(lastDayInMonth)) {
        setDisabled(true)
      }
      
      if (+new Date(day) < +new Date(lastDayInMonth)) {
        newSections.push({start: new Date(new Date(day).setDate(day.getDate() + 1)), end: null})
      }

      setSections(newSections)
    }
  }
 
  useEffect(() => {
    setHoverDay({start: new Date(lastSection.start), end: new Date(lastSection.end)})

    if (!lastSection.end) setDisabled(false)

    if (+new Date(lastSection.end) === +new Date(lastDayInMonth)) {
      setDisabled(true)
    }
  }, [sections])

  const unUtils = [...days].filter(day => !day.working)

  return (
    <Picker disabled={disabled}>
      <DayPicker
        showOutsideDays
        className="Range"
        month={new Date(days[0].date)}
        weekdaysShort={["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"]}
        selectedDays={[      
          ...sections.filter(section => section.start && section.end).map(section => ({
            from: new Date(section.start),
            to: new Date(section.end)
          })),
          {from: hoverDay?.start, to: hoverDay?.end}
        ]}
        modifiers={{
          hoverDay: hoverDay?.end, 
          start: [...sections].map(section => new Date(section.start)),
          end: [...sections].map(section => new Date(section.end)),
          unUtilDay: unUtils.map(unUtil => new Date(unUtil.date)),
        }}
        onDayClick={handleDayClick}
        onDayMouseEnter={hoverEnd => {
          if (hoverEnd > new Date(lastSection.start)) {
            setHoverDay({...hoverDay, end: hoverEnd})
          }
        }}
      />
      { !sections[sections.length - 1].end &&
        <div className="current-section-length">
          Tamanho do período: {getDate(new Date(hoverDay?.end)) + 1 - getDate(new Date(hoverDay?.start)) || 0} dias
        </div>
      }
    </Picker>
  )
}

export default SectionPicker; 