import React from 'react';

const WandIcon = ({color, size}) => {
  return (
    <svg style={{ transform: `scale(${size/10})` }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.50055 3.6L10.0005 5L8.60055 2.5L10.0005 0L7.50055 1.4L5.00055 0L6.40055 2.5L5.00055 5L7.50055 3.6ZM19.5005 13.4L17.0005 12L18.4005 14.5L17.0005 17L19.5005 15.6L22.0005 17L20.6005 14.5L22.0005 12L19.5005 13.4ZM22.0005 0L19.5005 1.4L17.0005 0L18.4005 2.5L17.0005 5L19.5005 3.6L22.0005 5L20.6005 2.5L22.0005 0ZM14.3705 5.29C13.9805 4.9 13.3505 4.9 12.9605 5.29L1.29055 16.96C0.900547 17.35 0.900547 17.98 1.29055 18.37L3.63055 20.71C4.02055 21.1 4.65055 21.1 5.04055 20.71L16.7005 9.05C17.0905 8.66 17.0905 8.03 16.7005 7.64L14.3705 5.29ZM13.3405 10.78L11.2205 8.66L13.6605 6.22L15.7805 8.34L13.3405 10.78Z" fill={color}/>
    </svg>
  );
}

export default WandIcon;