import styled from 'styled-components'
import { colors } from 'theme/colors'
import fonts from 'theme/fonts'
import typography from 'theme/typography'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  min-width: 560px;
  width: 45%;
  padding: 40px 40px 60px 40px;
  font-family: 'Inter', sans-serif;

  span {
    font-weight: 500;
    margin: 10px 0;
  }

  .submit-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    span {
      text-align: left;
      font-size: 12px;
      margin: auto;
      margin-bottom: 30px;
    }

    .buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  .button-next {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .birthday-pick {
    width: 100%;
    cursor: pointer;

    .MuiIconButton-root {
      padding: 0px 10px !important;
    }

  }
`

export const OpenCalendarButton  = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-family: ${fonts.body.font};
    font-size: 12px;
    font-weight: 500;
    color: ${colors.primary.main};
    margin-right: 5px;
  }
`
