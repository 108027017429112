import React, { useState, useRef } from "react";
import { colors } from "../../../../theme/colors";

import { Container } from './styles'

const VerificationInput = ({ value, length, type, loading, setAcessCode }) => {
  const [code, setCode] = useState(value?.split('') || [...Array(length)].map(() => ""));
  const inputs = useRef([]);

  const processInput = (e, slot) => {
    const num = e.target.value;

    if (/[^0-9]/.test(num)) return;

    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every(num => num !== "")) {
      setAcessCode(newCode.join(""))
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      setAcessCode(null);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <Container>
      <div className="code-input">
        <div className="code-inputs">
          {code.map((num, idx) => {
            return (
              <input
                key={idx}
                style={{borderColor: code[idx] && colors.primary.main}}
                className={`${type}`}
                inputMode="numeric"
                maxLength={1}
                value={num}
                readOnly={loading}
                onChange={e => processInput(e, idx)}
                onKeyUp={e => onKeyUp(e, idx)}
                ref={ref => inputs.current.push(ref)}
              />
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default VerificationInput;
