import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  background-color: #fff;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
`