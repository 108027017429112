import React from 'react';

const CreateGoalIcon = () => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.5 2.75V4.25H12.25V6.5H10.75V4.25H8.5V2.75H10.75V0.5H12.25V2.75H14.5ZM12.25 13.25H1.75V2.75H6.25V1.25H1.75C0.925 1.25 0.25 1.925 0.25 2.75V13.25C0.25 14.075 0.925 14.75 1.75 14.75H12.25C13.075 14.75 13.75 14.075 13.75 13.25V8.75H12.25V13.25ZM9.25 8.75V11.75H10.75V8.75H9.25ZM6.25 11.75H7.75V5.75H6.25V11.75ZM4.75 11.75V7.25H3.25V11.75H4.75Z" fill="white"/>
    </svg>
  );
}

export default CreateGoalIcon;