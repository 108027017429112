import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/styles'
import CurrencyTextField from '../../../../components/CurrencyTextField'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  divider: {
    borderLeft: '2px solid #CCC',
    padding: theme.spacing(1, 2)
  },
  column: {
    flexBasis: '50%',
    marginTop: '80px'
  }
}))

export default function EditGoalModal({
  active,
  users,
  onClose,
  onSave,
  goal,
  ...rest
}) {
  const classes = useStyles()
  const [data, setData] = useState({
    dsr: 0,
    workingDays: 0,
    ticket: '',
    price: 0
  })

  useEffect(() => {
    if (goal) {
      setData({
        dsr: goal.dsr,
        workingDays: goal.workingDays,
        ticket: goal.mainGoals.ticket,
        price: goal.mainGoals.price
      })
    }
  }, [goal])

  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={active}
        onClose={onClose}
        aria-labelledby='title'
      >
        <DialogTitle id='title'>Editar meta</DialogTitle>
        <DialogContent>

          <Grid container spacing={1}>
            <Grid item xs>
              <CurrencyTextField
                label="Ticket médio"
                variant="outlined"
                value={data.ticket}
                currencySymbol="R$"
                //minimumValue="0"
                decimalCharacter=","
                digitGroupSeparator="."
                minimumValue="0"
                onChange={(event, value) =>
                  setData({
                    ...data,
                    ticket: value
                  })}
              />
            </Grid>
            <Grid item xs>
              <CurrencyTextField
                label="Preço médio"
                variant="outlined"
                value={data.price}
                minimumValue="0"
                currencySymbol="R$"
                decimalCharacter=","
                digitGroupSeparator="."
                onChange={(event, value) =>
                  setData({
                    ...data,
                    price: value
                  })}
              />
            </Grid>
            <Grid item xs>
              <TextField
                label='DSR'
                variant='outlined'
                type='number'
                onChange={e =>
                  setData({
                    ...data,
                    dsr: e.target.value
                  })}
                value={data.dsr}
                fullWidth
              />
            </Grid>
            <Grid item xs>
              <TextField
                label='Dias trabalhados'
                variant='outlined'
                type='number'
                onChange={e =>
                  setData({
                    ...data,
                    workingDays: e.target.value
                  })}
                value={data.workingDays}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              let submitData = {}
              if (data.ticket !== goal.mainGoals.ticket) submitData.ticket = Number(data.ticket)
              if (data.price !== goal.mainGoals.price) submitData.price = Number(data.price)
              if (data.dsr !== goal.dsr) submitData.dsr = Number(data.dsr)
              if (data.workingDays !== goal.workingDays) submitData.workingDays = Number(data.workingDays)
              Object.entries(submitData).length > 0 ? onSave(submitData) : onClose()
            }}
            style={{ height: '100%' }}
            color='primary'
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
