import React, { useState, useEffect } from 'react'

// Material UI
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import Grid from '@material-ui/core/Grid'

// libs
import { useForm } from 'react-hook-form'
import { formatPrice } from '../../../../../../utils/format'
import { InputAdornment, TextField } from '@material-ui/core'
import CurrencyTextField from '../../../../../../components/CurrencyTextField'


// Styles
const useStyles = makeStyles(theme => ({
  root: {},
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  }
}))

const Modal = ({ active, onClose, input, onSubmit, ...rest }) => {
  const classes = useStyles()

  const [isStore, setIsStore] = useState(false)
  const [data, setData] = useState({
    sold: 0,
    sales: 0,
    items: 0
  })

  useEffect(() => {
    if (input) {
      setIsStore(!input.sellerId)
      setData(input)
    }
  }, [input])

  const handleSubmit = () => {
    onSubmit({
      ...data,
      sold: typeof data.sold == 'string' ? Number(data.sold.replace(/[^0-9/./,]/g, '').replace(',', '.')) : data.sold,
      sales: Number(data.sales),
      items: Number(data.items)
    }, isStore)
    onClose()
  }

  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={active}
        onClose={onClose}
        maxWidth='sm'
        fullWidth
      >
          <DialogTitle id='alert-dialog-title'>Editar {input ? input.sellerId ? input.sellerId.name.complete : 'Outros' : '...'}</DialogTitle>
          <DialogContent>

            <Grid container spacing={1}>
              <Grid item xs={4}>
                <CurrencyTextField
                  fullWidth
                  label="Faturamento"
                  variant="outlined"
                  value={data.sold}
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  onChange={(event, value) =>
                    setData({
                      ...data,
                      sold: value
                    })}
                  onFocus={event => event.target.select()}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label="Quantidade de vendas"
                  type="number"
                  variant="outlined"
                  value={data.sales}
                  onChange={(event) =>
                    setData({
                      ...data,
                      sales: event.target.value
                    })}
                  onFocus={event => event.target.select()}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  label='Peças vendidas'
                  variant="outlined"
                  type="number"
                  onChange={(event) =>
                    setData({
                      ...data,
                      items: event.target.value
                    })}
                  value={data.items}
                  name='items'
                  onFocus={event => event.target.select()}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose} color='primary'>
              Cancelar
            </Button>
            <Button onClick={() => handleSubmit()} color='primary' autoFocus>
              Salvar
            </Button>
          </DialogActions>
      </Dialog>
    </div>
  )
}

export default Modal
