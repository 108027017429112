import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { clearPhoneNumber, phoneNumberRequest, sendCodeRequest, verificationSuccess } from 'store/modules/onboarding/actions';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import Countdown from 'react-countdown';
import jwtDecode from 'jwt-decode'

import VerificationInput from '../../Components/VerificationInput';
import { Button } from '../../Components/Button';

import { PhoneIcon } from '../../../../assets/Icons/PhoneIcon';
import { NeoproLogo } from '../../../../assets/Icons/NeoproLogo';
import { ProftFullIcon } from 'assets/Icons/ProftFullIcon';

import api from '../../../../repositories/api';
import { formatPhone } from 'utils/format';

import { colors } from '../../../../theme/colors';
import { Container } from './styles';

const PhoneVerification = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  
  const phoneNumber = useSelector(state => state.onboarding.phoneNumber)
  const nextUpdateAt = useSelector(state => state.onboarding.nextUpdateAt)
  
  const [code, setCode] = useState()
  const [canResendCode, setCanResendCode] = useState(true)

  const [loading, setLoading] = useState(false)

  const submitVerification = async () => {
    setLoading(true)
    try {
      const { data } = await api.axios.put(`auth/validate`, {
        phone: parseInt(phoneNumber),
        code: parseInt(code)
      })
      
      if (data.token) {
        localStorage.setItem('@Proft:activate-token', data.token)
        
        const { id } = jwtDecode(data.token)
        history.push(`/onboarding/${id}/create-account`)
      }
    } catch (error) {
      toast.error(error.response.data.error.friendlyMsg || 'Erro ao validar o código')
      console.log(error)
    }
    setLoading(false)
  }

  const resendCode = () => {
    dispatch(sendCodeRequest(phoneNumber))
    setCanResendCode(false)
  }

  useEffect(() => {;
    if (!phoneNumber) history.push('/')
  }, [])

  return (
    <Container>
      <div className="content">
        <div style={{transform: 'scale(0.5)'}}>
          <ProftFullIcon size={[200]} color={colors.primary.main} />
          {/* <NeoproLogo color={colors.primary.main} /> */}
        </div>
        <div className="content-card">
          <div className="card-title">
            <span>Verificar celular</span>
            <div className="icon">
              <PhoneIcon />
            </div>
          </div>
          <p className="description">
            Um código de verificação foi enviado via SMS para o número:
            <strong> {formatPhone(phoneNumber) || '...'}</strong>
            <br/>
            <br/>
            Digite o código abaixo para podermos validar seu número.
          </p>
          <VerificationInput 
            setAcessCode={setCode} 
            setAcessCode={(value) => setCode(value)} 
            length={6} 
            type="verification" 
          />
          <div className="resend-code">
            {!canResendCode &&
              <Countdown 
                date={nextUpdateAt}
                onComplete={() => setCanResendCode(true)}
                renderer={time => (
                  <span>{`${String(time.minutes).padStart(2, '0')}:${String(time.seconds).padStart(2, '0')}`}</span>
                )} 
              />
            }
            <Button 
              text="Reenviar Código"
              textColor={colors.secondary.main}
              disabled={!canResendCode} 
              onClick={resendCode} 
              style={{padding: 0}}
            />
          </div>
        </div>
        <div className="card-buttons">
          <h3 onClick={() => {
            dispatch(clearPhoneNumber())
            history.push('/')
          }}>Voltar</h3>
          <Button 
            text="Verificar"
            loading={loading}
            textColor={colors.lightGrey.lightest}
            backgroundColor={colors.primary.main}
            disabled={!code || code?.length < 6}
            onClick={submitVerification}
          />
        </div>
      </div>
    </Container>
  )
}

export default PhoneVerification;