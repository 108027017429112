import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  width: 400px;
  background: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px;
  -webkit-box-shadow: 0px 0px 17px -1px rgba(0,0,0,0.2);
  -moz-box-shadow: 0px 0px 17px -1px rgba(0,0,0,0.2);
  box-shadow: 0px 0px 17px -1px rgba(0,0,0,0.2);
  font-family: 'Inter', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;

  .close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: 0;
    margin: 10px;
    cursor: pointer;
    color: lightgray;
  }

  div {
    width: 100%;

    h1 {
      text-align: left;
      font-size: 20px;
      margin: 0 0 10px;
    }

    .input-container {
      position: relative;
      display: flex;
      align-items: center;

      input {
        display: flex;
        justify-content: center;
        margin: 10px auto;
        text-align: center;
        width: 90%;
        padding: 10px;
        font-family: 'Inter', sans-serif;
    
        font-size: 20px;
        border: 1px solid #C4C4C4;
        border-radius: 5px;
        outline: none;
      }

      label {
        position: absolute;
        right: 0;
        font-size: 18px;
        font-weight: 600;
        margin-right: 30px;
      }
    }

  .slider-container {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      font-weight: 500;
      color: #546E7A;
    }

    .slider {
      margin: 0 15px 0 10px;
      position: relative;
    }

    .MuiSlider-rail {
      background-color: #DDDDDD;
      height: 3px;
    }

    .MuiSlider-track {
      height: 3px;
      color: #FFC700
    }

    .Mui-focusVisible {
      box-shadow: none;
    }

    .MuiSlider-mark {
      padding: 3px;
      border-radius: 50%;
      background-color: #FFC700;
      margin-top: -2px;
    }

    .MuiSlider-thumb {
      color: white;
      border: 3px solid #FFC700;
      cursor: grab;
      padding: 4px;
      box-shadow: none;
    }

    .MuiSlider-active {
      cursor: grabbing;
      box-shadow: none;
    }
  }

  .example-card {
      width: 90%;
      background-color: #F6F2E4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      text-align: left;
      border-radius: 8px;
      margin: 20px auto;
      padding: 10px;
      color: #BFA182;

      h3 {
        margin: 0;
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 10px;
      }

      p {
        font-size: 12px;
        margin: 0;
      }
    }

    button {
      cursor: pointer;
      font-family: 'Inter', sans-serif;
      padding: 10px;
      width: 100%;
      border: none;
      border-radius: 5px;
      background-color: #702CB9;
      color: white;
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 600;
    }
  }
`;
