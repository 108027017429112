import React, { useEffect, useState } from 'react'

// Libs
import { useSelector, useDispatch } from 'react-redux'
import jwt from 'jwt-decode'
import clsx from 'clsx'

// Utils
import { formatPrice } from '../../utils/format'

// Material UI
import { makeStyles } from '@material-ui/styles'
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import TimelineIcon from '@material-ui/icons/Timeline'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';

// Components
import ValueCard from 'components/ValueCard'
import { StoresTable } from './components'
import { Toolbar } from '../../components'

// Actions
import { storesReportRequest } from '../../store/modules/report/actions'
import { clearRedux } from '../../store/modules/main/actions'
import { logout, userRequest } from 'store/modules/user/actions'
import EmptyState from 'components/EmptyState'
import { Store } from '@material-ui/icons'
import Storefront from '@material-ui/icons/Storefront'
import AddStoreForm from 'components/AddStoreForm'
import { createStoreRequest, storeRequest } from 'store/modules/store/actions'
import DialogFreeCredits from 'components/DialogFreeCredits'


const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

  },
  root: {
    padding: theme.spacing(1),
    margin: theme.spacing(2),
    minWidth: 300
  },

  card: {
    transition: 'all .2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.08)'
    },
    cursor: 'pointer'
  },
  pos: {
    marginBottom: 12
  },
  avatar: {
    justifyContent: 'center',
    width: '100px',
    height: '100px',
    marginBottom: theme.spacing(1)
  },
  sellsContainer: {
    display: 'flex',
    flexDirection: 'column'
  },
  toolbarWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    marginBottom: theme.spacing(1)
  },
  margin: {
    marginBottom: '16px',
    marginLeft: '8px'
  }
}))

const Stores = ({ history }) => {
  const dispatch = useDispatch()
  const classes = useStyles()

  // Redux
  const storesReport = useSelector(state => state.report.storesReport)
  const user = useSelector(state => state.user.user)
  const store = useSelector(state => state.store.store)

  const [gridView, setGridView] = useState(false)
  const [tab, setTab] = useState(0)

  const token = localStorage.getItem('@Proft:token')
  
  useEffect(() => {
    if (token) {
      const { id } = jwt(token)
      if (!storesReport && user && user.stores?.length > 0) {
        dispatch(storesReportRequest(id, token))
      } else {
        if (!user._id) dispatch(userRequest(id, token))
      }
    } else {
      window.localStorage.removeItem('@Proft:token')
      dispatch(logout())
      history.push(`/login`)
    }
  }, [token, user, storesReport])

  const handleSelectStore = (storeId) => {
    history.push(`/${storeId}/dashboard`)
  }

  useEffect(() => {
    //se tiver store quer dizer que ele ja entrou numa store e voltou sem ser no botao de trocar de loja
    if (store) {
      dispatch(clearRedux()) 
    }
  }, [])


  return (
    <div className={classes.root}>
      {user?._id && <DialogFreeCredits open={!user?.wallet?.credits?.find(coupon => coupon.name === "welcome")} />}
      { tab === 0 ? (
        <>
          <h2 style={{marginBottom: 20}}>Início</h2>
          <div className={classes.toolbarWrapper}>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <h4 variant="h5" style={{ marginRight: '8px' }}>Suas Lojas</h4>
              <Divider orientation="vertical" flexItem style={{ marginRight: '8px', marginLeft: '8px' }}/>
              {/* <FormControl className={classes.margin}>
                <InputLabel htmlFor="input-with-icon-adornment"></InputLabel>
                <Input
                  disableUnderline
                  id="input-with-icon-adornment"
                  startAdornment={
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  }
                />
              </FormControl> */}
            </div>
            <Toolbar
              onAdd={() => setTab(1)}
              actionText='Adicionar loja'
              buttonIcon={<AddIcon />}
              disableButton={user?.stores?.length > 0}
              tooltipMessage={user?.stores?.length > 0 && 'Você só pode criar uma loja gratuitamente!'}
              label={storesReport && storesReport.stores.length > 0 ? `${storesReport.stores.length} loja(s)` : ' Nenhuma loja'}
            />
          </div>
          <Divider className={classes.divider} />
          <Grid container spacing={4}>
            { user.stores?.length > 0 ? 
              <Grid item xs={12}>
              <>
                {gridView ? (
                  <Grid
                    container
                  // style={{
                  //   display: 'grid',
                  //   gridTemplateColumns: 'repeat(3, 1fr)',
                  // }}
                  >
                    {storesReport?.stores?.map(store => (
                      <Grid item lg={3} sm={6} xl={3} xs={12}>
                        <Card onClick={() => handleSelectStore(store._id)} key={String(store._id)} className={clsx(classes.root, classes.card)}>
                          <CardContent className={classes.container}>
                            <Avatar alt={store.name} className={classes.avatar} src={store.photo.url} />
                            <Typography variant='h5' component='h2'>
                              {store.name}
                            </Typography>
                            <Typography className={classes.pos} color='textSecondary'>
                              {store.address.city}
                            </Typography>
                            <Divider />

                            <List>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar style={{ background: '#2ecc71' }}>
                                    <LoyaltyIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Peças vendidas" secondary={store.total.items} />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar style={{ background: '#2ecc71' }}>
                                    <ShoppingCartIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Quantidade de vendas" secondary={store.total.sales} />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar style={{ background: '#2ecc71' }}>
                                    <AttachMoneyIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Faturamento" secondary={formatPrice(store.total.sold)} />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar style={{ background: '#2ecc71' }}>
                                    <TimelineIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Projeção" secondary={formatPrice(store.projection.sold)} />
                              </ListItem>
                              <ListItem>
                                <ListItemAvatar>
                                  <Avatar style={{ background: '#2ecc71' }}>
                                    <InsertChartIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary="Porcentual da meta" secondary={`${(store.total.sold / store.metric.acDayGoal * 100).toFixed(2)}%`} />
                              </ListItem>
                            </List>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                    <StoresTable
                      stores={storesReport ? storesReport.stores.map(store => ({
                        ...store,
                        sold: store.total.sold,
                        sales: store.total.sales,
                        items: store.total.items.toFixed(),
                        goalPercent: Number((((store.total.sold / store.metric.acDayGoal) || 0) * 100).toFixed(2)),
                        acDayGoal: store.metric.acDayGoal,
                        mainGoal: store.metric.mainGoal,
                        projection: store.projection.sold
                      })) : []}
                      handleSelectStore={handleSelectStore}
                    />
                  )}
              </>
              </Grid>
              : 
              <EmptyState
                icon={<Storefront fontSize="large"  />}
                title='Ops! Você ainda não tem lojas cadastradas.'
                description="As lojas adicionadas serão listadas aqui."
              />
            }
          </Grid>
        </>
      ) : tab === 1 && (
        <AddStoreForm 
          user={user} 
          setTab={setTab} 
          onClick={data => dispatch(createStoreRequest({userId: user._id, ...data}, token))} 
        />
      )

      }
    </div>
  )
}

export default Stores
