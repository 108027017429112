import React, { useEffect, useState } from 'react'
import { Switch, Redirect, useHistory } from 'react-router-dom'
import jwtDecode from 'jwt-decode'

import { RouteWithLayout } from './components'
import { Main as MainLayout, Minimal as MinimalLayout, Owner as OwnerLayout } from './layouts'

import {
  Dashboard as DashboardView,
  Inputs as InputsView,
  UserList as UserListView,
  Settings as SettingsView,
  // Settings as SettingsView,
  // SignUp as SignUpView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  Goals as GoalsView,
  Goal as GoalView,
  Input as InputView,
  Reports as ReportsView,
  Stores as StoresView,
  Sellers as SellersView,
  Services as ServicesView,
  ServiceDay as ServiceDayView,
  AddGoal,
  // StoreSettings as StoreSettingsView
} from './views'

// Views do Onboarding
import Login from 'views/Onboarding/Pages/Login'
import PhoneVerification from 'views/Onboarding/Pages/PhoneVerification'
import CreateAccount from './views/Onboarding/Pages/CreateAccount'
import { useDispatch, useSelector } from 'react-redux'
import { userRequest } from 'store/modules/user/actions'
import PromptModal from 'components/PromptModal'
import { MailIcon } from 'assets/Icons/MailIcon'
import { colors } from 'theme/colors'
import EmailVerification from 'views/Onboarding/Pages/EmailVerification'
import { sendCodeRequest } from 'store/modules/onboarding/actions'
import PasswordReset from 'views/Onboarding/Pages/PasswordReset'

const Routes = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const user = useSelector(state => state.user.user)

  const token = localStorage.getItem('@Proft:token')
  const [emailConfirmModal, setEmailConfirmModal] = useState(false)

  useEffect(() => {
    if (token) {
      if (!!user._id) { // garante que tem user
        if (
          !(user.identification?.email?.validated || user.email?.validated) && 
          !history.location.pathname.includes('verification') // nao aparece o modal se ele tiver na tela de verificar
        ) setEmailConfirmModal(true)
      } 
      else {
        const { id } = jwtDecode(token)
        dispatch(userRequest(id, token))
      }
    }
  }, [user])

  return (
    <>
      <PromptModal 
        open={!!emailConfirmModal}
        icon={<MailIcon size={[40, 40]} color={colors.primary.main} />}
        title={`Precisamos confirmar seu email (${user?.identification?.email?.value || user?.email?.value || user?.email || '...'})`}
        description={`Você ainda não confirmou o seu email! A confirmação é uma camada de segurança que garantirá à você a melhor experiência de uso com a Proft`}
        rightTitle="Confirmar email"
        onRight={() => {
          setEmailConfirmModal(false)
          dispatch(sendCodeRequest({email: user.identification?.email?.value}))
          history.push(`/verification/email=${user?.identification?.email?.value}`)
        }}
        onClose={() => setEmailConfirmModal(false)}
      /> 
      <Switch>
        <Redirect exact from='/' to='/login' />
        <RouteWithLayout
          component={DashboardView}
          exact
          layout={MainLayout}
          path='/:storeId/dashboard'
        />
        <RouteWithLayout
          component={UserListView}
          exact
          layout={MainLayout}
          path='/:storeId/users'
        />
        <RouteWithLayout
          component={InputsView}
          exact
          layout={MainLayout}
          path='/:storeId/inputs'
        />
        <RouteWithLayout
          component={InputView}
          exact
          layout={MainLayout}
          path='/:storeId/input/:id'
        />
        <RouteWithLayout
          component={StoresView}
          exact
          layout={OwnerLayout}
          path='/stores'
        />
        <RouteWithLayout
          component={SettingsView}
          exact
          layout={MainLayout}
          path='/store/:id'
        />
        <RouteWithLayout
          component={SellersView}
          exact
          layout={OwnerLayout}
          path='/:storeId/sellers'
        />
        <RouteWithLayout
          component={SettingsView}
          exact
          layout={MainLayout}
          path="/:storeId/settings"
        />
        <RouteWithLayout
          component={GoalsView}
          exact
          layout={MainLayout}
          path='/:storeId/goals'
        />
        <RouteWithLayout
          component={AddGoal}
          exact
          layout={MinimalLayout}
          path='/:storeId/goals/create'
        />
        <RouteWithLayout
          component={AddGoal}
          exact
          layout={MinimalLayout}
          path='/:storeId/goals/:goalId/edit'
        />
        <RouteWithLayout
          component={GoalView}
          exact
          layout={MainLayout}
          path='/:storeId/goals/:id/:userId?'
        />
        <RouteWithLayout
          component={ServicesView}
          exact
          layout={MainLayout}
          path='/:storeId/events'
        />
        <RouteWithLayout
          component={ServiceDayView}
          exact
          layout={MainLayout}
          path='/:storeId/events/:dayId'
        />
        <RouteWithLayout
          component={GoalView}
          exact
          layout={MainLayout}
          path='/:storeId/goal/:id/:userId?'
        />
        <RouteWithLayout
          component={ReportsView}
          exact
          layout={MainLayout}
          path='/:storeId/report'
        />
        {/* <RouteWithLayout
          component={SignUpView}
          exact
          layout={MinimalLayout}
          path="/register"
        /> */}
        <RouteWithLayout
          component={Login}
          exact
          layout={MinimalLayout}
          path='/login'
        />
        <RouteWithLayout
          component={PasswordReset}
          exact
          layout={MinimalLayout}
          path='/change-password/verification/cpf=:cpf'
        />
        <RouteWithLayout
          component={PasswordReset}
          exact
          layout={MinimalLayout}
          path='/change-password/:id'
        />
        <RouteWithLayout
          component={PhoneVerification}
          exact
          layout={MinimalLayout}
          path='/verification/phone=:phoneNumber'
        />
        <RouteWithLayout
          component={EmailVerification}
          exact
          layout={MinimalLayout}
          path='/verification/email=:userEmail'
        />
        <RouteWithLayout // criar conta gestor
          component={CreateAccount}
          exact
          layout={MinimalLayout}
          path='/onboarding/create-account'
        />
        <RouteWithLayout // criar conta user
          component={CreateAccount}
          exact
          layout={MinimalLayout}
          path='/onboarding/:id/create-account'
        />
        
        <RouteWithLayout
          component={NotFoundView}
          exact
          layout={MinimalLayout}
          path='/not-found'
        />
        {/* <Redirect to='/not-found' /> */}
      </Switch>
    </>
  )
}

export default Routes
