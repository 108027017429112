import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

// Material UI
import { makeStyles } from '@material-ui/styles'
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';

import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { ProfileCard } from 'components/Topbar/style';


const useStyles = makeStyles(theme => ({
  root: {
    margin: theme.spacing(1)
  },
  itemText: {
    fontWeight: '500',
    marginLeft: theme.spacing(1)
  },
  menuItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

const AccountMenuDropdown = ({ userData, onLogoff }) => {

  const classes = useStyles()

  const history = useHistory()

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  return (
    <div className={classes.root}>
      <Avatar style={{ cursor: 'pointer' }} ref={anchorRef} onClick={handleToggle} alt={userData?.name || 'Usuário'} src={userData?.photo?.url} />
        <ProfileCard open={open}>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList id="menu-list-grow" onKeyDown={handleListKeyDown}>
            <MenuItem onClick={handleClose}>
              <ListItem>
                <ListItemAvatar>
                    <Avatar src={userData?.photo?.url} />
                  </ListItemAvatar>
                  <ListItemText primary={userData?.name?.complete || '...'} secondary="Editar conta" onClick={() => history.push(`settings`)} />
                </ListItem>
            </MenuItem>
            {/* IR PARA PAGINA DE COBRANÇA */}
            {/* <MenuItem className={classes.menuItem} onClick={handleClose}>
              <AttachMoneyIcon />
              <Typography className={classes.itemText}>Cobrança</Typography>
            </MenuItem> */}
            <MenuItem className={classes.menuItem} onClick={onLogoff}>
              <ExitToAppIcon color="error"/>
              <Typography className={classes.itemText} color="error">Sair</Typography>
            </MenuItem>
          </MenuList>
        </ClickAwayListener>
      </ProfileCard>
    </div>
  )
}

AccountMenuDropdown.propTypes = {
  userData: PropTypes.object,
  onLogoff: PropTypes.func,
}

export default AccountMenuDropdown