import produce from 'immer'
import moment from 'moment'

const INITIAL_STATE = {
  error: null,
  loading: false,
  modal: false,
  currentGoal: null,
  currentGoalLoaded: false,
  savedGoal: {},
  goal: null,
  goalList: [],
  metadata: {
    pagination: {
      currentPage: 1,
      pageCount: 1,
      totalCount: -1,
      limit: 10
    }
  }
}

export default function goal(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@goal/GOAL_REQUEST': {
        draft.loading = true
        break
      }
      case '@goal/LIST_REQUEST': {
        draft.loading = true
        break
      }
      case '@goal/LIST_SUCCESS': {
        draft.goalList = action.payload.dataRequest.items
        draft.metadata = action.payload.dataRequest.metadata
        draft.loading = false
        break
      }
      case '@goal/SAVE': {
        break
      }
      case '@goal/CLEAR_SAVE': {
        draft.saved = false
        break
      }
      case '@goal/ADD_REQUEST': {
        draft.loading = true
        draft.saved = false
        break
      }
      case '@goal/ADD_SUCCESS': {
        draft.savedGoal = action.payload.dataRequest
        draft.goalList = [action.payload.dataRequest, ...state.goalList].sort((a, b) => new Date(a.month) - new Date(b.month))
        draft.loading = false
        draft.modal = false
        draft.saved = true
        break
      }
      case '@goal/FAILED': {
        draft.loading = false
        draft.error = action.payload.dataRequest
        break
      }
      case '@goal/GOAL_SUCCESS': {
        draft.goal = action.payload.dataRequest
        draft.loading = false
        const currentMonth = moment()
        currentMonth.set({
          date: 1,
          hour: 12,
          minute: 0,
          second: 0,
          millisecond: 0
        })
        // Já aproveita e vê se é o mês atual p/ atualizar o redux
        if ((currentMonth.toISOString() === moment(draft.goal.month).toISOString() || (draft.currentGoal && draft.currentGoal._id === draft.goal._id)) && draft.goal.config.active) {
          draft.currentGoal = draft.goal
          draft.currentGoalLoaded = true
        }
        // Atualizar na lista do listGoal também
        if (draft.goalList.length > 0) {
          draft.goalList = draft.goalList.map(goal => goal._id === draft.goal._id ? draft.goal : goal)
        }

        break
      }
      case '@modal/GOAL_OPEN': {
        draft.modal = true
        break
      }
      case '@modal/GOAL_CLOSE': {
        draft.modal = false
        break
      }
      case '@user/LOGOUT': {
        return INITIAL_STATE
      }
      case '@goal/CURRENT_SUCCESS': {
        draft.currentGoal = action.payload.dataRequest
        draft.currentGoalLoaded = true
        break
      }
      case '@goal/CURRENT_FAILED': {
        draft.currentGoalLoaded = false
        break
      }
      case '@goal/DELETE_REQUEST': {
        const goalIndex = draft.goalList.findIndex(
          g => g._id === action.payload.goalId
        )
        draft.goalList.splice(goalIndex, 1)
        break
      }
      case '@goal/EDIT_DAY_REQUEST': {
        draft.loading = true
        break
      }
      case '@goal/EDIT_MAINGOALS_REQUEST': {
        draft.loading = true
        break
      }
      case '@user/UNLINK_SUCCESS': {
        if (draft.currentGoal) {
          draft.currentGoal.usersWorking = [...draft.currentGoal.usersWorking].filter(u => u.userId._id !== action.payload.userId)
          draft.currentGoal.days = [...draft.currentGoal.days].map(day => ({ ...day, users: day.users.filter(user => user !== action.payload.userId) }))

          const findGoalIndex = draft.goalList.findIndex(goal => goal._id === draft.currentGoal._id)

          if (findGoalIndex >= 0) {
            draft.goalList[findGoalIndex].usersWorking = [...draft.goalList[findGoalIndex].usersWorking].filter(u => u.userId._id !== action.payload.userId)
            draft.goalList[findGoalIndex].days = [...draft.goalList[findGoalIndex].days].map(day => ({ ...day, users: day.users.filter(user => user !== action.payload.userId) }))
          }
        }
        break
      }
      case '@error/REQUEST_FAILED': {
        draft.loading = false
        break
      }
      case '@main/CLEAR_REDUX': {
        return INITIAL_STATE
      }
      default:
        return state
    }
  })
}
