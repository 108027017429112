import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { Tabs, Divider, colors, Grid } from '@material-ui/core'
import moment from 'moment'

import CalendarIcon from '@material-ui/icons/CalendarToday'

import Header from 'components/Header'
import { goalRequest, goalDeleteRequest, goalEditDayRequest, goalEditMainGoalsRequest } from 'store/modules/goal/actions'
import { userListRequest } from 'store/modules/user/actions'

import ValueCard from 'components/ValueCard'
import { formatPrice, formatPriceShort } from '../../utils/format'
import Goals from './components/Goals'
import EditGoalModal from './components/EditGoalModal'
import Sellers from './components/Sellers'
import Days from './components/Days'
import Sections from './components/Sections'
import { AttachMoney, DateRange, Today } from '@material-ui/icons'
import PeopleIcon from '@mui/icons-material/People';
import { TabOption } from './styles'


const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  tab: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'red',
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(1)
  }
}))

const Goal = ({ match, history }) => {
  const { id, userId, storeId } = match.params
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const goalList = useSelector(state => state.goal.goalList)
  const requestedGoal = useSelector(state => state.goal.goal)
  const currentGoal = useSelector(state => state.goal.currentGoal)
  const userList = useSelector(state => state.user.userList)
  const userListMetadata = useSelector(state => state.user.metadata)
  const user = useSelector(state => state.user.user)
  const store = useSelector(state => state.store.store)

  const token = window.localStorage.getItem('@Proft:token')

  const [goal, setGoal] = useState()
  const [users, setUsers] = useState([])
  const [newUser, setNewUser] = useState()

  const [tab, setTab] = useState(userId ? 'sellers' : 'goals')
  const [visibleEditGoal, setVisibleEditGoal] = useState(false)

  useEffect(() => {
    if (goal && user && store) {
      if (userListMetadata.pagination.totalCount > 0) {
        const usersWorking = goal.usersWorking.map(u => String(u.userId?._id))
        setUsers(store.users.filter(u => {
          const thisStore = u.stores.find(store => store.storeId === storeId)
          // Usuário já não deve estar na meta, não deve ser o usuário logado, e deve ser seller
          return thisStore ? !usersWorking.includes(String(u._id)) && u._id !== user._id && thisStore.type === 'seller' : false
        }))
      } else {
        dispatch(userListRequest(storeId, token))
      }
    }
  }, [goal, user, store])

  useEffect(() => {
    const listFind = goalList.find(g => g._id === id)
    if (listFind) {
      setGoal(listFind)
    } else if (requestedGoal && requestedGoal._id === id) {
      setGoal(requestedGoal)
    } else if (currentGoal) {
      if (currentGoal && currentGoal._id === id) {
        setGoal(currentGoal)
      }
    } else {
      dispatch(goalRequest(id, token))
    }
  }, [goalList, requestedGoal])

  useEffect(() => {
    if (goal) setVisibleEditGoal(false)
  }, [goal])

  useEffect(() => {
    if (userId && userList) {
      setNewUser(userList.find(u => u._id === userId))
    }
  }, [userId])

  const tabs = [
    { icon: <AttachMoney />, value: 'goals', label: 'Metas' },
    { icon: <PeopleIcon />, value: 'sellers', label: 'Vendedores' },
    { icon: <Today />, value: 'days', label: 'Dias' },
    { icon: <DateRange />, value: 'sections', label: 'Períodos' },
  ]

  const handleDeleteGoal = () => {
    confirm({
      title: `Meta de ${goal ? moment(goal.month).format('MMMM') : '...'}`,
      description: 'Tem certeza que deseja apagar a meta? Os lançamentos desse mês serão mantidos.',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(
          goalDeleteRequest(goal._id, window.localStorage.getItem('@Proft:token'))
        )
        history.push(`/${storeId}/goals`)
      })
      .catch(() => { })
  }

  return (
    <div className={classes.root}>
      <Header
        data={goal}
        goal
        route={`/${storeId}/goals`}
        title='Metas'
        actionText="Apagar"
        subtitle={`Meta de ${goal ? moment(goal.month).format('MMMM') : '...'}`}
        value={`${goal ? formatPrice(goal.mainGoals.total) : 0}`}
        handleDelete={handleDeleteGoal}
        secondaryText="Editar"
        handleSecondary={() => history.push(`/${storeId}/goals/${id}/edit`)}
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Meta da loja'
            value={`${goal ? formatPriceShort(goal.mainGoals.total) : 0}`}
            color='#682AC7'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Ticket médio'
            value={`${goal?.mainGoals.ticket >= 1000 ? formatPriceShort(goal?.mainGoals.ticket) : formatPrice(goal?.mainGoals.ticket || 0)}`}
            onClick={() => setVisibleEditGoal(true)}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='Preço médio'
            value={`${goal?.mainGoals.price >= 1000 ? formatPriceShort(goal?.mainGoals.price) : formatPrice(goal?.mainGoals.price || 0)}`}
            onClick={() => setVisibleEditGoal(true)}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='DSR'
            value={goal && goal.dsr}
            icon={<CalendarIcon />}
            onClick={() => setVisibleEditGoal(true)}
          />
        </Grid>
      </Grid>
      <Tabs
        className={classes.tabs}
        onChange={(e, value) => setTab(value)}
        scrollButtons='auto'
        value={tab}
        variant='scrollale'
        indicatorColor='primary'
      >
        {tabs.map(tab => (
          <TabOption icon={tab.icon} iconPosition="start" key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </Tabs>
      <Divider className={classes.divider} />

      <div className={classes.content}>
        {tab === 'goals' && <Goals goal={goal} />}
        {tab === 'sellers' && <Sellers goal={goal} users={users} newUser={user} newUser={newUser} setNewUser={setNewUser} />}
        {tab === 'days' && <Days goal={goal} onEditDay={(day) => dispatch(goalEditDayRequest(goal._id, day._id, day, token))} />}
        {tab === 'sections' && <Sections sections={goal.sections} />}

      </div>

      <EditGoalModal
        active={visibleEditGoal}
        onClose={() => setVisibleEditGoal(false)}
        goal={goal}
        onSave={data => dispatch(goalEditMainGoalsRequest(goal._id, data, token))}
      />
    </div>
  )
}

Goal.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default Goal
