import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.topTable.length}, 1fr);
  text-align: left;
  font-family: 'Inter', sans-serif;
  color: #546E7A;

  span {

    background-color: #fff;
    font-size: 14px;
    color: #546E7A;
    padding: 5px;
    border-bottom: 2px solid #EEEEEE;
    border-right: ${props => props.topTable.length === 1 ? '0' : '2'}px solid #EEEEEE;
  }
`;