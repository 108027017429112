import React, { useEffect, useState } from 'react';
import { MenuItem, Select } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import { Close, Info, Person, Warning } from '@material-ui/icons';
import StorefrontIcon from '@material-ui/icons/Storefront';
import PersonIcon from '@material-ui/icons/Person';
import ExitIcon from 'assets/Icons/ExitIcon';

import PromptModal from 'components/PromptModal';

import { formatPrice } from '../../../../../../../../../../../utils/format';
import CurrencyTextField from '../../../../../../../../../../../components/CurrencyTextField';

import { Container } from './styles';
import { useSelector } from 'react-redux';
import { getDaysInMonth } from 'date-fns/esm';

const GoalModal = (
  { 
    data,
    usersWorking,
    isCreatingNewGoal, 
    isEditingGoal, 
    currentGoal, 
    monthGoals,
    handleSetIsCreatingGoal, 
    handleSetIsEditingGoal, 
    createNewGoal, 
    editGoal, 
}) => {
  const [sellerGoal, setSellerGoal] = useState({ // Estado para controlar input de dados
    name: '1a meta',
    value: 0,
    bonusPercentage: null,
    commission: 1,
    bonus: 0,
    type: data.config?.commission?.storeSold ? 'store' : 'user',
    typeBonus: 'user'
  });

  const [totalCommission, setTotalCommission] = useState(0)
  const [isBonusEmpty, setIsBonusEmpty] = useState(true)


  // Callbacks de calculos de comissao
  const getCommission = ({goal, commission, weight}) => {
    return (goal * commission * weight)
  }

  const getDSR = () => {
    return getCommission({goal: sellerGoal.value, commission: (sellerGoal.commission / 100), weight: 1}) / (getDaysInMonth(new Date(data.month)) - data.dsr) * data.dsr
  }

  const getBonus = () => {
    const bonus = ( sellerGoal.bonusPercentage === true 
      ? (parseFloat(sellerGoal.bonus) / 100) * (sellerGoal.typeBonus === 'user' ? sellerGoal.value : data.mainGoals.total)
      : parseFloat(sellerGoal.bonus)
    )

    return bonus
  }

  const getComplement = () => {
    const complement = data.salary.base - getCommission({goal: sellerGoal.value, commission: sellerGoal.commission / 100, weight:1 }) - (data.dsr > 0 ? getDSR() : 0)
    return complement > 0 ? complement : 0
  }

  useEffect(() => {
    if (usersWorking?.length > 0 && monthGoals?.length === 0) {
      setSellerGoal({...sellerGoal, name: '1a meta', value: data.mainGoals.total / usersWorking.length})
    }
  }, [monthGoals, usersWorking])

  useEffect(() => { // Sempre que algo for repreenchido, ele recalcula para mostrar em tempo real
    setTotalCommission(
      (data.salary.directCommission ? 0 : data.salary.base) + (getBonus() || 0) + 
      (parseFloat(sellerGoal.commission) / 100 * (sellerGoal.type === 'user' ? sellerGoal.value : sellerGoal.type === 'store' ? data.mainGoals.total : 1)) +
      (data.dsr > 0 ? getDSR() : 0) +
      (data.salary.complement ? getComplement() : 0)
    )
  }, [sellerGoal])
  

  useEffect(() => { // Useeffect que ve se o cara ta editando a meta e modifica os valores % para virarem inteiros novamente
    if (currentGoal && isEditingGoal) {
      setSellerGoal(
        { ...currentGoal, 
          bonus: currentGoal.bonus * (currentGoal.bonusPercentage ? 100 : 1), 
          commission: currentGoal.commission * 100
        }
      )
      if (currentGoal.bonus > 0) setIsBonusEmpty(false)
    }
  }, [currentGoal, isEditingGoal])

 
  useEffect(() => { // Useeffect que monitora a criacao de metas p fazer as proximas virem preenchidas com os dados da anterior + alguns %
    if (monthGoals.length >= 1) {
      const lastGoal = monthGoals[monthGoals.length - 1] 

      setSellerGoal({
        name: `${monthGoals.length + 1}a meta`,
        value: lastGoal.value + lastGoal.value * 0.1,
        commission: lastGoal.commission * 100,
        bonus: lastGoal.bonus * (lastGoal.bonusPercentage ? 100 : 1),
        bonusPercentage: lastGoal.bonus === 0 ? 'no-bonus' : lastGoal.bonusPercentage,
        type: lastGoal.type,
        typeBonus: lastGoal.typeBonus
      })
    }
  }, [monthGoals, isCreatingNewGoal])

  return (
    <>
    <Modal
      onClose={() => {
        handleSetIsCreatingGoal(false)
        handleSetIsEditingGoal(false)
      }}
      open={isCreatingNewGoal || isEditingGoal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500
      }}
    >
      <Container>
        <div className='goal-modal-content'>
          <div 
            className='close'
            onClick={() => {
              handleSetIsCreatingGoal(false)
              handleSetIsEditingGoal(false)
            }}
          >
            <Close />
          </div>
          <div className='goal-modal-header'>
            <div className='goal-modal-title'>
              { isCreatingNewGoal ? <h1> Nova meta para o vendedor </h1> : <h1> Editando meta para o vendedor </h1>}
              <span>Indique o valor da meta, valor e referência da comissão e bonificação.</span>
            </div>
          </div>

          <div className='goal-modal-form'>             
            <div className="goal-modal-form-row">
              <div>
                Para a meta 
                <input 
                  value={sellerGoal.name || ''}
                  placeholder="1a meta" 
                  style={{ width: 150 }}
                  onChange={e => setSellerGoal({...sellerGoal, name: e.target.value})} 
                />, 

                quando o vendedor vender 
                <CurrencyTextField
                  fullWidth
                  onFocus={event => event.target.select()}
                  value={sellerGoal.value || ''}
                  currencySymbol="R$"
                  decimalCharacter=","
                  placeholder="20000"
                  digitGroupSeparator="."
                  inputProps={{ style: { textAlign: 'left' } }}
                  onChange={(event, value) => {
                    setSellerGoal({...sellerGoal, value: value})
                  }}
                />
              </div>
              

              <div className="goal-modal-form-row" >
                receberá 
                <span>
                  <input
                    type="text"
                    pattern="[0-9]+"
                    maxLength={4}
                    value={sellerGoal.commission && String(sellerGoal.commission).substr(0, 4) || ''}
                    style={{ width: 80, marginRight: 0 }} 
                    onChange={e => setSellerGoal({...sellerGoal, commission: e.target.value > 100 ? 100 : String(e.target.value).replace(',','.')})}
                    placeholder="0.6"
                  />
                  <span style={{ marginRight: 10, paddingBottom: 7, borderBottom: '1px solid #C4C4C4', color: '#702CB9' }}>
                    %
                  </span>
                </span>

                de <strong>comissão</strong> de quanto 
                <Select 
                  value={sellerGoal.type || ''}
                  onChange={e => {
                    setSellerGoal({...sellerGoal, type: e.target.value})
                  }}        
                >
                  <MenuItem value='store'>
                    <Option store />
                  </MenuItem>
                  <MenuItem value='user'>
                    <Option />
                  </MenuItem>
                </Select> 

                vender            
              </div>

              
              <div className="goal-modal-form-row">
                e {typeof sellerGoal.bonusPercentage !== "string" ? 'terá ' : ''}
                  {!sellerGoal.bonusPercentage ? <span>de <strong>bônus</strong></span> : ''}

                {!isBonusEmpty &&
                ( sellerGoal.bonusPercentage === true ?
                  <span className="bonus-input-percent">
                    <input 
                      type="text" 
                      pattern="[0-9]*"
                      maxLength={4}
                      placeholder="0.6"
                      value={sellerGoal.bonus && String(sellerGoal.bonus).substr(0,4) || ''}
                      style={{ width: 80, marginRight: 0 }} 
                      onChange={e => setSellerGoal({...sellerGoal, bonus: e.target.value > 100 ? 100 : String(e.target.value).replace(',','.')})}
                    />
                    <span style={{ paddingBottom: 7, paddingRight: 5, borderBottom: '1px solid #C4C4C4', marginRight: -5, color: '#702CB9' }}>
                      %
                    </span>
                  </span>
                  : sellerGoal.bonusPercentage === false &&
                  <CurrencyTextField
                    fullWidth
                    value={sellerGoal.bonus || ''}
                    onFocus={event => event.target.select()}
                    currencySymbol="R$"
                    decimalCharacter=","
                    digitGroupSeparator="."
                    placeholder="2000"
                    inputProps={{ style: { textAlign: 'left'} }}
                    onChange={(event, value) => setSellerGoal({...sellerGoal, bonus: value})}
                  />
                  )
                }

                <Select 
                  labelId="goal-bonus-label"
                  id="goal-bonus"
                  label="de bonificação"
                  className='bonus-select'
                  value={sellerGoal.bonusPercentage || ''}
                  style={{ width: typeof sellerGoal.bonusPercentage === "boolean" ? 20 : 150, top: sellerGoal.bonusPercentage === true && 12, marginLeft: 3}}
                  onChange={ev => {
                    setSellerGoal({...sellerGoal, bonusPercentage: ev.target.value, bonus: 0})

                    setIsBonusEmpty(false)
                  }}
                >
                  <MenuItem 
                    id="bonus-zero"
                    value={'no-bonus'}
                  > 
                    <h5 style={{fontSize: 14}}>Sem Bonificação</h5>
                  </MenuItem>
                  <MenuItem
                    id="bonus-percent"
                    value={true} 
                  >
                    <h4>% - Porcentagem</h4>
                  </MenuItem>
                  <MenuItem 
                    id="bonus-value"
                    value={false} 
                  > 
                    <h4>R$ - Valor Fechado</h4>
                  </MenuItem>
                </Select>

                { sellerGoal.bonusPercentage === true && // ta === true pq se for sem bonificacao ele recebe uma string e passa caso tenha algo
                  <>
                    de <strong>bônus</strong> de quanto
                    <Select 
                      value={sellerGoal.typeBonus || ''}
                      onChange={ev => setSellerGoal({...sellerGoal, typeBonus: ev.target.value})}
                    >
                      <MenuItem id="bonus-store" value='store'>
                        <Option store />
                      </MenuItem>
                      <MenuItem id="bonus-user" value='user'>
                        <Option />
                      </MenuItem>
                    </Select>
                    vender
                  </>
                }    
              </div>
            </div>
          </div>

               
          <div className='goal-modal-footer'>
            <div className="sellers-count-card">
              <Info />
              <div>
                <span>Essa é uma previsão estimada! (aconselhamos você consultar o valor de comissão individual na etapa de revisão)</span>
              </div>
            </div>
            <div className="goal-infos">
              <div>
                <span className='total-commission'>{formatPrice(totalCommission || 0)}</span>
                <span>{`${!data.salary.directCommission ? 'Piso salarial + ' : ''} Comissão + Bonificação ${data.dsr > 0 && ' + DSR'}`}</span>
              </div>
              <button 
                className='stepper-button-next' 
                disabled={
                  isCreatingNewGoal &&
                  ( 
                    !sellerGoal.value ||
                    (typeof (sellerGoal.bonusPercentage) === 'boolean' && !sellerGoal.bonus) || 
                    !sellerGoal.commission || 
                    !sellerGoal.name || 
                    !sellerGoal.type||
                    ((sellerGoal.bonusPercentage === true && sellerGoal.bonus) && !sellerGoal.typeBonus)
                  )
                }
                onClick={() => {
                  if (isCreatingNewGoal) {
                    createNewGoal({
                      ...sellerGoal,                  
                      commission: parseFloat(sellerGoal.commission/100),
                      bonusPercentage: typeof (sellerGoal.bonusPercentage) === 'boolean' ? sellerGoal.bonusPercentage : sellerGoal.bonus == 0 ? false : true,
                      bonus: parseFloat(sellerGoal.bonusPercentage ? (sellerGoal.bonus/100) : sellerGoal.bonus),
                      typeBonus: sellerGoal.typeBonus ? sellerGoal.typeBonus : 'user'
                    });
                  } else {
                    if (isEditingGoal) {
                      editGoal({
                        name: sellerGoal.name ? sellerGoal.name : 'Meta sem nome',
                        value: sellerGoal.value ? sellerGoal.value : 0,
                        commission: sellerGoal.commission ? parseFloat(sellerGoal.commission/100) : 0,
                        type: sellerGoal.type ? sellerGoal.type : data.config.commission.storeSold ? "store" : "user",
                        bonusPercentage: typeof (sellerGoal.bonusPercentage) === "boolean" ? sellerGoal.bonusPercentage : false,
                        bonus: parseFloat(sellerGoal.bonusPercentage ? sellerGoal.bonus/100 : sellerGoal.bonus ? sellerGoal.bonus : 0),
                        typeBonus: sellerGoal.typeBonus ?  sellerGoal.typeBonus : "user"
                      })
                    }
                  }
                  handleSetIsCreatingGoal(false)
                  handleSetIsEditingGoal(false)
                }}
              >
                { isCreatingNewGoal ? 'Adicionar' : 'Editar'}
              </button>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
    </>
  );
}

export const Option = ({ store }) => {
  return (
    <>
    { store ? <StorefrontIcon /> : <PersonIcon fontSize='small' /> }
    <span style={{ marginLeft: 5, fontSize: 16 }}>{ store ? 'a loja' : 'o vendedor' }</span>
    </>
  )
}

export default GoalModal;