import React, { useState, useEffect, useRef } from 'react'

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Badge from '@material-ui/core/Badge';
import NotificationsIcon from '@material-ui/icons/Notifications';
import IconButton from '@material-ui/core/IconButton';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';

import Typography from '@material-ui/core/Typography'
import TelegramIcon from '@material-ui/icons/Telegram';
import Grid from '@material-ui/core/Grid';


// import MenuItem from '@material-ui/core/MenuItem';
// import MenuList from '@material-ui/core/MenuList';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
// import Avatar from '@material-ui/core/Avatar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  paper: {
    padding: theme.spacing(3),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  messageText: {
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(1),
    fontWeight: 'bold'
  },
  badge: {
    marginRight: '1rem',
    backgroundColor: '#5F279A',
    padding: theme.spacing(0.5),
    borderRadius: '5px'
  }
}));

const NewNotifications = () => {

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <Badge onClick={handleToggle} ref={anchorRef} badgeContent={2} color="error" className={classes.badge}>
        <IconButton size="small">
          <NotificationsIcon style={{ color: "#fff" }}/>
        </IconButton>
      </Badge>
      <Popper style={{ zIndex: 100, marginTop: '1.5rem' }} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
            >
              <Paper className={classes.paper}>
                <ClickAwayListener onClickAway={handleClose}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TelegramIcon />
                        <Typography className={classes.messageText}>Ainda não tem nada por aqui</Typography>
                    </Grid>
                  </Grid>
                  {/* <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </MenuList> */}
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
    </>
  )
}

export default NewNotifications