import React from 'react';

import { Container } from './styles';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

const ReviewItem = ({title, value, subValue, contentChildren, children, valueTextColor, extraChildren}) => {
  return (
    <Container>
    { children ?
      <>
        {children}
      </>
      :
      <>
       <div className="content-card-info">
          <div className="content-card-left">
            <span className="content-card-title">{title}</span>
            {extraChildren}
          </div>
          <ArrowRightIcon className="content-card-arrow" />
       </div>
        { contentChildren ?
          <div className="content-card-content">
            {contentChildren}
          </div>
          :
          <>
            <span 
              className="content-card-value" 
              style={{color: valueTextColor ? valueTextColor : '#455A64'}}
            >
              {value}
            </span>
            { subValue &&
              <span className="content-card-subvalue">
                {subValue}
              </span>
            }
          </>  
        }
      </>
    }
    </Container>
  );
}

export default ReviewItem;