import { combineReducers } from 'redux'

import onboarding from './onboarding/reducer'
import goal from './goal/reducer'
import input from './input/reducer'
import user from './user/reducer'
import error from './error/reducer'
import report from './report/reducer'
import store from './store/reducer'
import main from './main/reducer'
import integration from './integration/reducer'
import day from './day/reducer'



export default combineReducers({
  onboarding,
  goal,
  input,
  user,
  error,
  report,
  store,
  main,
  integration,
  day
})
