import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { useHistory } from 'react-router-dom'
import moment from 'moment'

import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardActions,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  IconButton
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'

import { formatPrice } from '../../../utils/format'
import { Delete, Visibility } from '@material-ui/icons'
import { useConfirm } from 'material-ui-confirm'
import { useDispatch } from 'react-redux'
import { goalDeleteRequest } from 'store/modules/goal/actions'
import Tooltip from './AddGoal/components/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableRow: {
    cursor: 'pointer',
    minHeight: 60,
  },
  draftTag: {
    backgroundColor: '#ffd991',
    fontSize: 12,
    marginLeft: 10,
    padding: '3px 5px',
    border: '1px solid #bf9443',
    borderRadius: 5,
  }
}))

export default function GoalsTable({
  className,
  goals,
  storeId,
  onChangeRows,
  onChangePage,
  metadata,
  ...rest
}) {
  const classes = useStyles()
  const history = useHistory()
  const confirm = useConfirm()
  const dispatch = useDispatch()

  const handleDeleteGoal = (goal) => {
    confirm({
      title: `Meta de ${goal.month ? moment(goal.month).format('MMMM') : '...'}`,
      description: `Tem certeza que deseja apagar o rascunho da meta de ${goal.month ? moment(goal.month).format('MMMM') : '...'}?`,
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(
          goalDeleteRequest(goal._id, window.localStorage.getItem('@Proft:token'))
        )
        history.push(`/${storeId}/goals`)
      })
      .catch(() => { })
  }

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell style={{ width: '2rem' }} />
                  <TableCell>Mês</TableCell>
                  <TableCell>Meta da Loja</TableCell>
                  <TableCell>Meta de Ticket</TableCell>
                  <TableCell>Meta de Preço Médio</TableCell>
                  <TableCell>Quantidade de Vendedores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {goals && goals.length > 0 ? (
                  goals.map((goal, i) => (
                    <TableRow
                      className={classes.tableRow}
                      hover
                      key={i}
                      selected={false}
                      onClick={() => goal.config?.active && history.push(`/${storeId}/goals/${goal._id}`)}
                      style={{cursor: goal.config?.active ? 'pointer' : 'default'}}
                    >
                      <TableCell align='center' style={{position: 'relative', display: 'flex'}}>
                       { goal.config?.active && 
                        <IconButton
                            aria-label="view" 
                            coloer="secundary"
                            onClick={() => history.push(`/${storeId}/goals/${goal._id}`)} 
                            data-tip data-for={`view-goal-${i}-modal`}
                            style={{width: 35, height: 35}}
                          >
                            <Visibility style={{ color: '#63727A' }} />
                            <Tooltip 
                              id={`view-goal-${i}-modal`} 
                              content={
                                <p>Visualizar meta de {moment(goal.month).format('MMMM')}</p>
                              }
                              place="right"
                            />
                          </IconButton>
                        }
                        {!goal.config?.active &&
                          <> 
                            <IconButton
                              aria-label="edit" 
                              coloer="secundary"
                              onClick={() => history.push(`/${storeId}/goals/${goal._id}/edit`)} 
                              data-tip data-for={`edit-goal-${i}-modal`}
                              style={{width: 35, height: 35}}
                            >
                              <EditIcon style={{ color: '#63727A' }} />
                              <Tooltip 
                                  id={`edit-goal-${i}-modal`} 
                                  content={
                                    <p>Editar meta de {moment(goal.month).format('MMMM')}</p>
                                  }
                                  place="right"
                                />
                            </IconButton>
                            <IconButton 
                              aria-label="delete" 
                              coloer="secundary"
                              onClick={() => handleDeleteGoal(goal)} 
                              data-tip data-for={`delete-goal-${i}-modal`}
                              style={{width: 35, height: 35}}
                            >
                              <Delete style={{color: '#f04f65'}} />
                              <Tooltip 
                                id={`delete-goal-${i}-modal`} 
                                content={
                                  <p>Deletar rascunho da meta de {moment(goal.month).format('MMMM')}</p>
                                }
                                place="right"
                              />
                            </IconButton>
                          </>
                        }
                      </TableCell>
                        <TableCell className={!goal.config?.active ? classes.nameContainer : undefined}>
                          <Typography variant='body1'>
                            {moment(goal.month).format('MMMM/YYYY')}
                            { !goal.config?.active &&
                              <>
                                <span className={classes.draftTag}>Rascunho</span>
                              </>
                            }
                          </Typography>
                        </TableCell>
                      <TableCell>{formatPrice(goal.mainGoals?.total ? goal.mainGoals?.total : 0)}</TableCell>
                      <TableCell>
                        {formatPrice(goal.mainGoals?.ticket ? goal.mainGoals?.ticket : 0)}
                      </TableCell>
                      <TableCell>{formatPrice(goal.mainGoals?.price ? goal.mainGoals?.price : 0)}</TableCell>
                      <TableCell>{goal.usersWorking ? goal.usersWorking?.length : 0}</TableCell>
                    </TableRow>
                  ))
                ) : (
                    <TableRow>
                      <TableCell colSpan='6' style={{ textAlign: 'center' }}>
                        {goals ? 'Sem metas cadastradas.' : 'Carregando...'}
                      </TableCell>
                    </TableRow>
                  )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component='div'
          count={metadata ? metadata.pagination.totalCount : 0}
          onChangePage={(event, page) => onChangePage(page)}
          onChangeRowsPerPage={(event, rows) => onChangeRows(rows)}
          page={metadata ? metadata.pagination.currentPage - 1 : 0}
          rowsPerPage={metadata.pagination.limit}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </CardActions>
    </Card>
  )
}

GoalsTable.propTypes = {
  className: PropTypes.string,
  goals: PropTypes.array,
  storeId: PropTypes.string,
  onChangeRows: PropTypes.func,
  onChangePage: PropTypes.func,
  metadata: PropTypes.object
}
