import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import EditIcon from '@material-ui/icons/Edit'
import moment from 'moment'
import { formatPrice } from '../../../../utils/format'


import EditDayModal from './components/EditDayModal'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

const Days = ({ goal, onEditDay }) => {
  const classes = useStyles()
  const [modal, setModal] = useState(false)
  const [data, setData] = useState()

  const handleOpenModal = data => {
    setData(data)
    setModal(true)
  }

  useEffect(() => {if(goal) setModal(false)}, [goal])

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '2rem' }} />
              <TableCell>Dia útil</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Meta</TableCell>
              <TableCell>Vendedores</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goal &&
              goal.days.map(row => (
                <TableRow
                  key={row._id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => handleOpenModal(row)}
                >
                  <TableCell align='center'><EditIcon style={{ color: '#63727A' }} /></TableCell>
                  <TableCell>
                    {row.working ? (
                      <CheckCircleIcon style={{ color: '#2ecc71' }} />
                    ) : (
                      <NotInterestedIcon />
                    )}
                  </TableCell>
                  <TableCell>
                    {moment(row.date).format('DD - dddd')}
                  </TableCell>
                  <TableCell>
                    <b>{row.working ? `${formatPrice(row.goal)}` : '-'}</b>
                  </TableCell>
                  <TableCell>
                    {row.working ? row.users.length : '-'}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditDayModal 
        active={modal}
        onClose={() => setModal(false)} 
        onSubmit={onEditDay}
        data={data} 
      />
    </>
  )
}

export default Days
