import styled, {css} from 'styled-components';
import { colors } from '../../../../theme/colors';

export const Container = styled.button`
  border-radius: 30px;
  min-width: 160px;
  width: ${({fullWidth}) => fullWidth && '100%'};
  display: flex;
  justify-content: center;
  align-items: center;
  border-width: 2;
  border-style: solid;
  font-size: 14px;
  font-weight: 700;
  font-family: "Commissioner";
  cursor: pointer;
  padding: 12px 24px;
  outline: none;
  transition: 0.3s ease-in-out;
  
  ${props => ({
    borderColor: `${props.borderColor ? props.borderColor : 'transparent'}`,
    backgroundColor: `${props.backgroundColor ? props.backgroundColor : 'transparent'}`,
    color: `${props.textColor}`,
  })}

  h4 {
    font-weight: 700;
    font-size: 16px;
    margin-right: ${({hasIcon}) => hasIcon ? '10px' : '0px'};
  }

  &:disabled {
    background-color: ${colors.darkGrey.main};
    border-color: transparent;
    color: ${colors.lightGrey.light};
    cursor: not-allowed;
  }
`;
