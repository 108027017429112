import React from 'react';
import { TextField } from '@material-ui/core';
import { Container, InputContainer } from './styles';
import Alert from 'views/Onboarding/Pages/CreateAccount/Components/Alert';

const Input = ({setValue, iconLeft, iconRight, onIconRight, error, success, selectable, margin, disabled, ...rest}) => {
  return (
    <Container disabled={disabled} margin={margin}>
      <InputContainer selectable={selectable} error={error} hasIcon={[iconLeft, iconRight]}>
        {iconLeft && <div className="icon-left">{iconLeft}</div>}
        <TextField
          className="input"
          variant="outlined"
          disabled={disabled}
          onChange={setValue}
          {...rest}
        />
        {iconRight && <div className="icon-right" onClick={onIconRight}>{iconRight}</div>}
      </InputContainer> 
      <div className={`${error && "error" || success && "success"}-container`}>
        <Alert message={error || success} error={error} success={success} />
      </div>
    </Container>
  )
}

export default Input;