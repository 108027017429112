import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import GoalsTable from './components/GoalsTable'
import AddGoalModal from './components/AddGoalModal'

import { Toolbar } from '../../components'

import {
  goalListRequest,
  goalAddRequest,
  handleOpenModal,
  handleCloseModal,
  clearGoalSave
} from '../../store/modules/goal/actions'
import { userListRequest } from '../../store/modules/user/actions'
import AddGoal from './components/AddGoal'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  }
}))

export default function Goals({ match }) {
  const userList = useSelector(state => state.user.userList)
  const userMetadata = useSelector(state => state.user.metadata)
  const goalList = useSelector(state => state.goal.goalList)
  const goalMetadata = useSelector(state => state.goal.metadata)
  const loading = useSelector(state => state.goal.loading)
  const modalGoal = useSelector(state => state.goal.modal)

  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()
  const confirm = useConfirm()

  const { storeId } = match.params
  const token = window.localStorage.getItem('@Proft:token')
  
  const [hasSellers, setHasSellers] = useState(
    userList &&
    userList.length > 0 &&
    userList
      .filter(
        user =>
          user.stores.filter(
            store => store.storeId === storeId && store.type === 'seller'
          ).length > 0
      )
      .map(seller => seller._id).length > 0
  )

  useEffect(() => {
    if (goalMetadata.pagination.totalCount < 0) {
      dispatch(goalListRequest(storeId, token))
    }
    if (userMetadata.pagination.totalCount < 0) {
      dispatch(userListRequest(storeId, token))
    }
  }, [])

  useEffect(() => {
    setHasSellers(
      userList &&
      userList.length > 0 &&
      userList
        .filter(
          user =>
            user.stores.filter(
              store => store.storeId === storeId && store.type === 'seller'
            ).length > 0
        )
        .map(seller => seller._id).length > 0
    )
  }, [storeId, userList])

  const onAdd = () => {
    if (hasSellers) {
      dispatch(clearGoalSave())
      history.push(`/${storeId}/goals/create`)
    } else {
      confirm({
        title: 'Sem vendedores!',
        description: 'Você ainda não tem vendedores cadastrados, antes de criar uma meta, vá até a tela de usuários e registre pelo menos um vendedor.',
        confirmationText: 'Ir para lá!',
        cancellationText: 'Fechar'
      })
        .then(() => {
          history.push(`/${storeId}/users`)
        })
        .catch(() => { })
    }
  }

  const onRefresh = () => {
    dispatch(goalListRequest(storeId, token))
    dispatch(userListRequest(storeId, token))
  }

  const addGoal = response => {
    dispatch(goalAddRequest(response, token))
  }

  const handleChangePage = (page) => {
    if (page + 1 <= goalMetadata.pagination.pageCount) {
      dispatch(goalListRequest(
        storeId,
        token,
        page + 1,
        goalMetadata.pagination.limit
      ))
    }
  }

  const handleChangeRows = (rowsNumber) => {
    dispatch(goalListRequest(
      storeId,
      token,
      goalMetadata.pagination.currentPage,
      Number(rowsNumber.props.value)
    ))
  }

  return (
    <div className={classes.root}>
      <Toolbar
        onAdd={onAdd}
        onRefresh={onRefresh}
        actionText='Adicionar Meta'
      />
      <GoalsTable
        storeId={storeId}
        goals={goalList}
        metadata={goalMetadata}
        onChangeRows={handleChangeRows}
        onChangePage={handleChangePage}
      />
      {/* {hasSellers && (
        // <AddGoalModal
        //   active={modalGoal}
        //   loading={loading}
        //   onClose={() => dispatch(handleCloseModal())}
        //   onSubmit={addGoal}
        //   goals={goalList}
        //   sellers={userList.filter(
        //     user =>
        //       user.stores.filter(
        //         store => store.storeId === storeId && store.type === 'seller'
        //       ).length > 0)}
        // />
      )} */}
    </div>
  )
}
