import styled, {css} from "styled-components";

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff !important;
`;

export const AddGoalStepContainer = styled.div`
  background-color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: calc(100vh - 140px);
  margin-top: 140px;
  width: 98vw;
  position: relative;
  pointer-events: ${props => props.loadingData ? 'none' : 'inherit'};
  opacity: ${props => props.loadingData ? '0.2' : '1'};

  .button-skip-to-step {
    position: fixed;
    bottom: 0;
    left: 0;
    font-family: 'Inter', sans-serif;
    margin-bottom: 60px;
    margin-left: 60px;
    padding: 15px 20px;
    background-color: #702CB9;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 18px;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    &:hover {
      transform: translateX(10px);
    }
  }
`;

export const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex: 1;
  transition: 0.4s ease-in-out;
  position: relative;
  background-color: #fff !important;

  .goal-step-container {
    margin: 5rem 0;

    .goal-step-card-container {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-end;

      .goal-step-card-container-title {
        font-family: 'Inter', sans-serif;
        font-size: 22px;
        margin-bottom: 24px;
        color: #263238;
      }

      .goal-step-card-buttons {
        display: flex;
        justify-content: center; 
        align-items: center;
      }
    }

    .stepper-button-next {
      font-family: 'Inter', sans-serif;
      margin-top: 33px;
      margin-left: auto;
      padding: 9px 12px;
      background-color: #702CB9;
      text-transform: uppercase;
      font-weight: 600;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;

      .next-button-icon {
        font-size: 10px;
        margin-left: 10px;
      }
    }

    button:disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }

    .stepper-button-edit {
      background-color: #E8E5EB;
      color: #702cb9;
      padding: 10px;
      text-transform: uppercase;
      border: none;
      border-radius: 5px;
      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      cursor: pointer;
      margin-left: auto;
      font-family: 'Inter', sans-serif;
    }
  }
`;

export const SupportButton = styled.a`
  overflow: hidden;
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 30px;
  cursor: pointer;
  z-index: 99;
  border-radius: 50%;
  background: #FFFFFF;
  width: 60px;
  height: 60px;

  font-family: 'Inter';
  font-weight: 600;
  color: #37474F;

  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);
  transition: 0.3s ease-in-out;

  span {
    margin-left: 7px;
    min-width: 130px;
  }

  ${props => props.textVisible && css`
    width: 200px;
    height: 50px;
    border-radius: 8px;
  `}
`