import React from 'react';

export const ArrowRightIcon = ({color = '#fff'}) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M10 20C4.47967 19.994 0.00606237 15.5204 0 10V9.80002C0.109931 4.30455 4.63459 -0.0720257 10.1307 0.000898217C15.6268 0.0738221 20.0337 4.5689 19.9978 10.0654C19.9619 15.5618 15.4966 19.9989 10 20ZM10 2.00002C5.58172 2.00002 2 5.58174 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C17.995 5.5838 14.4162 2.00498 10 2.00002ZM10 15L8.59 13.59L11.17 11H5V9.00002H11.17L8.59 6.41002L10 5.00002L15 10L10 15Z" 
    fill={color}/>
    </svg>
    
  );
}