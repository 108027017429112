import React from 'react'
export const MetaDistribuida = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.0975 31.7993C20.5896 31.7993 23.432 28.957 23.432 25.4649C23.432 21.9729 20.5896 19.1305 17.0975 19.1305C13.6055 19.1305 10.7631 21.9729 10.7631 25.4649C10.7631 28.957 13.6055 31.7993 17.0975 31.7993ZM17.0975 34.9665C12.8746 34.9665 4.42871 37.078 4.42871 41.301V44.4682H29.7664V41.301C29.7664 37.078 21.3205 34.9665 17.0975 34.9665Z" fill="#E5E5E5"/>
  <path d="M50.4755 31.7993C53.9675 31.7993 56.8099 28.957 56.8099 25.4649C56.8099 21.9729 53.9675 19.1305 50.4755 19.1305C46.9834 19.1305 44.1411 21.9729 44.1411 25.4649C44.1411 28.957 46.9834 31.7993 50.4755 31.7993ZM50.4755 34.9665C46.2525 34.9665 37.8066 37.078 37.8066 41.301V44.4682H63.1443V41.301C63.1443 37.078 54.6984 34.9665 50.4755 34.9665Z" fill="#C4C4C4"/>
  <path d="M83.8524 31.7993C87.3445 31.7993 90.1868 28.957 90.1868 25.4649C90.1868 21.9729 87.3445 19.1305 83.8524 19.1305C80.3604 19.1305 77.518 21.9729 77.518 25.4649C77.518 28.957 80.3604 31.7993 83.8524 31.7993ZM83.8524 34.9665C79.6295 34.9665 71.1836 37.078 71.1836 41.301V44.4682H96.5213V41.301C96.5213 37.078 88.0754 34.9665 83.8524 34.9665Z" fill="#C4C4C4"/>
  <path d="M12.7939 40.1641L21.4023 48.7724" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M12.7939 48.7724L21.4023 40.1641" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M13.4432 71.2676H6.86518C5.4846 71.2676 4.34766 70.1307 4.34766 68.7501V65.5017C4.34766 64.1211 5.4846 62.9841 6.86518 62.9841H13.4432C14.8238 62.9841 15.9608 64.1211 15.9608 65.5017V68.7501C15.9608 70.1307 14.8238 71.2676 13.4432 71.2676Z" fill="#702CB9"/>
  <path d="M27.33 71.2676H20.7519C19.3713 71.2676 18.2344 70.1307 18.2344 68.7501V65.5017C18.2344 64.1211 19.3713 62.9841 20.7519 62.9841H27.33C28.7105 62.9841 29.8475 64.1211 29.8475 65.5017V68.7501C29.7663 70.1307 28.6293 71.2676 27.33 71.2676Z" fill="#702CB9"/>
  <path d="M60.6268 56.0812H40.3242C38.9436 56.0812 37.8066 54.9443 37.8066 53.5637V50.3153C37.8066 48.9347 38.9436 47.7977 40.3242 47.7977H60.708C62.0886 47.7977 63.2255 48.9347 63.2255 50.3153V53.5637C63.1443 54.9443 62.0074 56.0812 60.6268 56.0812Z" fill="#702CB9"/>
  <path d="M94.0039 56.0812H73.6201C72.2395 56.0812 71.1025 54.9443 71.1025 53.5637V50.3153C71.1025 48.9347 72.2395 47.7977 73.6201 47.7977H94.0039C95.3845 47.7977 96.5214 48.9347 96.5214 50.3153V53.5637C96.5214 54.9443 95.3845 56.0812 94.0039 56.0812Z" fill="#702CB9"/>
  <path d="M35.5322 70.4556H83.8525" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M83.8525 70.4555V61.1163" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M87.5072 64.6896L83.8527 61.0352L80.1982 64.6896L83.8527 61.0352" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  <path d="M50.4746 70.4555V61.1163" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M54.1293 64.6896L50.4748 61.0352L46.8203 64.6896L50.4748 61.0352" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>

  )