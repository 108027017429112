import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useConfirm } from 'material-ui-confirm';
import jwtDecode from 'jwt-decode';
import { useHistory } from 'react-router';
import { emailSuccess, phoneNumberSuccess, requestLoading, sendCodeRequest, stopLoading } from 'store/modules/onboarding/actions';
import { userRequest } from 'store/modules/user/actions';

import { LockerIcon } from '../../../../assets/Icons/LockerIcon';
import { PersonIcon } from '../../../../assets/Icons/PersonIcon';
import { EyeIcon } from '../../../../assets/Icons/EyeIcon';
import { NeoproLogo } from '../../../../assets/Icons/NeoproLogo';
import { PhoneIcon } from '../../../../assets/Icons/PhoneIcon';
import bgImg from '../../../../assets/neoproLogin.png'
import { EyeSlashedIcon } from 'assets/Icons/EyeSlashedIcon';

import Input from '../../Components/Input';
import Form from '../../Components/Form';
import { Button } from '../../Components/Button'

import { cpfMask, phoneMask } from 'utils/inputMasks';
import { ProftFullIcon } from 'assets/Icons/ProftFullIcon';
import { getTokenStoreId } from 'utils/getTokenStoreId';
import api from '../../../../repositories/api'

import { colors } from '../../../../theme/colors';
import { Container } from './styles';
import { toast } from 'react-toastify';

const Login = () => {
  const confirm = useConfirm()
  const dispatch = useDispatch()
  const history = useHistory()
  const { register, handleSubmit, errors } = useForm()
  
  const token = localStorage.getItem('@Proft:token')
  
  const email = useSelector(state => state.onboarding.email)
  const phoneNumber = useSelector(state => state.onboarding.phoneNumber)
  const onboardingLoading = useSelector(state => state.onboarding.loading)
  const error = useSelector(state => state.onboarding.error)
  const user = useSelector(state => state.user.user)
  
  const [loading, setLoading] = useState(false)
  const [responseError, setResponseError] = useState()
  
  // 0 - Login
  // 1 - Ativar conta
  // 2 - Recupera senha
  const [activeStep, setActiveStep] = useState(0)

  const [showPassword, setShowPassword] = useState(false)

  const [phone, setPhone] = useState('')
  const [cpf, setCpf] = useState('')

  const [cpfError, setCpfError] = useState()

  const getUserStores = ({token}) => { // verifica quantas lojas o user tem para redirecionar para a pagina correta
    if (token) {
      const stores = user.stores
      if (stores.length > 0) {
        if (stores.length > 1) history.push('/stores')
        else if (stores.length === 1) history.push(`${getTokenStoreId(user.stores[0])}/dashboard`)
      } else {
        const userEmail = user.identification?.email?.value || user.email?.value || user.email

        if (userEmail) {
          history.push('/stores')
        }
      }
    }

    setLoading(false)
  }

  const handleSignIn = data => {
    setLoading(true)
    api.login(data.email, data.password)
      .then(login => {
        if (login) {
          window.localStorage.setItem('@Proft:token', login)
          const user = jwtDecode(login)

          dispatch(userRequest(user.id, login))
        }
      })
      .catch(err => {
        if (err.response && err.response.status) {
          switch (err.response.status) {
            case 401:
              setResponseError(err.response.data.error.friendlyMsg)
              break
            case 500:
              setResponseError(err.response.data.error.friendlyMsg)
              break
            default:
              confirm({
                title: 'Ops!',
                description: 'Deu algum problema...',
                confirmationText: 'Ok!',
                cancellationText: 'Fechar'
              }).then(() => { }).catch(() => { })
              break
          }
        } else {
          confirm({
            title: 'Ops!',
            description: !err.isAxiosError ? err.message : 'Ops, pelo jeito o servidor está desligado.',
            confirmationText: 'Ok!',
            cancellationText: 'Fechar'
          }).then(() => { }).catch(() => { })
        }

        setLoading(false)
      })
  }

  const verifyPhone = () => dispatch(sendCodeRequest({phoneNumber: phone}))

  const verifyCpf = async () => {
    try {
      dispatch(requestLoading())
      const { data } = await api.axios.post('/user/changePassword', {cpf: cpf})
      
      if (data) {
        if (data.email) dispatch(emailSuccess(data.email))
        if (data.phone) dispatch(phoneNumberSuccess(data.phone))
        history.push(`/change-password/verification/cpf=${cpf}`)
      }
    } catch (error) {
      console.log(error)
      setCpfError(error.response.data.error.friendlyMsg)
    }
    dispatch(stopLoading())
  }

  useEffect(() => {
    if (token) {
      const { id } = jwtDecode(token)
      if (user._id) getUserStores({token})
      else dispatch(userRequest(id, token))
    }
  }, [user, token])

  useEffect(() => {
    if (activeStep === 1 && !email && !!phoneNumber) history.push(`/verification/phone=${phoneNumber}`)
  }, [phoneNumber, email]);

  const steps = [
    <>
      <div className="top-logo">
        <ProftFullIcon color={colors.primary.main}/>
        {/* <NeoproLogo color="blue" /> */}
      </div>
      <Form 
        title="Acessar painel"
        onSubmit={handleSubmit(handleSignIn)}
        contentChildren={
          <>
            <Input 
              label="CPF/Email" 
              name="email"
              iconLeft={<PersonIcon />} 
              error={!!errors.email || !!responseError}
              inputRef={register({ required: true })}
              error={(!!errors.email && 'CPF/Email é obrigatório') || (responseError?.includes("Login") && responseError)}
              // InputProps={{
              //   inputComponent: /^\d+$/.test(userLogin?.split(0, 3)) && cpfMask,
              // }}
            
              inputProps={{
                'data-cy': 'login'
              }}
              margin={[0, 0]}
            />
            <Input 
              label="Senha"
              name="password"
              type={showPassword ? "text" : "password"}
              iconLeft={<LockerIcon />} 
              error={!!errors.password || !!responseError}
              inputRef={register({ required: true })}
              iconRight={!showPassword ? <EyeIcon /> : <EyeSlashedIcon />}
              onIconRight={() => setShowPassword(!showPassword)}
              error={(!!errors.password && 'Senha é obrigatória') || (responseError?.includes("Senha") && responseError)}
              inputProps={{
                'data-cy': 'password'
              }}
              margin={[5, 0]}
            />
          </>
        }
        buttonChildren={
          <>
            <div className="content-buttons">
              <span onClick={() => setActiveStep(2)} className="forgot-password">Esqueci minha senha</span>
              <span onClick={() => setActiveStep(1)} className="activate-account">Ativar conta</span>
            </div>
      
            <div className="content-buttons" >
              <Button
                text="Entrar"
                type="submit"
                name="btn-login"
                data-cy="btn-login"
                loading={loading}
                backgroundColor={colors.primary.main}
                textColor={colors.lightGrey.lightest}
                style={{margin: '50px auto 0px'}}
              />
            </div>
            <Button
              text="Cadastre sua conta"
              borderColor="transparent"
              textColor={colors.primary.main}
              backgroundColor="transparent"
              style={{marginTop: 10}}
              onClick={() => history.push('/onboarding/create-account')}
              fullWidth
            />
          </>
        }
      />
    </>,
    <Form 
      title="Ativar conta"
      onSubmit={handleSubmit(verifyPhone)}
      description="Para começar precisamos validar seu celular. O seu gestor deve ter criado sua conta através do painel da loja."
      contentChildren={
        <Input 
          label="Número de celular" 
          iconLeft={<PhoneIcon color={colors.primary.main} />} 
          value={phone} 
          setValue={e => setPhone(e.target.value.replace(/[() -]/g, ''))}
          InputProps={{
            inputComponent: phoneMask
          }}
          error={error}
        />
      }
      buttonChildren={
        <div className="activate-account-content-buttons" style={{marginTop: 50}}>
          <Button
            disabled={!phone}
            text="Enviar código"
            type="submit"
            loading={onboardingLoading}
            backgroundColor={colors.primary.main}
            textColor={colors.lightGrey.lightest}
          />
          <Button
            text="Voltar"
            textColor={colors.darkGrey.darkest}
            onClick={() => setActiveStep(0)}
          />
        </div>
      }
    />,
    <Form 
      title="Esqueci minha senha"
      onSubmit={handleSubmit(verifyCpf)}
      description="Preencha seu CPF para verificarmos seu cadastro e criar uma nova senha. (caso você não tenha definido seu cpf, talvez precisaremos ativar sua conta primeiro)"
      contentChildren={
        <Input 
          label="CPF" 
          iconLeft={<PersonIcon color={colors.primary.main} />} 
          value={cpf} 
          setValue={e => setCpf(e.target.value.replace(/[. -]/g, ''))}
          InputProps={{
            inputComponent: cpfMask
          }}
          error={cpfError}
        />
      }
      buttonChildren={
        <div className="activate-account-content-buttons" style={{marginTop: 50}}>
          <Button
            disabled={!cpf}
            text="Enviar código"
            type="submit"
            loading={onboardingLoading}
            backgroundColor={colors.primary.main}
            textColor={colors.lightGrey.lightest}
          />
          <Button
            text="Voltar"
            textColor={colors.darkGrey.darkest}
            onClick={() => setActiveStep(0)}
          />
        </div>
      }
    />
  ]

  return (
    <Container>
      <div className="image-section">
        {/* <img src={bgImg} />  ATIVAR NA NEOPRO */} 
        <div className="content">
          <ProftFullIcon />
          {/* <NeoproLogo /> */}
          <div className="content-checks">
            { ["Engaje seus vendedores", "Bata as metas da loja", "Controle na palma da mão"].map((check, i) => (
              <div className="check" key={i}>
                <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.5 13.7687C3.8181 13.7687 0.833333 10.7839 0.833333 7.10198C0.833333 3.42009 3.8181 0.435318 7.5 0.435318C11.1819 0.435318 14.1667 3.42009 14.1667 7.10198C14.1626 10.7822 11.1802 13.7646 7.5 13.7687ZM7.48933 12.4353H7.5C10.4445 12.4324 12.8295 10.0438 12.828 7.09932C12.8265 4.15484 10.4391 1.76865 7.49467 1.76865C4.55019 1.76865 2.16281 4.15484 2.16133 7.09932C2.15986 10.0438 4.54486 12.4324 7.48933 12.4353ZM6.16667 10.4353L3.5 7.76865L4.44 6.82865L6.16667 8.54865L10.56 4.15532L11.5 5.10198L6.16667 10.4353Z" fill="#F7F7F8"/>
                </svg>
                <span>{check}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="login-section">
        <div className="content">
          {steps[activeStep]}
        </div>
      </div>
    </Container>
  );
}

export default Login;