import styled from 'styled-components'
import { colors } from 'theme/colors'
import fonts from 'theme/fonts'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  margin: 20px auto;
  width: 100%;
  background: white; 
  border-radius: 16px;

  .top-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;

    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        height: 50px;
        padding: 10px;
        background-color: ${colors.lightGrey.lightest};
        border-radius: 50%;
      }

      h1 {
        margin-top: 0;
        margin-bottom: 10px;
        font-family: ${fonts.subtitle.font};
        font-size: ${fonts.subtitle.size};
        font-weight: ${fonts.subtitle.weight};
        color: ${colors.darkGrey.main};
      }
    }

    span {
      font-weight: 500;
      font-family: ${fonts.body.font};
      font-size: ${fonts.body.size};
      font-weight: ${fonts.body.weight};
      color: ${colors.darkGrey.darkest};
    }
  }

  .card-children {
    width: 100%;
    
    .lined-children {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
      border: 2px solid ${colors.lightGrey.main};
      border-radius: 10px;
      padding: 0px 10px;

      span {
        color: ${colors.darkGrey.main};
        font-size: 14px;
        font-weight: 500;
      }
    }

    .inlined-children {
      display: grid;
      justify-content: space-between;
      align-items: flex-start;
      grid-template-columns: repeat(2, 48%);
      grid-template-rows: 1fr;
      width: 100%;
    }
  }


`