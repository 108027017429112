import React, { useEffect, useState } from 'react';

import { Backdrop, Modal } from '@material-ui/core';

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import PersonIcon from '@material-ui/icons/Person';
import StorefrontIcon from '@material-ui/icons/Storefront';
import CreateIcon from '@material-ui/icons/Create'
import DeleteIcon from '@material-ui/icons/Delete';

import { formatPrice } from '../../../../../../../../../../../utils/format';
import PromptModal from '../../../../../../../../../../../components/PromptModal';
import Tooltip from '../../../../../../Tooltip';

import { Container } from './styles'
import { getDaysInMonth } from 'date-fns';

const TableItem = ({ data, onDelete, onEdit, index, goal }) => { 
  const [isShowModal, setIsShowModal] = useState(false);

  // Callbacks de calculos de comissao
  const getCommission = ({goal, commission}) => (goal * commission * 1 ) // o terceiro parametro eh o peso que por padrao eh 100%

  const getDSR = () => getCommission({goal: goal.value, commission: goal.commission}) / (getDaysInMonth(new Date(data.month)) - data.dsr) * data.dsr

  const getBonus = () => ( 
    goal.bonusPercentage === true 
    ? goal.bonus * (goal.typeBonus === 'user' ? goal.value : data.mainGoals.total)
    : goal.bonus
  )
  const getComplement = () => {
    const complement = data.salary.base - getCommission({goal: goal.value, commission: goal.commission}) - (data.dsr > 0 ? getDSR() : 0)
    return complement > 0 ? complement : 0
  }
  
  const getFullCommission = () => (
    (data.salary.directCommission ? 0 : data.salary.base) + (getBonus() || 0) + 
    (goal.commission * (goal.type === 'user' ? goal.value : goal.type === 'store' ? data.mainGoals.total : 1)) +
    (data.dsr > 0 ? getDSR() : 0) +
    (data.salary.complement ? getComplement() : 0)
  )

  return (
    <>
      <Container>
        <td>
          <div 
            data-tip data-for={`goal-${index+1}-tooltip`}
            style={{ border: index === 0 && '2px solid #FFC700'}} 
            className="goal-icon"
          >
            <span>{index + 1}</span>
            <Tooltip
              id="goal-1-tooltip"
              content={
                <p>Meta Principal</p>
              }
            />
          </div>
        </td>
        <td>
          <strong>
            {goal.name}
          </strong>
        </td>
        <td>
          {formatPrice(goal.value)}
        </td>
        <td>
          <div className="goal-percent-comission">
            <span>{ (goal.commission * 100).toFixed(2) }%</span>
            { goal.type === 'user' ? <PersonIcon data-tip data-for={`${index}-user-tooltip`} /> : <StorefrontIcon data-tip data-for={`${index}-store-tooltip`} /> }
            <Tooltip 
              id={`${index}-${goal.type}-tooltip`} 
              place="right" 
              content={
                <p>
                  {`${(goal.commission * 100).toFixed(2)}% ${goal.type === "user" ? 'de suas vendas' : 'do faturamento da loja'}`}
                </p>
              } 
            />
          </div>
        </td>
        <td style={{ opacity: goal.bonus === 0 ? 0.5 : 1 }}>
          {goal.bonus > 0 ? (goal.bonusPercentage ? (goal.bonus * 100).toFixed(2) + '%' : formatPrice(goal.bonus)) : 'Sem bônus'}
        </td>
        <td id='value' className="comission-cell">
          { formatPrice(getFullCommission())}
        </td>
        <td>
        <div className="table-row-button-container">
          <button onClick={onEdit} type="button" className='edit'>
            <CreateIcon />
          </button>
          <button onClick={() => setIsShowModal(true)}  type="button" className='delete'>
            <DeleteIcon />
          </button>
        </div>
        </td>

        <PromptModal 
          open={isShowModal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
          isAlert
          icon={<DeleteOutlineIcon fontSize='large' />}
          onClose={() => setIsShowModal(false)}
          leftTitle='Cancelar'
          onLeft={() => setIsShowModal(false)}
          rightTitle='Remover meta'
          onRight={() => {
            onDelete(index)
            setIsShowModal(false)
          }}
        >
          <span style={{ fontSize: 18, textAlign: 'center', marginTop: -10 }}>
            Deseja remover a meta <br />
            <strong style={{ textDecoration: 'underline' }}>{goal.name}</strong>?
          </span>
        </PromptModal>
      </Container>
    </>
  )
}

export default TableItem