import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size ? props.size : 24}px;
  height: ${props => props.size ? props.size : 24}px;
  min-width: ${props => props.size ? props.size : 24}px;
  min-height: ${props => props.size ? props.size : 24}px;
  font-size: ${props => props.size ? props.size * 0.5 : 12}px;
  font-weight: 600;
  border-radius: 50%;
  margin: 0;
  background-color: #d5dde3;
  color: #77828c;
`;
