import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Inter' !important;
  }

  body {
    -ms-overflow-style: none;
    scrollbar-width: 4px;
    scrollbar-scrollbar-color: #b5b5b5;

    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #b5b5b5;
      border-radius: 50px;
    }
  }
`

export default GlobalStyle;