import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { animateScroll as scroll, Element, Link } from 'react-scroll';
import { endOfMonth, getDate, set, startOfMonth } from 'date-fns';

// Components
import CardButton from './Components/CardButton';
import OrLineDivisor from '../../OrLineDivisor';

// Icons UI
import CreateIcon from '@material-ui/icons/Create';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

// Assets
import { ComissaoDireta } from '../../../../../../../assets/Icons/Stepper/Comission/ComissaoDireta';
import { PisoComissao } from '../../../../../../../assets/Icons/Stepper/Comission/PisoComissao';
import { VariosPeriodos } from '../../../../../../../assets/Icons/Stepper/Period/VariosPeriodos';
import { UnicoPeriodo } from '../../../../../../../assets/Icons/Stepper/Period/UnicoPeriodo';
import { ResultadoMes } from '../../../../../../../assets/Icons/Stepper/ComissionBase/ResultadoMes';
import { ResultadoPeriodo } from '../../../../../../../assets/Icons/Stepper/ComissionBase/ResultadoPeriodo';
import { MetaFixa } from '../../../../../../../assets/Icons/Stepper/GoalDistribution/MetaFixa';
import { MetaDistribuida } from '../../../../../../../assets/Icons/Stepper/GoalDistribution/MetaDistribuida';
import { TotalLoja } from '../../../../../../../assets/Icons/Stepper/ReceiveComission/TotalLoja';
import { MetaIndividual } from '../../../../../../../assets/Icons/Stepper/ReceiveComission/MetaIndividual';

// Styles
import { Container } from './style';

const CommissionStep = ({ data, handleNextStep, setDeleteStep, updateGoal, storeGoal, savedGoal }) => {

  useEffect(() => { // Auto scroll para o botao de proxima etapa se ja tiver tudo
    if (
      typeof data.salary === "object" &&
      typeof data.distribute === "boolean" &&
      data.sections?.length > 0 &&
      typeof data.config?.commission?.storeSold === 'boolean'
    ) scroll.scrollToBottom()
    else scroll.scrollToTop()
  }, [])
  
  return (
    <Container>
        <Element name='commission' className='goal-step-card-container'>
          <div style={ typeof(data.salary?.directCommission) === 'boolean'
            ? { pointerEvents: 'none', opacity: 0.3 } 
            : { pointerEvents: 'auto', opacity: 1 } 
          } 
          >
            <h1 className='goal-step-card-container-title'>Como será a remuneração dos vendedores?</h1>
            <div className='goal-step-card-buttons'>
              <Link to='period' offset={-100} smooth={true}>
                <CardButton
                  selected={data.salary?.directCommission}
                  cardTitle="Comissionista puro" 
                  cardDescription="A remuneração do vendedor refere-se à comissão das suas vendas." 
                  tooltipContent={
                    <p>
                      João vendeu <span>R$ 50.000</span>
                      <br />
                      Sua comissão é de <span>8%</span>.
                      <br />
                      <br />
                      50.000 * 0,8 = 4.000
                      <br />
                      <br />
                      Sua remuneração será de <span>R$ 4.000</span>.
                    </p>
                  }
                  buttonAction={() => {
                    scroll.scrollToBottom()
                    updateGoal({salary: {...data.salary, directCommission: true}})
                    scroll.scrollToBottom()
                  }}
                  buttonText="Escolher Comissionista puro" 
                  > 
                  <ComissaoDireta />
                </CardButton>
              </Link>

            <OrLineDivisor />
            
            <Link to='period' offset={-90} smooth={true}>
              <CardButton 
                selected={data.salary?.directCommission === false}
                cardTitle="Salário + Comissão" 
                cardDescription="O vendedor recebe um salário base mais a comissão das suas vendas." 
                tooltipContent={
                  <p>
                    João vendeu <span>R$ 50.000</span>
                    <br />
                    Sua comissão é de <span> 5%</span>.
                    <br />
                    O Piso Salarial é de <span>R$ 1.500</span>. 
                    <br />
                    <br />
                    50.000 * 0,05 = R$ 2.500
                    <br />
                    2.500 + 1.500 = R$ 4.000
                    <br />
                    <br />
                    Sua remuneração será de <span>R$ 4.000</span>. 
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                  updateGoal({salary: {...data.salary, directCommission: false}})
                }}
                buttonText="Escolher Salário + Comissão" 
              >
                <PisoComissao />
              </CardButton>
            </Link>
          </div>
        </div>
          { typeof (data.salary?.directCommission) === 'boolean' &&
          <Link to='commission' offset={-170} smooth={true}>
            <button 
              onClick={() => {
                setDeleteStep(["directCommission"])
              }}
              className='stepper-button-edit'>
                Alterar 
                <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
            </button> 
          </Link>
          }
        </Element> 

      { (typeof (data.salary?.directCommission) === 'boolean' || data.sections?.length > 0) &&
       <Element name='period' className='goal-step-card-container'>
        <div style={ data.sections?.length > 0
          ? { pointerEvents: 'none', opacity: 0.3 } 
          : { pointerEvents: 'auto', opacity: 1 } } 
        >
          <h1 className='goal-step-card-container-title'>Qual o período da meta?</h1>
          <div className='goal-step-card-buttons'>
            <Link to='commission-base' smooth={true} offset={-100}>
              <CardButton
                selected={data.sections?.length === 1}
                cardTitle="Período Único" 
                cardDescription="A meta abrange todos os dias do mês como um único período." 
                tooltipContent={
                  <p>
                    <span>Janeiro - 31 dias</span>
                    <br />
                    <br />
                    Período único - dia 1 a 31
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                   updateGoal({
                      sections: [{
                        start: set(startOfMonth(new Date(data?.month)),  {hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
                        end: set(endOfMonth(new Date(data?.month)), {hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
                      }],
                      salary: {...data.salary, splitCommission: false}
                   })
                }}
                buttonText="Escolher Período Único" 
              >
                <UnicoPeriodo />
              </CardButton>
            </Link>

            <OrLineDivisor />

            <Link to='commission-base' smooth={true} offset={-100}>
              <CardButton 
                selected={data.sections.length > 1}
                cardTitle="Vários Períodos" 
                cardDescription="Os dias do mês podem ser divididos em vários períodos para a meta." 
                tooltipContent={
                  <p>
                    <span>Janeiro - 31 dias</span>
                    <br />
                    <br />
                    Período 1: dia 1 a 8 <br />
                    Período 2: dia 9 a 15 <br />
                    Período 3: dia 16 a 22 <br />
                    Período 4: dia 23 a 31
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                 updateGoal({
                   config: {
                     ...data.config,
                     nextSectionVisibility: true,
                   },
                    sections: [
                      {
                        start: set(new Date(data?.month), {date: 1, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
                        end: set(new Date(data?.month), {date: 8, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
                      },
                      {
                        start: set(new Date(data?.month), {date: 9, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
                        end: set(new Date(data?.month), {date: 15, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
                      },
                      {
                        start: set(new Date(data?.month), {date: 16, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
                        end: set(new Date(data?.month), {date: 22, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
                      },
                      {
                        start: set(new Date(data?.month), {date: 23, hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
                        end: set(new Date(data?.month), {date: getDate(endOfMonth(new Date(data?.month))), hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
                      }
                    ]
                 })
  
                }}
                buttonText="Escolher Vários Períodos"
              >
                <VariosPeriodos />
              </CardButton>
            </Link>
          </div>
        </div>
        { data.sections?.length > 0 &&
          <Link to='period' offset={-170} smooth={true}>
            <button 
              onClick={() => {
                setDeleteStep(["sections", "days", "splitCommission"])
              }}
              className='stepper-button-edit'>
                Alterar 
                <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
            </button> 
          </Link>
        }
      </Element>
      }

     { data.sections?.length >= 2 &&
       <Element name='commission-base' className='goal-step-card-container'>
        <div style={ typeof(data?.salary?.splitCommission) === 'boolean'
          ? { pointerEvents: 'none', opacity: 0.3 } 
          : { pointerEvents: 'auto', opacity: 1 } } 
        >
          <h1 className='goal-step-card-container-title'>Qual a referência para receber a comissão?</h1>
          <div className='goal-step-card-buttons'>
            <Link to='goal-distribution' offset={-100} smooth={true}>
              <CardButton
                selected={data?.salary?.splitCommission === false}
                cardTitle="Vendas do mês" 
                cardDescription="A comissão é paga somente se o vendedor bater a meta do mês." 
                tooltipContent={
                  <p>
                    Resultado de João no mês: 
                    <br />
                    <br />
                    Meta do mês: <span>R$ 30.000</span> | Vendas João: <span style={{color: '#FFC700' }}>R$ 27.000</span> <br/>
                    Período 1: dia 01 a 10 - Meta: <span>R$ 10.000</span> | João: <span style={{color: '#FFC700' }}>R$ 9.000</span> <br/>
                    Período 2: dia 11 a 20 - Meta: <span>R$ 10.000</span> | João: <span style={{color: '#27AE60' }}>R$ 11.000</span> <br/>
                    Período 3: dia 21 a 31 - Meta: <span>R$ 10.000</span> | João: <span style={{color: '#FFC700' }}>R$ 9.000</span> 
                    <br/>
                    <br/>
                    João não recebe comissão, pois não bateu a meta do mês
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                  updateGoal({salary: {...data.salary, splitCommission: false}});
  
                }}
                buttonText="Escolher Vendas do mês" 
              >
                <ResultadoMes />
              </CardButton>
            </Link>

            <OrLineDivisor />

            <Link to='goal-distribution' offset={-100} smooth={true}>
              <CardButton 
                selected={data?.salary?.splitCommission}
                cardTitle="Vendas por período" 
                cardDescription="A comissão é garantida conforme o vendedor bate a meta de cada período." 
                tooltipContent={
                  <p>
                    Resultado de João no mês: 
                    <br />
                    <br />
                    Meta do mês: <span>R$ 30.000</span> | Vendas João: <span style={{color: '#FFC700' }}>R$ 27.000</span> <br/>
                    Período 1: dia 01 a 10 - Meta: <span>R$ 10.000</span> | João: <span style={{color: '#FFC700' }}>R$ 9.000</span> <br/>
                    Período 2: dia 11 a 20 - Meta: <span>R$ 10.000</span> | João: <span style={{color: '#27AE60' }}>R$ 11.000</span> <br/>
                    Período 3: dia 21 a 31 - Meta: <span>R$ 10.000</span> | João: <span style={{color: '#FFC700' }}>R$ 9.000</span> 
                    <br/>
                    <br/>
                    João recebe apenas comissão referente ao Período 2, no qual a meta foi batida.
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                  updateGoal({salary: {...data.salary, splitCommission: true}})
  
                }}
                buttonText="Escolher Vendas por período"
              >
                <ResultadoPeriodo />
              </CardButton>
            </Link>
          </div>
        </div>
        { (data?.salary?.splitCommission || data?.salary?.splitCommission === false) &&
        <Link to='commission-base' offset={-170} smooth={true}>
          <button 
            onClick={() => {
              setDeleteStep(["splitCommission"])
            }}
            className='stepper-button-edit'>
              Alterar 
              <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
          </button> 
          </Link>
        }
      </Element> 
     }

     { (typeof data.salary?.splitCommission === "boolean" || typeof data.distribute  === "boolean") &&   
      <Element name='goal-distribution' className='goal-step-card-container'>
        <div style={ typeof(data?.distribute) === 'boolean'
          ? { pointerEvents: 'none', opacity: 0.3 } 
          : { pointerEvents: 'auto', opacity: 1 } } 
        >
          <h1 className='goal-step-card-container-title'>A meta poderá ser redistribuída para a equipe?</h1>
          <div className='goal-step-card-buttons'>  
            <Link to='receive-commission' offset={-100} smooth={true}>
              <CardButton
                selected={data?.distribute === false}
                cardTitle="Não redistribuir" 
                cardDescription="A meta da equipe não será alterada quando um vendedor tirar folga ou for desligado." 
                buttonAction={() => {
                  scroll.scrollToBottom()
                  updateGoal({distribute: false})
                }}
                buttonText="Escolher Não redistribuir" 
              >
                <MetaFixa />
              </CardButton>
            </Link>

            <OrLineDivisor />

            <Link to='receive-commission' offset={-100} smooth={true}>  
              <CardButton 
              selected={data?.distribute}
              cardTitle="Redistribuir a meta" 
              cardDescription="Caso um vendedor se ausente ou seja desligado, sua meta será redistribuída entre os seus colegas." 
              buttonAction={() => {
                scroll.scrollToBottom()
                updateGoal({distribute: true})

              }}
              buttonText="Escolher Redistribuir a meta"
            >
              <MetaDistribuida />
            </CardButton>
            </Link>
          </div>
        </div>
        { (data?.distribute || data?.distribute === false) &&
        <Link to='goal-distribution' offset={-170} smooth={true}>
          <button 
            onClick={() => {
              setDeleteStep(["distribute"])
            }}
            className='remunaration stepper-button-edit'>
              Alterar 
              <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
          </button>
          </Link>
        }
      </Element> 
     }

     { (data?.distribute || data?.distribute === false || data?.config?.commission?.storeSold || data?.config?.commission?.storeSold === false) &&
      <Element name='receive-commission' className='goal-step-card-container'>
        <div style={ typeof(data?.config?.commission?.storeSold) === 'boolean'
          ? { pointerEvents: 'none', opacity: 0.3 } 
          : { pointerEvents: 'auto', opacity: 1 } } 
        >
          <h1 className='goal-step-card-container-title'>Qual resultado garante a comissão do vendedor?</h1>
          <div className='goal-step-card-buttons'>
            <Link to='next-step' offset={-170} smooth={true}>
              <CardButton
                selected={data?.config?.commission?.storeSold}
                cardTitle="Meta da loja" 
                cardDescription="A comissão dos vendedores depende da conquista da meta principal da loja." 
                tooltipContent={
                  <p>
                    A meta da loja é <span>R$ 87.000</span>. 
                    <br />
                    A meta de cada vendedor é de <span>R$ 29.000</span>. 
                    <br />
                    <br />
                    João vendeu <span>R$ 31.000</span>. <br />
                    Maria vendeu <span>R$ 26.000</span>. <br />
                    Carla vendeu <span>R$ 29.000</span>.
                    <br />
                    <br />
                    Apesar de João e Carla terem batido suas metas individuais,
                    <span> a meta da loja não foi atingida</span>, já que o total de vendas foi de R$ 86.000.
                    <br/>
                    Logo, <span>nenhum vendedor receberá comissão</span>.
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                  updateGoal({config: {commission: {storeSold: true}}})
  
                }}
                buttonText="Escolher Meta da Loja" 
              >
                <TotalLoja />
              </CardButton>
            </Link>

            <OrLineDivisor />

            <Link to='next-step' offset={-170} smooth={true}>
              <CardButton
                selected={data?.config?.commission?.storeSold === false}
                cardTitle="Meta individual" 
                cardDescription="O vendedor receberá sua comissão caso atinja sua meta individual de vendas." 
                tooltipContent={
                  <p>
                    A meta da loja é de <span>R$ 87.000</span>.
                    <br/>
                    A meta de cada vendedor é de <span>R$ 29.000</span>. 
                    <br />
                    <br />
                    João vendeu <span>R$ 31.000</span>. 
                    <br />
                    Maria vendeu <span>R$ 26.000</span>. 
                    <br />
                    Carla vendeu <span>R$ 29.000</span>.
                    <br />
                    <br />
                    João e Carla bateram suas metas e receberão comissão.
                    Já Maria não será comissionada, pois não atingiu o valor da sua meta.
                  </p>
                }
                buttonAction={() => {
                  scroll.scrollToBottom()
                  updateGoal({config: {commission: {storeSold: false}}})
                }}
                buttonText="Escolher Meta individual"
              >
                <MetaIndividual />
              </CardButton>
            </Link>
          </div>
        </div>
        { (data?.config?.commission?.storeSold || data?.config?.commission?.storeSold === false) &&
         <Link to='receive-commission' offset={-170} smooth={true}>
          <button 
            onClick={() => {
              setDeleteStep(["storeSold"])
            }}
            className='remunaration stepper-button-edit'>
              Alterar 
              <CreateIcon style={{ marginLeft: 5, fontSize: 18 }} fontSize='small' />
          </button> 
         </Link>
        }
      </Element> 
     }
      
     { data && (
        typeof (data.config?.commission?.storeSold) === 'boolean' &&
        data.sections?.length > 0 &&
        typeof (data.salary?.directCommission) === 'boolean' &&
        typeof (data.salary?.splitCommission) === 'boolean' &&
        typeof (data.distribute) === 'boolean'
       ) && (
       <Element name='next-step'>
          <button 
            onClick={() => {
              handleNextStep()
            }} 
            style={{ margin: 'auto' }} 
            className='stepper-button-next'
          >
            Próxima etapa
            <ArrowForwardIosIcon className='next-button-icon' />
          </button>
      </Element>)
     }

    </Container>
  );
}

export default CommissionStep;