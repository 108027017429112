import React from 'react';

import { Container } from './styles';

// type = "success" || "alert"
const Alert = ({ message, error = false, success = false}) => {
  return (
    <Container>
      <span className={`${error && "error" || success && "success"}`}>{message}</span>
    </Container>
  )
}

export default Alert;