import styled from "styled-components";

export const Container = styled.div`
  border-bottom: 2px solid #EEEEEE;
  border-right: 2px solid #EEEEEE;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 7px;
  min-height: 75px;
  max-height: 75px;
  position: relative;

  border: ${({isModal}) =>
    isModal && '2px solid #702CB9'
  };

  background-color: ${({isModal}) =>
    isModal ? '#F0ECF4' : 'white'
  };

  box-shadow: ${({isModal}) =>
    isModal && '0 10px 20px rgba(0,0,0,0.2)'
  };

  z-index: ${({isModal}) =>
    isModal && '4'
  };

  .calendar-card-top {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;

    .calendar-card-top-checkbox {
      &:hover {
        span {
          color: #702CB9;
        }
      }
      span {
        font-family: 'Inter', sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: #546E7A;
        transition: 0.2s ease-in-out;
      }
    }

    .calendar-card-top-buttons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      position: absolute;
      right: 0;

      button {
        background-color: #ECEFF1;
        padding: 1px 3px;
        border: none;
        border-radius: 5px;
        cursor: pointer;
        margin: 0 2px;
        display: flex;
        align-items: center;
        transition: 0.2s ease-in-out;

        &:hover {
          background: #CFD8DC;
          color: #702CB9;
        }

        span {
          color: #37474F;
          font-weight: 600;
          font-family: 'Inter', sans-serif;
          font-size: 11px;
        }
      }
    }
  }

  .proft-suggestion {
    border-bottom: 2px solid #FAB300;
  }

  .calendar-card-input {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 100%;
    margin-top: 5px;
  }

  .disable-day {
    opacity: 0.4;
    pointer-events: none;
  }

  .modal-buttons {
    position: absolute;
    right: 0;
    bottom: 0;
    margin-right: -75px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    z-index: 5;
    
    button {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
      padding: 2px 5px;
      background-color: #37474F;
      border: none;
      border-radius: 5px;
      margin: 2px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-family: "Inter";
      color: white;
    }
  }

  .MuiInputBase-input {
    border: none !important;

  }

  .MuiFormControl-root {
    border: none !important;
  }

`

export const SellersContainer = styled.div`
  width: 80%;

  .sellers-top-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #263238;
      font-size: 12px;
      font-weight: 500;
    }

    .sellers-counter {
      background-color: #EEEEEE;
      padding: 5px;
      border-radius: 5px;
      font-weight: 600;
      color: #546E7A;
    }
  }

  .sellers-cards-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 8px;
    margin-top: 15px;

    .sellers-user-card {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #F5F6F8;
      padding: 5px 7px;
      border-radius: 200px;
      cursor: pointer;

      .sellers-user-card-info {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        img {
          border-radius: 50%;
        }

        span {
          margin-left: 8px;
          color: #263238;
          font-weight: 500;
          font-size: 14px;
        }
      }

      .seller-user-card-checkbox {
        color: #37474F;
        padding: 0;
      }
    }
  }
`

export const SuggestionAnimation = styled.div`
  background-color: white;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 100px;
  border-radius: 8px;
  font-family: 'Inter', sans-serif;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 20px rgba(0,0,0,0.2);

  span {
    color: #263238;
  }

  strong {
    font-size: 20px;
  }
`