import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types'
import { getDate, getDay, getWeeksInMonth, startOfMonth } from 'date-fns';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import TopTableDays from './Components/TopTableDays'
import CardDay from './Components/CardDay'

import { formatPrice } from '../../../../../../../../../utils/format';

import { Container } from './style'
import Tooltip from '../../../../Tooltip';
  
const Calendar = ({ data, dwt, setDwt, days, setDays, weekStartIndex, month, setTotalDaysValue, mainGoalValue, updateGoal, suggestion }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [progressBar, setProgressBar] = useState(0)

  const nextinputRef = useRef()

  const generateDwt = (days) => {
    setDwt(days.reduce((acc, day) => {
      const weekStartIndex = getDay(startOfMonth(new Date(data.month)))
      const i = weekStartIndex + (getDate(new Date(day.date)) - 1)
      const weekIndex = Math.floor(i/7) 
      acc.weeks[weekIndex] += day.goal
      acc.days[i-weekIndex*7] += day.goal
      acc.total += day.goal
      return acc
    }, {
      weeks: new Array(getWeeksInMonth(new Date(data.month))).fill(0),
      days: [0,0,0,0,0,0,0],
      total: 0
    }))
  }

  const setWorkDay = (i) => (value) => {
    const oldDays = [...days]
    
    const daysWorking = oldDays.filter(day => day.working).length

    oldDays[i].working = value
    
    if (suggestion) { // Se tiver sugestao proft ele redistribui o valor quando desabilita/habilita o dia um dia
      const dayValue = mainGoalValue * data.daysWeightSuggestion.days[i]
      oldDays[i].goal = oldDays[i].working ? dayValue : 0

      const part = ( // Essa parte eh o que ele tem que adicionar ou remover na distribuicao
        oldDays[i].working 
        ? -(dayValue / daysWorking) // se o dia ficar util, ele remove o valor dos outros dias
        : (mainGoalValue - oldDays.reduce((acc, day) => {
            if (day.working) acc += day.goal

              return acc
            }, 0)
          ) / (daysWorking - 1) // se o dia ficar unutil, ele adiciona valor do dia/dias uteis e adiciona em todo mundo
      )
      
      const redistributedDays = oldDays.map((day, j) => { // aqui ele mapeia todos os dias para atribuir o valor
        if (day.working && i !== j) {
          return {...day, goal: day.goal + part}
        } else return day
      })

      setDays(redistributedDays)
      generateDwt(redistributedDays)
    } else {
      oldDays[i].goal = 0
      setDays(oldDays)
      generateDwt(oldDays)
    }
  }

  const setSellersWorking = (i) => (sellers) => {
    const oldDays = [...days]
    oldDays[i].users = sellers.filter(seller => seller.active).map(user => user.userId._id)

    setDays(oldDays)
  }

  const setValue = (i) => (e) => {
    //Não recalcular se valor não mudar
    const oldDays = [...days]
    const oldValue = oldDays[i].goal
    let value = 0;

    if (!e.target.inputValue) value = 0
    else value = e.target.inputValue

    if (value !== oldValue) {
      if (!!suggestion) updateGoal({config: {...data.config, suggestion: false}})
      const dwtWeek = [...dwt.weeks]
      const dwtDays = [...dwt.days]
      
      const oldValue = oldDays[i].goal
      
      const weekIndex = Math.floor((i + weekStartIndex)/7) 

      dwtWeek[weekIndex] += value - oldValue
      dwtDays[(i + weekStartIndex)-(weekIndex*7)] += value - oldValue
      
      oldDays[i].goal = value

      setDwt({
        ...dwt,
        weeks: dwtWeek,
        days: dwtDays,
        total: dwt.total + value - oldValue
      })
      setDays(oldDays)
    }
  }

  const getDayOutMonth = (day) => {
    const dayMS = ((24*60*60*1000) * day)
    const date = new Date(month)
    date.setTime(date.getTime() - dayMS)

    return date
  }

  const changeDayUsersType = (day) => (// Precisa tratar dayUsers pois ele pode ser composto de string por causa do back e precisa ser obj
    typeof day.users[0] === "string"
    ? {...day, users: [...data.usersWorking].map(userW => ({...userW, active: [...day.users].includes(userW.userId?._id) ? true : false}))}
    : day
  )

  useEffect(() => { 
    setProgressBar(dwt.total / mainGoalValue)
    setTotalDaysValue(Number(dwt.total.toFixed(2)))
  }, [dwt.total])
  
  return (
    <Container>
      <div className="calendar">
       <div style={{ display: 'flex' }}>
          <div>
            <TopTableDays topTable={['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado']}/>
              <div className="calendar-month">
                { days.length > 0 && (new Array(7*getWeeksInMonth(month))).fill(null).map((el, i) => {
                  const monthIndex = i - weekStartIndex;
                  // Índice do próximo dia util é o dia util mais próximo do dia que está sendo editado
                  // Primeiro verifica-se se está em modo de edição, então é feito um splice pra economizar o loop e busca-se o primeiro dia util, onde ao adicionar o indice do dia sendo editado é igual ao indice no mês
                  const nextWorkingDayIndex = isEditing ? isEditing + days.slice(isEditing - weekStartIndex + 1).findIndex(day => day.working) + 1 : 0
                  if (i < weekStartIndex || monthIndex >= days.length) {
                    const date = getDayOutMonth(weekStartIndex - i)
                    return <CardDay data={data} key={i} date={date} index={i} disabled />
                  } else {
                    return <CardDay
                      ref={nextWorkingDayIndex === i ? nextinputRef : undefined}
                      data={data}
                      day={changeDayUsersType(days[monthIndex])} 
                      date={days[monthIndex].date}
                      key={i}
                  
                      setWorkDay={setWorkDay(monthIndex)}
                      setValue={setValue(monthIndex)}
                      setSellersWorking={setSellersWorking(monthIndex)}
                      handleNext={() => {
                        //Mandar para o próximo dia útil
                        if (nextWorkingDayIndex > isEditing) {
                          nextinputRef.current.focus()
                          setIsEditing(nextWorkingDayIndex)
                        } else {
                          setIsEditing(false)
                        }
                      }}
                      index={i}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                      suggestion={suggestion}
                    />
                  }
                })}
              </div>
          </div>
          <div className="calendar-total">
            <TopTableDays topTable={['Total']}/>
              <div className="weeks-cards">
                {dwt.weeks?.map((week, i) => (
                  <div key={i} className="total-card">
                    <span>
                        {formatPrice(week)}
                    </span>
                    <h4>{Math.ceil((week * 100) / mainGoalValue)}%</h4>
                  </div>
                ))}
              </div>
          </div>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{width: '70vw'}} className="calendar-total">
              <div className="days-cards">
                {dwt.days?.map((day, i) => (
                  <div key={i} className="total-card">
                    <span>
                        {formatPrice(day)}
                    </span>
                    <h4>{Math.ceil((day * 100) / mainGoalValue)}%</h4>
                  </div>
                ))}
              </div>
          </div>
          <div style={{zIndex: isEditing && 5}} className="calendar-total">
            <div className="total">
              <div className="total-card">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span>Soma dos dias</span>
                  <span data-tip data-for='tooltip-sum-days'>
                    { Number((dwt.total || 0).toFixed(2)) !== Number((mainGoalValue || 0).toFixed(2)) &&
                      <ErrorOutlineIcon
                        fontSize="small"
                        style={{ color: "#9E9E9E", marginLeft: 5 }} 
                      />
                    }
                  </span>
                  <Tooltip
                    place="top"
                    content={
                      <p>Iguale a soma dos dias com {"\n"}
                        a meta principal da loja ({formatPrice(mainGoalValue)})
                      </p>
                    } 
                    id='tooltip-sum-days' 
                  />
                </div>
                <span 
                  style={{ 
                   color:  
                    Number((dwt.total || 0).toFixed(2)) !== Number((mainGoalValue || 0).toFixed(2))
                    ? 'red' 
                    : '#27AE60',
                    fontSize: 14 
                   }}
                >
                  {formatPrice(dwt.total)}
                </span>
                <div className="total-progress-container">
                  <div className="bar">
                    <div className="progress" style={{ width: `${progressBar * 100}%` }} />
                  </div>
                </div>
                { Number(dwt.total.toFixed(2)) - Number(mainGoalValue.toFixed(2)) < 0 ?
                  <span style={{ marginLeft: 'auto' }}>Faltam {formatPrice(Number(mainGoalValue.toFixed(2) - Number(dwt.total.toFixed(2))))}</span>
                  : Number((dwt.total || 0).toFixed(2)) !== Number((mainGoalValue || 0).toFixed(2)) &&
                  <span style={{ marginLeft: 'auto' }}>Retire {formatPrice(Number(dwt.total.toFixed(2)) - Number(mainGoalValue.toFixed(2)))}</span>
               }
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>  
  );
}

Calendar.propTypes = {
  days: PropTypes.array,
  setDays: PropTypes.func,
  weekStartIndex: PropTypes.number,
}

export default Calendar;