import React from 'react'
import { useEffect, useState } from 'react'

// Material UI
import Checkbox from '@material-ui/core/Checkbox';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import SpeedIcon from '@material-ui/icons/Speed';
import CloseIcon from '@material-ui/icons/Close';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

// Styles
import { Container } from './styles'
import GoalSliderModal from '../GoalSliderModal';
import Tooltip from '../../../../../../Tooltip';
import UserDefaultPhoto from 'views/Goals/components/AddGoal/components/UserDefaultPhoto';

const TransferListCard =  ({ data, working, inGoal, user, onClick, updateUserDSR, updateUserWeigth }) => {
  const [isGoalModalOpen, setIsGoalModalOpen] = useState(false);

  const [seller, setSeller] = useState({
    dsr: true,
    weight: 1,
    sliderValue: 100
  });

  const [auxSeller, setAuxSeller] = useState({})

  useEffect(() => {
    if (user) {
      setSeller({dsr: user.dsr, weight: user.goalWeight, sliderValue: user.goalWeight * 100})
    }
  }, [user])

  return (
    <Container>
      <div className="sellers-list-info-container">
        { user.userId?.photo?.url ? 
          <img style={{backgroundSize: 'cover'}} src={user.userId?.photo?.url} alt={user.userId?.name?.complete}/>
         : <UserDefaultPhoto username={user.userId?.name?.first} />
        }
        <strong>
          { user.userId?.name?.complete.length >= (working ? 20 : 30) ? 
            (((user.userId?.name?.complete).substring(0,(working ? 20 : 30)-3)) + '...') 
            : user.userId?.name?.complete
          }
        </strong>
      </div>
      <div className="sellers-list-action-container">
        {inGoal && (
          <>
            { data.dsr > 0 &&
              <>
                <Tooltip id="dsr-tooltip" content={<p>Irá receber DSR?</p>} />
                <strong data-tip data-for="dsr-tooltip" onClick={() => {
                  updateUserDSR(user, !seller.dsr)
                  setSeller({ ...seller, dsr: !seller.dsr })
                }}>
                  <Checkbox 
                    color="default"
                    style={{ margin: 0, padding: 0 }}
                    checked={seller.dsr}
                  />
                  DSR
                </strong>
              </>
            }

            <Tooltip id="weight-tooltip" content={<p>Alterar peso do vendedor</p>} />
            <strong 
              data-tip data-for="weight-tooltip" 
              onClick={() => setIsGoalModalOpen(true)}
            >
              <SpeedIcon />
              {Math.round(seller.sliderValue)}%
            </strong>
          </>
        )}
        <button type="button" onClick={onClick}>
          {inGoal ? <CloseIcon className="ungoal-back-button" /> : <ChevronRightIcon />} 
        </button>
      </div>
        <GoalSliderModal
          sellerWeight={user.goalWeight}
          sellerName={user.userId?.name?.complete}
          data={data}
          seller={seller} 
          setSeller={setSeller} 
          open={isGoalModalOpen}
          onClose={() => {
            setIsGoalModalOpen(false)
          }}
          onSave={() => {
            setIsGoalModalOpen(false)
            updateUserWeigth(user, seller.weight)
          }}
        />
    </Container>
  )
}

export default TransferListCard