import React, { useEffect, useState } from 'react';
import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';
import getDate from 'date-fns/getDate';
import { animateScroll as scroll } from 'react-scroll'
import { endOfMonth, set, startOfMonth } from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import { goalAddSuccess, goalListRequest } from 'store/modules/goal/actions';
import { useParams, useHistory } from 'react-router';

import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import ReviewCard from './Components/ReviewCard';
import ReviewItem from './Components/ReviewItem';
import Tooltip from '../../Tooltip';
import UserDefaultPhoto from '../../UserDefaultPhoto';

import { formatPrice } from '../../../../../../../utils/format';
import CreateGoalIcon from '../../../../../../../assets/Icons/CreateGoal';

import { Container, SellersGoalTable } from './styles';
import { Edit, Warning } from '@material-ui/icons';

const ReviewStep = ({data, setActiveStep, storeGoal, updateGoal}) => {
  const history = useHistory()
  const { storeId, goalId } = useParams()
  const dispatch = useDispatch()

  const store = useSelector(state => state.store.store)

  const token = localStorage.getItem('@Proft:token')

  const month = new Date(data.month)
  month.setMonth(month.getMonth() + 1)
  month.setDate(0)

  const [users, setUsers] = useState([])
  
  const getCommission = ({goal, commission, weight}) => {
    return (goal * commission * weight)
  }
  
  const getDSR = ({goal, commission, weight, dsr, days}) => {
    return (getCommission({goal, commission, weight}) / (days - dsr) * dsr)
  }
  
  const getBonus = ({isPercent, bonus, typeBonus, goal}) => {
    return (isPercent ? (bonus * (typeBonus === 'store' ? data.mainGoals.total : goal)) : bonus)
  }

  const getSalary = ({goal, base, commission, weight}) => {
    return ((data.salary.directCommission ? 0 : base) + getCommission({goal, commission, weight}))
  }

  const getStoreSalary = ({base, storeGoal, commission}) => {
    return ((data.salary.directCommission ? 0 : base) + (storeGoal * commission))
  }
  
  useEffect(() => {
    if (store) {
      setUsers(store.users.reduce((acc, user) => {
        const currentStore = user.stores.find(userStore => userStore.storeId === store._id)
        
        if (currentStore?.type === "seller") {
          acc.push({
            userId: {
              _id: user._id, 
              name: {...user.name}, 
              photo: {...user.photo},
            },
            dsr: null,
            goalWeight: null
          })
        }
        
        return ([...acc].filter(user => data.usersWorking.find(userW => userW.userId?._id === user.userId?._id)))
      }, []))
    }
    
  }, [store, data.usersWorking])
  
  useEffect(() => {
    if (data.sections.length === 0) {
      updateGoal({sections: [{
        start: set(startOfMonth(new Date(data.month)), {hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString(),
        end: set(endOfMonth(new Date(data.month)), {hours: 12, minutes: 0, seconds: 0, milliseconds: 0}).toISOString()
      }]})
    }
  }, [data.sections])
  
  useEffect(() => scroll.scrollToTop(), [])
  
  return (
    <Container>
      <h1>Revise a meta para: {store.name}</h1>
      <ReviewCard
        name="Mês"
        items={
          <ReviewItem 
          title="Mês da meta"
          value={format(new Date(data.month), 'MMMM/yyyy', {locale: ptBR})}
          />
        }
        setActiveStep={() => setActiveStep(0)}
        />
      <ReviewCard
        name="Comissão"
        items={
          <>
            <ReviewItem 
              title="Tipo de Comissão"
              value={`${data.salary.directCommission ? 'Comissão direta' : 'Piso salarial + comissão'}`}
            />
            <ReviewItem 
              title="Período da meta"
              value={data.sections.length > 1 ? 'Vários períodos' : 'Período único'}
              subValue={`${data.sections.length}`}
              />
            <ReviewItem 
              title="Base da comissão"
              value={`${data.salary.splitCommission ? 'Resultado por período' : 'Resultado por mês'}`}
            />
            <ReviewItem 
              title="Distribuição da meta"
              value={`${data.distribute ? 'Meta distribuída' : 'Meta fixa'}`}
            />
            <ReviewItem 
              title="Recebimento da comissão"
              value={`${data.config.commission.storeSold ? 'Total da loja' : 'Meta individual'}`}
            />
          </>
        }
        setActiveStep={() => setActiveStep(1)}
      />
      <ReviewCard
        name="Valores"
        items={
          <>
            <ReviewItem 
              title="DSR"
              value={data.dsr === 0 ? 'Inativo' : data.dsr}
            />
            <ReviewItem 
              title="Piso Salarial"
              value={formatPrice(data.salary.base)}
            />
            { data.salary.complement &&
              <ReviewItem 
                title="Complemento do salário"
                value="Ativo"
              />
            }
            <ReviewItem 
              title="Meta da loja"
              value={formatPrice(data.mainGoals.total)}
              valueTextColor="#27AE60"
            />
            <ReviewItem 
              title="Meta de ticket médio"
              value={formatPrice(data.mainGoals.ticket)}
              valueTextColor="#27AE60"
            />
            <ReviewItem 
              title="Meta de preço médio"
              value={formatPrice(data.mainGoals.price)}
              valueTextColor="#27AE60"
            />
        </>
        }
        setActiveStep={() => setActiveStep(2)}
      />
        <ReviewCard
        name="Meta"
        items={
          <>
            { data.config?.commission?.fixed?.commission > 0 &&
              <ReviewItem 
                title="Comissão base"
                value={`${(data.config?.commission?.fixed?.commission * 100).toFixed(2)}%`}
              />
            }
            <ReviewItem 
              title="Dias de loja aberta"
              value={
                data.days.reduce((acc,curr) => {
                  return acc += curr.working
                }, 0)
              }
            />
            
            { data.distribute && (data.monthGoals[0].value * data.usersWorking.length !== data.mainGoals.total) && 
              <div className="distribute-message">
                <Warning style={{color: '#700c0c'}} />
                <p>
                  Você selecionou meta distribuída, então aconselhamos que o valor da primeira meta de vendedores (<strong>{data.monthGoals[0].name}</strong>)
                  seja a meta da loja dividido pelos vendedores que participarão da meta! 
                  <strong> ({`${formatPrice(data.mainGoals.total)}/${data.usersWorking.length} = ${formatPrice(data.mainGoals.total/data.usersWorking.length)}`})</strong>
                  <br/>
                  <br/>
                  <strong>Edite o valor clicando no <Edit style={{fontSize: 13}} /> de Meta e alterando a etapa de Meta dos vendedores!</strong>
                </p>
              </div>
            }

            <ReviewItem>
            <SellersGoalTable>
              <div className="top-header">
                <h2>Meta por vendedor</h2>
                <div className="top-header-goals">
                  { data.monthGoals.map((goal, i) => (
                    <div key={i} className="goal">
                      <h1>{goal.name.length > 15 ? goal.name.substring(0, 15-3) + '...' : goal.name}</h1>
                      <span>{formatPrice(goal.value)}</span>
                    </div>
                  ))}
                </div>
              </div>

              <div className="sellers-goals">
                { data.usersWorking.map((user, i) => data.days.some(day => day.users.includes(user.userId?._id)) ? (
                  <div key={i} className="seller-card">
                    <div className="seller-card-info">
                      <div className="seller-card-info-left">
                        <h3>{
                              ((user.userId.name.complete).length > 16) ? 
                              (((user.userId.name.complete).substring(0,16-3)) + '...') 
                              : user.userId.name.complete 
                            }
                         </h3>
                        <span>{`Peso ${(user.goalWeight * 100).toFixed(2)}% / ${(user.dsr && data.dsr > 0) ? 'Com DSR' : 'Sem DSR'}`}</span>
                      </div>
                    { user?.userId?.photo?.url ?
                      <img style={{backgroundSize: 'cover'}} src={user.userId.photo.url} />
                      : 
                      <UserDefaultPhoto username={user.userId.name.first} size={30} />
                    }
                    </div>
                    <div className="seller-card-goals">
                    { data.monthGoals.map((goal, j) => {
                      const goalValue =
                        ( data.distribute ?
                            data.days.reduce((value, day) => {
                              const currUser = day.users.includes(user.userId._id)
                              if (day.working && currUser) value += (day.goal / day.users.length)
                              return value
                            }, 0) * goal.value / data.monthGoals[0].value
                          : goal.value * data.days.reduce((acc, day) => {
                            if (day.working) acc += day.goal

                            return acc
                          }, 0) / data.mainGoals.total
                        ) * user.goalWeight

                      const commission = (goal.type === "store" ? data.mainGoals.total : goalValue) * goal.commission
                      const bonus = getBonus({isPercent: goal.bonusPercentage, bonus: goal.bonus, typeBonus: goal.typeBonus, goal: goalValue})
                      const dsr = getDSR({goal: goalValue, commission: goal.commission, weight: 1, dsr: data.dsr, days: getDate(month)})

                      const salary = (goal.type === "store")
                      ? getStoreSalary({base: data.salary.base, storeGoal: data.mainGoals.total, commission: goal.commission}) 
                      : getSalary({goal: goalValue, base: data.salary.base, commission: goal.commission, weight: 1})

                      return (
                        <div key={j} className="seller-card-goal">
                          <h3>{formatPrice(goalValue)}</h3>
                          <div>
                            <span>
                              {formatPrice(salary + (bonus || 0) + (dsr || 0))}
                            </span>
                            <ErrorOutlineIcon 
                              data-tip
                              data-for={`seller-card-tooltip-${i}-${j}`}
                              style={{ fontSize: 16, color: '#BDBDBD', marginLeft: 5}} 
                            />
                            <Tooltip 
                              id={`seller-card-tooltip-${i}-${j}`}
                              content={
                                <div style={{flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'flex-start'}}>
                                  <strong>{`${user.userId.name.first} (${goal.name})`}</strong>
                                  <br/>
                                  <strong>Remuneração prevista:</strong>
                                  { !data.salary.directCommission && 
                                    <p>
                                      Piso Salarial: {formatPrice(data.salary.base)}
                                    </p>
                                  }
                                  { data.dsr > 0 && 
                                    <p>
                                      {`DSR (${data.dsr}): 
                                      ${formatPrice(dsr)}`} 
                                    </p>
                                  }
                                  {
                                    <p>
                                      Comis. {(goal.commission * 100).toFixed(2)}% ({goal.type === "store" ? 'loja' : 'vendedor'}): {formatPrice(commission)}
                                    </p>
                                  }
                                  { bonus > 0 &&
                                    <p>
                                      Bonificação ({goal.typeBonus === "store" ? 'loja' : 'vendedor'}): {formatPrice(bonus)}
                                    </p>
                                  }

                                  <br/>

                                  <strong>Total: {formatPrice(salary + (bonus || 0) + (dsr || 0))}</strong>
                                </div>
                              } 
                            />
                          </div>
                        </div>
                      )
                    })}
                    </div>
                  </div>
                ) : null)}
              </div>
            </SellersGoalTable>
            </ReviewItem>
            <ReviewItem 
              title={`Períodos da meta (${data.sections.length})`}
              extraChildren={data.config.suggestion ? <h3 style={{color: "#FAB300"}}>Com sugestão Proft</h3> : <h3 style={{color: 'grey'}}>Sem sugestão Proft</h3>}
              contentChildren={
                data.days.reduce((sections, day) => { // Passa por todos os days já somando a meta dentro de cada section
                  if(day.working){
                    const date = new Date(day.date)
                    sections = sections.map(section => {
                      if(date >= section.start && date <= section.end){
                        section.goal += day.goal 
                        section.days++
                      }
                      return section
                    })
                  }
                  return sections
                }, data.sections.reduce((acc, {start, end}) => { //Inicializa valor inicial do reduce apenas com sections completas e valores zerados
                  if (end) acc.push({
                    start: typeof start === 'string' ? new Date(start) : start,
                    end: typeof end === 'string' ? new Date(end) : end,
                    goal: 0,
                    days: 1
                  })
                  return acc
                }, [])).map((section, i) => (
                  <div key={i} className="content-card-content-children">
                    <span className="content-card-value">{`Período ${i+1} - ${formatPrice(section.goal)}`}</span>
                    <span className="content-card-subvalue">
                      {`${format(new Date(section.start), 'dd/MM')} a ${format(new Date(section.end), 'dd/MM')}
                      - ${section.days - 1} dias úteis`}
                  </span>
                  </div>
                ))
              }
            />
        </>
        }
        setActiveStep={() => setActiveStep(3)}
      />

      <button 
        onClick={() => {
          storeGoal({...data, storeId})
          dispatch(goalAddSuccess(data))
          history.push(`/${storeId}/goals/${goalId}`)
          dispatch(goalListRequest(storeId, token))
        }}
        className="create-goal-button"
      >
        <span>Finalizar e voltar para o painel</span> 
        <CreateGoalIcon />
      </button>
    </Container>
  )
}

export default ReviewStep;