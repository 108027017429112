import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { storeRequest } from 'store/modules/store/actions';
import api from '../../../../repositories/api'
import moment from 'moment';
import { toast } from 'react-toastify';
import { getDate, getYear, set } from 'date-fns';
import endOfMonth from 'date-fns/endOfMonth';
 
// Components
import Header from './components/Header'
import SideBar from './components/SideBar'
import MonthStep from './components/StepperContainer/MonthStep';
import CommissionStep from './components/StepperContainer/CommissionStep';
import ValueStep from './components/StepperContainer/ValueStep';
import GoalStep from './components/StepperContainer/GoalStep';
import ReviewStep from './components/StepperContainer/ReviewStep';
import PromptModal from '../../../../components/PromptModal';

import { CircularProgress, Modal } from '@material-ui/core';
import BackIcon from '../../../../assets/Icons/BackIcon';
import ModeCommentIcon from '@material-ui/icons/ModeComment';

// Styles
import { CenterContainer, AddGoalStepContainer, MainContainer, SupportButton } from './style'

const AddGoal = () => {
  const { storeId, goalId } = useParams()
  const history = useHistory()
  const dispatch = useDispatch()

  const store = useSelector(state => state.store.store)
  const goalList = useSelector(state => state.goal.goalList) 
 
  const [loadingData, setLoadingData] = useState(false)
  const [loadingUpload, setLoadingUpload] = useState(false)

  const [savedGoal, setSavedGoal] = useState('')
  const [currentGoal, setCurrentGoal] = useState({
    storeId: storeId,
    days: [],
    sections: [],
    usersWorking: [],
    monthGoals: [],
    mainGoals: {},
    dwt: {},
    sectionsList: [],
    daysWeightSuggestion: [],
    lastDaysBackup: [],
  })

  const [activeStep, setActiveStep] = useState(0)
  const [deleteStep, setDeleteStep] = useState()
  const [supportModalTextVisible, setSupportModalTextVisible] = useState(true)

  const getCurrentStep = (data) => {
   // Funcao para procurar o primeiro false e redirecionar pra o step

    // Interpreta os dados que já foram preenchidos p/ determinar qual step direcionar.
    // Cada elemento do array são as condições daquela determinada step
    //0 Mês
    //1 Comissão
    //2 Metas da loja aqui
    //3 Metas
    //4 Revisão (se todo mundo der true, significa que ele ta na revisao, o indexOf retorna -1, entao trata o index < 0 para return 4)
    
    setActiveStep((index => index < 0 ? 4 : index)([
      !!data.month && !!data.storeId,
      typeof data.salary === "object" && typeof data.distribute === "boolean" && data.sections?.length > 0 && typeof data.config?.commission?.storeSold === 'boolean',
      data.dsr >= 0 && data.salary?.base > 0 && data.mainGoals.ticket > 0,
      data.config?.commission?.fixed?.commission >= 0 && data.days?.length > 0 && data.monthGoals?.length > 0 && data.usersWorking?.length > 0
    ].indexOf(false)))
  }

  const updateGoal = (obj) => {
    const newGoal = {...currentGoal}
    Object.entries(obj).forEach(([key, value]) => newGoal[key] = value)

    setCurrentGoal(newGoal)

    // VE SE TA DANDO UPDATE EM ALGUNS DESSE ABAIXO, PORQUE SE FOR, ELE NAO MANDA PRO BACK QUANDO UPDATAR A GOAL
    if (!["dwt", "sectionsList", "daysWeightSuggestion", "lastDaysBackup", "suggestion"].find(el => Object.keys(obj).includes(el))) storeGoal(newGoal)
  }
  
  const handleNextStep = () => { // Callback dos botoes "Proxima etapa"
    storeGoal(currentGoal)
    getCurrentStep(currentGoal)
  }

  const deleteGoalStep = (deleteStep) => {
    const remove = (obj, key) => { // Vai varrendo o currentGoal atras do step que passou na chamada pra dar remove nele
      for (let k in obj) {
          if (k === key) {
            if (Array.isArray(obj[k])) obj[key] = []
            else delete obj[key]
            return true
          } else if (typeof obj[k] === "object") {
              if (remove(obj[k], key)) return true
          }
      }
      return false
    }

    const newState = {...currentGoal}
    deleteStep.forEach(el => remove(newState, el))

    setCurrentGoal(newState)
  }

  const getDaysWeightSuggestion = (id) => {
    // Se nao tiver preenchido os dias ainda, ele sugere o peso
    try {
      api.axios.get(`/goal/${id}/suggestion`).then(({data}) => setCurrentGoal(goal => ({...goal, daysWeightSuggestion: data, config: {...goal.config, suggestion: true}})))
    } catch (error) {
      console.log(error);
    }
  }

  const loadGoal = async (id) => {
    setLoadingData(true)
    try {
      //Store carregada
      if (id) { 
        //Se tiver id, quer dizer que está no modo edição ou ja selecionou o mes e tem que carregar a goal.
        const loadedGoal = await api.axios.get(`/goal/${id}`)

        // Preenche os dados de comissao com o valor da ultima meta
        const lastGoalActive = goalList?.find(goal => goal.config.active)

        if (goalList?.length > 0 && lastGoalActive && moment(goalList[0].month).unix() > moment('2021-08-01T15:00:00.000Z').unix() && !loadedGoal.data.salary?.directCommission) {
          const dataToSave = {
            ...loadedGoal.data,
            daysWeightSuggestion: [...currentGoal.daysWeightSuggestion],
            sections: [...lastGoalActive.sections].map((section, i) => {
              if (i === lastGoalActive.sections.length - 1) {
                return ({
                  start: new Date(set(new Date(section.start), {year: new Date(getYear(new Date(loadedGoal.data.month))), month: new Date(loadedGoal.data.month).getMonth()})),
                  end: new Date(set(new Date(section.end), {
                    date: getDate(endOfMonth(new Date(loadedGoal.data.month))),
                    year: new Date(getYear(new Date(loadedGoal.data.month))), 
                    month: new Date(loadedGoal.data.month).getMonth()
                  }))
                })
              } else {
                return ({
                  start: new Date(set(new Date(section.start), {year: new Date(getYear(new Date(loadedGoal.data.month))), month: new Date(loadedGoal.data.month).getMonth()})),
                  end: new Date(set(new Date(section.end), {year: new Date(getYear(new Date(loadedGoal.data.month))), month: new Date(loadedGoal.data.month).getMonth()}))
                })
              }
            }),
            distribute: lastGoalActive.distribute,
            salary: {
              ...loadedGoal.data.salary,
              directCommission: lastGoalActive.salary.directCommission,
              splitCommission: lastGoalActive.salary.splitCommission
            },
            config: {
              ...loadedGoal.data.config,
              commission: {
                ...loadedGoal.data.config?.commission,
                storeSold: lastGoalActive.config.commission.storeSold
              }
            },
          }
          
          if (loadedGoal.data.days.length === 0 && goalId) getDaysWeightSuggestion(goalId)

          setSavedGoal(JSON.stringify({...dataToSave}))
          setCurrentGoal({...dataToSave})
          storeGoal(dataToSave)

          getCurrentStep(dataToSave)
        } else {
          setSavedGoal(JSON.stringify({...currentGoal, ...loadedGoal.data}))
          setCurrentGoal({...currentGoal, ...loadedGoal.data})
          getCurrentStep({...currentGoal, ...loadedGoal.data})
        }
      }
      setLoadingData(false)
    } catch(err) {
      console.log(err)
      toast.error(err?.response?.data?.error?.friendlyMsg || 'Ocorreu um erro ao carregar esta meta')
    }
  }

  const storeGoal = async (goal) => {
    setLoadingUpload(true)
    try {
      // Back so aceita o envio dos dados sem esses listados abaixos (ou seja, precisa do ...rest apenas pois os outros sao dados auxiliares)
      const { _id, createdAt, updatedAt, dwt, sectionsList, daysWeightSuggestion, lastDaysBackup, ...goalRest } = goal

      
      // Tratamento de dados pra enviar pro back
      if (goalRest.sections && goalRest.sections.length > 0) goalRest.sections = goalRest.sections.map(section => ({start: section.start, end: section.end}))
      if (goalRest.days && goalRest.days.length > 0) goalRest.days = goalRest.days.map(day => ({date: day.date, working: day.working, users: day.users, goal: day.goal}))
      if (goalRest.usersWorking && goalRest.usersWorking.length > 0) goalRest.usersWorking = goalRest.usersWorking.map(user => ({userId: user.userId._id, dsr: user.dsr, goalWeight: user.goalWeight, active: user.active}))
      if (goalRest.monthGoals && goalRest.monthGoals.length > 0) goalRest.monthGoals = goalRest.monthGoals.map(goal => ({ name: goal.name, value: goal.value, type: goal.type, typeBonus: goal.typeBonus, bonusPercentage: goal.bonusPercentage, bonus: goal.bonus, commission: goal.commission}))
      if (typeof goalRest.suggestion === "boolean") goalRest.config = {...goalRest.config, suggestion: goalRest.suggestion}

      const { data } = await api.axios.post('/goal', goalRest)
      
      // Redireciona o usuario pra rota pra ter o funcionamento de edicao no fluxo
      if (!goalId && data._id && data.config.active === false) {
        history.push(`/${storeId}/goals/${data._id}/edit`)
      }

      if (data.month) setSavedGoal(JSON.stringify(goal))
    } catch (err) {
      console.log(err)
      toast.error('Ocorreu um erro ao salvar a meta. Verifique os dados e tente novamente!')
    }
    setLoadingUpload(false)
  }

  //useEffect para preparar a goal  
  useEffect(() => {
    if (!store){
      dispatch(storeRequest(storeId))
    } else {
      if (store && goalId) loadGoal(goalId)
    }
  }, [store, goalId])

  // Botao de suporte ficar sem texto
  useEffect(() => {
    const timeout = setTimeout(() => {
      setSupportModalTextVisible(false)
      clearTimeout(timeout)
    }, 10000)
  }, [])

  const steps = [
    <MonthStep 
      data={currentGoal}
      storeGoal={storeGoal}
      updateGoal={updateGoal} 
      handleNextStep={handleNextStep}
      history={history}
    />,
    <CommissionStep 
      dispatch={dispatch}
      data={currentGoal} 
      updateGoal={updateGoal} 
      setDeleteStep={setDeleteStep} 
      handleNextStep={handleNextStep}
      storeGoal={storeGoal}
      savedGoal={savedGoal}
    />,
    <ValueStep
      month={currentGoal.month}
      salary={currentGoal.salary}
      dsr={currentGoal.dsr}
      mainGoals={currentGoal.mainGoals}
      dispatch={dispatch}
      updateGoal={updateGoal} 
      setDeleteStep={setDeleteStep} 
      handleNextStep={handleNextStep} 
    />,
    <GoalStep
      data={currentGoal}
      dataMonth={currentGoal.month}
      dataSections={currentGoal.sections}
      dataUsersWorking={currentGoal.usersWorking}
      dataDaysWeightSuggestion={currentGoal.daysWeightSuggestion}
      dataMonthGoals={currentGoal.monthGoals}
      dataDays={currentGoal.days}
      dataMainGoals={currentGoal.mainGoals}
      dataDsr={currentGoal.dsr}
      dataConfig={currentGoal.config} 
      dispatch={dispatch}
      data={currentGoal}
      updateGoal={updateGoal}
      dataLastDaysBackup={currentGoal.lastDaysBackup}
      setDeleteStep={setDeleteStep} 
      handleNextStep={handleNextStep}
    />,
    <ReviewStep
      data={currentGoal}
      setActiveStep={setActiveStep}
      storeGoal={storeGoal}
      updateGoal={updateGoal} 
    />
  ]
  
  return (
    <MainContainer>
      {loadingData && <CircularProgress style={{position: 'fixed', top: '50%', left: '50%', zIndex: 99}} size={40} />}
      <Header
        data={currentGoal} 
        savedGoal={savedGoal}
        activeStep={activeStep}
        setActiveStep={setActiveStep}  
        dispatch={dispatch} 
        storeGoal={storeGoal}
        loading={loadingUpload}
      />
      <AddGoalStepContainer loadingData={loadingData}>
        <CenterContainer>
          <div className='goal-step-container'>
            {!loadingData && steps[activeStep]}
          </div>
        </CenterContainer>
        { activeStep < 4 &&  <SideBar storeName={store?.name} data={currentGoal} /> }
        <SupportButton 
          onMouseEnter={() => setSupportModalTextVisible(true)} 
          onMouseLeave={() => setSupportModalTextVisible(false)} 
          textVisible={supportModalTextVisible}
          href="https://wa.me/5544991546704"
          target="_blank"
        >
          <ModeCommentIcon style={{color: '#37474F'}} />
          {supportModalTextVisible && <span>Precisa de ajuda?</span>}
        </SupportButton>
      </AddGoalStepContainer>
      <PromptModal
        open={!!deleteStep}
        icon={<BackIcon />}
        onClose={() => setDeleteStep()}
        title='Deseja continuar a alteração?'	
        description={'Se você alterar essa informação, as etapas abaixo serão perdidas.'}
        onLeft={() => setDeleteStep()}
        rightTitle='Alterar'
        onRight={() => {
          deleteGoalStep(deleteStep)
          setDeleteStep()
        }}
        isAlert
      >
        <ul style={{marginBottom: 20}}>
        {deleteStep?.map((deleted, i) => (
          <li style={{listStyle: 'none', fontWeight: 'bold', fontSize: 13, fontFamily: 'Inter', textAlign: 'center'}} key={i}>{{
            directCommission: 'Condição de tipo de comissão (Direta/Piso+Comissão)',
            splitCommission: 'Condição de base de comissão (Resultado do mês/período)',
            distribute: 'Condição de redistribuição da meta (Direta/Redistribuída)',
            fixed: 'Comissão fixa',
            storeSold: 'Condição de comissão quando atingir meta da loja/vendedor',
            monthGoals: 'Metas do mês dos vendedores',
            usersWorking: 'Vendedores que irão trabalhar nesta meta',
            days: 'Metas diárias (calendário)',
            dwt: 'Somatório de metas diárias',
            sections: 'Períodos',
            sectionsList: 'Valores dos períodos',
            base: 'Piso salarial',
            complement: 'Complemento de salário',
            total: 'Meta da loja',
            price: 'Meta de preço médio',
            ticket: 'Meta de ticket médio'
          }[deleted] || deleted}
          </li>))}
        </ul>
      </PromptModal>
    </MainContainer>
  )
}

export default AddGoal