import React, { useState, useRef, useEffect } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useHistory } from 'react-router'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Libs
import moment from 'moment'

// Material UI
import { makeStyles, useTheme } from '@material-ui/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Avatar from '@material-ui/core/Avatar';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'
import StorefrontIcon from '@material-ui/icons/Storefront'

// Components
import AccountMenuDropdown from '../AccountMenuDropdown'
import NewNotifications from '../NewNotifications'
import { AddInput } from 'components'
import { getTokenStoreId } from 'utils/getTokenStoreId'
import Credits from 'components/Credits'
import { useDispatch, useSelector } from 'react-redux'
import { requestWallet } from 'store/modules/user/actions'


const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  title: {
    color: 'white'
  },
  and: {
    color: 'white',
    fontWeight: 'bold',
    paddingLeft: 8,
    paddingRight: 8
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    cursor: 'pointer'
  },
  dev: {
    paddingLeft: 8,
    color: '#FF8E53',
    fontWeight: 900
  },
  syncText: {
    color: '#fff',
    marginRight: theme.spacing(1)
  }
}))

const Topbar = ({
  className,
  onSidebarOpen,
  onLogoff,
  storeName,
  user,
  goToStore,
  storeIntegrationData,
  currentStoreId,
  ...rest
}) => {
  
  const classes = useStyles()
  const theme = useTheme()
  const dispatch = useDispatch()

  const history = useHistory()

  const token = localStorage.getItem('@Proft:token')

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  })

  const wallet = useSelector(state => state.user.wallet)

  useEffect(() => {
    if (user?._id && !wallet) dispatch(requestWallet(user._id, token))
  }, [user, wallet])

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color={'a' === 'development' ? 'secondary' : 'primary'}>
      <Toolbar>
        {/* <RouterLink to={currentStoreId ? `/${currentStoreId}/dashboard` : ''}> */}
          <div className={classes.titleBox} onClick={() => history.push('/stores')}>
            <img
              src='/images/logo.svg'
              alt='Logo'
              height='20px'
              style={{ margin: '10px 0px 10px 10px' }}
            />
          </div>
        {/* </RouterLink> */}
        {process.env.NODE_ENV === 'development' && (
          <>
            <Typography variant='h3' className={classes.dev}>
              {isDesktop && 'Desenvolvimento'}
            </Typography>
            {/* <Typography variant='h3' className={classes.dev}>
              {currentStoreId && isDesktop && `StoreId: ${currentStoreId}`}
            </Typography> */}
          </>
        )}
        <div className={classes.flexGrow} />
        {storeIntegrationData && storeIntegrationData.provider && storeIntegrationData.lastUpdate && (
          <Typography className={classes.syncText}>
            Última sincronização: <strong>{moment(storeIntegrationData.lastUpdate.date).format('DD/MM - HH:mm:ss')}</strong>
          </Typography>
        )}
        {user &&
          user.stores.length > 1 &&
          currentStoreId &&
          ['owner', 'manager', 'cashier'].includes(user.stores.find(store => getTokenStoreId(store) === currentStoreId).type) && (
            <>
              <Hidden lgUp>
                <IconButton
                  onClick={goToStore}
                  color='primary'
                  aria-label='upload picture'
                  component='span'
                >
                  <StorefrontIcon style={{ color: '#fff' }} />
                </IconButton>
              </Hidden>
              <Hidden mdDown>
                <Button
                  variant='contained'
                  onClick={goToStore}
                  style={{ marginRight: '8px' }}
                >
                  Trocar Loja
              </Button>
              </Hidden>
            </>
          )}
        <Credits loading={!wallet} value={wallet?.balance} />
        {!storeIntegrationData?.provider && (
          <AddInput />
        )}
        {/* <Hidden mdDown>
          {/* <IconButton color="inherit">
            <Badge
              badgeContent={notifications.length}
              color="primary"
              variant="dot"
            >
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton
            className={classes.signOutButton}
            color='inherit'
            onClick={onLogoff}
          >
            <InputIcon />
          </IconButton>
        </Hidden> */}
        <AccountMenuDropdown userData={user} onLogoff={onLogoff} />
        <Hidden lgUp>
          <IconButton color='inherit' onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  storeName: PropTypes.string,
  currentStoreId: PropTypes.string,
}

export default Topbar
