import React from 'react'
import PropTypes from 'prop-types';

import Close from '@material-ui/icons/Close';

import { Container } from './styles';
import { Modal } from '@material-ui/core';

const PromptModal = (
  { 
    open,
    icon,
    onClose,
    title, 
    description, 
    children, 
    leftTitle = 'Cancelar', 
    onLeft,
    disableLeft = false,
    leftPrimary = false, 
    rightTitle = 'Avançar', 
    onRight,
    disableRight = false,
    isAlert = false,
    ...rest
  }, ref) => {
  return (
    <Modal open={open} {...rest}>
      <Container>
        <div className='top-content'>
          { icon && 
            <div className={`icon ${isAlert ? 'alert' : ''}`}>
              {icon}        
            </div> 
          }
          <Close onClick={onClose} className='close-modal-top-button' />
        </div>

        { (title || description) &&
          <div className='title-header'>
            { title && <h1>{title}</h1> }
            { description && <p>{description}</p> }
          </div>
        }

        { children &&
          <div className='center-content'>
            {children}
          </div>
        }

        <div className='footer-buttons'>
          { (leftTitle) &&
            <button
              disabled={disableLeft}
              onClick={onLeft ? onLeft : onClose} 
              className={leftPrimary ? 'action-button-left' : ''}
            >
              {leftTitle}
            </button> 
          }
          <button
            disabled={disableRight}
            onClick={onRight} 
            className={`action-button ${isAlert ? 'alert' : ''}`}
          >
            {rightTitle}
          </button>
        </div>
      </Container>
    </Modal>
  );
}

// PromptModal.propTypes = {
//   icon: PropTypes.any,
//   onClose: PropTypes.func, 
//   title: PropTypes.string, 
//   description: PropTypes.string, 
//   leftTitle: PropTypes.string, 
//   onLeft: PropTypes.func,
//   rightTitle: PropTypes.string,
//   onRight: PropTypes.func,
//   leftPrimary:PropTypes.bool,
//   isAlert: PropTypes.bool, 
// }

export default PromptModal;