import React, { useState, useEffect } from 'react'

// Libs
import DayPicker, { DateUtils } from 'react-day-picker'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import clsx from 'clsx'
import PropTypes from 'prop-types'


// Material UI
import { makeStyles, useTheme } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import Chip from '@material-ui/core/Chip'
import Table from '@material-ui/core/Table'
import TableContainer from '@material-ui/core/TableContainer'
import TableBody from '@material-ui/core/TableBody'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import Checkbox from '@material-ui/core/Checkbox'
import InputAdornment from '@material-ui/core/InputAdornment'
import Switch from '@material-ui/core/Switch'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Divider from '@material-ui/core/Divider'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import CircularProgress from '@material-ui/core/CircularProgress'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import {
  DatePicker,
} from '@material-ui/pickers';

// Icons
import DeleteIcon from '@material-ui/icons/Delete'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CheckIcon from '@material-ui/icons/CheckCircle'
import ExclamationIcon from '@material-ui/icons/Error'
import EditIcon from '@material-ui/icons/Edit';

// Components
import TabPanel from '../../../../components/TabPanel'
import dayPickerBR from '../../../../common/DayPicker-pt-BR'
import CurrencyTextField from '../../../../components/CurrencyTextField'
import SectionPicker from './components/SectionPicker'

// Utils
import { formatPrice } from '../../../../utils/format'

import 'react-day-picker/lib/style.css'

// Styles
const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { marginBottom: theme.spacing(1) },
  tableContainer: {
    marginBottom: theme.spacing(2),
    maxHeight: '60vh'
  },
  card: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(0.2)
  },
  title: {
    padding: theme.spacing(1)
  },
  save: {
    marginBottom: theme.spacing(4)
  },
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  rowForm: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  },
  sumHeader: {
    marginBottom: theme.spacing(1)
  },
  heading: {
    fontWeight: 'bold'
  },
  divider: {
    borderLeft: '2px solid #CCC',
    padding: theme.spacing(1, 2)
  },
  column: {
    flexBasis: '50%'
  },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
}))

const AddGoalModal = props => {
  const {
    active,
    onClose,
    onSubmit,
    addGoal,
    goals,
    sellers,
    loading,
    ...rest
  } = props

  const classes = useStyles()
  const theme = useTheme()
  const confirm = useConfirm()

  const [switchDsrVisible, setSwitchDsrVisible] = useState(true)

  const weekDays = [
    'Domingo',
    'Segunda',
    'Terça',
    'Quarta',
    'Quinta',
    'Sexta',
    'Sabado'
  ]
  let dateOneMonth = moment()
  let dateLastMonth = moment()

  dateOneMonth.set({ date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 })
  dateLastMonth.set({ date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 })

  const [dates, setDates] = useState([])
  const { storeId } = useParams()

  const defaultState = {
    storeId,
    usersWorking: sellers.map(seller => seller._id),
    month: '',
    dsr: 0,
    workingDays: '',
    salary: {
      base: '',
      directCommission: false,
      complement: false,
      splitCommission: true
    },
    bonPercent: false,
    mainGoals: {
      total: '',
      ticket: '',
      price: ''
    },
    monthGoals: [],
    sections: [{
      start: moment().startOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
      end: moment().endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
    }],
    days: [],
    sum: 0,
    distribute: false
  }
  const [tab, setTab] = useState(0)
  const [goalResponse, setGoalResponse] = useState(defaultState)

  useEffect(() => {
    if (sellers && sellers.length > 0) {
      setGoalResponse({
        usersWorking: sellers.map(seller => seller._id),
        ...goalResponse
      })
    }
  }, [sellers])

  useEffect(() => {
    const datesTmp = []

    if (goals && goals.length > 0) {
      dateOneMonth = moment(goals[0].month)
      dateOneMonth.set({ date: 1, hour: 12, minute: 0, second: 0, millisecond: 0 }).add(1, 'M')
      dateLastMonth = moment(dateOneMonth)
      dateLastMonth.add(5, 'M')
      setGoalResponse({
        ...goalResponse,
        month: dateOneMonth.toISOString(),
        mainGoals: goals[0].mainGoals
          ? goals[0].mainGoals
          : defaultState.mainGoals,
        salary: goals[0].salary ? goals[0].salary : defaultState.salary,
        sections: [{
          start: moment(dateOneMonth.toISOString()).startOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
          end: moment(dateOneMonth.toISOString()).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
        }]
      })
    } else {
      dateLastMonth.add(5, 'M')
    }
    while (dateOneMonth <= dateLastMonth) {
      datesTmp.push(dateOneMonth.toISOString())
      dateOneMonth.add(1, 'M')
    }
    setDates(datesTmp)
  }, [goals, sellers])

  // Deixa nome em negrito se já estiver selecionado
  const getStyles = (id, sellers, theme) => {
    return {
      fontWeight:
        sellers.indexOf(id) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightBold
    }
  }

  const changeMonthGoals = (i, field, event) => {
    const { monthGoals } = goalResponse
    let newData = event.target.value
    if (field !== 'name') {
      newData = Number(newData)
    }
    if (field === 'bonus') {
      monthGoals[i].bonField = newData
      monthGoals[i][field] = (Number(newData) / goalResponse.bonPercent ? 100 : 1)
    } else {
      monthGoals[i][field] = newData
    }
    setGoalResponse({ ...goalResponse, monthGoals })
  }

  const changeDayGoals = (i, field, event) => {
    const { days } = goalResponse
    let newData = event.target.value
    if (field === 'goal') {
      newData = Number(newData)
    }
    days[i][field] = newData
    setGoalResponse({
      ...goalResponse,
      days,
      sum:
        Math.round(
          (Number(
            goalResponse.days
              .map(x => x.goal)
              .reduce((prev, curr) => (prev += curr), 0)
          ) +
            Number.EPSILON) *
          100
        ) / 100
    })
  }

  const prepareMonth = (total = goalResponse.mainGoals.total) => {
    const dateOne = moment(goalResponse.month)
    const dateLast = moment(goalResponse.month)
    dateLast.add(1, 'M')
    dateOne.date(0)
    dateLast.date(0)

    const days = []
    while (dateOne < dateLast) {
      dateOne.add(1, 'd')
      days.push({
        date: new Date(dateOne),
        goal: 0,
        goalSuggestion: null,
        users: goalResponse.usersWorking
      })
    }
    const part = parseFloat(
      total / days.length
    ).toFixed(2)

    let amount = total

    days.forEach((row, i) => {
      if (i !== days.length) {
        row.goalSuggestion = part
        amount -= part
      } else {
        row.goalSuggestion = amount
      }
    })
    setGoalResponse({ ...goalResponse, days })
    setTab(2)
  }

  const changeTab = (iOld, iNew) => {
    if (iOld === 0 && iNew === 1) {
      if (
        goalResponse.dsr >= 0 &&
        goalResponse.salary.base > 0 &&
        goalResponse.workingDays > 0 &&
        goalResponse.usersWorking.length > 0
      ) {
        const monthDays = moment(goalResponse.month).daysInMonth()
        if (goalResponse.dsr + goalResponse.workingDays === monthDays || goalResponse.dsr === 0) {
          setTab(1)
        } else {
          confirm({
            title: 'Ops!',
            description: `DSR + Dias trabalhados deve ser igual a quantidade de dias do mês (${monthDays})`,
            confirmationText: 'Ok!',
            cancellationText: 'Fechar'
          }).then(() => { }).catch(() => { })
        }
      } else {
        confirm({
          title: 'Ops!',
          description: 'Preencha todos os campos antes de avançar.',
          confirmationText: 'Ok!',
          cancellationText: 'Fechar'
        })
          .then(() => { })
          .catch(() => { })
      }
    } else if (iOld === 1 && iNew === 2) {
      if (
        goalResponse.mainGoals.price > 0 &&
        goalResponse.mainGoals.ticket > 0 &&
        goalResponse.mainGoals.total > 0
      ) {
        if (goalResponse.monthGoals.length === 0) {
          goalResponse.monthGoals.push({
            name: '1ª meta',
            value:
              Math.round(
                (goalResponse.mainGoals.total /
                  goalResponse.usersWorking.length +
                  Number.EPSILON) *
                100
              ) / 100,
            bonus: 0,
            bonField: 0,
            commission: 0.0,
            minimal: false
          })
          setGoalResponse({
            ...goalResponse,
            monthGoals: goalResponse.monthGoals
          })
          setTab(2)
        }
      } else {
        confirm({
          title: 'Ops!',
          description: 'Preencha todos os campos antes de avançar.',
          confirmationText: 'Ok!',
          cancellationText: 'Fechar'
        })
          .then(() => { })
          .catch(() => { })
      }
    } else if (iOld === 2 && iNew === 3) {
      if (goalResponse.month) {
        if (
          goalResponse.monthGoals.length > 0 &&
          goalResponse.monthGoals[0].name.length > 0 &&
          goalResponse.monthGoals[0].value > 0 &&
          goalResponse.monthGoals[0].commission > 0
        ) {
          if (
            goalResponse.monthGoals.filter(month => {
              const preCom = (month.value * month.commission) / 100
              const bonus = goalResponse.bonPercent ? month.value * month.bonField / 100 : month.bonField
              const dsr = goalResponse.dsr > 0 ? ((preCom / goalResponse.workingDays) * goalResponse.dsr) : 0
              const base = goalResponse.salary.directCommission ? -goalResponse.salary.base : 0
              const preComplement = goalResponse.salary.base - preCom - dsr
              const complement = goalResponse.salary.directCommission && goalResponse.salary.complement && preComplement >= 0 ? preComplement : 0
              return preCom + dsr + bonus + base + complement < 0
            }).length > 0
          ) {
            confirm({
              title: 'Ops!',
              description: 'Comissão deve ser positiva.',
              confirmationText: 'Ok, vou corrigir',
              cancellationText: 'Fechar'
            })
              .then(() => { })
              .catch(() => { })
          } else if (
            goalResponse.monthGoals.length > 0 &&
            (goalResponse.monthGoals[0].value *
              goalResponse.usersWorking.length).toFixed(2) !==
            goalResponse.mainGoals.total.toFixed(2)
          ) {
            confirm({
              title: 'Valores não conferem!',
              description: `Vimos que mesmo que todos os vendedores atinjam a meta principal, ainda assim não será possível atingir a meta da loja. Deseja atualizar a meta da loja de R$${goalResponse.mainGoals.total.toFixed(2)
                } para R$${(goalResponse.monthGoals[0].value *
                  goalResponse.usersWorking.length).toFixed(2)}?`,
              confirmationText: 'Sim',
              cancellationText: 'Não, vou manter'
            }).then(() => {
              prepareMonth()
              setTab(3)
            }).catch(() => {
              prepareMonth()
              setTab(3)
            })
          } else {
            prepareMonth()
            setTab(3)
          }
        } else {
          confirm({
            title: 'Ops!',
            description: 'Preencha todos os campos.',
            confirmationText: 'Ok!',
            cancellationText: 'Fechar'
          })
            .then(() => { })
            .catch(() => { })
        }
      } else {
        confirm({
          title: 'Ops!',
          description: 'Mês não definido.',
          confirmationText: 'Ok!',
          cancellationText: 'Fechar'
        })
          .then(() => { })
          .catch(() => { })
      }
    } else if (iOld === 3 && iNew === 4) {
      // setTab(iNew)
      if (Number(Math.round(goalResponse.sum + 'e2') + 'e-2') == Number(Math.round(goalResponse.mainGoals.total + 'e2') + 'e-2')) {
        goalResponse.days.forEach((row, i) => {
          row.users = row.goal > 0 ? row.users : []
        })
        setGoalResponse(goalResponse)
        setTab(4)
      } else {
        confirm({
          title: 'Valores não conferem!',
          description: 'Por favor, ajuste os valores de metas diárias para se adequar a meta mensal.',
          confirmationText: 'Ok',
          cancellationText: 'Fechar'
        })
          .then(() => { })
          .catch(() => { })
      }
    } else if (iOld === 4 && iNew === 5) {
      if (goalResponse.sections[goalResponse.sections.length - 1].end === moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString()) {
        setTab(5)
      } else {
        confirm({
          title: 'Ops!',
          description: 'Por favor, preencha todos os períodos do mês.',
          confirmationText: 'Ok',
          cancellationText: 'Fechar'
        })
          .then(() => { })
          .catch(() => { })
      }
    }
  }

  const submitGoal = async () => {
    const response = {
      mainGoals: goalResponse.mainGoals,
      dsr: goalResponse.dsr,
      workingDays: goalResponse.workingDays,
      month: goalResponse.month,
      storeId: goalResponse.storeId,
      salary: goalResponse.salary,
      bonusPercentage: goalResponse.bonPercent,
      distribute: goalResponse.distribute
    }

    response.sections = goalResponse.sections.map(section => ({
      start: section.start,
      end: section.end
    }))

    response.usersWorking = goalResponse.usersWorking.map(user => ({
      userId: user,
    }))

    response.days = goalResponse.days.map(day => {
      return {
        date: day.date,
        goal: day.goal,
        working: day.goal > 0,
        users: day.goal > 0 ? day.users : []
      }
    })

    response.monthGoals = goalResponse.monthGoals.map(goal => {
      return {
        name: goal.name,
        value: goal.value,
        bonus: goalResponse.bonPercent ? goal.bonField / 100 : goal.bonField,
        commission: goal.commission / 100,
        minimal: goal.minimal
      }
    })
    onSubmit(response)

    // onClose();
    // setTab(0);

    setGoalResponse(defaultState)
    setTab(0)
  }

  const handleChangeSection = () => {
    setGoalResponse({
      ...goalResponse,
      sections: [{
        start: moment(goalResponse.month).startOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
        end: null,
      }]
    })
  }

  const handleAddSection = () => {
    if (goalResponse.sections[goalResponse.sections.length - 1].end) {
      setGoalResponse({
        ...goalResponse,
        sections: [...goalResponse.sections, {
          start: moment(goalResponse.sections[goalResponse.sections.length - 1].end).add('day', 1).set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
          end: null,
        }]
      })
    } else {
      confirm({
        title: 'Ops!',
        description: 'Você deve selecionar o fim do período atual antes de adicionar outro período.',
        confirmationText: 'Ok!',
        cancellationText: 'Fechar'
      })
        .then(() => { })
        .catch(() => { })
    }
  }

  // const handleChangePeriodDate = (index, data, field) => {
  //   let sections = goalResponse.sections

  //   sections[index][field] = data
  //   setGoalResponse({
  //     ...goalResponse,
  //     sections
  //   })
  // }

  const handleDeleteSection = (index) => {
    if (goalResponse.sections.length > 1 && goalResponse.sections.length === index + 1) {
      setGoalResponse({
        ...goalResponse,
        sections: goalResponse.sections.filter((item, periodIndex) => periodIndex !== index)
      })

    } else {
      confirm({
        title: 'Ops!',
        description: 'Você deve excluir o último período para excluir esse período.',
        confirmationText: 'Ok!',
        cancellationText: 'Fechar'
      })
        .then(() => { })
        .catch(() => { })
    }
  }

  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={active}
        fullWidth
        maxWidth='lg'
      >
        <DialogContent>
          <Stepper activeStep={tab} className={classes.root}>
            <Step key={0}>
              <StepLabel>Mês</StepLabel>
            </Step>
            <Step key={1}>
              <StepLabel>Loja</StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>Metas</StepLabel>
            </Step>
            <Step key={3}>
              <StepLabel>Dias</StepLabel>
            </Step>
            <Step key={4}>
              <StepLabel>Períodos</StepLabel>
            </Step>
            <Step key={5}>
              <StepLabel>Vendedores</StepLabel>
            </Step>
          </Stepper>

          <TabPanel value={tab} index={0}>
            <FormControl
              fullWidth
              variant='outlined'
              className={classes.margin}
              error={false}
            >
              <InputLabel htmlFor='mes'>Mês</InputLabel>
              <Select
                id='mes'
                name='mes'
                onChange={event =>
                  setGoalResponse({
                    ...goalResponse,
                    month: event.target.value,
                    sections: [{
                      start: moment(event.target.value).startOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                      end: moment(event.target.value).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                    }]
                  })}
                value={goalResponse.month}
                labelWidth={35}
                error={false}
                data-cy="select-goal-month"
              >
                {dates.map((date, i) => (
                  <MenuItem value={date} key={i} data-cy="goal-month">
                    {moment(date).format('MMMM - YYYY')}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText id='component-error-text'>{ }</FormHelperText>
            </FormControl>

            <Grid container spacing={1} className={classes.margin}>
              <Grid xs item>
                <TextField
                  id='diastrabalhados'
                  label='Dias Trabalhados'
                  variant='outlined'
                  fullWidth
                  type='number'
                  onFocus={event => event.target.select()}
                  inputProps={{ min: 0, max: 31, 'data-cy': 'working-days' }}
                  value={goalResponse.workingDays}
                  onChange={event =>
                    setGoalResponse({
                      ...goalResponse,
                      workingDays: Number(event.target.value)
                    })}
                />
              </Grid>

              <Grid item>
                <FormControlLabel
                  style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
                  control={
                    <Switch
                      inputProps={{ 'data-cy': 'dsr-switch' }}
                      checked={switchDsrVisible}
                      onChange={event => {
                        setSwitchDsrVisible(state => !state)
                        if (goalResponse.dsr > 0) {
                          setGoalResponse({
                            ...goalResponse,
                            dsr: 0
                          })
                        }
                      }}
                    />
                  }
                  label='DSR'
                />
              </Grid>
              <Grid xs item>
                {switchDsrVisible && (
                  <TextField
                    id='dsr'
                    label='DSR'
                    variant='outlined'
                    fullWidth
                    type='number'
                    onFocus={event => event.target.select()}
                    inputProps={{ min: 0, max: 10 }}
                    value={goalResponse.dsr}
                    onChange={event =>
                      setGoalResponse({
                        ...goalResponse,
                        dsr: Number(event.target.value)
                      })}
                  />
                )}
              </Grid>
            </Grid>
            <Grid container spacing={1} className={classes.margin}>
              <Grid xs item>
                <CurrencyTextField
                  fullWidth
                  className={classes.margin}
                  onFocus={event => event.target.select()}
                  label="Salário Base"
                  variant="outlined"
                  value={goalResponse.salary.base}
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  inputProps={{ style: { textAlign: 'left' }, 'data-cy': 'salary' }}
                  onChange={(event, value) => {
                    setGoalResponse({
                      ...goalResponse,
                      salary: {
                        ...goalResponse.salary,
                        base: value
                      }
                    })
                  }}
                />
              </Grid>
            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Typography className={classes.margin}>
              Insira as metas da loja.
            </Typography>
            <Grid container spacing={1} className={classes.margin}>
              <Grid xs item>
                <CurrencyTextField
                  fullWidth
                  onFocus={event => event.target.select()}
                  label="Ticket médio"
                  value={goalResponse.mainGoals.ticket}
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  inputProps={{ min: 0, 'data-cy': 'ticket' }}
                  variant="outlined"
                  onChange={(event, value) => {
                    setGoalResponse({
                      ...goalResponse,
                      mainGoals: {
                        ...goalResponse.mainGoals,
                        ticket: value
                      }
                    })
                  }}
                />
              </Grid>
              <Grid xs item>
                <CurrencyTextField
                  fullWidth
                  onFocus={event => event.target.select()}
                  label="Preço médio"
                  value={goalResponse.mainGoals.price}
                  currencySymbol="R$"
                  decimalCharacter=","
                  digitGroupSeparator="."
                  inputProps={{ min: 0, 'data-cy': 'price' }}
                  variant="outlined"
                  onChange={(event, value) => {
                    setGoalResponse({
                      ...goalResponse,
                      mainGoals: {
                        ...goalResponse.mainGoals,
                        price: value
                      }
                    })
                  }}
                />
              </Grid>
            </Grid>
            <CurrencyTextField
              fullWidth
              className={classes.margin}
              onFocus={event => event.target.select()}
              label="1a Meta da Loja"
              variant="outlined"
              value={goalResponse.mainGoals.total}
              currencySymbol="R$"
              decimalCharacter=","
              digitGroupSeparator="."
              inputProps={{ style: { textAlign: 'left' }, 'data-cy': 'total-goal' }}
              onChange={(event, value) =>
                setGoalResponse({
                  ...goalResponse,
                  mainGoals: {
                    ...goalResponse.mainGoals,
                    total: value
                  }
                })}
            />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <Grid container spacing={1} alignContent='center'>
              <Grid xs item>
                <FormControlLabel
                  style={{ width: '100%', justifyContent: 'center' }}
                  control={
                    <Switch
                      checked={goalResponse.salary.directCommission}
                      inputProps={{ 'data-cy': 'direct-commission-switch' }}
                      onChange={event =>
                        setGoalResponse({
                          ...goalResponse,
                          salary: {
                            ...goalResponse.salary,
                            directCommission: !goalResponse.salary.directCommission,
                            complement: goalResponse.salary.directCommission ? false : goalResponse.salary.complement
                          }
                        })}
                    />
                  }
                  label='Comissão direta (Salário incluso na comissão)'
                />
                {goalResponse.salary.directCommission && (
                  <FormControlLabel
                    style={{ width: '100%', justifyContent: 'center' }}
                    control={
                      <Switch
                        checked={goalResponse.salary.complement}
                        inputProps={{ 'data-cy': 'salary-complement-switch' }}
                        onChange={event =>
                          setGoalResponse({
                            ...goalResponse,
                            salary: {
                              ...goalResponse.salary,
                              complement: !goalResponse.salary.complement
                            }
                          })}
                      />
                    }
                    label='Complemento'
                  />
                )}
              </Grid>
              <Grid xs item>
                <FormControlLabel
                  style={{ width: '100%', justifyContent: 'center' }}
                  control={
                    <Switch
                      checked={goalResponse.bonPercent}
                      inputProps={{ 'data-cy': 'bon-percent-switch' }}
                      onChange={() => {
                        goalResponse.monthGoals.forEach(month => {
                          month.bonus = month.bonField / goalResponse.bonPercent ? 100 : 1
                        })
                        setGoalResponse({
                          ...goalResponse,
                          bonPercent: !goalResponse.bonPercent,
                          monthGoals: goalResponse.monthGoals
                        })
                      }}
                    />
                  }
                  label='Bonificação em percentual'
                />
              </Grid>
            </Grid>
            <Divider style={{ marginBottom: 20 }} />
            <FormControl
              className={classes.margin}
              variant='outlined'
              fullWidth
            >
              <InputLabel id='vendedores-label'>Vendedores</InputLabel>
              <Select
                labelId='vendedores-label'
                id='vendedores'
                multiple
                value={goalResponse.usersWorking}
                onChange={event => {
                  if (event.target.value.length > 0) {
                    setGoalResponse({
                      ...goalResponse,
                      usersWorking: event.target.value
                    })
                  } else {
                    confirm({
                      title: 'Ops!',
                      description: 'Você deve ter no mínimo um vendedor.',
                      confirmationText: 'Ok!',
                      cancellationText: 'Fechar'
                    })
                      .then(() => { })
                      .catch(() => { })
                  }
                }}
                input={<Input id='select-multiple-chip' variant='outlined' />}
                renderValue={selected => (
                  <div className={classes.chips}>
                    {selected.map(value => {
                      const user = sellers.find(x => x._id === value)
                      return (
                        <Chip
                          key={value}
                          label={user.name.complete}
                          className={classes.chip}
                        />
                      )
                    })}
                  </div>
                )}
                MenuProps={{
                  PaperProps: {
                    style: {
                      maxHeight: 225,
                      width: 250
                    }
                  }
                }}
              >
                {sellers.map(seller => (
                  <MenuItem
                    key={seller._id}
                    value={seller._id}
                    style={getStyles(
                      seller._id,
                      goalResponse.usersWorking,
                      theme
                    )}
                  >
                    {seller.name.complete}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography className={classes.margin}>
              Defina a meta mensal para cada vendedor.
            </Typography>
            <TableContainer component={Paper} className={classes.margin}>
              <Table
                stickyHeader
                className={classes.table}
                size='small'
                aria-label='simple table'
              >
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Nome</TableCell>
                    <TableCell align='center'>Valor</TableCell>
                    <TableCell align='center'>Comissão</TableCell>
                    <TableCell align='center'>Bonificação</TableCell>
                    {goalResponse.salary.complement && (
                      <TableCell align='center'>Complemento</TableCell>
                    )}
                    <TableCell align='center'>Comissão</TableCell>
                    <TableCell align='center' />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goalResponse.monthGoals.map((goal, i) => {
                    let commission = 0
                    const preCom = (goal.value * goal.commission) / 100
                    const bonus = goalResponse.bonPercent ? goal.value * goal.bonField / 100 : goal.bonField
                    const dsr = goalResponse.dsr > 0 ? ((preCom / goalResponse.workingDays) * goalResponse.dsr) : 0
                    const base = goalResponse.salary.directCommission ? -goalResponse.salary.base : 0
                    const preComplement = goalResponse.salary.base - preCom - dsr
                    const complement = goalResponse.salary.directCommission && goalResponse.salary.complement && preComplement >= 0 ? preComplement : 0
                    commission = preCom + dsr + bonus + base + complement
                    return (
                      <TableRow key={i}>
                        <TableCell component='th' scope='row'>
                          {goal.minimal ? 'Mínima' : (i === 0 && 'Principal')}
                          <TextField
                            onChange={event =>
                              changeMonthGoals(i, 'name', event)}
                            value={goalResponse.monthGoals[i].name}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <CurrencyTextField
                            fullWidth
                            className={classes.margin}
                            onFocus={event => event.target.select()}
                            // label="Total de vendas"
                            value={goalResponse.monthGoals[i].value}
                            currencySymbol="R$"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            inputProps={{ style: { textAlign: 'left' }, 'data-cy': 'month-goals-value' }}
                            onChange={(event, value) => changeMonthGoals(i, 'value', { target: { value: value } })}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          <TextField
                            type='number'
                            onFocus={event => event.target.select()}
                            inputProps={{ min: 0, max: 100, step: 0.5 }}
                            onChange={event =>
                              changeMonthGoals(i, 'commission', event)}
                            value={goalResponse.monthGoals[i].commission}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  %
                                </InputAdornment>
                              )
                              , 'data-cy': 'month-goals-commission'
                            }}
                          />
                        </TableCell>
                        <TableCell align='center'>
                          {goalResponse.bonPercent ? (
                            <TextField
                              type='number'
                              onFocus={event => event.target.select()}
                              inputProps={{ min: 0, max: 100, step: 0.1, 'data-cy': 'month-goals-bonField' }}
                              onChange={event =>
                                changeMonthGoals(i, 'bonus', event)}
                              value={goalResponse.monthGoals[i].bonField}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position='start'>
                                    %
                                  </InputAdornment>
                                )
                              }}
                              disabled={commission < 0}
                            />
                          ) : (
                              <CurrencyTextField
                                fullWidth
                                className={classes.margin}
                                onFocus={event => event.target.select()}
                                variant="standard"
                                currencySymbol="R$"
                                decimalCharacter=","
                                digitGroupSeparator="."
                                inputProps={{
                                  min: 0,
                                  step: 10
                                }}
                                value={goalResponse.monthGoals[i].bonField}
                                disabled={commission < 0}
                                onChange={(event, value) => changeMonthGoals(i, 'bonus', { target: { value: value } })}
                              />
                            )}
                        </TableCell>
                        {goalResponse.salary.complement && (
                          <TableCell align='center'>
                            <b>R${complement.toFixed(2)}</b>
                          </TableCell>
                        )}
                        <TableCell align='center'>
                          <b>R${commission.toFixed(2)}</b>
                        </TableCell>
                        <TableCell align='center'>
                          <Button
                            color='secondary'
                            onClick={() => {
                              if (goalResponse.monthGoals.length > 1) {
                                if (
                                  goalResponse.monthGoals.filter(
                                    x => !x.minimal
                                  ).length > 1 ||
                                  goalResponse.monthGoals[i].minimal
                                ) {
                                  goalResponse.monthGoals.splice(i, 1)
                                  setGoalResponse({
                                    ...goalResponse,
                                    monthGoals: goalResponse.monthGoals
                                  })
                                } else {
                                  confirm({
                                    title: 'Ops!',
                                    description:
                                      'Você deve ter no mínimo uma meta comum.',
                                    confirmationText: 'Ok!',
                                    cancellationText: 'Fechar'
                                  })
                                    .then(() => { })
                                    .catch(() => { })
                                }
                              } else {
                                confirm({
                                  title: 'Ops!',
                                  description:
                                    'Você deve ter no mínimo uma meta.',
                                  confirmationText: 'Ok!',
                                  cancellationText: 'Fechar'
                                })
                                  .then(() => { })
                                  .catch(() => { })
                              }
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <Button
              color='secondary'
              onClick={() => {
                const { monthGoals } = goalResponse
                const qtGoals = goalResponse.monthGoals.filter(x => !x.minimal)
                  .length
                const hasMinimal =
                  goalResponse.monthGoals.filter(x => x.minimal).length > 0
                const newGoal = {
                  name: `${qtGoals + 1}ª meta`,
                  value: 0,
                  bonus: 0,
                  bonField: 0,
                  commission: 0,
                  minimal: false
                }
                if (hasMinimal) {
                  monthGoals.splice(qtGoals, 0, newGoal)
                } else {
                  monthGoals.push(newGoal)
                }
                setGoalResponse({ ...goalResponse, monthGoals })
              }}
            >
              + Meta
            </Button>
            <Button
              color='secondary'
              onClick={() => {
                if (goalResponse.monthGoals.filter(x => x.minimal).length > 0) {
                  confirm({
                    title: 'Ops!',
                    description: 'Já foi definida uma meta mínima',
                    confirmationText: 'Ok!',
                    cancellationText: 'Fechar'
                  })
                    .then(() => { })
                    .catch(() => { })
                } else {
                  setGoalResponse({
                    ...goalResponse,
                    monthGoals: [
                      ...goalResponse.monthGoals,
                      {
                        name: 'Meta mínima',
                        value:
                          goalResponse.monthGoals.length > 0
                            ? goalResponse.monthGoals[0].value * 0.8
                            : 0,
                        bonus: 0,
                        bonField: 0,
                        commission: 0,
                        minimal: true
                      }
                    ]
                  })
                }
              }}
            >
              + Meta Mínima
            </Button>
          </TabPanel>
          <TabPanel value={tab} index={3}>
            <Grid container className={classes.sumHeader}>
              <Grid item xs>
                <Typography align='center'>Somatória das metas:</Typography>
                <Typography
                  align='center'
                  color={goalResponse.sum === goalResponse.mainGoals.total ? 'secondary' : 'error'}
                >
                  <b>{`R$ ${goalResponse.sum === '' ? '0.00' : goalResponse.sum.toFixed(2)}`}</b>
                </Typography>
              </Grid>
              {Number(Math.round(goalResponse.sum + 'e2') + 'e-2') !== Number(Math.round(goalResponse.mainGoals.total + 'e2') + 'e-2') && (
                <Grid item xs>
                  <Typography align='center'>Distância da meta:</Typography>
                  <Typography align='center' color='error'>
                    <b>{`R$ ${Number(Number(Math.round(goalResponse.sum + 'e2') + 'e-2') - Number(Math.round(goalResponse.mainGoals.total + 'e2') + 'e-2')).toFixed(2)}`}</b>
                  </Typography>
                </Grid>
              )}
              <Grid item xs>
                <Typography align='center'>Meta da Loja:</Typography>
                <Typography align='center'>
                  <b>{`R$ ${goalResponse.mainGoals.total === '' ? '0.00' : goalResponse.mainGoals.total.toFixed(2)}`}</b>
                </Typography>
              </Grid>
            </Grid>
            <TableContainer
              component={Paper}
              className={classes.tableContainer}
            >
              <Table
                className={classes.table}
                size='small'
                aria-label='sticky table'
                stickyHeader
              >
                <TableHead>
                  <TableRow>
                    <TableCell align='center'>Dia</TableCell>
                    <TableCell align='center'>Meta da loja</TableCell>
                    <TableCell align='center'>Dia útil</TableCell>
                    <TableCell align='center'>Reunião com vendedores</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {goalResponse.days.map((row, i) => {
                    const dateString = row.date
                      .toISOString()
                      .substring(5, 10)
                      .split('-')
                    return (
                      <TableRow key={i}>
                        <TableCell
                          component='th'
                          scope='row'
                          align='center'
                          style={{ width: '30%' }}
                        >
                          {`${dateString[1]}/${dateString[0]} - ${weekDays[row.date.getDay()]}`}
                        </TableCell>
                        <TableCell align='center' style={{ width: '40%' }}>
                          <CurrencyTextField
                            fullWidth
                            className={classes.margin}
                            onFocus={event => event.target.select()}
                            // variant="outlined"
                            currencySymbol="R$"
                            decimalCharacter=","
                            digitGroupSeparator="."
                            // inputProps={{ style: { textAlign: 'left' } }}
                            onChange={(event, value) => changeDayGoals(i, 'goal', { target: { value: value } })}
                            inputProps={{
                              min: 0,
                              max: goalResponse.mainGoals.total,
                              step: 100
                            }}
                            value={
                              goalResponse.days[i].goal === 0
                                ? ''
                                : goalResponse.days[i].goal
                            }
                            placeholder={goalResponse.days[i].goalSuggestion}
                          />
                        </TableCell>
                        <TableCell align='center' style={{ width: '15%' }}>
                          <Checkbox
                            checked={goalResponse.days[i].goal > 0}
                            onChange={() =>
                              changeDayGoals(i, 'goal', {
                                target: {
                                  value:
                                    goalResponse.days[i].goal > 0
                                      ? 0
                                      : goalResponse.days[i].goalSuggestion
                                }
                              })}
                            value='primary'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                        <TableCell align='center' style={{ width: '15%' }}>
                          <Checkbox
                            checked={goalResponse.days[i].talkToSellers}
                            onChange={event =>
                              changeDayGoals(i, 'talkToSellers', {
                                target: {
                                  value: !goalResponse.days[i].talkToSellers
                                }
                              })}
                            value='primary'
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel value={tab} index={4}>
            <Grid container spacing={1}>
              <Grid xs={4} item>
                <Card className={classes.root} variant="outlined">
                  <CardContent>
                    <SectionPicker
                      handleDayClick={(day) => {
                        let sections = goalResponse.sections
                        const dayIsBefore = moment(day).isBefore(goalResponse.sections[goalResponse.sections.length - 1].start)
                        if (!dayIsBefore) {
                          sections[goalResponse.sections.length - 1].end = moment(day).toISOString()
                          sections[goalResponse.sections.length - 1].workingDays = moment(day).diff(sections[goalResponse.sections.length - 1].start, 'days') + 1

                          setGoalResponse({
                            ...goalResponse,
                            sections
                          })
                        }

                      }}
                      sections={goalResponse.sections.map(section => ({
                        from: new Date(section.start),
                        to: new Date(section.end)
                      }))}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid xs={8} item>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Card className={classes.card}>
                      <Typography
                        component='h3'
                        gutterBottom
                        variant='overline'
                        color={'inherit'}
                      >
                        CICLO MENSAL
                    </Typography>
                      <Typography
                        variant='h6'
                        color={'textPrimary'}
                      >
                        {`${moment(goalResponse.month).startOf('month').format('DD/MM/YYYY')} a ${moment(goalResponse.month).endOf('month').format('DD/MM/YYYY')}`}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={6}>
                    <Card className={classes.card}>
                      <Typography
                        component='h3'
                        gutterBottom
                        variant='overline'
                        color={'inherit'}
                      >
                        META PRINCIPAL DA LOJA
                    </Typography>
                      <Typography
                        variant='h6'
                        color={'textPrimary'}
                      >
                        {formatPrice(goalResponse.mainGoals.total)}
                      </Typography>
                    </Card>
                  </Grid>
                  <Grid item xs={12}>
                    <Card>
                      <div
                        style={{
                          padding: 16,
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography variant='h5'>
                          Períodos
                        </Typography>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                        >
                          <FormGroup row>
                            <FormControlLabel
                              control={<Switch
                                checked={goalResponse.salary.splitCommission}
                                onChange={() => {
                                  setGoalResponse({
                                    ...goalResponse,
                                    salary: {
                                      ...goalResponse.salary,
                                      splitCommission: !goalResponse.salary.splitCommission
                                    }
                                  })
                                }}
                              />}
                              label="Acumular comissão"
                            />
                          </FormGroup>
                          {goalResponse.sections[0].end !== moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString() && (
                            <>
                              <Button
                                variant="text"
                                onClick={() => {
                                  confirm({
                                    title: 'Atenção!',
                                    description: 'Você tem certeza que deseja voltar para o mês completo? Não é possível desfazer essa ação.',
                                    confirmationText: 'Sim',
                                    cancellationText: 'Não'
                                  })
                                    .then(() => setGoalResponse({
                                      ...goalResponse, sections: [{
                                        start: moment(goalResponse.month).startOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                                        end: moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString(),
                                      }]
                                    }))
                                    .catch(() => { })
                                }}>
                                VOLTAR PARA MÊS COMPLETO
                              </Button>
                            </>
                          )}
                          <Button
                            disabled={goalResponse.sections.length > 1 && goalResponse.sections[goalResponse.sections.length - 1].end === moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString()}
                            variant="outlined"
                            color="primary"
                            onClick={() => {
                              if (goalResponse.sections[0].end === moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString()) {
                                handleChangeSection()
                              } else {
                                handleAddSection()
                              }
                              // goalResponse.sections.length <= 0 ? handleChangeSection() : handleAddPeriod()
                            }}>
                            {goalResponse.sections.length > 0 && goalResponse.sections[0].end === moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString() ? 'TROCAR PARA PERÍODOS' : '+PERÍODO'}
                          </Button>
                        </div>
                      </div>
                      <TableContainer className={classes.container}>
                        <Table stickyHeader aria-label="sticky table">
                          <TableHead>
                            <TableRow>
                              <TableCell
                              >
                                Período
                              </TableCell>
                              <TableCell
                              >
                                Data inicial
                              </TableCell>
                              <TableCell
                              >
                                Data final
                              </TableCell>
                              <TableCell
                              >
                                Dias úteis
                              </TableCell>
                              <TableCell
                              >
                                Meta
                              </TableCell>
                              <TableCell />
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {goalResponse.sections.map((section, index) => (
                              <TableRow key={index} hover role="checkbox" tabIndex={-1}>
                                <TableCell>
                                  {goalResponse.sections[0].end === moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString() ?
                                    'Mês completo' :
                                    `Período ${index + 1}`
                                  }
                                </TableCell>
                                <TableCell>
                                  {moment(section.start).format('DD/MM/YYYY')}
                                </TableCell>
                                <TableCell>
                                  {section.end ? moment(section.end).format('DD/MM/YYYY') : 'Selecione o fim  do período'}
                                </TableCell>
                                <TableCell>
                                  {goalResponse.days.filter(day => new Date(goalResponse.sections[index].start) <= new Date(day.date) && new Date(goalResponse.sections[index].end) >= new Date(day.date) && day.goal > 0).length}
                                </TableCell>
                                <TableCell>
                                  {formatPrice(goalResponse.days.reduce((acc, day) => {
                                    if (new Date(goalResponse.sections[index].start) <= new Date(day.date) && new Date(goalResponse.sections[index].end) >= new Date(day.date)) {
                                      acc += day.goal
                                    }
                                    return acc
                                  }, 0))}
                                </TableCell>
                                <TableCell>
                                  <IconButton
                                    disabled={(goalResponse.sections.length !== index + 1) ||
                                      (goalResponse.sections[0].end === moment(goalResponse.month).endOf('month').set({ hour: 12, minutes: 0, seconds: 0, milliseconds: 0 }).toISOString())
                                    }
                                    onClick={() => handleDeleteSection(index)}
                                    color={goalResponse.sections.length === index + 1 ? 'primary' : 'default'}
                                    aria-label="upload picture"
                                    component="span"
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Card>
                  </Grid>
                </Grid>
              </Grid>

            </Grid>
          </TabPanel>
          <TabPanel value={tab} index={5}>
            <Grid item>
              <FormControlLabel
                style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}
                control={
                  <Switch
                    checked={goalResponse.distribute}
                    onChange={event => {
                      setGoalResponse({
                        ...goalResponse,
                        distribute: !goalResponse.distribute
                      })
                    }}
                  />
                }
                label='Distribuir meta'
              />
            </Grid>
            {goalResponse.days.length > 0 && goalResponse.usersWorking.map((userId, i) => {
              const user = sellers.find(x => x._id === userId)
              const workingDays = goalResponse.days.filter(day => day.goal > 0)
              const goingEvery = workingDays.every(day =>
                day.users.includes(userId)
              )
              const nome = user.name.complete
              return (
                <Accordion
                  TransitionProps={{ unmountOnExit: true }}
                  key={i}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                  >
                    <Grid
                      container
                      direction='row'
                      justifyContent='space-between'
                      alignItems='center'
                    >
                      <Grid item>
                        <Typography className={classes.heading}>
                          {nome}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Grid
                          container
                          direction='row'
                          alignItems='center'
                          spacing={1}
                        >
                          <Grid item>
                            <Typography className={classes.heading}>
                              {goingEvery ? `Irá todos os dias` : `Irá em ${goalResponse.days.filter(day => day.users.includes(userId)).length} dias de ${workingDays.length}`}
                            </Typography>
                          </Grid>
                          <Grid item>
                            {goingEvery ? (
                              <CheckIcon
                                style={{ color: '#2ecc71' }}
                                size='small'
                              />
                            ) : (
                                <ExclamationIcon
                                  style={{ color: '#3498db' }}
                                  size='small'
                                />
                              )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.column}>
                      <DayPicker
                        month={new Date(goalResponse.month)}
                        canChangeMonth={false}
                        months={dayPickerBR.months}
                        weekdaysLong={dayPickerBR.weekdaysLong}
                        weekdaysShort={dayPickerBR.weekdaysShort}
                        firstDayOfWeek={0}
                        labels={dayPickerBR.labels}
                        modifiers={{
                          working: day =>
                            workingDays
                              .map(dayM => dayM.date.toISOString())
                              .includes(day.toISOString())
                        }}
                        modifiersStyles={{
                          working: {
                            color: '#8e44ad',
                            fontWeight: 'bold'
                          }
                        }}
                        selectedDays={goalResponse.days
                          .filter(day => day.users.includes(userId))
                          .map(day => day.date)}
                        onDayClick={(day, { selected }) => {
                          if (
                            workingDays
                              .map(dayM => dayM.date.toISOString())
                              .includes(day.toISOString())
                          ) {
                            const iDays = goalResponse.days.findIndex(dayF =>
                              DateUtils.isSameDay(dayF.date, day)
                            )
                            if (selected) {
                              // deselecionar
                              goalResponse.days[
                                iDays
                              ].users = goalResponse.days[iDays].users.filter(
                                userF => userF !== userId
                              )
                            } else {
                              // selecionar
                              goalResponse.days[iDays].users.push(userId)
                            }
                            setGoalResponse({
                              ...goalResponse,
                              days: goalResponse.days
                            })
                          }
                        }}
                      />
                    </div>
                    <div className={clsx(classes.column, classes.divider)}>
                      <Typography>
                        Selecione os dias em que <b>{nome}</b> irá trabalhar.
                      </Typography>
                      <Typography>
                        <b>Atenção: </b>A meta será proporcional aos dias trabalhados.
                      </Typography>

                      {goalResponse.monthGoals.map(g => {
                        //Pensar nessa porra aqui.. as metas listadas precisam ser o acumulado de todos os dias que o cara vai, dividido pela quantidade de pessoas que vão em cada dia
                        //Dps joga esse valor na regra de 3 pra entrar na proporção
                        const ISOList = workingDays.filter(day => day.users.includes(userId)).map(day => day.date.toISOString())
                        const goingCoef = goalResponse.days.filter(day => ISOList.includes(day.date.toISOString())).reduce((acc, day) => {
                          acc += day.goal
                          return acc
                        }, 0) / goalResponse.mainGoals.total

                        const goalValue = goalResponse.distribute ? goalResponse.days.filter(day => ISOList.includes(day.date.toISOString())).reduce((acc, day) => {
                          acc += day.goal / day.users.length
                          return acc
                        }, 0) * g.value / goalResponse.mainGoals.total * goalResponse.usersWorking.length : g.value * goingCoef

                        let commission = 0
                        const preCom = (goalValue * g.commission) / 100
                        const bonus = goalResponse.bonPercent ? g.value * g.bonField / 100 : g.bonField
                        const dsr = goalResponse.dsr > 0 ? ((preCom / goalResponse.workingDays) * goalResponse.dsr) : 0
                        const base = goalResponse.salary.directCommission ? -goalResponse.salary.base : 0
                        const preComplement = goalResponse.salary.base - preCom - dsr
                        const complement = goalResponse.salary.directCommission && goalResponse.salary.complement && preComplement >= 0 ? preComplement : 0

                        commission = preCom + dsr + bonus + base + complement
                        return (
                          <div key={g.name}>
                            <Typography variant='h5'>
                              {g.name}
                            </Typography>
                            <Typography>
                              {formatPrice(goalValue)} <b>{formatPrice(commission)}</b>
                            </Typography>
                          </div>
                        )
                      })}

                      {!goingEvery && (
                        <Button
                          color='primary' onClick={() => {
                            workingDays.forEach(day => {
                              if (!day.users.includes(userId)) {
                                day.users.push(userId)
                              }
                            })
                            setGoalResponse({ ...goalResponse, days: goalResponse.days })
                          }}
                        >Redefinir dias selecionados
                        </Button>
                      )}


                    </div>
                  </AccordionDetails>
                </Accordion>
              )
            })}
          </TabPanel>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              onClose()
              setTab(0)
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => (tab === 5 ? submitGoal() : changeTab(tab, tab + 1))}
            color='primary'
            data-cy="btn-submit"
          >
            {tab === 5 ? (
              loading ? (
                <CircularProgress size={24} />
              ) : (
                  'Adicionar'
                )
            ) : (
                'Próximo'
              )}
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}

AddGoalModal.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  addGoal: PropTypes.func,
  goals: PropTypes.array,
  sellers: PropTypes.array
}

export default AddGoalModal
