import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

export const Container = styled.div`
  background-color: ${colors.background.light};
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  .content {
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    .content-card {
      width: 40%;
      min-width: 500px;
      max-width: 50%;
      background-color: #fff;
      padding: 32px;
      border-radius: 15px;
      margin-top: 30px;

      .card-title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: "Commissioner", sans-serif;
        color: ${colors.darkGrey.main};
        font-size: 24px;
        font-weight: 700;
        margin-bottom: 10px;

        .icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 50px;
          height: 50px;
          padding: 5px;
          background-color: ${colors.lightGrey.lightest};
          border-radius: 50%;
        }
      }

      .description {
        font-family: "Inter", sans-serif;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGrey.darkest};

        .edit-email {
          color: ${colors.primary.main};
          cursor: pointer;
        }
      }

      .resend-code {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: auto;
        border-top: 1px solid ${colors.lightGrey.light};
        padding-top: 16px;

        p {
          font-family: "Inter";
          font-size: 12px;
          color: ${colors.darkGrey.light};
          width: 60%;
        }

        span {
          cursor: pointer;
          text-align: center;
          color: ${colors.secondary.main};
          font-family: "Commissioner";
          font-weight: 700;
          font-size: 14px;
        }

        .countdown {
          margin: auto;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
  
  .card-buttons {
    width: 40%;
    min-width: 500px;
    max-width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    
    h3 {
      cursor: pointer;
      font-family: "Commissioner", sans-serif;
      font-size: 14px;
      color: ${colors.darkGrey.darkest};
      font-weight: 700;
    }
  }
  `;
