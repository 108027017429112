import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

import { formatPrice } from '../../../../utils/format'

const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

function Goals ({ goal }) {
  const classes = useStyles()
  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            {/* <TableCell style={{ width: '2rem' }}></TableCell> */}
            <TableCell>Nome</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Valor</TableCell>
            <TableCell>Comissão</TableCell>
            <TableCell>Bônus</TableCell>
            <TableCell>Total Comissão</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {goal &&
            goal.monthGoals.map((row, i) => {
              const preCom = row.type === 'user' ? row.value * row.commission : goal.mainGoals.total * row.commission
              const bonus = row.bonusPercentage ? row.typeBonus === 'user' ? row.value * row.bonus : goal.mainGoals.total * row.bonus : row.bonus
              const dsr = goal.dsr > 0 ? ((preCom /(goal.days.length - goal.dsr)) * goal.dsr) : 0
              const base = goal.salary.directCommission ? -goal.salary.base : 0
              const preComplement = goal.salary.base - preCom - dsr
              const complement = goal.salary.directCommission && goal.salary.complement && preComplement >= 0 ? preComplement : 0
              const commission = preCom + dsr + bonus + base + complement

              return (
                <TableRow
                  key={row.name}
                  hover
                >
                  {/* <TableCell align="center"><EditIcon style={{ color: '#63727A' }} /></TableCell> */}
                  <TableCell component='th' scope='row'>
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell>
                    {i === 0 ? 'Principal' : 'Meta'}
                    {row.minimal && ' Mínima'}
                  </TableCell>
                  <TableCell>{formatPrice(row.value)}</TableCell>
                  <TableCell>{`${(row.commission * 100).toFixed(2)}%`}</TableCell>
                  <TableCell>{row.bonusPercentage ? `${(row.bonus * 100).toFixed(2)}%` : formatPrice(row.bonus)}</TableCell>
                  <TableCell>
                    {formatPrice(commission)}
                  </TableCell>
                </TableRow>
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default Goals
