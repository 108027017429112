import React from 'react';

import CreateIcon from '@material-ui/icons/Create';

import { StepperOptionContainer } from './style'

const StepperOption = ({ active, conditionToCompleted, progressTitle, onClick }) => (
  <StepperOptionContainer active={active} onClick={conditionToCompleted ? onClick : undefined} completed={conditionToCompleted}>
    { conditionToCompleted && 
      <CreateIcon className='edit-icon'/>   
    }
    <span>{progressTitle}</span>
  </StepperOptionContainer>
)

export default StepperOption;