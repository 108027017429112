import palette from './palette'

const fonts = {
  subtitle: {
    font: 'Commissioner',
    size: '24px',
    weight: 700
  },
  body: {
    font: 'Inter',
    size: '14px',
    weight: 500
  }
}

export default fonts
