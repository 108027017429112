import styled from "styled-components";

export const Container = styled.div`
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  z-index: 9999999 !important;

  .tooltip-container {
    display: flex !important;
    text-align: left !important;
    background-color: #263238 !important;
    color: white !important;
    font-family: 'Inter', sans-serif !important;
    font-size: 13px !important;
    opacity: 1 !important;
    padding: 10px !important;
    border-radius: 5px !important;
    max-width: 315px !important;
   
    ::before, ::after {
      display: none;
    }

    p {
      color: white;
      margin: 0;
    }
    span {
      font-weight: 600;
    }
  }
`