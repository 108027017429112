import React, { useState } from "react"

// Libs
import { useForm } from "react-hook-form";
import cep from 'cep-promise'
import { useSelector } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ImageSearchIcon from '@material-ui/icons/ImageSearch';
import CircularProgress from '@material-ui/core/CircularProgress'
import DoneIcon from '@material-ui/icons/Done';

// utils
import { cnpjMask, phoneMask, cepMask } from '../../utils/inputMasks'
// import defaultImage from '../../assets/defaultImage.png'

// Components
import SellersPlanSelect from '../SellersPlanSelect'
import { colors } from "theme/colors";
import { ArrowLeft, BusinessCenterTwoTone, KeyboardArrowLeft } from "@material-ui/icons";
import { FormHelperText, MenuItem } from "@material-ui/core";
import ReactSwitch from "react-switch";


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  customIcon: {
    color: '#CCCCCC',
    backgroundColor: '#FAFAFA',
    borderRadius: '155px',
    padding: theme.spacing(4),
    marginBottom: '18px',
    height: '120px',
    width: '120px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    border: '1px solid #C4C4C4'
  },
  iconText: {
    fontWeight: 'bold',
    color: '#CCCCCC',
  },
  badgeIcon: {
    background: '#702CB9',
    borderRadius: '150px',
    color: '#fff',
    padding: '10px',
  },
  sectionTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: theme.spacing(2),
  },
  input: {
    marginBottom: theme.spacing(1)
  },
  container: {
    marginTop: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  },
  avatarImage: {
    height: 150,
    width: 150,
    borderRadius: '50%',
    color: colors.darkGrey.lightest,
    border: `2px solid ${colors.lightGrey.main}`,
    background: colors.lightGrey.lightest,
    marginBottom: theme.spacing(2),
  },
  remunerationVisible: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginTop: theme.spacing(1),
  }
}))

const AddStoreForm = ({ user, onClick, loading, setTab }) => {
  const classes = useStyles()
  const { register, handleSubmit, errors } = useForm();

  // const cepField = watch('cep')
  // const formData = getValues()

  //const creditsBalance = useSelector(state => state.user.creditsBalance)


  // const [cepRequestLoading, setCepRequestLoading] = useState(false)

  const CEP_INITIAL_STATE = {
    cep: '',
    city: '',
    street: '',
    neighborhood: '',
  }
  const [cepData, setCepData] = useState(CEP_INITIAL_STATE)
  const [cepLoading, setCepLoading] = useState(false)
  const [cepError, setCepError] = useState()

  const [remunerationVisible, setRemunerationVisible] = useState(false)
  const [storeType, setStoreType] = useState()


  const storesType = [
    {
      type: 'street',
      name: 'Loja de rua'
    }, 
    {
      type: 'mall',
      name: 'Loja de shopping'
    }, 
    {
      type: 'kiosk',
      name: 'Quiosque'
    }, 
    {
      type: 'others',
      name: 'Outros'
    }
  ]


  const [preview, setPreview] = useState('')

  const handleChange = (e) => {
    const data = new FormData()
    if (e.target.files.length > 0) {
      var fileSize = ((e.target.files[0].size / 1024) / 1024).toFixed(4); // MB
      if (Number(fileSize) < 5) {
        data.append('photo', e.target.files[0])
        setPreview(URL.createObjectURL(e.target.files[0]))
      }
    }
  }

  const onSubmit = (submitData) => {
    const data = {
      name: submitData.name,
      corporateName: submitData.corporateName,
      cnpj: submitData.cnpj?.replace(/[./,-]/g, ''),
      address: {
        ...cepData,
        number: submitData.number,
      },
      remunerationVisible,
      type: storeType || "street",
      masterCode: submitData.masterCode,
    }
    
    if (submitData.phone) data.phone = submitData.phone?.replace(/[()-]/g,'')
    if (submitData.complement) data.address.complement = submitData.complement

    onClick(data)
  }

  const verifyCep = location => {
    setCepLoading(true)
    cep(location)
    .then(res => {
      setCepData(
        { ...cepData,
          cep: res.cep, 
          city: res.city, 
          neighborhood: res.neighborhood, 
          street: res.street
        }
      )
      setCepLoading(false)
    })
    .catch(err => {
      setCepError(err.errors[0].message)
      setCepData(CEP_INITIAL_STATE)
      setCepLoading(false)
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={classes.sectionTitle}>
        <Button 
          style={{marginRight: 10}} 
          type="button" 
          onClick={() => setTab(0)}
        >
          <KeyboardArrowLeft /> Voltar
        </Button> 
        <h2>Adicionar nova loja</h2>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <label htmlFor="avatar" className={classes.container}>
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                  
                }}
                badgeContent={<ImageSearchIcon className={classes.badgeIcon} style={{fontSize: 45}} color="primary" />}
              >
                {preview ? 
                  <Avatar src={preview} alt="Imagem de perfil" className={classes.avatarImage} /> 
                  : <BusinessCenterTwoTone className={classes.avatarImage} alt="Imagem de perfil" />
                }
              </Badge>
              <input
                style={{ display: "none" }}
                type="file"
                id="avatar"
                accept=".png, .jpg, .jpeg"
                onChange={handleChange}
              />
            </label>
            <Grid container>
              <Grid item xs={12} style={{marginTop: 30}}>
                <Typography className={classes.sectionTitle}>Identificação no sistema</Typography>
                <TextField
                  label="Nome da loja"
                  fullWidth
                  variant="outlined"
                  name="name"
                  inputRef={register({ required: true })}
                  margin="dense"
                  className={classes.input}
                  error={!!errors?.name}
                />
                <TextField
                  name="storeType"
                  variant='outlined'
                  margin='dense'
                  label="Tipo da Loja"
                  className={classes.input}
                  fullWidth
                  // inputRef={register({ required: true })}
                  select
                  value={storeType}
                  onChange={e => setStoreType(e.target.value)}
                >
                  {storesType.map((store, i) => (
                    <MenuItem key={i} value={store.type}>{store.name}</MenuItem>
                  ))}
                </TextField>
                <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.sectionTitle}>Dados da loja</Typography>
                <TextField
                  name="cnpj"
                  label="CNPJ"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  inputRef={register({ required: true })}
                  margin="dense"
                  error={!!errors?.cnpj}
                  InputProps={{
                    inputComponent: cnpjMask,
                  }}
                />
                <TextField
                  name="corporateName"
                  label="Razão Social"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  inputRef={register({ required: true })}
                  margin="dense"
                  error={!!errors?.corporateName}
                />
                <TextField
                  name="phone"
                  label="Telefone (opcional)"
                  fullWidth
                  variant="outlined"
                  className={classes.input}
                  inputRef={register({ required: true })}
                  margin="dense"
                  error={!!errors?.phone}
                  InputProps={{
                    inputComponent: phoneMask,
                  }}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <Grid container>
                <Grid item xs={12}>
                  <Typography className={classes.sectionTitle}>Endereço da loja</Typography>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="cep"
                        label="CEP"
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        onChange={(e) => {
                          if (e.target.value.length > 0) {
                            const cep = e.target.value.replace(/[^0-9]/g, '')
                            if (cep?.length === 8 && cep !== cepData?.cep) verifyCep(cep)
                          } else {
                            setCepData(CEP_INITIAL_STATE)
                          }

                          if (!!cepError) setCepError(null)
                        }}
                        inputRef={register({ required: true })}
                        margin="dense"
                        error={cepError}
                        InputProps={{
                          inputComponent: cepMask,
                          endAdornment: cepLoading ? <CircularProgress size={20} /> : null,
                        }}
                      />
                      <FormHelperText error={cepError}>{cepError}</FormHelperText>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="city"
                        label={!cepData?.city && 'Cidade'}
                        fullWidth
                        value={cepData?.city}
                        variant="outlined"
                        inputRef={register({ required: true })}
                        margin="dense"
                        error={!!errors?.city}
                        className={classes.input}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="neighborhood"
                        label={!cepData?.neighborhood && "Bairro"}
                        fullWidth
                        value={cepData?.neighborhood}
                        variant="outlined"
                        inputRef={register({ required: true })}
                        margin="dense"
                        error={!!errors?.neighborhood}
                        className={classes.input}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="street"
                        label={!cepData?.street && "Endereço"}
                        fullWidth
                        value={cepData.street}
                        inputRef={register({ required: true })}
                        variant="outlined"
                        className={classes.input}
                        margin="dense"
                        error={!!errors?.street}
                      />
                    </Grid>
                  </Grid>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="number"
                        label="Número"
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        inputRef={register({ required: true })}
                        margin="dense"
                        error={!!errors?.number}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TextField
                        name="complement"
                        label="Complemento (opcional)"
                        fullWidth
                        variant="outlined"
                        className={classes.input}
                        inputRef={register}
                        margin="dense"
                        error={!!errors?.complement}
                      />
                    </Grid>
                  </Grid>
                  <Divider style={{ marginTop: '1rem', marginBottom: '1rem' }} />
                  <Grid item xs={12}>
                    <Typography className={classes.sectionTitle}>Configuração da loja</Typography>
                    <TextField
                      label="Senha mestre (senha para acessar o vendedor no app)"
                      fullWidth
                      variant="outlined"
                      name="masterCode"
                      inputRef={register({ required: true })}
                      margin="dense"
                      error={!!errors?.masterCode}
                      inputProps={{ maxLength: 4 }}
                    />
                    <Grid item xs={12} className={classes.remunerationVisible}>
                      <Typography style={{marginBottom: 8}}>Apresentar valor de Remuneração aos vendedores</Typography>
                      <ReactSwitch
                        name="remunerationVisible"
                        onColor="#3a1463"
                        height={15}
                        width={40}
                        onHandleColor="#702CB9"
                        offHandleColor="#702CB9"
                        checked={!!remunerationVisible}
                        checkedIcon={null}
                        uncheckedIcon={null}
                        inputRef={register({ required: true })}
                        handleDiameter={20}
                        onChange={() => setRemunerationVisible(state => !state)}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          {/* <Grid item xs={12}>
            <SellersPlanSelect creditsBalance={creditsBalance} />
          </Grid> */}
          <Button
            variant="contained"
            color="primary"
            endIcon={<DoneIcon />}
            style={{ marginTop: '20px', marginLeft: 'auto', marginRight: 10}}
            type="submit"
          >
            {loading ? <CircularProgress size={24} /> : 'Cadastrar loja'}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default AddStoreForm