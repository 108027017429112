import React, { useEffect, useState } from 'react'

// Libs
import MaskedInput from 'react-text-mask'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { ptBR } from 'date-fns/locale';
import DateFnsUtils from '@date-io/date-fns';
import jwtDecode from 'jwt-decode';
import { toast } from 'react-toastify';

// Components
import Alert from './Components/Alert';
import CardForm from './Components/CardForm';
import Input from '../../Components/Input';
import VerificationInput from '../../Components/VerificationInput';
import { Button } from '../../Components/Button';

// Icons
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import LockIcon from '@material-ui/icons/Lock';
import CheckIcon from '@material-ui/icons/Check';
import { NeoproLogo } from '../../../../assets/Icons/NeoproLogo.js'
import { LockerIcon } from 'assets/Icons/LockerIcon';
import { PersonIcon } from '../../../../assets/Icons/PersonIcon'
import { CalendarIcon } from 'assets/Icons/CalendarIcon';
import { MailIcon } from 'assets/Icons/MailIcon';
import Close from '@material-ui/icons/Close';
import { ProftFullIcon } from 'assets/Icons/ProftFullIcon';

// Utils
import api from '../../../../repositories/api';
import { cpfMask, phoneMask } from 'utils/inputMasks';
import { Container, OpenCalendarButton } from './styles'
import { colors } from '../../../../theme/colors'
import { useHistory, useParams } from 'react-router';
import { useDispatch } from 'react-redux'
import { emailSuccess } from 'store/modules/onboarding/actions';
import { userSuccess } from 'store/modules/user/actions';

const CreateAccount = () => {
  const history = useHistory()
  const { phone } = useParams()
  const dispatch = useDispatch()

  // NOTA IMPORTANTE:
  // Esse component form é usado tanto para a criação de GESTOR quanto usuarios comuns
  // preencherem o resto dos seus dados, nesse caso, o phone do params vai vir preenchido
  // pois o usuario comum so chega nesse componente depois de validar seu numero de telefone
  // portanto, os campos que so existem quando !!phone, sao campos para usuario comum

  const [user, setUser] = useState()
  const [formData, setFormData] = useState({
    name: {
      first: null,
      last: null,
    },
    cpf: null,
    gender: null,
    birthDate: null,
    identification: {
      email: {
        value: null,
      },
    },
    password: null,
    masterCode: null,
  })

  const [loading, setLoading] = useState(false)
  const [dateError, setDateError] = useState(false)
  const [passwordCheck, setPasswordCheck] = useState({
    password: null,
    passwordConfirm: null,
  })

  const onSubmit = async () => {
    setLoading(true)
    try {
      const { gender, birthDate, masterCode, ...restData } = formData

      const endPoint = 
      !phone 
        ? api.axios.post(`user/`, restData)
        : api.axios.put(`user/${user?.id}`, formData) 

      const response = await endPoint


      if (response.data) {
        // dispatch(emailSuccess(response.data.email.value))
        dispatch(userSuccess(response.data.user))
        localStorage.setItem('@Proft:token', response.data.token)
        toast.success(
          'Conta criada com sucesso!', 
          {onClose: () => history.push(`/verification/email=${response.data.user.email.value}`)}
        )


        // setTimeout(() => {
        //   history.push('/')
        // }, 3000);
      }
    } catch (error) {
      console.log(error);
      toast.error(error?.response?.data?.error?.friendlyMsg || 'Ocorreu um erro no cadastro da sua conta')
    }
    setLoading(false)
  }

  const getUser = () => {
    const userToken = localStorage.getItem('@Proft:activate-token')

    if (userToken) setUser(jwtDecode(userToken))
    else history.push('/')
  }

  const DatePicker = () => ( 
    <MuiPickersUtilsProvider locale={ptBR} utils={DateFnsUtils}>
      <KeyboardDatePicker
        id="birthdate-input"
        className="birthday-pick"
        inputVariant="outlined"
        format="dd/MM/yyyy"
        label="Data de nascimento"
        value={formData.birthDate}
        ampm={false}
        keyboardIcon={
          <OpenCalendarButton>
            <span>Abrir calendário</span>
            <CalendarIcon />
          </OpenCalendarButton>
        }
        views={["year", "month", "date"]}
        allowKeyboardControl
        todayLabel="Hoje"
        cancelLabel="Cancelar"
        autoOk // false para ter que confirmar
        okLabel="Confirmar" // vazio para ficar sem button
        maxDate={moment().subtract(18, 'years')}
        maxDateMessage={false}
        onChange={date => {
          if (date instanceof Date && !isNaN(date)) {
            setFormData({
              ...formData, 
              birthDate: 
                moment(date)
                  .utc(0)
                  .set({hours: 15, minutes: 0, seconds: 0, milliseconds: 0})
                  .toISOString()
            })
            setDateError(false)
          } else setDateError(true)
        }}
      />
    </MuiPickersUtilsProvider>
  )

  const isGenderSelector = formData.gender ? formData.gender.includes('male' || 'female') : true

  useEffect(() => {
    if (!!phone) {
      if (user) {
        setFormData(data => ({
          ...data,
          identification: {
            ...data.identification,
            phone: {
              value: user.identification?.phone?.value || null
            }
          }
        }))
      }
      else getUser()
    }
  }, [user])

  return (
    <Container>
      <div style={{transform: 'scale(0.5)', marginBottom: 20}}>
        <ProftFullIcon size={[200]} color={colors.primary.main} />
        {/* <NeoproLogo color={colors.primary.main} /> */}
      </div>

      <CardForm
        title="Seus dados"
        description="Seu celular foi confirmado! Agora vamos precisar de mais alguns dados."
        icon={<PersonIcon size={[26, 24]} color={colors.primary.main} />}
      >
        <Input 
          label="Nome (de sua preferência)"
          value={formData.name.first}
          setValue={e => setFormData({...formData, name: {...formData.name, first: e.target.value}})}
        />

        <Input 
          label="Sobrenome"
          value={formData.name.last}
          setValue={e => setFormData({...formData, name: {...formData.name, last: e.target.value}})}
        />

        <Input 
          label="CPF"
          value={formData.cpf}
          setValue={e => setFormData({...formData, cpf: (e.target.value).replace(/\D/g, '')})}
          InputProps={{
            inputComponent: cpfMask,
          }}
        />

        {!!phone && 
          <>
            <Input
              id="gender-input"
              label="Gênero"
              value={formData.gender === "other" ? "" : formData.gender}
              select={isGenderSelector}
              selectable={isGenderSelector}
              iconRight={!isGenderSelector && <Close style={{color: colors.darkGrey.main}} />}
              onIconRight={() => setFormData({...formData, gender: null})}
              autoFocus={!isGenderSelector}
              onChange={e => setFormData({...formData, gender: e.target.value})}
            >
              <MenuItem value="male">Homem (trans ou cis)</MenuItem>
              <MenuItem value="female">Mulher (trans ou cis)</MenuItem>
              <MenuItem value="other">Outro</MenuItem>
            </Input>

            <Input
              error={
                !!dateError ? "Digite uma data válida!" 
                : moment().diff(formData.birthDate, 'years') < 18 && "Você precisa ser maior de 18 anos para prosseguir!"
              }
              InputProps={{
                inputComponent: DatePicker,
              }}
            />
          </>
        }
      </CardForm>

      <CardForm
        title="Contato"
        icon={<MailIcon size={[32, 20]} color={colors.primary.main} />}
      >
        {!!phone && 
          <Input 
            label="Telefone" 
            value={formData.identification.phone.value}
            disabled={user?.identification?.phone?.validated}
            setValue={e => setFormData({...formData, identification: {...formData.identification, phone: {...formData.identification.phone, value: e.target.value}}})}
            InputProps={{
              inputComponent: phoneMask
            }}
          />
        }
        <Input 
          label="E-mail"
          value={formData.identification.email.value}
          disabled={user?.identification?.email?.validated}
          type="email"
          setValue={e => setFormData({...formData, identification: {...formData.identification, email: {...formData.identification.email, value: e.target.value}}})}
        />
      </CardForm>

      <CardForm
        title="Segurança"
        description="Crie uma senha para sua conta. Tenha cuidado para não ser muito fácil. Mas lembre-se dela para depois!"
        icon={<LockerIcon size={[32, 30]} color={colors.primary.main} />}
      >
        <div className="inlined-children">
          <Input 
            label="Senha da conta" 
            type="password"
            value={passwordCheck.password}
            setValue={e => setPasswordCheck({...passwordCheck, password: e.target.value})}
            error={
              (passwordCheck.password?.length > 0 && passwordCheck.password?.length < 6) &&
              "A senha deve ter no mínimo 6 caracteres!" 
            }
          />

          <Input 
            label="Confirmar senha" 
            type="password"
            value={passwordCheck.passwordConfirm}
            setValue={e => {
              if (e.target.value === passwordCheck.password) {
                setFormData({...formData, password: passwordCheck.password})
              } 
              setPasswordCheck({...passwordCheck, passwordConfirm: e.target.value})
            }}
            error= {
              (!!passwordCheck.password && !!passwordCheck.passwordConfirm) &&
              (passwordCheck.password !== passwordCheck.passwordConfirm) &&
              "As senhas não coincidem"
            }
          />
        </div>

       {!!phone &&  
        <>
          <div className="lined-children">
            <span>Código de acesso</span>
            <VerificationInput 
              length={4} 
              type='access'
              value={formData.masterCode}
              setAcessCode={(value) => setFormData({...formData, masterCode: value})} 
            />
          </div>
          <Alert message="Para acessar algumas áreas do sistema você precisará inserir o seu Código de acesso de 4 números." />
        
        </> 
       }
      </CardForm>

      <div className="submit-bottom">
        <span>Ao clicar em Cadastrar, você concorda com nossos <strong>Termos de Uso</strong> e <strong>Políticas de Privacidade</strong></span>
       
        <div className="buttons">
          <Button
            text="Voltar"
            onClick={() => history.goBack()}
            textColor={colors.darkGrey.darkest}
            style={{minWidth: 0}}
          />
          <Button
            text="Cadastrar"
            rightIcon={<CheckIcon />}
            loading={loading}
            backgroundColor={colors.primary.main}
            textColor="#FFF"
            disabled={
              !!dateError ||
              !formData.name.first ||
              !formData.name.last ||
              !formData.cpf?.length === 11 ||
              !formData.identification.email.value?.includes('@') || 
              !formData.password || formData.password?.length < 6 ||
              !!phone ? (
                !formData.gender ||
                formData.masterCode?.length !== 4 ||
                !formData.birthDate ||
                !formData.identification.phone.value
              ) : false
            }
            onClick={onSubmit}
          />
        </div>
      </div>
    </Container>
  )
}

export default CreateAccount