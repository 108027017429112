import React, { useState } from 'react'
import { Backdrop, Modal } from '@material-ui/core';
import Slider from '@material-ui/core/Slider';
import { useEffect } from 'react';
import Close from '@material-ui/icons/Close';

import BoltImage from '../../../../../../../../../../../assets/bolt.png'
import { formatPrice } from '../../../../../../../../../../../utils/format';

// Styles
import { 
  Container, 
} from './styles'
import { SellersGoalTable } from '../../../../../ReviewStep/styles';

const GoalSliderModal = ({ data, sellerName, sellerWeight, seller, setSeller, open, onClose, onSave }) => {
  useEffect(() => {
    if (seller && (seller.weight > 3 || seller.sliderValue > 300)) setSeller({...seller, weight: 3, sliderValue: 300})
  }, [seller])
  
  return (
   <Modal
    open={open}
    onBackdropClick={onClose}
    closeAfterTransition
    BackdropComponent={Backdrop}
    BackdropProps={{
      timeout: 500
    }}
   > 
    <Container>
      <div className='close' onClick={() => {
        onClose()
        setSeller({...seller, weigth: sellerWeight, sliderValue: sellerWeight * 100})
      }}>
        <Close />
      </div>
      <div>
        <h1>Peso de {sellerName}</h1>
        <div className="input-container">
          <input 
            maxLength={5}
            value={seller.sliderValue !== undefined ? seller.sliderValue : 100} 
            onChange={e => setSeller({...seller, weight: e.target.value/100, sliderValue: e.target.value})}
          />
          <label>%</label>
        </div>
          
          <div className="slider-container">
            <span>0%</span>
            <Slider
              className="slider"
              defaultValue={100}
              step={5}
              min={0}
              max={300}
              onChange={(e, value) => setSeller({...seller, weight: value/100, sliderValue: value})}
              value={(seller.sliderValue)}
              marks={[
                {
                  value: 0,
                },
                {
                  value: 100,
                },
                {
                  value: 200,
                },
                {
                  value: 300,
                },
              ]}
            />
            <span>300%</span>
          </div>

          <div className="example-card">
            <h3><strong>Exemplo</strong> <img src={BoltImage} /></h3>
            <p>
              Meta individual: <strong>{formatPrice(10000)}</strong>
              <br/>
              Meta ajustada com o peso de {(seller.weight * 100).toFixed(0)}%: <strong>{formatPrice(10000 * seller.weight)}</strong>

              <br/>
              <br/>

              <strong>* Todas as metas serão afetadas com este peso!</strong>
            </p>
          </div>
          <button onClick={onSave}>
            Salvar
          </button>
      </div>
      </Container>
   </Modal>
  )
}

export default GoalSliderModal