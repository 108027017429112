import styled from 'styled-components';

export const Container = styled.div`
  background: transparent !important;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  transition: 0.5s ease-in-out;
  position: fixed;
  right: 0;
  margin-top: -11px;

  .sidebar-collapse-button {
    position: absolute;
    background-color: #EEEEEE;
    padding: 10px;
    box-shadow: -3px 0px 10px rgba(0, 0, 0, 0.1);
    border: 1px solid #DDDDDD;
    border-right: none;
    border-radius: 8px 0px 0px 8px;
    margin-top: 40px;
    color: #702CB9;
    font-size: 10px;
    margin-left: -41px;
    cursor: pointer;
  }
`;

export const SideBarContainer = styled.div`
  width: 300px;
  padding: 30px;
  background-color: #FAFAFA;
  font-family: 'Inter', sans-serif;
  letter-spacing: .5px;
  height: calc(100vh - 130px);
  overflow-y: scroll;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15); 
  transition: 0.5s ease-in-out;

  -ms-overflow-style: none;
  scrollbar-width: 4px;
  scrollbar-scrollbar-color: #b5b5b5;

  ::-webkit-scrollbar {
    width: 4px;
  }
  
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 50px;
  }
`;

export const TopInfo = styled.div`
  border-bottom: 1px solid #C4C4C4;
  margin-bottom: 25px;
  padding-bottom: 25px;

  div {
    margin-bottom: 5px;
    display: flex;
    align-items: center;

    .topinfo-icon {
      margin-right: 5px;
      color: #546E7A;
    }

    span {
      color: #546E7A;
      font-size: 11px;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  .topinfo-store-name {
    color: #65747B;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const MonthGoal = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 22px;

  h2 {
    color: #263238;
    margin: 0;
    font-size: 18px;
  }

  .monthgoal-info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    color: #65747B;
  }
  
  .monthgoal-store-info {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;

    span {
      color: rgba(101, 116, 123, 0.6);
    }

    .monthgoal-store-sellers {
      display: flex;
      align-items: center;

      span {
        margin-right: 5px;
      }
    }
  }
`;

export const MainGoal = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 30px;

  h2 {
    margin: 0px;
    color: #263238;
    font-size: 18px;
  }

`;