import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router'
import PropTypes from 'prop-types'

// Material UI
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import StorageIcon from '@material-ui/icons/Storage';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';

// Components
import MenuItemDropdown from './components/MenuItemDropdown'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: '#F5F6F8',
    textTransform: 'none',
    borderRadius: '4px',
    textAlign: 'left'
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: theme.spacing(2)
  },
  storageIcon: {
    color: '#fff',
    background: '#546E7A',
    borderRadius: '55px',
    padding: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}))

const SelectStoreMenuDropdown = ({ userStores }) => {

  const classes = useStyles()
  const history = useHistory()

  const { storeId } = useParams()

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [selectedStore, setSelectedStore] = useState()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectStore = (storeId) => {
    if (!selectedStore) {
      history.push(`/${storeId}/dashboard`)
    } else {
      if (history.location.pathname.includes('input')) {
        history.push(`/${storeId}/inputs`)
      } else if (history.location.pathname.includes('goal')) {
        history.push(`/${storeId}/goals`)
      } else {
        history.push(`${history.location.pathname.replace(selectedStore, storeId)}`)
      }
      history.go()

      // history.go()
      // if (history.location.pathname.includes('input')) {
      //   history.push(`${history.location.pathname.replace(selectedStore, storeId)}`)
      //   history.push
      // } else {
      //   }
    }

  }

  useEffect(() => {
    if (storeId) {
      setSelectedStore(storeId)
    }
  }, [storeId])

  return (
    <div className={classes.root}>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.button}
        endIcon={<ArrowDropDownIcon />}
        fullWidth
      >
        {((store) => (store ? (
          <div key={store.storeId?._id} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ListItemAvatar>
              <Avatar src={store.storeId?.photo?.url} />
            </ListItemAvatar>
            <ListItemText
              primary={store ? store.storeId.name : 'Não encontrado'}
              primaryTypographyProps={{
                style: {
                  fontWeight: '500',
                  textAlign: "flex-start"
                }
              }}
            />
          </div>
        ) : (
            <div className={classes.buttonWrapper}>
              <StorageIcon fontSize="large" className={classes.storageIcon} />
            Todas as lojas
            </div>
          )))(userStores.find(findStore => findStore.storeId._id == selectedStore))}
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        keepMounted
        PaperProps={{
          style: {
            padding: '0.5em',
            maxHeight: '19em',
            width: '17em',
            borderRadius: '8px',
            overflow: 'hidden'
          },
        }}
      >
        <div 
          style={{ 
            maxHeight: '200px', 
            overflow: 'auto' ,
          }}
        >
          {userStores.length > 0 && userStores.map((store) => (
            <MenuItemDropdown
              store={store}
              selectedStore={selectedStore}
              handleSelectStore={handleSelectStore} />
          ))}
        </div>
        <Divider style={{ marginTop: '8px', marginBottom: '8px' }}/>
        <Button
          aria-controls="simple-menu"
          aria-haspopup="true"
          onClick={() => history.push('/stores')}
          className={classes.button}
          endIcon={!selectedStore && <ArrowDropDownIcon />}
          fullWidth
          style={{ marginTop: 0 }}
        >
          <div className={classes.buttonWrapper}>
            <StorageIcon fontSize="large" className={classes.storageIcon} />
            Todas as lojas
            </div>
        </Button>
      </Menu>
    </div>
  )
}

SelectStoreMenuDropdown.propTypes = {
  userStores: PropTypes.array,
  selectedStore: PropTypes.string,
}

export default SelectStoreMenuDropdown;