import React, { useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'

// Utils
import { cepMask, cnpjMask, phoneMask } from "../../../../utils/inputMasks";

// Material UI
import { makeStyles } from '@material-ui/styles'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardContent from '@material-ui/core/CardContent'
import CardActions from '@material-ui/core/CardActions'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import AvatarInput from '../AvatarInput'
import ReactSwitch from 'react-switch';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';


// Styles
const useStyles = makeStyles((theme) => ({
  root: {
  },
  container: {
    display: 'flex',
    marginTop: theme.spacing(3),
  },

  margin: {
    marginRight: theme.spacing(2.5),
    marginBottom: theme.spacing(2),

  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  remunerationVisible: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: 10,
    marginTop: 5,
    fontFamily: 'Inter',
    fontSize: 12
  },
  switch: {
    marginTop: 5
  }
}))


const StoreSettings = ({
  className,
  loading,
  store,
  handleSubmitData,
  handleUpdateStoreAvatar,
  ...rest
}) => {
  const classes = useStyles()

  const defaultValues = {
    avatar: store.photo.url,
    name: store.name,
    phone: store?.phone,
    cnpj: store.cnpj,
    city: store.address.city,
    street: store.address.street,
    cep: store.address.cep,
    masterCode: store.masterCode,
    remunerationVisible: store.remunerationVisible,
    type: store.type
  }

  const [data, setData] = useState(defaultValues)

  const storesType = [
    {
      type: 'street',
      name: 'Loja de rua'
    }, 
    {
      type: 'mall',
      name: 'Loja de shopping'
    }, 
    {
      type: 'kiosk',
      name: 'Quiosque'
    }, 
    {
      type: 'others',
      name: 'Outros'
    }
  ]

  // Função para popular o estado de data
  const handleChangeData = event => {
    setData({
      ...data,
      [event.target.name]: event.target.value
    })
  }

  // Função para enviar a requisição
  const submitData = () => {
    handleSubmitData({
      ...data,
      phone: data.phone?.replace(/[^a-zA-Z0-9]/g, "",),
      cep: data.cep.replace(/[^a-zA-Z0-9]/g, "",),
      cnpj: data.cnpj.replace(/[^a-zA-Z0-9]/g, "",),
      type: data.type || 'street'
    })
  }

  return (
    <Grid spacing={2} container className={classes.container}>
      <Grid item xs={12} md={3} >
        <AvatarInput
          image={store && store.photo.url}
          title={store && store.name}
          subTitle={store && store.email}
          handleSubmitImage={(dataRequest) => handleUpdateStoreAvatar(dataRequest)}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <Card
          {...rest}
          className={clsx(classes.root, className)}
        >
          <form
            autoComplete='off'
            noValidate
          >
            <CardHeader
              title='Minha loja'
              subheader='Edite aqui as informações da sua loja'
            />
            <Divider />
            <CardContent>
              <Grid
                container
                spacing={1}
              >
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Nome'
                    margin='dense'
                    name='name'
                    onChange={handleChangeData}
                    required
                    value={data.name}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='Telefone'
                    margin='dense'
                    name='phone'
                    onChange={handleChangeData}
                    onFocus={(event) => event.target.select()}
                    value={data.phone}
                    InputProps={{
                      inputComponent: phoneMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Cep'
                    margin='dense'
                    name='cep'
                    onChange={handleChangeData}
                    required
                    value={data.cep}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      inputComponent: cepMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Cidade'
                    margin='dense'
                    name='city'
                    onChange={handleChangeData}
                    required
                    value={data.city}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Endereço'
                    margin='dense'
                    name='street'
                    onChange={handleChangeData}
                    required
                    value={data.street}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='CNPJ'
                    margin='dense'
                    name='cnpj'
                    onChange={handleChangeData}
                    required
                    value={data.cnpj}
                    onFocus={event => event.target.select()}
                    variant='outlined'
                    InputProps={{
                      inputComponent: cnpjMask,
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label='Senha mestre'
                    margin='dense'
                    name='masterCode'
                    onChange={handleChangeData}
                    required
                    value={data.masterCode}
                    onFocus={event => event.target.select()}
                    inputProps={{ maxLength: 4 }}
                    variant='outlined'
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className={classes.remunerationVisible}>
                    <span>Apresentar valor de Remuneração aos vendedores</span>
                    <ReactSwitch
                      onColor="#3a1463"
                      height={15}
                      width={40}
                      onHandleColor="#702CB9"
                      offHandleColor="#702CB9"
                      checkedIcon={''}
                      uncheckedIcon={''}
                      handleDiameter={20}
                      className={classes.switch}
                      checked={data.remunerationVisible}
                      onChange={() => setData({...data, remunerationVisible: !data.remunerationVisible})}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <TextField
                    variant='outlined'
                    margin='dense'
                    label="Tipo da Loja"
                    fullWidth
                    required
                    select
                    value={data.type}
                    onChange={e => setData({...data, type: e.target.value})}
                  >
                    {storesType.map((store, i) => (
                      <MenuItem key={i} value={store.type}>{store.name}</MenuItem>
                    ))}
                  </TextField>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                color='primary'
                variant='contained'
                className={classes.button}
                onClick={() => submitData()}
                disabled={JSON.stringify(defaultValues) === JSON.stringify(data)}
              >
                {loading ? (
                  <CircularProgress size={24} style={{ color: '#fff' }} />
                ) : (
                    'Salvar'
                  )}
              </Button>
            </CardActions>
          </form>
        </Card >
      </Grid>
    </Grid>
  )
}

StoreSettings.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  store: PropTypes.object,
  handleSubmitData: PropTypes.func,
  handleUpdateStoreAvatar: PropTypes.func,
}

export default StoreSettings
