import styled from 'styled-components'
import { colors } from '../../../../theme/colors'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  margin: auto;

  .code-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Inter';

    .code-inputs {
      display: flex;
      justify-content: center;
      align-items: center;
      
      .verification {
        border: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-align: center;
        height: 56px;
        width: 40px;
        border-radius: 10px;
        border: 2px solid;
        margin: 0 4px;
        font-family: 'Inter';
        font-weight: 500;
        font-size: 22px;
        color: ${colors.darkGrey.darkest};

        &:focus {
          outline: none;
        }
      }

      .access {
        border: none;
        background-image: none;
        background-color: transparent;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        text-align: center;
        font-family: 'Inter';
        font-weight: 500;
        margin: 0 10px;
        width: 25px;
        border-bottom: 3px solid #7248b6;
        font-size: 25px;

        &:focus {
          outline: none;
        }
      }
    }
  }
`