import styled from 'styled-components'

export const Container = styled.div`
  font-weight: 600;
  font-size: 12px;
  color: grey;
  margin: 10px auto;
  font-family: 'Inter';
  
  .error {
    color: red;
  }

  .success {
    color: green;
  }
`