import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Bar } from 'react-chartjs-2'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  Typography
} from '@material-ui/core'
import palette from 'theme/palette'

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}))

const Performance = props => {
  const { className, data, ...rest } = props

  const classes = useStyles()

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader
        title='Performance'
      />
      <Divider />
      <CardContent>
        {data && data.goals ? (
          <div className={classes.chartContainer}>
            <Bar
              data={{
                labels: data.labels,
                datasets: [
                  {
                    label: 'Meta recalculada: R$',
                    backgroundColor: palette.secondary.main,
                    data: data.future
                  },
                  {
                    label: 'Meta: R$',
                    backgroundColor: palette.neutral,
                    data: data.goals
                  },
                  {
                    label: 'Faturado: R$',
                    backgroundColor: palette.primary.main,
                    data: data.sold
                  },
                ]
              }}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                animation: false,
                legend: { display: false },
                cornerRadius: 20,
                tooltips: {
                  enabled: true,
                  mode: 'index',
                  intersect: false,
                  borderWidth: 1,
                  borderColor: palette.divider,
                  backgroundColor: palette.white,
                  titleFontColor: palette.text.primary,
                  bodyFontColor: palette.text.secondary,
                  footerFontColor: palette.text.secondary
                },
                layout: { padding: 0 },
                scales: {
                  xAxes: [
                    {
                      barThickness: 8,
                      maxBarThickness: 20,
                      barPercentage: 0.5,
                      categoryPercentage: 0.5,
                      ticks: {
                        fontColor: palette.text.secondary
                      },
                      gridLines: {
                        display: false,
                        drawBorder: false
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        fontColor: palette.text.secondary,
                        beginAtZero: true,
                        min: 0
                      },
                      gridLines: {
                        borderDash: [2],
                        borderDashOffset: [2],
                        color: palette.divider,
                        drawBorder: false,
                        zeroLineBorderDash: [2],
                        zeroLineBorderDashOffset: [2],
                        zeroLineColor: palette.divider
                      }
                    }
                  ]
                }
              }}
            />
          </div>
        ) : <Typography variant="subtitle2">Não há meta para esse mês.</Typography>}
      </CardContent>
      {/* <Divider />
      <CardActions className={classes.actions}>
        <Button
          color='primary'
          size='small'
          variant='text'
        >
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
    </Card>
  )
}

Performance.propTypes = {
  className: PropTypes.string
}

export default Performance
