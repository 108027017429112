import styled from 'styled-components';

export const ProfileCard = styled.div`
  position: absolute;
  top: 10;
  right: 0;
  z-index: 1;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  display: ${({ open }) => (open ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px;
`;
