import React, { useEffect, useState } from 'react';

import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR'
import { getDaysInMonth, getWeeksInMonth } from 'date-fns';

// Styled Components
import {
  Container,
  SideBarContainer,
  TopInfo,
  MonthGoal,
  MainGoal,
} from './styles';

// Icons
import TuneIcon from '@material-ui/icons/Tune';
import PeopleIcon from '@material-ui/icons/People';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import CloseIcon from '@material-ui/icons/Close';

import MainGoalInfo from './Components/MainGoalInfo';
import Tooltip from '../Tooltip';

const SideBar = ({ storeName, data }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCollapsed(true);
    }, 5000);
    return () => clearTimeout(timer)
  }, []);

  const toggleCollapseSideBar = () => {
    setIsCollapsed(!isCollapsed);
  }

  // Goal Date Formatting
  // const goalMonth = format(new Date(data?.month.$date), 'MMMM', {locale: ptBR});
  // const mainGoalName = data?.monthGoals.map(e => (e.name));

  // const getWeekends = data?.month.reduce((acc, current) => {
  //   if (getDay(new Date(current.date.$date)) === 0) {
  //     acc.sundays ++;
  //   } else {
  //     if (getDay(new Date(current.date.$date)) === 6) {
  //       acc.saturdays ++;
  //     }
  //   }
  //   return acc;
  // }, { saturdays: 0, sundays: 0 })

  // Main Goal Quantity Details
  const totalSell = Math.ceil(data?.mainGoals?.total / data?.mainGoals?.ticket);
  const pricePiece = Math.ceil(data?.mainGoals?.ticket / data?.mainGoals?.price);
  const totalPiece = Math.ceil(data?.mainGoals?.total / data?.mainGoals?.price);

  return (
   <Container style={{ marginRight: isCollapsed ? -300 : 0}}>

    <div onClick={toggleCollapseSideBar} className='sidebar-collapse-button'>
      { isCollapsed ? <NavigateBeforeIcon fontSize='small' /> : <CloseIcon fontSize='small' /> }
    </div>

    <SideBarContainer>
      <TopInfo>
        <div>
        <TuneIcon className="topinfo-icon" fontSize="small" />
        <span>Meta para</span>
        </div>
        <span className="topinfo-store-name">{storeName}</span>
      </TopInfo>

      <MonthGoal>

        <h2>Meta para { data?.month ? format(new Date(data?.month), 'MMMM', {locale: ptBR}) : '...'}</h2>

        <div className='monthgoal-info'>
          <span>- { data?.month ? getDaysInMonth(new Date(data?.month)) : '#' } dias</span>
          <span>- { data?.month ? getWeeksInMonth(new Date(data?.month)) : '#' } semanas</span>
          {/* <span>- {getWeekends.saturdays} sábados</span>
          <span>- {getWeekends.sundays} domingos</span> */}
        </div>

        <div className='monthgoal-store-info'>
      
          <span style={{ color: (data?.salary?.directCommission === true || data?.salary?.directCommission === false) && '#65747B' }}>
            {
                data?.salary?.directCommission === true ? 'Comissão direta'
              : data?.salary?.directCommission === false ? 'Piso + Comissão'
              : '(?) Como será feita a comissão'
            }
          </span>

          <span style={{ color: data?.sections?.length > 0 && '#65747B' }}>
            {
                data?.sections?.length === 1 ? 'Período Único'
              : data?.sections?.length > 1 ? 'Vários periodos'
              : '(?) Período da Meta'
            }
          </span>

          <span style={{ color: (data?.salary?.splitCommission === true || data?.salary?.splitCommission === false) && '#65747B' }}>
            {
                data?.salary?.splitCommission === true ? 'Comissão por Período'
              : data?.salary?.splitCommission === false ? 'Comissão por Mês'
              : '(?) Base da comissão'
            }
          </span>

          <span style={{ color: (data?.distribute === true || data?.distribute === false) && '#65747B' }}>
            {
                data?.distribute === true ? 'Meta Distribuída'
              : data?.distribute === false ? 'Meta Fixa'
              : '(?) Distribuição da Meta'
            }
          </span>

          <span style={{ color: (data?.config?.commission?.storeSold === true || data?.config?.commission?.storeSold === false) && '#65747B' }}>
            {
                data?.config?.commission?.storeSold === true ? 'Meta total da Loja'
              : data?.config?.commission?.storeSold === false ? 'Meta Individual'
              : '(?) Modelo da Meta'
            }
          </span>


        <div className='monthgoal-store-info'>
          <span style={{ color: data?.dsr >= 0 && '#65747B' }}>
            { data?.dsr > 0 ? data?.dsr : data?.dsr === 0 ? 'Sem' : '(?)' } DSR
          </span>

          <span style={{ color: data?.days?.length && '#65747B' }}>
            { data?.days?.length 
              ? data.days.reduce((acc,curr) => {
                  return acc += curr.working
                }, 0) 
              : '(?)' 
            } dias de loja aberta
          </span>

          <br/>

          <div className='monthgoal-store-sellers'>
            { data?.usersWorking?.length > 0 ?
              (
                <>
                  <span style={{ color: '#65747B' }}>
                    { data?.usersWorking?.length }{ data?.usersWorking?.length === 1 ? ' vendedor' : ' vendedores' }
                  </span>
                  <PeopleIcon data-tip data-for='sellers' style={{ color: '#65747B' }} fontSize='small'/>
                  <Tooltip 
                    id='sellers'
                    content={
                      <div style={{flexDirection: 'column'}}>
                        <span style={{color: 'white'}}>
                          { data?.usersWorking?.length }
                          { data?.usersWorking?.length === 1 ? ' vendedor ' : ' vendedores ' } 
                          na meta
                        </span>
                        { data?.usersWorking.map((user, i) => (
                          <p key={i}>{user?.userId?.name?.complete}</p>
                        )) }
                      </div>
                    } 
                  />
                </>
              )
                :
                <span>(?) Vendedores da meta</span>
            }
          </div>
        </div>

        </div>

      </MonthGoal>

      <MainGoal>
        <h2>Meta principal da loja</h2>
        <div className="maingoal-info">
        <MainGoalInfo
          title='1ª meta da loja'
          value={data?.mainGoals?.total}
          isGoalPrice={true}
          isPrice={true}
        />
        <MainGoalInfo
          title='Ticket médio'
          value={data?.mainGoals?.ticket}
          isPrice={true}
        />
        <MainGoalInfo
          title='Preço médio'
          value={data?.mainGoals?.price}
          isPrice={true}
        />
        <MainGoalInfo
          title='Total de vendas'
          value={totalSell}
        />
        <MainGoalInfo
          title='Peças por venda'
          value={pricePiece}
            />
        <MainGoalInfo
          title='Total de peças'
          value={totalPiece}
        />
        </div>
      </MainGoal>
    </SideBarContainer>
   </Container>
  );
}

export default SideBar;