import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'


import RefreshIcon from '@material-ui/icons/Refresh'
import Tooltip from 'views/Goals/components/AddGoal/components/Tooltip'

const useStyles = makeStyles(theme => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  refresh: {
    marginRight: theme.spacing(1)
  },
  label: {
    marginRight: theme.spacing(1.5),
  }
}))

const Toolbar = ({ onAdd, tooltipMessage, disableButton, onRefresh, actionText, buttonIcon, label, className, children, ...rest }) => {
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        {label && (
          <Typography className={classes.label} color="textSecondary">
            {label}
          </Typography>
        )}
        {children}
        <div style={{marginLeft: 'auto'}}>
          <div data-tip data-for="button-tip">
            {onRefresh && (
              <Button color='primary' onClick={onRefresh} className={classes.refresh} data-cy="btn-refresh">
                <RefreshIcon />
              </Button>
            )}
            { tooltipMessage && 
              <Tooltip 
                effect="solid" 
                place="top" 
                id="button-tip" 
                content={
                  <p><strong>{tooltipMessage}</strong></p>
                } 
              />
            }
            {actionText && actionText.length > 0 && (
              <Button endIcon={buttonIcon && buttonIcon} disabled={disableButton} color='primary' variant='contained' onClick={onAdd} data-cy="btn-new-data">
                {actionText}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string,
  onAdd: PropTypes.func,
  onRefresh: PropTypes.func,
  actionText: PropTypes.string,
  buttonIcon: PropTypes.node,
}

export default Toolbar
