import styled from "styled-components";

export const Container = styled.div`
  background-color: #F5F6F8;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .stepper-bar {
    height: 5px;
    width: 50%;
    margin: auto;
    border-radius: 50px;
    background-color: #ddd;
    position: relative;

    .progress-stepper-circle-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      transition: 0.5s ease-in-out;
      padding-left: 10px;
      
      .progress-stepper-circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #AC2FAE;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%)
      }
    }

    .progress-stepper-bar {
      height: 5px;
      width: 0px;
      background: linear-gradient(to right, #702CB9 20%, #AC2FAE 100%);
      border-radius: 50px;
      transition: 0.5s ease-in-out;
    }

  }
  
  .stepper-options {
    width: 62%;
    font-family: 'Inter', sans-serif;
    font-size: 12px;
    color: #C4C4C4;
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;

    div {
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 5%;

      span {
        transition: 0.3s ease-in-out;
      }
    }
  }
`