import styled from 'styled-components';
import { colors } from 'theme/colors';

export const Container = styled.div`
  background: ${colors.primary.darkest};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 12px;
  border-radius: 3px;
  margin: 0px 10px;
  min-height: 35px;

  .value {
    min-width: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    font-weight: 700;
    font-size: 14px;
  }
`;
