import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { colors } from 'theme/colors';

import { Container } from './styles'

export const Button = ({text, icon, loading, loadingColor, loadingSize, ...rest}) => {
  return (
    <Container
      hasIcon={!!icon}
      {...rest}
    >
     { loading ?
      <CircularProgress size={loadingSize || 20} style={{color: loadingColor || "#FFF"}} />
      : 
      <>
        <h4>{text}</h4>
        {icon}
      </>
    }
    </Container>
  );
}