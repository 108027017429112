import React from 'react'
export const MetaFixa = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M17.0277 31.8105C20.5228 31.8105 23.3677 28.9656 23.3677 25.4705C23.3677 21.9754 20.5228 19.1305 17.0277 19.1305C13.5325 19.1305 10.6877 21.9754 10.6877 25.4705C10.6877 28.9656 13.5325 31.8105 17.0277 31.8105ZM17.0277 34.9805C12.801 34.9805 4.34766 37.0938 4.34766 41.3205V44.4905H29.7077V41.3205C29.7077 37.0938 21.2543 34.9805 17.0277 34.9805Z" fill="#E5E5E5"/>
  <path d="M50.4349 31.8105C53.93 31.8105 56.7749 28.9656 56.7749 25.4705C56.7749 21.9754 53.93 19.1305 50.4349 19.1305C46.9398 19.1305 44.0949 21.9754 44.0949 25.4705C44.0949 28.9656 46.9398 31.8105 50.4349 31.8105ZM50.4349 34.9805C46.2082 34.9805 37.7549 37.0938 37.7549 41.3205V44.4905H63.1149V41.3205C63.1149 37.0938 54.6616 34.9805 50.4349 34.9805Z" fill="#C4C4C4"/>
  <path d="M83.8411 31.8105C87.3363 31.8105 90.1811 28.9656 90.1811 25.4705C90.1811 21.9754 87.3363 19.1305 83.8411 19.1305C80.346 19.1305 77.5011 21.9754 77.5011 25.4705C77.5011 28.9656 80.346 31.8105 83.8411 31.8105ZM83.8411 34.9805C79.6145 34.9805 71.1611 37.0938 71.1611 41.3205V44.4905H96.5211V41.3205C96.5211 37.0938 88.0678 34.9805 83.8411 34.9805Z" fill="#C4C4C4"/>
  <path d="M12.7197 40.1825L21.3356 48.8797" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M12.7197 48.8797L21.3356 40.1825" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M27.2692 75.7029H6.8674C5.48561 75.7029 4.34766 74.5649 4.34766 73.1831V69.9319C4.34766 68.5501 5.48561 67.4121 6.8674 67.4121H27.2692C28.651 67.4121 29.789 68.5501 29.789 69.9319V73.1831C29.7077 74.6462 28.5697 75.7029 27.2692 75.7029Z" fill="#E5E5E5"/>
  <path d="M60.5952 56.1139H40.2746C38.8928 56.1139 37.7549 54.9759 37.7549 53.5942V50.3429C37.7549 48.9611 38.8928 47.8231 40.2746 47.8231H60.6764C62.0582 47.8231 63.1962 48.9611 63.1962 50.3429V53.5942C63.1149 55.0572 61.9769 56.1139 60.5952 56.1139Z" fill="#702CB9"/>
  <path d="M94.0016 56.1139H73.5998C72.218 56.1139 71.0801 54.9759 71.0801 53.5942V50.3429C71.0801 48.9611 72.218 47.8231 73.5998 47.8231H94.0016C95.3834 47.8231 96.5214 48.9611 96.5214 50.3429V53.5942C96.5214 55.0572 95.3834 56.1139 94.0016 56.1139Z" fill="#702CB9"/>
  <path d="M12.7197 71.3949L21.3356 80.0921" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  <path d="M12.7197 80.0921L21.3356 71.3949" stroke="#C4C4C4" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
  </svg>
  
  )