import React from 'react'
import PropTypes from 'prop-types'

// Material UI
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Container } from './styles'
import Tooltip from '../Tooltip';

const GoalIndicatorCard = ({ cardTitle, cardValue, tooltipId, tooltipContent }) => {
  return (
    <Container>
      <header>
        <strong>{cardTitle}</strong>
        { tooltipContent &&
          <>
            <ErrorOutlineIcon data-tip data-for={tooltipId} style={{ color: '#C4C4C4', fontSize: 20 }}/>
            <Tooltip id={tooltipId} content={tooltipContent} />
          </>
        }
      </header>
      <h1>{cardValue}</h1>
    </Container>
  )
}

GoalIndicatorCard.propTypes = {
  cardTitle: PropTypes.string,
  cardValue: PropTypes.number,
  tooltipText: PropTypes.string,
}

export default GoalIndicatorCard