import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { goalAddRequest, goalListRequest } from 'store/modules/goal/actions';

// Material UI
import { Grid } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

// Components
import Navbar from './Components/Navbar';
import StepperProgress from './Components/StepperProgress';

import { Container } from './style'

const Header = ({ data, savedGoal, activeStep, setActiveStep, storeGoal, loading }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { storeId } = useParams()

  const token = localStorage.getItem('@Proft:token')

  const [canSaveGoal, setCanSaveGoal] = useState(true)
  
  useEffect(() => setCanSaveGoal(savedGoal !== JSON.stringify(data)), [savedGoal, data])

  return (
    <Container>
      <Navbar
        data={data}
        token={token}
        storeId={storeId}
        canSaveGoal={canSaveGoal}
        onBack={() => history.push(`/${storeId}/goals`)}
        onBackSave={() => {
          dispatch(goalListRequest(storeId, token))
          
          history.push(`/${storeId}/goals`)
        }}
        onSave={() => storeGoal(data)}
        loading={loading}
      />
      <Grid item xs={12}>
        <StepperProgress data={data} activeStep={activeStep} setActiveStep={setActiveStep} />
      </Grid>
    </Container>
  )
}

export default Header