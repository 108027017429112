import styled from 'styled-components';
import { colors } from '../../../../theme/colors';

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60vw 40vw;
  grid-template-rows: 1fr;
  height: 100vh;

  @media (max-width: 1200px) {
    grid-template-columns: 100vw;

    .image-section {
      display: none;
    }
  }

  .image-section {
    position: relative;
    overflow: hidden;
    background: linear-gradient(to right, ${colors.primary.light}, ${colors.primary.dark});

    img {
      width: 100%;
      height: 100%;
      transform: translateX(-10%, -10%);
    }
    
    .content {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0; 
      left: 0;

      .content-checks {
        margin-top: 30px;

        .check {
          display: flex;
          align-items: center;
          margin: 5px auto;

          span {
            margin-left: 5px;
            color: white;
            font-family: 'Inter', sans-serif;
            font-size: 16px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }

  .login-section {
    background-color: ${colors.background.light};
    width: 100%;
    height: 100%;
    margin: auto;
    padding: 30px;

    .top-logo {
      display: none;
      margin-bottom: 40px;

      @media (max-width: 1200px) {
        display: flex;
      }
    }

    .content {
      margin: auto;
      width: 75%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-family: "Commissioner", sans-serif;

      .content-buttons {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Commissioner', sans-serif;

        @media screen and (max-width: 500px) {
          flex-direction: column;
        }

        .forgot-password {
          color: ${colors.darkGrey.main}
        }
        
        .activate-account {
          color: ${colors.secondary.main}
        }
        
        span {
          font-size: 14px;
          font-weight: 700;
          cursor: pointer;
          margin-bottom: 10px;
        }
      }
    }
  }
`;
