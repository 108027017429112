import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useHistory, useParams } from 'react-router';
import Countdown from 'react-countdown';

import { Button } from '../../Components/Button';
import Input from '../../Components/Input'

import { NeoproLogo } from '../../../../assets/Icons/NeoproLogo';
import { ProftFullIcon } from 'assets/Icons/ProftFullIcon';

import api from '../../../../repositories/api';

import { colors } from '../../../../theme/colors';
import { Container } from './styles';
import { ShieldIcon } from 'assets/Icons/ShieldIcon';
import moment from 'moment';
import jwtDecode from 'jwt-decode'
import { userRequest } from 'store/modules/user/actions';
import { clearEmail, clearPhoneNumber, sendCodeRequest, userEmailRequest } from 'store/modules/onboarding/actions';
import { Check } from '@material-ui/icons';
import { MailIcon } from 'assets/Icons/MailIcon';
import { CircularProgress } from '@material-ui/core';
import { ofuscateEmail, ofuscatePhone } from 'utils/format';
import { PhoneIcon } from 'assets/Icons/PhoneIcon';
import VerificationInput from 'views/Onboarding/Components/VerificationInput';
import { LockerIcon } from 'assets/Icons/LockerIcon';
import { ArrowRightIcon } from 'assets/Icons/ArrowRightIcon';
import { EyeIcon } from 'assets/Icons/EyeIcon';
import { EyeSlashedIcon } from 'assets/Icons/EyeSlashedIcon';

const PasswordReset = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { id } = useParams()

  const token = localStorage.getItem('@Proft:token')
  
  const user = useSelector(state => state.user.user)
  const error = useSelector(state => state.onboarding.error)
  const nextUpdateAt = useSelector(state => state.onboarding.nextUpdateAt)
  const email = useSelector(state => state.onboarding.email)
  const phoneNumber = useSelector(state => state.onboarding.phoneNumber)


  const [activeStep, setActiveStep] = useState(0)
  const [code, setCode] = useState()
  const [password, setPassword] = useState({
    password: null,
    confirmation: null
  })

  const [passwordVisible, setPasswordVisible] = useState({
    password: false,
    confirmation: false
  })
  const [verificationType, setVerificationType] = useState(!!email ? 'email' : !!phoneNumber ? 'phone' : null)
  const [codeError, setCodeError] = useState(false)
  const [canResendCode, setCanResendCode] = useState(true)
  const [loading, setLoading] = useState(false)

  const submitVerification = async (type) => {
    setLoading(true)
    try {
      const authData = type === "email" ? {email: email} : {phone: phoneNumber}

      const { data } = await api.axios.put(`auth/validate`, {
        ...authData,
        code: parseInt(code)
      })
      
      if (data.token) {
        localStorage.setItem('@Proft:changePassword-token', data.token)

        const { id } = jwtDecode(data.token)
        toast.success(
          'Seu email foi verificado com sucesso!', 
          {onClose: () => {
            history.push(`/change-password/${id}`)
            setActiveStep(1)
          }}
        )
      }
    } catch (error) {
      const message = error.response.data.error.friendlyMsg
      setCodeError(message)
      console.log(error)
    }
    setLoading(false)
  }

  const resendCode = (type) => {
    const data = 
      type === "email" 
      ? {email}
      :  type === "phone" && {phoneNumber}

    type === "phone" && setCanResendCode(false)

    dispatch(sendCodeRequest(data))
  }

  const handleConfirmPasswordChange = async () => {
    setLoading(true)
    try {      
      const { data } = await api.axios.put(`user/${id}`, 
        { password: password.password },
      )

      if (data) {
        toast.success('Sua senha foi alterada com sucesso!', {
          onClose: () => {
            localStorage.removeItem('@Proft:changePassword-token')
            dispatch(clearEmail())
            dispatch(clearPhoneNumber())
            history.push('/')
          }
        })
      }
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error.friendlyMsg)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (error) {
      setCanResendCode(true)
      toast.error('Não foi possível enviar o código para este email ou telefone')
    }
  }, [error])

  useEffect(() => {
    if (!(token || email || phoneNumber)) history.push('/')
  }, [])
  
  const steps = [
    <>
      <div className="content-card">
        <div className="card-title">
          <span>{verificationType === "email" ? 'Verificar email' : 'Verificar celular'}</span>
          <div className="icon">
            {verificationType === "email" ? <ShieldIcon /> : <PhoneIcon size={[31, 30]} />}
          </div>
        </div>
        {verificationType === "email" ? 
          <p className="description">
            Enviamos um código de seis dígitos para o email: <br />
            <strong> {ofuscateEmail(email) || '...'}</strong>
            <br/><br/>
            Digite abaixo o código para validarmos a alteração de senha!
          </p>
          :
          <p className="description">
            Enviamos um código de seis dígitos para o número via SMS: <br />
            <strong> {ofuscatePhone(phoneNumber) || '...'}</strong>
            <br/><br/>
            Digite abaixo o código para validarmos a alteração de senha!
          </p>
        }
        {verificationType === "email" ? 
          <Input
            label={"Código de verificação"}
            style={{margin: '10px 0px 0px'}}
            value={code || ''}
            onChange={(e) => {
              if (!!codeError) setCodeError(null)
              setCode(e.target.value)
            }}
            error={codeError}
            inputProps={{
              maxLength: 6,
            }}
          />
        : <VerificationInput
            value={code}
            setAcessCode={(value) => setCode(value)} 
            length={6} 
            type="verification" 
          />
        }
        <div className="resend-code">
          { verificationType === "email" && 
            <p>
              Ainda não recebeu o código? <br/>
              Aguarde e atualize sua caixa de entrada ou <br/>
              envie um novo código clicando em <br/>
              <strong>reenviar código</strong>.
            </p>
          }
          <div className="countdown">
            { (!canResendCode && !!nextUpdateAt) ?
              <Countdown 
                date={new Date(nextUpdateAt)}
                onComplete={() => setCanResendCode(true)}
                renderer={time => (
                  <span style={{cursor: 'default'}}>{`Próximo código em ${String(time.minutes).padStart(2, '0')}:${String(time.seconds).padStart(2, '0')}`}
                  </span>
                )} 
              />
              : 
              <Button 
                text="Reenviar Código"
                textColor={colors.secondary.main}
                disabled={verificationType === "phone" && !canResendCode} 
                onClick={() => resendCode(verificationType)}
                margin={[0, 0]}
                style={{padding: 0}}
              />
            }
          </div>
        </div>
      </div>
      <div className="card-buttons">
        <Button 
          text="Voltar para login"
          textColor={colors.darkGrey.darkest}
          onClick={() => {
            dispatch(clearEmail())
            history.goBack()
          }}
          style={{padding: 0}}
        />
        <div style={{display: 'flex', alignItems: 'center'}}>
          {!!phoneNumber && 
            <Button 
              text={verificationType === "email" ? "Verificar com celular" : "Verificar com email"}
              loading={loading}
              disabled={verificationType === "email" && !canResendCode}
              textColor={colors.primary.main}
              disabledBackground={verificationType === "email" && !canResendCode && colors.lightGrey.darkest}
              borderColor={colors.primary.main}
              onClick={() => {
                resendCode("phone")
                setVerificationType(verificationType === "email" ? 'phone' : 'email')
              }}
              style={{marginRight: '10px'}}
            />
          }
          <Button 
            text={"Verificar"}
            loading={loading}
            textColor={colors.lightGrey.lightest}
            backgroundColor={colors.primary.main}
            disabled={!code}
            onClick={() => submitVerification(verificationType)}
          />
        </div>
      </div>
    </>,
    <>
      <div className="content-card">
        <div className="card-title">
          <span>Redefinir senha</span>
          <div className="icon">
            <LockerIcon />
          </div>
        </div>
        <p className="description">
          Defina sua nova senha. Cuide para não ser muito fácil, mas que você consiga lembrar depois.
        </p>
        <div style={{marginTop: 20}}>
          <Input
            label="Senha"
            value={password.password || ''}
            fullWidth
            iconRight={!passwordVisible.password ? <EyeIcon /> : <EyeSlashedIcon />}
            onIconRight={() => setPasswordVisible({...passwordVisible, password: !passwordVisible.password})}
            type={!!passwordVisible.password ? "text" : "password"}
            onChange={(e) => setPassword({...password, password: e.target.value})}
            error={password.password?.length < 6 && 'A senha deve ter no mínimo 6 caracteres'}
          />
          <Input
            label="Confirmar senha"
            value={password.confirmation || ''}
            fullWidth
            iconRight={!passwordVisible.confirmation ? <EyeIcon /> : <EyeSlashedIcon />}
            onIconRight={() => setPasswordVisible({...passwordVisible, confirmation: !passwordVisible.confirmation})}
            type={!!passwordVisible.confirmation ? "text" : "password"}
            onChange={(e) => setPassword({...password, confirmation: e.target.value})}
            error={(
                password.confirmation?.length > 0 && 
                password.password !== password.confirmation
              ) && 'As duas senhas devem ser similares!'
            }
          />
        </div>
      </div>
      <div className="card-buttons">
        <Button 
          text="Definir nova senha"
          loading={loading}
          textColor={colors.lightGrey.lightest}
          backgroundColor={colors.primary.main}
          icon={<ArrowRightIcon />}
          disabled={!password.password || password.password?.length < 6 || password.password !== password.confirmation}
          onClick={handleConfirmPasswordChange}
          style={{margin: 'auto'}}
        />
      </div>
    </>,
  ]

  return (
    <Container>
      <div className="content">
        <div style={{transform: 'scale(0.5)'}}>
          <ProftFullIcon size={[200]} color={colors.primary.main} />
          {/* <NeoproLogo color={colors.primary.main} /> */}
        </div>
        {steps[activeStep]}
      </div>
    </Container>
  )
}

export default PasswordReset;