import React from 'react'
import PropTypes from 'prop-types'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

import { Container } from './styles'
import CurrencyTextField from '../../../../../../components/CurrencyTextField';
import Tooltip from '../Tooltip';

const SalaryCard = ({ value, onChange, cardTitle, tooltipContent, disclaimerText, inputLabel }) => {
  return (
    <Container>
      <header className="card-check-header">
        <h1>{cardTitle}</h1>
        { tooltipContent && 
          <>
            <ErrorOutlineIcon data-tip data-for={cardTitle} style={{ color: '#C4C4C4', fontSize: 20 }}/>
            <Tooltip id={cardTitle} content={tooltipContent} />
          </>
        }
      </header>
      {disclaimerText && <span className="disclaimer">{disclaimerText}</span>}
      <div className="card-check-input-container">
        <CurrencyTextField
          label={inputLabel}
          variant='outlined'
          fullWidth
          onFocus={event => event.target.select()}
          value={value}
          currencySymbol="R$"
          decimalCharacter=","
          digitGroupSeparator="."
          inputProps={{ style: { textAlign: 'left' } }}
          onChange={onChange}
        />

      </div>
    </Container>
  )
}

SalaryCard.propTypes = {
  cardTitle: PropTypes.string,
  inputLabel: PropTypes.string,
  tooltipContent: PropTypes.element,
  disclaimerText: PropTypes.string,
  value: PropTypes.number,
  setValue: PropTypes.func,
}

export default SalaryCard