import axios from 'axios'

const URL = process.env.NODE_ENV === 'development'
  ? 'http://localhost:8080/v1'
  : 'https://apistaging.loca.lt/v1'

const axiosInstance = axios.create({
    baseURL: URL,
    timeout: 15000
  })

axiosInstance.interceptors.request.use(function (config) {
    const token = 
      localStorage.getItem('@Proft:token') || 
      localStorage.getItem('@Proft:activate-token') ||
      localStorage.getItem('@Proft:changePassword-token')
    config.headers.Authorization =  token ? `Bearer ${token}` : ''
    return config
})

const login = (login, password) => {
  return new Promise((resolve, reject) => {
    axiosInstance
      .post(`/auth/account`, { login, password })
      .then(response => {
        if (response.status === 200) {
          resolve(response.data.token)
        } else {
          reject(response.data.error)
        }
      })
      .catch(err => reject(err))
  })
}


export default {
  login,
  axios: axiosInstance
}
