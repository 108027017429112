import React, { useState, useEffect } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import PerfectScrollbar from 'react-perfect-scrollbar'

import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Accordion from '@material-ui/core/Accordion'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Button from '@material-ui/core/Button';
import DayPicker, { DateUtils } from 'react-day-picker'
import { formatPrice } from '../../../utils/format'
import 'react-day-picker/lib/style.css'
import moment from 'moment'

import dayPickerBR from '../../../common/DayPicker-pt-BR'

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  column: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start'
  }
}))

export default function ReportsTable({
  className,
  reports,
  requestReports,
  currentGoal,
  tableHeaders,
  ...rest
}) {
  const classes = useStyles()

  const date = new Date()
  const [selectedDays, setSelectedDays] = useState({
    from: new Date(date.getFullYear(), date.getMonth(), 1, 12),
    to: new Date(date.getFullYear(), date.getMonth() + 1, 0, 12)
  })

  const handleDayClick = day => {
    let range = DateUtils.addDayToRange(day, selectedDays)
    if (range.from && range.to) {
      if (range.from.toISOString() !== range.to.toISOString()) {
        requestReports(range)
      } else {
        range = {
          from: null,
          to: null
        }
      }
    }
    setSelectedDays(range)
  }

  useEffect(() => {
    if (reports && reports.store) {
      const newDate = new Date(reports.store.metric.lastInputDate)
      if (newDate.toISOString() !== selectedDays.to.toISOString()) {
        setSelectedDays({
          from: selectedDays.from,
          to: newDate
        })
      }
    }
  }, [reports])

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <Accordion TransitionProps={{ unmountOnExit: true }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1a-content'
          id='panel1a-header'
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            <Grid item>
              <Typography className={classes.heading}>
                Selecionado de
                <b>
                  &nbsp;
                  {selectedDays.from
                    ? selectedDays.from.toLocaleDateString()
                    : '-'}
                  &nbsp;
                </b>
                até
                <b>
                  &nbsp;
                  {selectedDays.to ? selectedDays.to.toLocaleDateString() : '-'}
                </b>
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <div className={classes.column}>
            <DayPicker
              className='Selectable'
              numberOfMonths={1}
              month={new Date()}
              months={dayPickerBR.months}
              modifiers={{
                start: selectedDays.from,
                end: selectedDays.to
              }}
              weekdaysLong={dayPickerBR.weekdaysLong}
              weekdaysShort={dayPickerBR.weekdaysShort}
              firstDayOfWeek={0}
              labels={dayPickerBR.labels}
              selectedDays={selectedDays}
              onDayClick={day => handleDayClick(day)}
            />
          {selectedDays.from && selectedDays.to && (
                <Button onClick={() => setSelectedDays({
                    from: null,
                    to: null
                 })}>
                  Limpar seleção
                </Button> 
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  {tableHeaders.map(header => <TableCell key={header}>{header}</TableCell>)}
                </TableRow>
              </TableHead>
              <TableBody>
                {reports && reports.store && (
                  <TableRow className={classes.tableRow} hover key={0} selected>
                    <TableCell>
                      <b>Loja</b>
                    </TableCell>
                    <TableCell>
                      <b>{formatPrice(reports.store.metric.acDayGoal)}</b>
                    </TableCell>
                    <TableCell>
                      <b>{formatPrice(reports.store.total.sold)}</b>
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell><b>{`${(reports.store.total.sold / reports.store.metric.acDayGoal * 100).toFixed(2)}%`}</b></TableCell>
                    <TableCell>
                      <b>{formatPrice(reports.store.projection.sold)}</b>
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      <b>{reports.store.total.sales.toFixed(0)}</b>
                    </TableCell>
                    <TableCell>
                      <b>{formatPrice((reports.store.total.sold / reports.store.total.sales) || 0)}</b>
                    </TableCell>
                    <TableCell>
                      <b>{((reports.store.total.items / reports.store.total.sales) || 0).toFixed(2)}</b>
                    </TableCell>
                    <TableCell>
                      <b>{formatPrice((reports.store.total.sold / reports.store.total.items) || 0)}</b>
                    </TableCell>
                    <TableCell>
                      <b>{reports.store.total.items.toFixed(0)}</b>
                    </TableCell>
                  </TableRow>
                )}
                {reports && reports.sellers.length > 0 ? (
                  [...reports.sellers].sort((a, b) => b.total.sold - a.total.sold)
                    .map((seller, i) => (
                      <TableRow
                        className={classes.tableRow}
                        hover
                        key={i + 1}
                        selected={false}
                      >
                        <TableCell>{seller.name.first}</TableCell>
                        <TableCell>{formatPrice(seller.metric.acDayGoal)}</TableCell>
                        <TableCell>{formatPrice(seller.total.sold)}</TableCell>
                        <TableCell>{formatPrice(seller.commission)}</TableCell>
                        <TableCell>{seller.projection.goal.name}</TableCell>
                        <TableCell>
                          {(seller.total.sold / seller.metric.acDayGoal * 100 || 0).toFixed(2)}%
                      </TableCell>
                        <TableCell>
                          <b>{formatPrice(seller.projection.sold)}</b>
                        </TableCell>
                        <TableCell>
                          <b>{formatPrice(seller.projection.commission)}</b>
                        </TableCell>
                        <TableCell>{seller.total.sales.toFixed(0)}</TableCell>
                        <TableCell>
                          {formatPrice((seller.total.sold / seller.total.sales) || 0)}
                        </TableCell>
                        <TableCell>
                          {((seller.total.items / seller.total.sales) || 0).toFixed(2)}
                        </TableCell>
                        <TableCell>
                          {formatPrice((seller.total.sold / seller.total.items) || 0)}
                        </TableCell>
                        <TableCell>{seller.total.items.toFixed(0)}</TableCell>
                      </TableRow>
                    ))
                ) : (
                    <TableRow>
                      <TableCell colSpan='13' style={{ textAlign: 'center' }}>
                        <Typography variant="subtitle2">Sem relatório para apresentar.</Typography>
                      </TableCell>
                    </TableRow>
                  )}
                {reports && reports.other && (
                  <TableRow className={classes.tableRow} hover key={1000}>
                    <TableCell>
                      Outras vendas
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      {formatPrice(reports.other.sold)}
                    </TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>-</TableCell>
                    <TableCell>
                      {reports.other.sales.toFixed(0)}
                    </TableCell>
                    <TableCell>
                      {formatPrice((reports.other.sold / reports.other.sales) || 0)}
                    </TableCell>
                    <TableCell>
                      {((reports.other.items / reports.other.sales) || 0).toFixed(2)}
                    </TableCell>
                    <TableCell>
                      {formatPrice((reports.other.sold / reports.other.items) || 0)}
                    </TableCell>
                    <TableCell>
                      {reports.other.items.toFixed(0)}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  )
}

ReportsTable.propTypes = {
  className: PropTypes.string,
  reports: PropTypes.object,
  requestReports: PropTypes.func
}
