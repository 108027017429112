import React, { useState, useEffect } from 'react'

// Libs
import { makeStyles } from '@material-ui/styles'
import { useSelector, useDispatch } from 'react-redux'
import { useConfirm } from 'material-ui-confirm'
import { useHistory } from 'react-router-dom'
import JwtDecode from 'jwt-decode'

// Components
import UsersTable from './components/UsersTable'
import AddUserModal from './components/AddUserModal'
import { Toolbar } from '../../components'
import EditUserModal from './components/EditUserModal'

// Actions
import {
  userListRequest,
  userAddRequest,
  userEditRequest,
  unLinkUser,
  userUpdateLink,
  userExistsRequest,
  userLinkRequest,
  userAddModal,
  userExistsSuccess,
  clearNewUser
} from '../../store/modules/user/actions'
import { requestFailed } from '../../store/modules/error/action'
import AddUserForm from './components/AddUserForm'
import { Button } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { goalListRequest } from 'store/modules/goal/actions'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

export default function UserList({ match }) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const history = useHistory()

  // userList: state.repositories.userList,
  // loading: state.repositories.loading

  // User
  const userList = useSelector(state => state.user.userList)
  const metadata = useSelector(state => state.user.metadata)
  const loading = useSelector(state => state.user.loading)
  const searchLoading = useSelector(state => state.user.searchLoading)
  const userExists = useSelector(state => state.user.userExists)
  const addUserModal = useSelector(state => state.user.addUserModal)
  const error = useSelector(state => state.user.error)
  const newUser = useSelector(state => state.user.newUser)

  // Goal
  const currentGoal = useSelector(state => state.goal.currentGoal)

  const { storeId } = match.params
  const token = window.localStorage.getItem('@Proft:token')
  const decoded = JwtDecode(token)

  const [addModal, setAddModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [user, setUser] = useState()

  const onRefresh = () => {
    dispatch(
      userListRequest(
        storeId,
        token
      )
    )
  }

  const handleChangePage = (page) => {
    if (page + 1 <= metadata.pagination.pageCount) {
      dispatch(userListRequest(
        storeId,
        token,
        page + 1,
        metadata.pagination.limit
      ))
    }
  }

  const handleChangeRows = (rowsNumber) => {
    dispatch(userListRequest(
      storeId,
      token,
      metadata.pagination.currentPage,
      Number(rowsNumber.props.value)
    ))
  }

  const handleSubmit = (data) => {
    const { phone, ...dataRest } = data
    data = {...dataRest, identification: {phone: {value: data.phone}}}
    dispatch(userEditRequest(data, token, user._id))
  }

  const handleOpenModal = (data) => {
    setUser(data)
    setEditModal(true)
  }

  const handleUpdateUserLink = (data) => {
    dispatch(userUpdateLink(data, user._id, localStorage.getItem('@Proft:token'), storeId))
  }

  const handleLinkUser = (data) => {
    dispatch(userLinkRequest({ ...data, storeId: storeId }, userExists._id, token))
  }

  const handleCheckCpf = (data) => {
    dispatch(userExistsRequest(data, token))
  }

  useEffect(() => {
    if (metadata.pagination.totalCount < 0) {
      onRefresh()
    }
  }, [])

  useEffect(() => {
    if (userList) {
      setAddModal(false)
      setEditModal(false)
    }
  }, [userList])

  useEffect(() => {
    if (!addUserModal) {
      dispatch(requestFailed(null))
      dispatch(userExistsSuccess({
        found: false
      }))
    }
  }, [addUserModal])

  useEffect(() => {
    if (currentGoal) {
      if (newUser.success && newUser.type === 'seller') {
        confirm({
          title: 'Vincular usuário a meta',
          description: `Foi identificada uma meta esse mês, deseja incluir o vendedor?`,
          confirmationText: 'Sim',
          cancellationText: 'Cancelar'
        }).then(() => {
          history.push(`/${storeId}/goals/${currentGoal._id}/${newUser.userId}`)
          dispatch(clearNewUser())
        }).catch(() => dispatch(clearNewUser()))
      }
    } else dispatch(goalListRequest(storeId, token))
  }, [newUser, currentGoal])

  return (
    <div className={classes.root}>
      <Toolbar
        onAdd={() => setAddModal(true)}
        onRefresh={!addModal && onRefresh}
        children={addModal &&
          <>
            <Button startIcon={<ArrowBack />} onClick={() => setAddModal(false)}>
              Voltar
            </Button>
            <h1 style={{marginLeft: 10, fontFamily: 'Inter', fontSize: 24}}>Adicionando novo usuário</h1>
          </>
        }
        actionText={!addModal && 'Adicionar novo Usuário'}
      />
      <EditUserModal
        active={editModal}
        onClose={() => setEditModal(false)}
        loading={loading}
        user={user}
        storeId={storeId}
        isDifferentUser={user && decoded.id !== user._id}
        handleUpdateUserLink={handleUpdateUserLink}
        handleSubmitData={handleSubmit}
        handleUnLinkUser={() => dispatch(unLinkUser(storeId, user._id, token))}
      />
     { addModal ? 
        <AddUserForm
          searchLoading={searchLoading}
          handleCheckCpf={handleCheckCpf}
          error={error}
          currentGoal={currentGoal}
          setAddModal={setAddModal} 
          linkUser={(data) => handleLinkUser(data)}
          addUser={user => dispatch(userAddRequest(user,token))}
        /> 
      : (
        <UsersTable
          users={userList}
          usersWorking={currentGoal && currentGoal.usersWorking.map(u => u.userId?._id)}
          handleOpenModal={handleOpenModal}
          onChangeRows={handleChangeRows}
          onChangePage={handleChangePage}
          metadata={metadata}
        />
      )
     }
      {/* <AddUserModal
        handleCheckCpf={handleCheckCpf}
        userExists={userExists}
        error={error}
        loading={loading}
        active={addUserModal}
        searchLoading={searchLoading}
        currentGoal={currentGoal}
        onClose={() => dispatch(userAddModal(false))}
        linkUser={(data) => handleLinkUser(data)}
        addUser={user => dispatch(
          userAddRequest(
            { ...user, storeId: storeId },
            token
          )
        )}
      /> */}
    </div>
  )
}
