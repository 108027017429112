import React, { useEffect, useState, useRef, useLayoutEffect } from 'react';
import { getDate, format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { Checkbox, Modal } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import { Check, Person } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

import CurrencyTextField from '../../../../../../../../../../../components/CurrencyTextField';
import PromptModal from '../../../../../../../../../../../components/PromptModal';

import { Container, SellersContainer } from './style';
import CheckPerson from '../../../../../../../../../../../assets/Icons/CheckPerson';
import UserDefaultPhoto from 'views/Goals/components/AddGoal/components/UserDefaultPhoto';
import Tooltip from 'views/Goals/components/AddGoal/components/Tooltip';

const CardDay = (
  {
    data,
    day,
    date,
    disabled,

    setWorkDay,
    setValue, 
    setSellersWorking,

    index,
    isEditing,
    setIsEditing,
    suggestion,
    handleNext
  }, ref
) => {
  const currentInput = useRef()
  const [inputValue, setInputValue] = useState()

  const [sellerModal, setSellerModal] = useState(false)
  
  const [usersWorkingDay, setUsersWorkingDay] = useState([])
  
  const handleChangeUserActive = (userIndex) => { // Callback para tirar ou adicionar usuario no dia dentro do modal
    const newUsersWorking = [...usersWorkingDay]
    newUsersWorking[userIndex].active = !newUsersWorking[userIndex].active
  
    setUsersWorkingDay(newUsersWorking)
  }

  const handleFinish = (event) => {
    if(event.key) { // Está dentro do input e tem um event
      if ((event.key === "Enter" || event.key === "Tab" || event.button >= 0) && inputValue >= 0) {
        event.target.blur()
        event.preventDefault()
        setValue({ target: { inputValue: inputValue === null ? 0 : inputValue } })
        handleNext()
      }
      if (event.key === "Escape") {
        event.target.blur()
        setIsEditing(false)
      }
    }else{ // Veio de fora do input
      if (currentInput.current) {
        currentInput.current.blur()
        if(!event.cancel){
          setInputValue(currentInput.current.value)
          setValue({ target: { inputValue: inputValue === null ? 0 : inputValue } })
        }
      }
      setIsEditing(false)
    }
  } 

  const handleCancel = () => handleFinish({cancel: true})

  useEffect(() => { // Define os vendedores para renderizar no modal
    if (!disabled && day?.users) setUsersWorkingDay([...day.users].map(user => ({...user})))
  }, [day?.users])
  
  useLayoutEffect(() => {
    if (day?.goal) setInputValue(day.goal)
  }, [day])

  useEffect(() => { // Define a referência para o Calendar
    if(currentInput && ref && ref.current !== currentInput.current) ref.current = currentInput.current
  }, [ref, currentInput])

  return (
  <>
    { isEditing === index && 
      <div
        className="editing-modal-backdrop" 
        onClick={handleCancel}
      /> 
    }
    <Container
        isModal={isEditing === index}
    >
      <div
        className={disabled && 'disable-day'}
      >
        <div className='calendar-card-top'>
          <div style={{cursor: 'pointer' }} className='calendar-card-top-checkbox' onClick={() => setWorkDay(!day?.working)}>
            <Checkbox
              className="checkbox"
              checked={!disabled && day?.working}
              style={{
                color: '#78909C',
                padding: 0,
                marginRight: 2,
              }}
              size='small'
            />
            <span>{getDate(new Date(date))}</span>
          </div>
          <Tooltip
            id={`cardDay-${(getDate(new Date(day?.date)))}-sellers-tooltip`}
            content={
              <p>Vendedores que irão trabalhar neste dia</p>
            }
          />
          { !disabled && day?.working &&
            <div className='calendar-card-top-buttons' data-tip data-for={`cardDay-${(getDate(new Date(day?.date)))}-sellers-tooltip`}>
              <button
                onClick={() => setSellerModal(true)}
              >
                <Person 
                  fontSize="small" 
                  style={{ color: '#37474F', marginRight: 2, fontSize: 16 }} 
                />
                 <span>{[...usersWorkingDay].filter(user => user.active).length ?? '-'}</span>
              </button>
            </div>
          }
        </div>
        { !disabled && day?.working &&
          <div
            className={`calendar-card-input ${suggestion && 'proft-suggestion'}`}
            onClick={e => {
              setIsEditing(index)
            }}
            onKeyDown={handleFinish}
          >
           <CurrencyTextField 
              ref={ref => currentInput.current = ref?.input}
              style={{
                background: '#F7F7F8',
                padding: '0px 5px',
                borderRadius: 3,
                width: '100%',
                underline: {
                  '&:before': {
                      borderBottom: '1px solid rgba(255, 133, 51, 0.42)'
                  },
                  '&:after': {
                      borderBottom: `2px solid rgba(255, 133, 51, 0.42)`
                  },
                  '&:hover:not($disabled):not($focused):not($error):before': {
                      borderBottom: `2px solid rgba(255, 133, 51, 0.42)`
                  }
                }
              }}
              fullWidth
              onFocus={event => event.target.select()}
              currencySymbol="R$"
              decimalCharacter=","
              digitGroupSeparator="."
              value={day.goal}
              inputProps={{ style: { textAlign: 'left' } }}
              onChange={(e, v) => {
                if (!isEditing) setIsEditing(index)
                setInputValue(v)
              }}
            />
          </div>
        }
      </div>

      {isEditing === index &&
        <div className="modal-buttons">
          <button
            onClick={handleCancel}
          >
            <Close style={{ fontSize: 14, color: 'white', marginRight: 3 }} />
            ESC
          </button>
          <button 
            disabled={day?.goal === null && !inputValue}
            onClick={handleFinish}
            style={{ backgroundColor: '#27AE60' }}
          >
            <Check style={{ fontSize: 14, color: 'white', marginRight: 3 }} />
            ENTER
          </button>
        </div>
      }

      <PromptModal
        open={sellerModal}
        icon={<CheckPerson />}
        title={`Vendedores que irão trabalhar ${format(new Date(date), 'EEEE', {locale: ptBR})}, ${format(new Date(date), 'dd/yyyy')}:`}
        onClose={() => {
          if (JSON.stringify(day?.users) !== JSON.stringify(usersWorkingDay)) setUsersWorkingDay([...day.users].map(user => ({...user})))
          setSellerModal(false)
        }}
        disableRight={usersWorkingDay.filter(user => user.active).length === 0}
        onRight={() => {
          setSellersWorking([...usersWorkingDay])
          setSellerModal(false)
        }}
        rightTitle={usersWorkingDay.filter(user => user.active).length === 0 ? 'Adicione pelo menos um vendedor' : "Salvar"}
      >
        { day && 
          <SellersContainer>
            <div className="sellers-top-title">
              <span>Selecione quem irá trabalhar no dia</span>
              <span 
                className="sellers-counter"
              >
                {[...usersWorkingDay].filter(user => user.active).length}/{data.usersWorking.filter(user => !!user.userId?._id)?.length}
              </span>
            </div>
            <div className="sellers-cards-container">
              {usersWorkingDay.map((user, i) => !!user.userId?._id && (
                <div 
                  onClick={() => handleChangeUserActive(i)}
                  key={i} 
                  className="sellers-user-card"
                >
                  <div className="sellers-user-card-info">
                    {user.userId?.photo?.url ? 
                      <img style={{backgroundSize: 'cover'}} width={25} height={25} src={user.userId?.photo.url} />
                      : <UserDefaultPhoto username={user.userId?.name.first} size={25} />
                    }
                    <span>
                      { user.userId?.name.complete.length > 20 ?  
                        String(user.userId?.name.complete).substring(0, 20-3) + '...' 
                        : user.userId?.name.complete
                      }
                    </span>
                  </div>
                  <Checkbox
                    className="seller-user-card-checkbox"
                    checked={user.active || false}
                    icon={<RadioButtonUncheckedIcon fontSize="small" />}
                    checkedIcon={
                      <CheckCircleOutlineIcon fontSize="small" />
                    }
                  />
                </div>
              )) }
            </div>
          </SellersContainer>
        }
      </PromptModal>
    </Container>
  </>
  )
}

export default React.forwardRef(CardDay);