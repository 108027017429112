import styled from 'styled-components';

export const Container = styled.div`
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  background-color: #F6F6F6;
  width: 380px;
  border-radius: 8px;
  padding: 20px;

  header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    h1 {
      font-size: 16px;
      margin: 0 10px 0 0;
      color: #37474F;
      text-align: left;
    }
  }

  div {
    width: 100%;
  }

  div:first-child {
    margin-top: 5px;
  }

  span.disclaimer {
    color: #546E7A;
    font-size: 12px;
    margin-bottom: 10px;
  }

  .MuiInputAdornment-root {
    width: 20px;
  }
`;
