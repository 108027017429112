import { CircularProgress } from '@material-ui/core';
import React from 'react';
import Lottie from 'react-lottie';
import { formatPrice } from 'utils/format';
import { Container } from './styles';
import Animation from '../../assets/walletCoin.json'

const Credits = ({loading, value}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: Animation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
          
  return (
    <Container>
      <Lottie style={{marginRight: 8}} options={defaultOptions} height={20} width={20} />

      <div className='value'>
        {loading 
          ? <CircularProgress size={12} style={{color: "#fff"}} /> 
          : <span>{formatPrice(value || 0)}</span>
        }
      </div>
    </Container>
  );
}

export default Credits;