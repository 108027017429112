import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

// Libs
import { useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { cnpjMask, cpfMask } from "../../../../../../utils/inputMasks";
import { formatCNPJ } from '@brazilian-utils/brazilian-utils';
import { toast } from 'react-toastify'

// Redux
import { useDispatch } from 'react-redux'
import { integrationRequest, integrationDisconnectRequest } from '../../../../../../store/modules/integration/actions'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { useConfirm } from 'material-ui-confirm'
import {
  CircularProgress,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField,
  Grid,
  FormHelperText,
  Switch,
  FormControlLabel,
  List,
  ListItemIcon,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListItemSecondaryAction,
  Avatar,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },

  margin: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}))


const SetaDigital = ({ className, storeIntegrationData, store, loading, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'setadigital'

  const { register, handleSubmit, reset, errors } = useForm({
    defaultValues: {
      username: '',
      password: '',
      serverIp: ''
    }
  })

  const cnpjDefaultValues = {
    data: '',
    error: false,
    errorMessage: ''
  }

  const [useOtherSales, setUseOtherSales] = useState(false)
  const [otherSalesCPF, setOtherSalesCPF] = useState('')
  const [stores, setStores] = useState([])
  const [cnpj, setCNPJ] = useState(cnpjDefaultValues)


  // LocalStorage
  const token = localStorage.getItem('@Proft:token')
  const { storeId } = useParams()

  const onSubmit = (data) => {
    confirm({
      title: 'Atenção',
      description: 'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na Proft e na SetaDigital?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(integrationRequest({ ...data, useOtherSales, cpfOtherSales: otherSalesCPF.replace(/[^a-zA-Z0-9]/g, ""), provider, listCnpj: stores }, provider, storeId, token))
      })
      .catch(() => { })
  }

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description: 'Você tem certeza que deseja remover a integração com SetaDigital?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => dispatch(integrationDisconnectRequest(provider, storeId, token)))
      .catch(() => { })
  }

  useEffect(() => {
    if (storeIntegrationData?.username && storeIntegrationData?.provider === provider) {
      reset({ username: storeIntegrationData.username, password: storeIntegrationData.password, serverIp: storeIntegrationData.serverIp })
      setUseOtherSales(storeIntegrationData.useOtherSales)
      if (storeIntegrationData.useOtherSales) {
        setOtherSalesCPF(storeIntegrationData.cpfOtherSales)
      }
    }
    if (storeIntegrationData.listCnpj) {
      setStores(storeIntegrationData.listCnpj)
    } else {
      setStores(oldState => [...oldState, store.cnpj])
    }
  }, [storeIntegrationData])

  const renderError = (error) => errors[error] && (
    <FormHelperText id='component-error-text'>
      Este campo é obrigatório
    </FormHelperText>
  )


  const handleAddStore = (data) => {
    const filteredCNPJ = data.replace(/[^a-zA-Z0-9]/g, "",)
    const findCNPJ = stores.some(s => s.cnpj === filteredCNPJ)
    if (!findCNPJ) {
      setStores([...stores, filteredCNPJ])
      setCNPJ(cnpjDefaultValues)
    } else {
      setCNPJ({
        ...cnpj,
        error: true,
        errorMessage: 'O CNPJ informado já esta vinculado a está integração'
      })
    }
  }

  return (
    <>
      <Card
        {...rest}
      >
        <form
          autoComplete='off'
          noValidate
          onSubmit={handleSubmit(onSubmit)}
        >
          <CardHeader
            title='SetaDigital'
          />
          <Divider />
          <CardContent>
            <Typography>
              Antes de iniciar a integração, certifique-se de que todos os vendedores estão cadastrados, assim como seus CPFs estão definidos e idênticos à SetaDigital. Caso haja qualquer diferença nos valores, o vendedor não será identificado e a venda não será considerada, prejudicando a validade do relatório.<br />

              Se acontecer de um vendedor não ter sido identificado e sua venda entrar em outros, corrija os dados do vendedor e aguarde a próxima sincronização.<br />

              Para realizar a integração, antes é necessário entrar em contato com o suporte da SetaDigital, solicitando as informações de acesso ao banco de dados para conectar à Proft. Eles irão fornecer os dados solicitados abaixo.< br />

              A sincronização acontece todo dia à 01:00 AM
              </Typography>
            <>
              <Divider style={{ marginTop: '1rem' }} />
              <Typography variant="h4" style={{ marginTop: '1rem' }}>
                Dados
            </Typography>
              <Grid container spacing={1}>
                <Grid item sm={3} xs={12}>
                  <TextField
                    label="Usuário"
                    fullWidth
                    variant='outlined'
                    margin="normal"
                    id="username"
                    name="username"
                    className={classes.textField}
                    onFocus={event => event.target.select()}
                    inputRef={register({ required: true })}
                    error={!!errors.username}
                  />
                  {renderError('username')}
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    label="Senha"
                    fullWidth
                    variant='outlined'
                    margin="normal"
                    id="password"
                    name="password"
                    className={classes.textField}
                    onFocus={event => event.target.select()}
                    inputRef={register({ required: true })}
                    error={!!errors.password}
                  />
                  {renderError('password')}
                </Grid>
                <Grid item sm={3} xs={12}>
                  <TextField
                    label="IP"
                    fullWidth
                    variant='outlined'
                    margin="normal"
                    id="serverIp"
                    name="serverIp"
                    className={classes.textField}
                    onFocus={event => event.target.select()}
                    inputRef={register({ required: true })}
                    error={!!errors.serverIp}
                  />
                  {renderError('serverIp')}

                </Grid>
              </Grid>
              <Divider style={{ marginTop: '1rem' }} />
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Avançado</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <List subheader={
                    <Grid container>
                      <Grid item xs={12}>
                        <Typography >
                          Você pode definir a regra de adicionar em outras vendas todas aquelas que não tiverem um vendedor vinculado e identificado pela proft.
                          Também é possível centralizar as outras vendas em um cpf específico informado abaixo.
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                          style={{ justifyContent: 'center', alignItems: 'center' }}
                          control={
                            <Switch
                              checked={useOtherSales}
                              onChange={event => {
                                setUseOtherSales(state => !state)
                              }}
                            />
                          }
                          label='Incluir outras vendas'
                        />
                        <TextField
                          label="CPF outras vendas"
                          fullWidth
                          variant='outlined'
                          margin="dense"
                          id="otherSales"
                          name="otherSales"
                          value={otherSalesCPF}
                          inputRef={register({ required: useOtherSales })}
                          onChange={(e) => setOtherSalesCPF(e.target.value)}
                          className={classes.textField}
                          onFocus={event => event.target.select()}
                          InputProps={{
                            inputComponent: cpfMask,
                          }}
                        // inputRef={register({ required: useOtherSales })}
                        // error={!!errors.otherSales}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="CNPJ"
                          margin="dense"
                          fullWidth
                          variant='outlined'
                          id="cnpj"
                          name="cnpj"
                          value={cnpj.data}
                          className={classes.textField}
                          onFocus={event => event.target.select()}
                          onChange={(e) => {
                            setCNPJ({
                              ...cnpj,
                              data: e.target.value
                            })
                          }}
                          InputProps={{
                            inputComponent: cnpjMask,
                          }}
                          // inputRef={register({ required: true })}
                          error={cnpj.error}
                        />
                        {/* {cnpj.error && ( */}
                        <FormHelperText error={cnpj.error} id='component-error-text'>
                          {cnpj.errorMessage}
                        </FormHelperText>
                        {/* )} */}
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          color='primary'
                          disabled={loading}
                          className={classes.button}
                          onClick={() => handleAddStore(cnpj.data)}
                        // type="submit"
                        >
                          Adicionar
                        </Button>
                      </Grid>
                    </Grid>
                  }>
                    {stores.map((storeCNPJ, index) => {
                      const labelId = `checkbox-list-secondary-label-${index}`;
                      return (
                        <ListItem key={index} button>
                          <ListItemIcon>
                            <IconButton onClick={() => {
                              if (stores.length <= 1) {
                                toast.error('Não foi possível remover o CNPJ, é necessário ter pelo menos um CNPJ cadastrado.')
                              } else {
                                confirm({
                                  title: 'Atenção',
                                  description: 'Antes de continuarmos, você confirma que deseja remover o cnpj?',
                                  confirmationText: 'Sim',
                                  cancellationText: 'Cancelar'
                                })
                                  .then(() => {
                                    const newStores = stores.filter(s => s !== storeCNPJ)
                                    setStores(newStores)
                                  })
                                  .catch(() => { })
                              }

                            }} color="default" aria-label="delete" component="span">
                              <DeleteIcon />
                            </IconButton>
                          </ListItemIcon>
                          <ListItemText id={labelId} primary={formatCNPJ(storeCNPJ)} />
                        </ListItem>
                      );
                    })}
                  </List>
                </AccordionDetails>
              </Accordion>
            </>
          </CardContent>
          <CardActions >
            <Button
              color='primary'
              variant='contained'
              disabled={loading}
              className={classes.button}
              type="submit"
            >
              {loading ? (
                (<><CircularProgress size={24} style={{ color: '#fff', marginRight: '8px' }} /> {' Conectando ao SetaDigital...'}</>)
              ) : (
                  'Salvar'
                )}
            </Button>
            {storeIntegrationData?.provider === provider && (
              <Button
                style={{ color: '#e74c3c' }}
                className={classes.button}
                disabled={loading}
                onClick={() => handleDisconnectIntegration()}
              >
                {loading ? (
                  (<><CircularProgress size={24} style={{ color: '#e74c3c', marginRight: '8px' }} /> {' Desconectando...'}</>)
                ) : (
                    'Desconectar'
                  )}
              </Button>
            )}
          </CardActions>
        </form>
      </Card >

    </>
  )
}

SetaDigital.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool,
}

export default SetaDigital
