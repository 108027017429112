import React from 'react';

import { Line } from './styles';

const OrLineDivisor = ({ children }) => {
  return (
    <Line>
      <div>
      { children ? children : <span>OU</span> }
      </div>
    </Line>
  );
}

export default OrLineDivisor;