import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

// Redux
import { useSelector, useDispatch } from 'react-redux'

// Material UI
import { makeStyles } from '@material-ui/styles'
import { Divider, colors, Grid } from '@material-ui/core'
import PieChartIcon from '@material-ui/icons/PieChart'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'
import { useConfirm } from 'material-ui-confirm'

// Components
import Header from 'components/Header'
import { formatPrice } from '../../utils/format'
import { monthGoalRequest } from '../../store/modules/goal/actions'
import {
  inputRequest,
  inputUpdateRequest,
  inputDeleteRequest
} from '../../store/modules/input/actions'
import Users from './components/Users'
import ValueCard from '../../components/ValueCard'

// Styles
const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  tabs: {
    marginTop: theme.spacing(3)
  },
  divider: {
    backgroundColor: colors.grey[300]
  },
  alert: {
    marginTop: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  }
}))

const Input = ({ match, history }) => {
  const { id, storeId } = match.params

  // Estado
  const [input, setInput] = useState()
  const [goal, setGoal] = useState()

  const inputList = useSelector(state => state.input.inputList)
  const inputMetadata = useSelector(state => state.input.metadata)
  const requestedInput = useSelector(state => state.input.input)
  const requestedGoal = useSelector(state => state.goal.goal)
  const currentGoal = useSelector(state => state.goal.currentGoal)
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()

  const token = window.localStorage.getItem('@Proft:token')

  // UseEffect para encontrar o input no redux
  // caso não ache faça uma requisição
  useEffect(() => {
    if (requestedInput && requestedInput._id === id) {
      setInput(requestedInput)
    } else {
      // Verifica se existe o inputList
      if (inputMetadata.pagination.totalCount > 0) {
        // Busca o input dentro do redux
        const inputExists = inputList.find(i => i._id === id)
        // Se não existir o input dentro do redux ele faz uma requisição
        if (inputExists) {
          setInput(inputExists)
        } else {
          dispatch(inputRequest(id, token))
        }
      } else {
        dispatch(inputRequest(id, token))
      }
    }
  }, [inputList, requestedInput])

  useEffect(() => {
    // Verifica se já tem o input, ele é necessário pra identificar o mês
    if (input) {
      // Mês desse input
      const inputMonth = moment(input.date)
      inputMonth.utcOffset(0).set({
        date: 1,
        hour: 15,
        minute: 0,
        second: 0,
        millisecond: 0
      })
      // Se já tem um mês requisitado no redux, vê se ele corresponde ao mês do input
      if (
        requestedGoal &&
        requestedGoal.month &&
        inputMonth.toISOString() === moment(requestedGoal.month).toISOString()
      ) {
        setGoal(requestedGoal.days.find(d => d.date === input.date))
      } else {
        // Se não, vê se o mês atual é o do input
        if (
          currentGoal &&
          currentGoal.month &&
          inputMonth.toISOString() === moment(currentGoal.month).toISOString()
        ) {
          setGoal(currentGoal.days.find(d => d.date === input.date))
        } else {
          // Se não, faz requisição do mês da input, que vai ser detectada no primeiro if
          dispatch(monthGoalRequest(storeId, token, inputMonth))
        }
      }
    }
  }, [input, requestedGoal])

  const handleDeleteInput = () => {
    confirm({
      title: `Lançamento de ${input ? moment(input.date).format('DD/MMMM - dddd') : '...'
        }`,
      description: 'Você tem certeza que deseja deletar o lançamento?',
      confirmationButtonProps: {
        "data-cy": "btn-delete-confirm"
      },
      confirmationText: 'Sim',
      cancellationText: 'Não'
    })
      .then(() => {
        history.push(`/${storeId}/inputs`)
        dispatch(inputDeleteRequest(input._id, token))
      })
      .catch(() => { })
  }

  return (
    <div className={classes.root}>
      <Header
        data={input}
        route={`/${storeId}/inputs`}
        actionText="APAGAR LANÇAMENTO"
        title='Lançamentos'
        subtitle={`Lançamento de ${input ? moment(input.date).format('DD/MMMM - dddd') : '...'
          }`}
        handleDelete={handleDeleteInput}
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='percentual da meta'
            value={goal ? (goal?.goal > 0 ? ((input.total.sold / goal.goal) * 100).toFixed(1) + '%' : 'Sem meta') : '...'}
            icon={<PieChartIcon />}
            color='#FFC700'
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='faturamento'
            value={`${input ? formatPrice(input.total.sold) : '0'}`}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='quantidade de vendas'
            value={input && input.total.sales.toFixed()}
            icon={<LocalMallIcon />}
          />
        </Grid>
        <Grid item lg={3} sm={6} xs={12}>
          <ValueCard
            title='peças vendidas'
            value={input && input.total.items.toFixed()}
            icon={<ShoppingBasketIcon />}
          />
        </Grid>
      </Grid>
      <Divider className={classes.divider} />
      <div className={classes.content}>
        <Users
          input={input}
          onUpdateSeller={(data, store) => dispatch(inputUpdateRequest(storeId, input._id, data, token, store))}
        />
      </div>
    </div>
  )
}

Input.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
}

export default Input
