// Material UI
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 350,
    padding: theme.spacing(2)
  },
  title: {
    fontSize: '14px',
    color: '#263238',
  },
  subTitle: {
    fontSize: '16px',
    color: '#263238',
    fontWeight: 'bold'
  },
  permissionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2)
  },
  label: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(1.5),
    fontWeight: 'bold',
    fontSize: '14px',
    transition: '0.2s ease-in'
  },
  noPermission: {
    textDecoration: 'line-through',
    color: '#d5d5d5',
    fontWeight: 500
  },
  permissionIcon: {
    marginRight: theme.spacing(1),
  }
}));

const UserPermissions = ({ type }) => {
  const classes = useStyles();

  // const types = {
  //   seller: [
  //     true,
  //     true,
  //     true,
  //     true,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //   ],
  //   sellerVr: [
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //     false,
  //   ],
  //   manager: [
  //     true,
  //     true,
  //     false,
  //     false,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     false,
  //   ],
  //   super: [
  //     true,
  //     true,
  //     false,
  //     false,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //     true,
  //   ]
  // }

  const permissions = [
    { label: 'Acompanhar metas pelo aplicativo', hasPermission: { seller: true, sellerVr: true, manager: true, super: true}[type] },
    { label: 'Visualizar ranking de vendas', hasPermission: { seller: true, sellerVr: true, manager: true, super: true}[type] },
    { label: 'Participar da Lista da Vez', hasPermission: { seller: true, sellerVr: true, manager: false, super: false}[type] },
    { label: 'Participar das metas', hasPermission: { seller: true, sellerVr: true, manager: false, super: false}[type] },
    { label: 'Criação e edição dos lançamentos da loja', hasPermission: { seller: false, sellerVr: true, manager: true, super: true}[type] },
    { label: 'Gerenciar usuários Vendedores e Caixas', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Gerenciar metas da loja', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Monitorar atendimentos e conversões', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Acesso aos relatórios gerenciais', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Alterar configurações da loja', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Consultar desempenho de vendedores', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Gestão de múltiplas lojas', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Resultados acumulados de múltiplas lojas', hasPermission: { seller: false, sellerVr: false, manager: true, super: true}[type] },
    { label: 'Gerenciar usuários Gerentes', hasPermission: { seller: false, sellerVr: false, manager: false, super: true}[type] },
  ]

  return (
    <Card className={classes.root}>
      <div>
        <Typography className={classes.title}>
          Permissões da função
        </Typography>
        <Typography variant="h3" className={classes.subTitle}>
          {{
            seller: 'Vendedor',
            sellerVr: 'Vendedor VR',
            manager: 'Gerente',
            super: 'Supervisor'
          }[type]}
        </Typography>
      </div>
      <div className={classes.permissionsContainer}>
        {permissions.map(permission => (
          <Typography className={[classes.label, !permission.hasPermission && classes.noPermission]}>
            <CheckCircleIcon style={{color: permission.hasPermission ? '#45d178' : '#e5e5e5'}} fontSize="small" className={classes.permissionIcon}/>
              {permission.label}
          </Typography>
        ))}
      </div>
    </Card>
  )
}

export default UserPermissions