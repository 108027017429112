import {
  Button, Card,
  CardActions, CardContent, CardHeader, CircularProgress,
  Divider,
  Grid, Typography,
  List, ListItem, TextField, FormHelperText,
  ListItemText, Badge
} from '@material-ui/core';
// Material UI
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useConfirm } from 'material-ui-confirm'

// Redux
import { useDispatch } from 'react-redux';
// Libs
import { useParams } from 'react-router-dom';
import api from '../../../../../../repositories'
import { integrationDisconnectRequest, integrationRequest } from '../../../../../../store/modules/integration/actions';
// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },

  margin: {
    marginBottom: theme.spacing(1),
  },
  button: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  selectedBadge: {
    backgroundColor: "#FE6B8B",
    color: "white"
  }
}))


const QuantoSobra = ({ className, storeIntegrationData, store, loading, ...rest }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const provider = 'quantosobra'

  const DEFAULT_PROVIDER = {
    username: '',
    password: '',
    storeId: ''
  }

  const [providerData, setProviderData] = useState(DEFAULT_PROVIDER)
  const [storeLoading, setStoreLoading] = useState(false)
  const [error, setError] = useState(false)

  const [storeList, setStoreList] = useState([])

  // LocalStorage
  const token = localStorage.getItem('@Proft:token')
  const { storeId } = useParams()

  const handleDisconnectIntegration = () => {
    confirm({
      title: 'Atenção',
      description: 'Você tem certeza que deseja remover a integração com a QuantoSobra?',
      confirmationText: 'Sim',
      cancellationText: 'Cancelar'
    })
      .then(() => {
        dispatch(integrationDisconnectRequest(provider, storeId, token))
        setStoreList([])
        setProviderData(DEFAULT_PROVIDER)
      })
      .catch(() => { })
  }

  const handleSubmit = () => {
    setError(false)
    if(!providerData.username || !providerData.password){
      return setError('Todos os campos devem estar preenchidos.')
    }
    if (!providerData.storeId) {
      setStoreLoading(true)
      api.axios.put(`/integration/quantosobra/store/${storeId}`, providerData)
        .then(({ data }) => {
          setStoreList(data.storeList)
          setStoreLoading(false)
        }).catch(err => {
          setError('Erro ao conectar com a QuantoSobra.')
          console.log(err)
          setStoreLoading(false)
        })
    } else {
      confirm({
        title: 'Atenção',
        description: 'Antes de continuarmos, você confirma que os vendedores estão com o mesmo CPF na Proft e na QuantoSobra?',
        confirmationText: 'Sim',
        cancellationText: 'Cancelar'
      })
        .then(() => {
          dispatch(integrationRequest({ ...providerData, provider }, provider, storeId, token))
        })
        .catch(() => { })
    }
  }

  useEffect(() => {
    if (storeIntegrationData?.username && storeIntegrationData?.provider === provider) setProviderData({
      username: storeIntegrationData.username || '',
      password: storeIntegrationData.password || '',
      storeId: storeIntegrationData.storeId || ''
    })
  }, [storeIntegrationData])

  return (
    <>
      <Card
        {...rest}
      >
        <CardHeader
          title='QuantoSobra'
        />
        <Divider />
        <CardContent>
          <Typography>
            Antes de iniciar a integração, certifique-se de que todos os vendedores estão cadastrados, assim como seus CPFs estão definidos e idênticos à QuantoSobra. Caso haja qualquer diferença nos valores, o vendedor não será identificado e a venda entrará em "Outras vendas", prejudicando a validade do relatório.<br />

            Se acontecer de um vendedor não ter sido identificado e sua venda entrar em "Outras vendas", corrija os dados do vendedor e aguarde a próxima sincronização.<br />
          </Typography>
          <Divider style={{ marginTop: '1rem' }} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Login"
                fullWidth
                variant='outlined'
                margin="normal"
                id="username"
                name="username"
                className={classes.textField}
                onFocus={event => event.target.select()}
                onChange={e => setProviderData({ ...providerData, username: e.target.value })}
                value={providerData.username}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField
                label="Senha"
                fullWidth
                variant='outlined'
                margin="normal"
                id="password"
                name="password"
                type="password"
                className={classes.textField}
                onFocus={event => event.target.select()}
                onChange={e => setProviderData({ ...providerData, password: e.target.value })}
                value={providerData.password}
              />
            </Grid>
            <Grid item sm={12} xs={12}>
              {error && <FormHelperText style={{color: 'red'}}>
                {error}
              </FormHelperText>}
              <List>
                {storeList.length > 0 &&
                  <>
                    <Typography>Selecione uma loja abaixo</Typography>
                    {storeList.map(store => (
                      <div key={store[1]} >
                        <ListItem className={classes.itemContainer} selected={store[1] == providerData.storeId} key={store[1]} button onClick={() => setProviderData({ ...providerData, storeId: store[1] })}>
                          <ListItemText primary={`${store[0]} (#${store[1]})`} />
                          {store[1] == providerData.storeId && <Badge
                            classes={{ badge: classes.selectedBadge }}
                            style={{ left: -35 }}
                            badgeContent="Selecionado"
                          />}
                        </ListItem>
                        <Divider />
                      </div>
                    ))}
                  </>}
              </List>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions >
          {storeIntegrationData?.provider !== provider && (
            <Button
              color='primary'
              variant='contained'
              disabled={loading || storeLoading}
              onClick={handleSubmit}
              className={classes.button}
              type="submit"
            >
              {loading || storeLoading ? (
                (<><CircularProgress size={24} style={{ color: '#fff', marginRight: '8px' }} /> {' Conectando à QuantoSobra...'}</>)
              ) : providerData.storeId ?
                'Salvar' : 'Conectar'
              }
            </Button>
          )}
          {storeIntegrationData?.provider === provider && (
            <Button
              style={{ color: '#e74c3c' }}
              className={classes.button}
              disabled={loading || storeLoading}
              onClick={handleDisconnectIntegration}
            >
              {loading ? (
                (<><CircularProgress size={24} style={{ color: '#e74c3c', marginRight: '8px' }} /> {' Desconectando...'}</>)
              ) : (
                'Desconectar'
              )}
            </Button>
          )}
        </CardActions>

      </Card >

    </>
  )
}

QuantoSobra.propTypes = {
  className: PropTypes.string,
  storeIntegrationData: PropTypes.object,
  loading: PropTypes.bool,
}

export default QuantoSobra
