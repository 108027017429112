import React from 'react';
import PropTypes from 'prop-types'

// Material UI
import Radio from '@material-ui/core/Radio';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';

// import { Container } from './styles';

function MenuItemDropdown({ handleSelectStore, store, selectedStore }) {
  return (
    <MenuItem selected={false} onClick={() => handleSelectStore(store.storeId._id)}>
        <Radio
          checked={selectedStore == store.storeId._id}
          // onChange={handleChange}
          value="a"
          name="radio-button-demo"
          inputProps={{ 'aria-label': 'A' }}
          style={{ marginRight: '-15px', marginLeft: '-15px' }}
          color="primary"
        />
        <ListItem>
          <ListItemAvatar>
          <Avatar src={store.storeId.photo?.url} />
          </ListItemAvatar>
        <ListItemText 
          primary={store ? store.storeId.name : 'Não encontrado'} 
          secondary={store ? store.storeId.city : 'Ops'}
          primaryTypographyProps={{
            style: {
              fontWeight: '500',
              whiteSpace: "normal",
              textAlign: "flex-start"
            }
          }} 
        />
      </ListItem>
    </MenuItem>
  )
}

MenuItemDropdown.propTypes = {
  handleSelectStore: PropTypes.func,
  store: PropTypes.object,
  selectedStore: PropTypes.string,
}

export default MenuItemDropdown;