import React, { useEffect, useState } from 'react'

// Libs
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/styles'

// Compoments
import ReportsTable from './components/ReportsTable'

// Redux
import { monthRequest, reportRequest } from '../../store/modules/report/actions'
import { inputListRequest } from 'store/modules/input/actions'
import { CSVLink } from 'react-csv'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { Checkbox, Modal } from '@mui/material'
import { CheckboxItem, SheetButton, SheetModal } from './components/styles'
import { CloseTwoTone, CloudDownloadTwoTone, TableChartTwoTone } from '@material-ui/icons'
import { formatPrice } from 'utils/format'
import moment from 'moment'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

export default function Reports({ match }) {
  const dispatch = useDispatch()
  const monthReport = useSelector(state => state.report.monthReport)
  const currentGoal = useSelector(state => state.goal.currentGoal)
  const store = useSelector(state => state.store.store)
  const reportsList = useSelector(state => state.report.reportsList)
  const inputMetadata = useSelector(state => state.input.metadata)
  const token = window.localStorage.getItem('@Proft:token')
  const { storeId } = match.params

  const [reports, setReports] = useState()
  const [reportRange, setReportRange] = useState({
    from: moment().startOf('month').set({hours: 12}).toISOString(),
    to: null
  })

  const [sheetModalVisible, setSheetModalVisible] = useState(false)
  const [sheetColumnItems, setSheetColumnItems] = useState([0, 1 , 2])
  const [sheetColumnValue, setSheetColumnValue] = useState([])

  // Ao inicializar, se não tiver exibindo nenhum report, solicita os do mês
  useEffect(() => {
    if (inputMetadata.pagination.totalCount > 0) {
      if (!monthReport && !reportsList && !reports) {
        dispatch(monthRequest(storeId, token))
      }
    } else {
      if (inputMetadata.pagination.totalCount < 0) {
        dispatch(inputListRequest(storeId, token))
      }
    }
  }, [])


  // Se chegar os reports do mês, e não tiver nenhum intervalo pré selecionado, atualiza
  useEffect(() => {
    // apresenta reports do intervalo selecionado
    if (reportsList) setReports(reportsList)
    else setReports(monthReport)
  }, [monthReport, reportsList])

  useEffect(() => {
    if (inputMetadata.pagination.totalCount > 0 && !monthReport && !reportsList && !reports) {
      dispatch(monthRequest(storeId, token))
    }
  }, [inputMetadata])

  
  const tableHeaders = [
    'Nome',
    'Meta',	
    'Faturamento',
    'Comissão Atual', 
    'Meta Atual',
    '% da meta',
    'Projeção de Venda',
    'Projeção de Comissão',
    'N˚ Vendas',	
    'Ticket Médio',
    'Peça/Venda',
    'Preço Médio',
    'N˚ Peças',
  ]

  useEffect(() => {
    if (!!reports) {
      setReportRange(state => ({...state, to: reports.store.metric.lastInputDate}))
    }
  }, [reports])

  useEffect(() => { // useeff que monitora quais dados os usuario selecionou para filtrar e formar o data pra planilha
    if (!!reports && sheetColumnItems.length > 0) {
      const storeOptions = [
        "Loja",
        formatPrice(reports.store.metric.acDayGoal),
        formatPrice(reports.store.total.sold),
        '-',
        '-',
        (reports.store.total.sold / reports.store.metric.acDayGoal * 100).toFixed(2),
        formatPrice(reports.store.projection.sold),
        '-',
        reports.store.total.sales,
        formatPrice(reports.store.total.sold / reports.store.total.sales),
        (reports.store.total.items / reports.store.total.sales).toFixed(2),
        formatPrice(reports.store.total.sold / reports.store.total.items),
        reports.store.total.items
      ]
      const storeItems = sheetColumnItems.map(itemIndex => storeOptions[itemIndex])
      
      setSheetColumnValue([storeItems])
      reports.sellers.map(seller => {
        const options = [
          seller.name.complete,
          formatPrice(seller.metric.acDayGoal),
          formatPrice(seller.total.sold),
          formatPrice(seller.commission),
          seller.projection.goal.name,
          (seller.total.sold / seller.metric.acDayGoal * 100).toFixed(2),
          formatPrice(seller.projection.sold),
          formatPrice(seller.projection.commission),
          seller.total.sales,
          formatPrice(seller.total.sold / seller.total.sales),
          (seller.total.items / seller.total.sales).toFixed(2),
          formatPrice(seller.total.sold / seller.total.items),
          seller.total.items
        ]
        const items = sheetColumnItems.map(itemIndex => options[itemIndex])

        setSheetColumnValue(state => [...state, items])
      })
    }
  }, [reports, sheetColumnItems])

  const classes = useStyles()
  return (
    <div className={classes.root}>
      {reports &&
        <SpreadSheetModal
          open={sheetModalVisible}
          store={store}
          reports={reports}
          headers={tableHeaders}
          sheetColumnItems={sheetColumnItems}
          setSheetColumnItems={setSheetColumnItems}
          setSheetModalVisible={setSheetModalVisible}
          columnsToExport={sheetColumnValue}
          reportRange={reportRange}
        />
      }
      <div className={classes.content}>
        <SheetButton onClick={() => setSheetModalVisible(true)}>
          <TableChartTwoTone style={{marginRight: 5}} />
           Exportar relatório para planilhas
        </SheetButton>
        <ReportsTable 
          reports={reports} 
          tableHeaders={tableHeaders}
          currentGoal={currentGoal?.monthGoals}
          requestReports={range => {
            setReportRange(range)
            dispatch(reportRequest(storeId, token, range))
          }} 
        />
      </div>
    </div>
  )
}

const SpreadSheetModal = ({open, reportRange, reports, headers, store, setSheetModalVisible, sheetColumnItems, setSheetColumnItems, columnsToExport}) => (
  <SheetModal 
    open={open}
    onClose={() => setSheetModalVisible(false)}
  >
    <div className='content'>
      <h1>Selecione quais itens você deseja visualizar na planilha</h1>
      <span>Os itens que você habilitar abaixo serão os itens exibidos na sua planilha</span>

      <div className='close-button' onClick={() => setSheetModalVisible(false)}>
        <CloseTwoTone style={{color: 'lightgrey'}} />
      </div>

      <div className='checkbox-container'>
        {headers.map((header, i) => {
          const required = ['Nome', 'Meta', 'Faturamento'].find(el => el === header)
          return (
            <CheckboxItem
              key={i} 
              checked={!!required || !!sheetColumnItems.includes(i)}
              disabled={!!required} 
              onClick={() => {
                if (sheetColumnItems.includes(i)) setSheetColumnItems(sheetColumnItems.filter((el) => el !== i))
                else setSheetColumnItems([...sheetColumnItems, i].sort((a, b) => a - b))
              }}
            >
              <Checkbox 
                color='success'
                checked={!!required || !!sheetColumnItems.includes(i)}
                disabled={!!required} 
              >
              </Checkbox>
              <span>{header}</span>
            </CheckboxItem>
          ) 
        })}
      </div>

        
      <CSVLink 
        filename={
          `[PROFT_${format(new Date(reports.store.metric.today), 'dd-MM-yyyy', {locale: ptBR})}]Relatorio-${store?.name}-${format(new Date(reportRange.from), 'dd-MM-yyyy', {locale: ptBR})}-a-${format(new Date(reportRange.to), 'dd-MM-yyyy', {locale: ptBR})}`
        } 
        data={columnsToExport}
        headers={headers.filter((_, i) => sheetColumnItems.includes(i))}
        
      >
        <SheetButton>
          <CloudDownloadTwoTone style={{marginRight: 5}} />
          Fazer download da planilha
        </SheetButton>
      </CSVLink>
    </div>
  </SheetModal>
)
