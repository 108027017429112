import React, { useEffect, useState } from 'react';

// Material UI
import AddIcon from '@material-ui/icons/Add';

// Styles
import { Container, Header, Content, Table } from './styles';

// Components
import TableItem from './components/TableItem';
import Modal from './components/Modal';
import { CircularProgress } from '@material-ui/core';


const GoalsTable = ({ data, updateGoal, usersWorking, monthGoals, setMonthGoals}) => { 
  const [isCreatingNewGoal, setIsCreatingNewGoal] = useState(false);
  const [isEditingGoal, setIsEditingGoal] = useState(false);
  const [indexToEdit, setIndexToEdit] = useState();

  const handleSetIsCreatingGoal = (value) => {
    setIsCreatingNewGoal(value)
  }

  const handleSetIsEditingGoal = (value) => {
    setIsEditingGoal(value)
  }
  
  const handleCreateNewGoal = (goal) => {
    const newMonthGoal = [...monthGoals, goal]
    setMonthGoals(newMonthGoal)

    updateGoal({monthGoals: newMonthGoal})
  }

  const handleEditGoal = (id, goal) => {
    const newMonthGoal = [...monthGoals]
    newMonthGoal[id] = goal

    setMonthGoals(newMonthGoal)
    updateGoal({monthGoals: newMonthGoal})
  }

  const handleDelete = (index) => {
    const newMonthGoal = [...monthGoals].filter((monthGoal, i) => (i !== index))
    setMonthGoals(newMonthGoal)

    updateGoal({monthGoals: newMonthGoal})
  }

  useEffect(() => {
    if (data.monthGoals.length > 0) setMonthGoals(data.monthGoals)
  }, [data.monthGoals])

  return (
    <>
      <Container>
        <Header>
          <div className="table-header-info">
            <h1>Metas individuais</h1>
            <p>Defina a meta mensal para cada vendedor</p>
          </div>
          <div className="table-header-action">
            <button 
              className='table-header-add-goal-button' 
              onClick={() => handleSetIsCreatingGoal(true)}
            >
              Adicionar Meta <AddIcon fontSize='small' />
            </button>
          </div>
        </Header>
        <Modal
          data={data}
          isCreatingNewGoal={isCreatingNewGoal} 
          isEditingGoal={isEditingGoal}
          handleSetIsCreatingGoal={handleSetIsCreatingGoal} 
          handleSetIsEditingGoal={handleSetIsEditingGoal}
          createNewGoal={handleCreateNewGoal}
          editGoal={(goal) => handleEditGoal(indexToEdit, goal)}
          currentGoal={isEditingGoal && monthGoals[indexToEdit]}
          monthGoals={monthGoals}
          usersWorking={usersWorking}
        /> 
        <Content>
          <Table>
          { (data.monthGoals.length > 0 || monthGoals.length > 0) ?
            <>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Nome</th>
                  <th>Valor</th>
                  <th>Comissão</th>
                  <th>Bonificação</th>
                  <th>Total</th>
                  <th />
                </tr>
              </thead>
              <tbody className='table-row-container'>
              { (data.monthGoals.length > 0 ? data.monthGoals : monthGoals).map((goal, i) => (
                <TableItem
                  data={data}
                  key={i} 
                  index={i}
                  goal={goal}
                  onDelete={handleDelete}
                  onEdit={() => {
                    handleSetIsEditingGoal(true);
                    setIndexToEdit(i)
                  }}
                />
              ))}
              </tbody>
            </> 
            : <thead>
                <tr className='seller-without-goal-message'>
                  <th>Adicione pelo menos uma meta para o vendedor.</th>
                </tr>
              </thead>
            }
          </Table>
        </Content>
      </Container>
    </>
  )
}

export default GoalsTable;