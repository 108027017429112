import React from 'react';

import { Container } from './styles';

const Form = ({title, description, contentChildren, buttonChildren, ...rest}) => {
  return (
    <Container {...rest}>
      <div className="title-container">
        {title && <h1>{title}</h1>}
        {description && <span>{description}</span>}
      </div>
      {contentChildren}
      {buttonChildren}
    </Container>
  )
}

export default Form;