import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import CheckIcon from '@material-ui/icons/CheckCircle'
import NotInterestedIcon from '@material-ui/icons/NotInterested'
import EditUserGoalModal from '../EditUserGoalModal'
import AddUserGoalModal from '../AddUserGoalModal'

import EditIcon from '@material-ui/icons/Edit'

import { goalLinkUser } from 'store/modules/goal/actions'
import { useDispatch } from 'react-redux'
import { Button } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650
  },
  button: {
    marginBottom: theme.spacing(2),
  }
}))

const Sellers = ({ goal, users, newUser, setNewUser }) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const [userEdit, setUserEdit] = useState()
  const [modal, setModal] = useState(false)
  const token = window.localStorage.getItem('@Proft:token')

  // Quando o goal for atualizado, fechar modal
  useEffect(() => setUserEdit(), [goal])

  useEffect(() => {
    if (newUser) {
      setUserEdit(newUser)
    }
  }, [newUser])

  return (
    <>
      <Button variant='outlined' className={classes.button} onClick={() => setModal(true)}>Adicionar vendedor</Button>
      {goal?.distribute && <Typography><b>Meta distribuída</b></Typography>}
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: '2rem' }} />
              <TableCell>Nome</TableCell>
              <TableCell>Irá todos os dias</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {goal && goal.usersWorking.map((row) => {
              // if (!row.active) return
              const daysNotGoing = goal.days.filter(day => !day.users.includes(String(row.userId?._id)) && day.working)
              return (
                <TableRow
                  key={row._id}
                  hover
                  style={{ cursor: 'pointer' }}
                  onClick={() => setUserEdit(row.userId)}
                >
                  <TableCell align='center'><EditIcon style={{ color: '#63727A' }} /></TableCell>
                  <TableCell>{row.userId?.name.complete}</TableCell>
                  <TableCell>{daysNotGoing.length === 0 ? <CheckIcon style={{ color: '#2ecc71' }} size='small' /> : (<Typography><NotInterestedIcon size='small' /> Não irá em {daysNotGoing.length} dia{daysNotGoing.length > 1 && 's'}.</Typography>)}</TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <EditUserGoalModal
        modal={!!userEdit}
        currentMonthGoal={goal}
        handleLinkUserGoal={(user, goalId) => {
          dispatch(goalLinkUser(user, goalId, token))
          setUserEdit()
          setNewUser()
        }}
        user={userEdit}
        onClose={() => setUserEdit()}
      />
      <AddUserGoalModal
        modal={modal}
        handleAddUser={setUserEdit}
        users={users}
        onClose={() => setModal(false)}
      />
    </>
  )
}

export default Sellers
