import React, { useState, useEffect } from 'react'

import Grid from '@material-ui/core/Grid'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { useConfirm } from 'material-ui-confirm'

import DayPicker, { DateUtils } from 'react-day-picker'
import dayPickerBR from '../../../../common/DayPicker-pt-BR'

import { makeStyles } from '@material-ui/styles'
import clsx from 'clsx'

import { formatPrice } from '../../../../utils/format'
import { Checkbox, Input, TextField } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
  margin: { margin: theme.spacing(1) },
  buttonSuccess: {
    backgroundColor: 'white',
    '&:hover': {
      backgroundColor: 'white'
    }
  },
  divider: {
    borderLeft: '2px solid #CCC',
    padding: theme.spacing(1, 2)
  },
  column: {
    flexBasis: '50%',
    marginTop: '80px'
  },
  sellerTopCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    fontFamily: 'Inter',
    marginLeft: 'auto',
    marginBottom: -70
  }
}))

export default function EditUserGoalModal({
  modal,
  currentMonthGoal,
  handleLinkUserGoal,
  user,
  onClose,
  ...rest
}) {
  const classes = useStyles()
  const confirm = useConfirm()
  const [days, setDays] = useState([])

  const [seller, setSeller] = useState({
    dsr: true,
    goalWeight: 100
  })

  useEffect(() => {
    if (currentMonthGoal && user) {
      const workingDays = currentMonthGoal.days
        .filter(day => day.working && day.users.map(user => String(user)).includes(user._id))
        .map(day => new Date(day.date))
      setDays(workingDays)
      
      const selectedUser = currentMonthGoal.usersWorking.find(userW => userW.userId._id === user._id)
      if (selectedUser) setSeller({dsr: selectedUser.dsr, goalWeight: selectedUser.goalWeight * 100})
    }
  }, [currentMonthGoal, user])
  
  return (
    <div {...rest} className={classes.root}>
      <Dialog
        open={modal}
        onClose={onClose}
      >
        <DialogTitle id='form-dialog-title'>Editar dias</DialogTitle>
        <DialogContent>
          { (user && currentMonthGoal) ? (
            <>
              <div className={classes.sellerTopCard}>
                <div>
                  <Checkbox
                    color="primary"
                    checked={seller.dsr}
                    onChange={(e, value) => {
                      setSeller({...seller, dsr: value})
                    }}
                  />
                  <span style={{fontSize: 13}}>Irá receber DSR?</span>
                </div>
                <div style={{position: 'relative'}}>
                  <TextField
                    label="Peso do vendedor"
                    focused
                    style={{width: 120}}
                    value={seller.goalWeight || ''}
                    onChange={(e, value) => setSeller({...seller, goalWeight: e.target.value > 300 ? 300 : e.target.value.replace('.', ',')})}
                  />
                  <label style={{position: 'absolute', right: 0, top: '50%', marginRight: 10, fontFamily: 'Inter'}}>%</label>
                </div>
              </div>
            <Grid container spacing={1} className={classes.margin}>
              <Grid item xs>
                <DayPicker
                  month={new Date(currentMonthGoal.month)}
                  canChangeMonth={false}
                  months={dayPickerBR.months}
                  weekdaysLong={dayPickerBR.weekdaysLong}
                  weekdaysShort={dayPickerBR.weekdaysShort}
                  firstDayOfWeek={0}
                  labels={dayPickerBR.labels}
                  modifiers={{
                    working: day =>
                      currentMonthGoal.days.filter(d => d.working)
                        .map(dayM => dayM.date)
                        .includes(day.toISOString())
                  }}
                  selectedDays={days}
                  modifiersStyles={{
                    working: {
                      color: '#000',
                      fontWeight: 'bold',
                      fontFamily: 'Inter'
                    }
                  }}
                  onDayClick={(day, { selected }) => {
                    if (
                      currentMonthGoal.days.filter(d => d.working)
                        .map(dayM => dayM.date)
                        .includes(day.toISOString())
                    ) {
                      if (selected) {
                        // deselecionar
                        const selectedIndex = days.findIndex(selectedDay =>
                          DateUtils.isSameDay(selectedDay, day)
                        )
                        days.splice(selectedIndex, 1)
                        setDays([...days])
                      } else {
                        // selecionar
                        // days.push(day.toISOString())
                        setDays([...days, day])
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item xs>
                <div className={clsx(classes.column, classes.divider)}>
                  <Typography>
                    Selecione os dias em que <b>{user ? user.name.first : '...'}</b> irá trabalhar.
                  </Typography>
                  <Typography>
                    <b>Atenção: </b>A meta será proporcional aos dias trabalhados.
                  </Typography>
                  {currentMonthGoal && days && currentMonthGoal.monthGoals.map(g => {
                    if(user){
                      const currUser = currentMonthGoal.usersWorking.find(userDb => String(userDb.userId._id) === String(user._id))
                      const weight = (currUser?.goalWeight ? currUser?.goalWeight : 1)
                      const ISOList = days.map(day => day.toISOString())
                      const goingCoef = currentMonthGoal.days.filter(day => ISOList.includes(day.date)).reduce((acc, day) => {
                        acc += day.goal
                        return acc
                      }, 0) / currentMonthGoal.mainGoals.total

                      const acDayGoal = currentMonthGoal.days.filter(day => ISOList.includes(day.date)).reduce((acc, day) => {
                        acc += day.goal / day.users.length
                        return acc
                      }, 0)

                      const sellerMainGoal = [...currentMonthGoal.monthGoals][0].value

                      /*
                        Regra de 3 da distribuição:
                        acDayGoal => Meta principal do vendedor
                        defaultValue => Próximas metas (2a, 3a, 4a... meta)
                        
                        Sendo assim:
                        acDayGoal * metaAtual / metaPrincipalDoVendedor = proximasMetas
                      */

                      const defaultValue = (currentMonthGoal?.distribute ? (acDayGoal * g.value) / sellerMainGoal : g.value * goingCoef)
                      const goalValue = defaultValue * weight

                      let commission = 0
                      const preCom = (goalValue * g.commission)
                      const bonus = g?.bonusPercentage ? goalValue * g.bonus : g.bonus
                      const dsr = currentMonthGoal?.dsr > 0 && currUser?.dsr ? ((preCom / (currentMonthGoal?.days.length - currentMonthGoal?.dsr)) * currentMonthGoal?.dsr) : 0
                      const base = currentMonthGoal?.salary.directCommission ? -(currUser?.salary ? currUser?.salary : currentMonthGoal?.salary.base) : 0
                      const preComplement = (currUser?.salary ? currUser?.salary : currentMonthGoal?.salary.base) - preCom - dsr
                      const complement = currentMonthGoal?.salary.directCommission && currentMonthGoal?.salary.complement && preComplement >= 0 ? preComplement : 0
                      commission = preCom + dsr + bonus + base + complement
                      return (
                        <div key={g.name}>
                          <Typography variant='h5'>
                            {g.name}
                          </Typography>
                          <Typography>
                            {formatPrice(goalValue)} <b>{formatPrice(commission <= 0 ? 0 : commission)}</b>
                          </Typography>
                        </div>
                      )
                    }
                  })}
                </div>
              </Grid>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Button
                    onClick={() => {
                      confirm({
                        title: 'Remover vendedor da meta',
                        description: `Você tem certeza que deseja remover ${user.name.first} da meta?`,
                        confirmationText: 'Sim',
                        cancellationText: 'Não'
                      })
                        .then(() => {
                          handleLinkUserGoal({ days: [], userId: user._id, dsr: seller.dsr, goalWeight: seller.goalWeight }, currentMonthGoal._id)
                        })
                        .catch(() => { })
                    }}
                    style={{ color: '#e74c3c' }}
                  >
                    Remover da meta
                  </Button>
                  <Button
                    onClick={() => setDays(currentMonthGoal.days
                      .filter(day => day.working)
                      .map(day => new Date(day.date)))}
                  >
                    Todos os dias
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            </>
          ) : (
              <DialogContentText>
                Carregando...
              </DialogContentText>
            )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancelar
          </Button>
          <Button
            onClick={() => {
              const submit = () => handleLinkUserGoal(
                { days: days.map(d => d.toISOString()), userId: user._id, dsr: seller.dsr , goalWeight: parseFloat(seller.goalWeight) / 100 },
                currentMonthGoal._id
              )

              if (days.length < currentMonthGoal.days.filter(d => d.working).length) {
                confirm({
                  title: 'Salvar',
                  description: `Atenção: A meta de ${user.name.first} será proporcional aos dias trabalhados.`,
                  confirmationText: 'Ok',
                  cancellationText: 'Cancelar'
                })
                  .then(() => submit())
                  .catch(() => { })
              } else {
                submit()
              }
            }}
            type='submit'
            color='primary'
          >
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
