import { Modal } from '@material-ui/core';
import styled, { css } from 'styled-components';

export const SheetButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #188038;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  color: white;
  margin-left: auto;
  margin-bottom: 12px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font-family: 'Roboto';
  font-weight: bold;

  &:hover {
    background: #126d2d;
  }
`;

export const SheetModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Roboto';
  
  
  div.content {
    background: white;
    border-radius: 12px;
    padding: 30px;
    width: 50vw;
    max-width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    outline: none !important;
    position: relative;
  }

  h1 {
    font-size: 24px;
    max-width: 80%;
    text-align: center;
    margin-bottom: 20px;
  }
  
  div.close-button {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
    z-index: 66;
    cursor: pointer;
  }

  div.checkbox-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 80%;
    margin: 30px auto;
    }
  }
`

export const CheckboxItem = styled.div`
  margin: 0 10px;
  cursor: pointer;
  
   span {
    transition: 0.3s ease-in-out;
   }

  ${({ checked }) => checked && css`
    color: #188038;
  `}

  ${({ disabled }) => disabled ? css`
    color: #c0c0c0;
    cursor: default;
  `
  : css`
    &:hover {
      span {
        color: #188038;
      }
    }
  `
  }
`

