import React from 'react';

const CheckPerson = () => {
  return (
    <svg width="36" height="31" viewBox="0 0 36 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M11.5 24.1667L17 18.7767C16.285 18.7034 15.7533 18.6667 15.1667 18.6667C10.2717 18.6667 0.5 21.1234 0.5 26V29.6667H17L11.5 24.1667ZM15.1667 15C19.2183 15 22.5 11.7184 22.5 7.66671C22.5 3.61504 19.2183 0.333374 15.1667 0.333374C11.115 0.333374 7.83333 3.61504 7.83333 7.66671C7.83333 11.7184 11.115 15 15.1667 15Z" fill="#702CB9"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M23.3617 30.5834L17 24.1667L19.5667 21.5817L23.3617 25.3951L32.7667 15.9167L35.3333 18.5017L23.3617 30.5834Z" fill="#702CB9"/>
    </svg>
  );
}

export default CheckPerson;