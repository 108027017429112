import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 90px;
  text-align: center;
  font-family: 'Inter', sans-serif;

  .goals-price-step {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    h1.goals-price-step-title {
      color: #263238;
      font-size: 22px;
      margin-bottom: 24px;
    }
  }

  .goals-price-step-info {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;

    .goals-price-inputs {
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      grid-row-gap: 22px;
      width: 60%;
    }

    .goals-price-overview {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      span {
        margin-bottom: 24px;
        font-weight: 600;
        color: #263238;
        text-align: start;
      }

      .goals-price-overview-indicator {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-row-gap: 20px;
      }
    }
  }

`