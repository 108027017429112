import React from 'react';

// import { Container } from './styles';
import DayPicker from 'react-day-picker'
import dayPickerBR from '../../../../../common/DayPicker-pt-BR'
import Helmet from 'react-helmet';
import 'react-day-picker/lib/style.css';
import '../../../../../../node_modules/react-day-picker/lib/style.css'


function SectionPicker({ sections, handleDayClick, ...rest }) {
  return (
    <div>
      <DayPicker
        {...rest}
        className="Selectable"
        selectedDays={sections}
        month={new Date(sections[0].from)}
        canChangeMonth={false}
        months={dayPickerBR.months}
        weekdaysLong={dayPickerBR.weekdaysLong}
        weekdaysShort={dayPickerBR.weekdaysShort}
        labels={dayPickerBR.labels}
        onDayClick={handleDayClick}
        modifiers={{
          start: sections.map(section => section.from),
          end: sections.map(section => section.to)
        }}
      />
      <Helmet>
        <style>{`
          .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
            background-color: #f0f8ff !important;
            color: #4a90e2;
          }
          .Selectable .DayPicker-Day {
            border-radius: 0 !important;
          }
          .Selectable .DayPicker-Day--start {
            border-top-left-radius: 50% !important;
            border-bottom-left-radius: 50% !important;
            background-color: #4a90e2 !important;
            color: #fff;
          }
          .Selectable .DayPicker-Day--end {
            border-top-right-radius: 50% !important;
            border-bottom-right-radius: 50% !important;
            background-color: #4a90e2 !important;
            color: #fff;
          }
        `}
        </style>
      </Helmet>
    </div>
  )
}

export default SectionPicker;