import styled from 'styled-components';

export const Container = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F5F6F8;
  width: 100%;
  padding: 5px;
  border-radius: 25px;

  .sellers-list-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;

    img {
      width: 24px;
      height: 24px;
      border-radius: 24px;
    }

    strong {
      color: #37474F;
      max-width: 200px;
      margin-left: 8px;
      white-space: nowrap;
      o-text-overflow: ellipsis;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .sellers-list-action-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    strong {
      font-size: 12px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;
      color: #546E7A;
      background: #E2E4E9;
      border-radius: 3px;
      padding: 3px 4px;
      margin-right: 8px;
      cursor: pointer;

      svg {
        font-size: 16px;
        margin-right: 4px;
      }

      &:hover {
        background: #702CB9;
        color: #fff;

        svg {
          color: #fff;
        }
      }
    }
    
    button {
      width: 20px;
      height: 20px;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 0;
      background: #E2E4E9;
      color: #37474F;
      cursor: pointer;
      outline: none;

      svg {
        color: inherit;
        font-size: 18px;
        
      }

      &:hover {
        background: #27AE60;
        color: #fff;
      }
    }

    .ungoal-back-button:hover {
      background-color: #37474F !important;
      border-radius: 50%;
      padding: 1px;
    }
  }
`;
