import React from 'react'
// Libs
import PropTypes from 'prop-types'


// Styles
import './styles.scss'

// Assets
import boltIcon from '../../../../../../../../../assets/bolt.png'
import Tooltip from '../../../../Tooltip'

const CardButton = ({ 
  selected,
  children, 
  cardTitle, 
  cardDescription, 
  tooltipContent,
  buttonAction, 
  buttonText,
}) => {
  return (
    <button
      style={{borderColor: selected && '#702CB9'}}
      onClick={buttonAction} 
      className="button-container"
    >
      {children}
      <div className="button-content-info">
        <h1>{cardTitle}</h1>
        <p>{cardDescription}</p>
        { tooltipContent && 
          <>
            <strong data-tip data-for={cardTitle}>Exemplo <img src={boltIcon} alt="Icon"/></strong>
            <Tooltip id={cardTitle} content={tooltipContent} />
          </>
        }

      </div>
      <p>{buttonText}</p>
    </button>
  )
}

CardButton.propTypes = {
  selected: PropTypes.bool,
  children: PropTypes.element,
  cardTitle: PropTypes.string, 
  cardDescription: PropTypes.string, 
  tooltipText: PropTypes.string, 
  buttonAction: PropTypes.func,
  buttonText: PropTypes.string 
}

export default CardButton