import React from 'react';

// Libs
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

// MaterialUI
import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'

// Styles
const useStyles = makeStyles({
  table: {
    minWidth: 650
  }
})

function Sections({ sections }) {
  const classes = useStyles()

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Data inicial</TableCell>
            <TableCell>Data final</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sections.map(row => (
            <TableRow
              key={row._id}
            >
              <TableCell>{DateTime.fromISO(row.start).toFormat('dd/MM/yyyy')}</TableCell>
              <TableCell>{DateTime.fromISO(row.end).toFormat('dd/MM/yyyy')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

Sections.propTypes = {
  sections: PropTypes.array,
}

export default Sections;